<div class="container content">
    <page-title title="2017-18 State and National Tables"></page-title>

    <p>The 2017-18 tables are based on data collected from all of the nation’s school districts and schools—approximately 17,300 school districts and 96,300 schools.</p>

    <mat-accordion>

        <span class="has-text-weight-bold">Student Enrollment</span>
        <mat-expansion-panel>
            <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
                <mat-panel-title>
                    Tables for Enrollment
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="accordion-panel-body">
                <p class="has-text-centered is-size-6 has-text-weight-bold">Student Enrollment</p>
                <p>
                    This set of Excel files contains data for all states on overall enrollment, enrollment of students served under IDEA,
                    enrollment of students served under Section 504, and enrollment of students who are English language learners.
                    For each category, there are three spreadsheets: total students, male students, and female students.
                </p>
                <div class="columns is-gapless is-multiline">
                    <div class="column is-one-quarter has-text-centered">
                        <a href="assets\downloads\2017-2018\Student-Enrollment\All-Enrollment\Enrollment-Overall.xlsx" rel="noopener noreferrer" target="_blank">Enrollment</a>
                    </div>
                    <div class="column is-one-quarter has-text-centered">
                        <a href="assets\downloads\2017-2018\Student-Enrollment\All-Enrollment\Enrollment-IDEA.xlsx" rel="noopener noreferrer" target="_blank">IDEA enrollment</a>
                    </div>
                    <div class="column is-one-quarter has-text-centered">
                        <a href="assets\downloads\2017-2018\Student-Enrollment\All-Enrollment\Enrollment-Section-504-only.xlsx" rel="noopener noreferrer" target="_blank">Section 504 enrollment</a>
                    </div>
                    <div class="column is-one-quarter has-text-centered">
                        <a href="assets\downloads\2017-2018\Student-Enrollment\All-Enrollment\Enrollment-Limited-English-Proficient.xlsx" rel="noopener noreferrer" target="_blank">ELL Enrollment</a>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
                <mat-panel-title>
                    Preschool Enrollment
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="accordion-panel-body">
                <p class="has-text-centered is-size-6 has-text-weight-bold">Preschool Enrollment</p>
                <p>
                    This Excel file contains preschool enrollment data for all states. The file contains three spreadsheets: total children, male children, and female children.
                </p>
                <div class="columns is-gapless is-multiline">
                    <div class="column is-full has-text-centered">
                        <a href="assets\downloads\2017-2018\Student-Enrollment\Preschool-Enrollment\Preschool-Enrollment.xlsx" rel="noopener noreferrer" target="_blank">Preschool Enrollment</a>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>

        <br />

        <span class="has-text-weight-bold">School Programs</span>


        <mat-expansion-panel>
            <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
                <mat-panel-title>
                    English Language Instruction Educational Programs
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="accordion-panel-body">
                <p class="has-text-centered is-size-6 has-text-weight-bold">English Language Instruction Program Enrollment</p>
                <p>This Excel file contains data for English language learners enrolled in English language instruction educational programs for all states. The file contains three spreadsheets: total children, male children, and female children.</p>
                <div class="columns is-gapless is-multiline">
                    <div class="column is-full has-text-centered">
                        <a href="assets\downloads\2017-2018\School-Programs\English-Language-Instruction-Education-Programs\English-Language-Instruction-Programs.xlsx" rel="noopener noreferrer" target="_blank">English language instruction educational program enrollment</a>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel>
            <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
                <mat-panel-title>
                    Gifted and Talented
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="accordion-panel-body">
                <p class="has-text-centered is-size-6 has-text-weight-bold">Gifted and Talented Enrollment</p>
                <p>
                    This Excel file contains data on students enrolled in gifted and talented programs for all states. The file contains three spreadsheets: total students, male students, and female students.
                </p>
                <div class="columns is-gapless is-multiline">
                    <div class="column is-full has-text-centered">
                        <a href="assets\downloads\2017-2018\School-Programs\Gifted-and-Talented\Gifted-Talented-Enrollment.xlsx" rel="noopener noreferrer" target="_blank">Gifted and talented enrollment</a>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel>
            <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
                <mat-panel-title>
                    International Baccalaureate (IB)
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="accordion-panel-body">
                <p class="has-text-centered is-size-6 has-text-weight-bold">
                    International Baccalaureate Enrollment
                </p>
                <p>
                    This Excel file contains data on student enrollment in International Baccalaureate (IB) programs for all states.
                    The file contains three spreadsheets: total students, male students, and female students.

                </p>
                <div class="columns is-gapless">
                    <div class="column is-full has-text-centered">
                        <a href="assets\downloads\2017-2018\School-Programs\International-Baccalaureate-(IB)\IB-Enrollment-by-state.xlsx" rel="noopener noreferrer" target="_blank">IB Enrollment</a>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>

        <br />

        <span class="has-text-weight-bold">
            Course Enrollment
        </span>
        <mat-expansion-panel>
            <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
                <mat-panel-title>
                    Advanced Placement Courses
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="accordion-panel-body">
                <p class="has-text-centered is-size-6 has-text-weight-bold">
                    Advanced Placement Total Enrollment
                </p>
                <p>
                    This Excel file contains student enrollment in Advanced Placement for all states.
                    There are three spreadsheets: total students, male students, and female students.
                </p>
                <div class="has-text-centered">
                    <a href="assets\downloads\2017-2018\Course-Enrollment\Advanced-Placement-(courses)\Advanced-Placement-Enrollment.xlsx" rel="noopener noreferrer" target="_blank">Advanced placement</a>
                </div>

                <br /><br />
                <p class="has-text-centered is-size-6 has-text-weight-bold">
                    Advanced Placement Enrollment by Subject
                </p>
                <p>
                    This set of Excel files contains student enrollment data for all states, presented by Advanced Placement (AP) subject.
                    For each AP subject, there are three spreadsheets: total students, male students, and female students.
                </p>
                <div class="columns is-gapless is-multiline">
                    <div class="column is-one-third has-text-centered">
                        <a href="assets\downloads\2017-2018\Course-Enrollment\Advanced-Placement-(courses)\Advanced-Placement-Mathematics-Enrollment.xlsx" rel="noopener noreferrer" target="_blank">AP mathematics</a>
                    </div>
                    <div class="column is-one-third has-text-centered">
                        <a href="assets\downloads\2017-2018\Course-Enrollment\Advanced-Placement-(courses)\Advanced-Placement-Science-Enrollment.xlsx" rel="noopener noreferrer" target="_blank">AP science</a>
                    </div>
                    <div class="column is-one-third has-text-centered">
                        <a href="assets\downloads\2017-2018\Course-Enrollment\Advanced-Placement-(courses)\Advanced-Placement-Other-Subjects-Enrollment.xlsx" rel="noopener noreferrer" target="_blank">AP other subjects</a>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel>
            <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
                <mat-panel-title>
                    Mathematics and Science
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="accordion-panel-body">
                <p class="has-text-centered is-size-6 has-text-weight-bold">
                    Schools Offering Mathematics and Science Courses
                </p>
                <p>
                    This set of Excel files contains data for schools offering courses in mathematics and science for all states. Each file contains one spreadsheet for total schools.
                </p>
                <div class="columns is-gapless is-multiline">
                    <div class="column has-text-centered">
                        <a href="assets\downloads\2017-2018\Course-Enrollment\Mathematics-and-Science\Middle-Schools-Offering-Mathematics-Classes.xlsx" rel="noopener noreferrer" target="_blank">Middle schools offering mathematics courses</a>
                    </div>
                    <div class="column has-text-centered">
                        <a href="assets\downloads\2017-2018\Course-Enrollment\Mathematics-and-Science\High-Schools-Offering-Mathematics-Classes.xlsx" rel="noopener noreferrer" target="_blank">High schools offering mathematics courses</a>
                    </div>
                    <div class="column has-text-centered">
                        <a href="assets\downloads\2017-2018\Course-Enrollment\Mathematics-and-Science\High-Schools-Offering-Science-Classes.xlsx" rel="noopener noreferrer" target="_blank">High schools offering science courses</a>
                    </div>
                </div>

                <br /><br />

                <p class="has-text-centered is-size-6 has-text-weight-bold">
                    Mathematics Tables by Course
                </p>
                <p>
                    This set of Excel files contains student enrollment data for all states, presented by mathematics course.
                    For each mathematics course, except Algebra I Grade 7 and Geometry Grade 8, there are three spreadsheets:
                    total students, male students, and female students. For each Algebra I Grade 7 and Geometry Grade 8 course,
                    there is one spreadsheet for total students.
                </p>
                <div class="columns is-multiline is-gapless">
                    <div class="column is-one-fourth has-text-centered">
                        <a href="assets\downloads\2017-2018\Course-Enrollment\Mathematics-and-Science\Enrollment-in-Algebra-I_Grade-7.xlsx" rel="noopener noreferrer" target="_blank">Algebra I Grade 7</a>
                    </div>
                    <div class="column is-one-fourth has-text-centered">
                        <a href="assets\downloads\2017-2018\Course-Enrollment\Mathematics-and-Science\Enrollment-in-Algebra-I_Grade-8.xlsx" rel="noopener noreferrer" target="_blank">Algebra I Grade 8</a>
                    </div>
                    <div class="column is-one-fourth has-text-centered">
                        <a href="assets\downloads\2017-2018\Course-Enrollment\Mathematics-and-Science\Enrollment-in-Algebra-I_Grades-9-10.xlsx" rel="noopener noreferrer" target="_blank">Algebra I Grades 9-10</a>
                    </div>
                    <div class="column is-one-fourth has-text-centered">
                        <a href="assets\downloads\2017-2018\Course-Enrollment\Mathematics-and-Science\Enrollment-in-Algebra-I_Grades-11-12.xlsx" rel="noopener noreferrer" target="_blank">Algebra I Grades 11-12</a>
                    </div>
                </div>
                <div class="columns is-multiline is-gapless">
                    <div class="column is-one-fourth has-text-centered">
                        <a href="assets\downloads\2017-2018\Course-Enrollment\Mathematics-and-Science\Enrollment-in-Geometry-in-Grade-8.xlsx" rel="noopener noreferrer" target="_blank">Geometry Grade 8</a>
                    </div>
                    <div class="column is-one-fourth has-text-centered">
                        <a href="assets\downloads\2017-2018\Course-Enrollment\Mathematics-and-Science\Enrollment-in-Geometry-in-High-School.xlsx" rel="noopener noreferrer" target="_blank">Geometry in high school</a>
                    </div>
                    <div class="column is-one-fourth has-text-centered">
                        <a href="assets\downloads\2017-2018\Course-Enrollment\Mathematics-and-Science\Enrollment-in-Algebra-II.xlsx" rel="noopener noreferrer" target="_blank">Algebra II</a>
                    </div>
                    <div class="column is-one-fourth has-text-centered">
                        <a href="assets\downloads\2017-2018\Course-Enrollment\Mathematics-and-Science\Enrollment-in-Advanced-Mathematics.xlsx" rel="noopener noreferrer" target="_blank">Advanced mathematics</a>
                    </div>
                </div>
                <div class="columns is-multiline is-gapless has-text-centered">
                    <div class="column">
                        <a href="assets\downloads\2017-2018\Course-Enrollment\Mathematics-and-Science\Enrollment-in-Calculus.xlsx" rel="noopener noreferrer" target="_blank">Calculus</a>
                    </div>
                </div>

                <br /><br />
                <p class="has-text-centered is-size-6 has-text-weight-bold">
                    Science Tables by Course
                </p>
                <p style="padding: 5px;">
                    This set of Excel files contains student enrollment data for all states, presented by science course.
                    For each science course, there are three spreadsheets: total students, male students, and female students
                </p>
                <div class="columns is-multiline is-gapless">
                    <div class="column is-one-third has-text-centered">
                        <a href="assets\downloads\2017-2018\Course-Enrollment\Mathematics-and-Science\Enrollment-in-Biology.xlsx" rel="noopener noreferrer" target="_blank">Biology</a>
                    </div>
                    <div class="column is-one-third has-text-centered">
                        <a href="assets\downloads\2017-2018\Course-Enrollment\Mathematics-and-Science\Enrollment-in-Chemistry.xlsx" rel="noopener noreferrer" target="_blank">Chemistry</a>
                    </div>
                    <div class="column is-one-third has-text-centered">
                        <a href="assets\downloads\2017-2018\Course-Enrollment\Mathematics-and-Science\Enrollment-in-Physics.xlsx" rel="noopener noreferrer" target="_blank">Physics</a>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>

        <br />

        <span class="has-text-weight-bold">
            College Preparatory Exams
        </span>
        <mat-expansion-panel>
            <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
                <mat-panel-title>
                    Advanced Placement Exams
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="accordion-panel-body">
                <p class="has-text-centered is-size-6 has-text-weight-bold">
                    Advanced Placement Exam Taking
                </p>
                <p>
                    This set of Excel files contains data on Advanced Placement exam taking for all states.
                    Each file contains three spreadsheets: total students, male students, and female students.
                </p>
                <div class="columns is-gapless is-multiline has-text-centered">
                    <div class="column is-half">
                        <a href="assets\downloads\2017-2018\College-Prepatory-Exams\Advanced-Placement-Exams\Advanced-Placement-Participation-by-state_Did-Not-Take-Exam.xlsx" rel="noopener noreferrer" target="_blank">Did not take AP Exam</a>
                    </div>
                    <div class="column is-half">
                        <a href="assets\downloads\2017-2018\College-Prepatory-Exams\Advanced-Placement-Exams\Advanced-Placement-Participation-by-state_Took-Exam.xlsx" rel="noopener noreferrer" target="_blank">Took AP Exam</a>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel>
            <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
                <mat-panel-title>
                    SAT/ACT
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="accordion-panel-body">
                <p class="has-text-centered is-size-6 has-text-weight-bold">
                    SAT/ACT Participation
                </p>
                <p>
                    This Excel file contains SAT/ACT student participation data for all states.
                    The file contains three spreadsheets: total students, male students, and female students.
                </p>
                <div class="columns is-gapless">
                    <div class="column is-full has-text-centered">
                        <a href="assets\downloads\2017-2018\College-Prepatory-Exams\SAT-ACT\SAT-or-ACT-Participation-by-state.xlsx" rel="noopener noreferrer" target="_blank">SAT/ACT participation</a>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>

        <br />

        <span class="has-text-weight-bold">
            Student Retention
        </span>
        <mat-expansion-panel>
            <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
                <mat-panel-title>
                    Retention
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="accordion-panel-body">
                <p class="has-text-centered is-size-6 has-text-weight-bold">
                    Retention by Grade
                </p>
                <p>
                    This set of Excel files contains student retention data for all states, presented by grade.
                    For each grade, there are three spreadsheets: total students, male students, and female students.
                </p>
                <div class="columns is-multiline is-gapless">
                    <div class="column is-one-third has-text-centered">
                        <a href="assets\downloads\2017-2018\Student-Retention\Retention-(by-grade)\Retention-in-Kindergarten.xlsx" rel="noopener noreferrer" target="_blank">Kindergarten</a>
                    </div>
                    <div class="column is-one-third has-text-centered">
                        <a href="assets\downloads\2017-2018\Student-Retention\Retention-(by-grade)\Retention-in-Grade-1.xlsx" rel="noopener noreferrer" target="_blank">Grade 1</a>
                    </div>
                    <div class="column is-one-third has-text-centered">
                        <a href="assets\downloads\2017-2018\Student-Retention\Retention-(by-grade)\Retention-in-Grade-2.xlsx" rel="noopener noreferrer" target="_blank">Grade 2</a>
                    </div>
                </div>
                <div class="columns is-multiline is-gapless">
                    <div class="column is-one-third has-text-centered">
                        <a href="assets\downloads\2017-2018\Student-Retention\Retention-(by-grade)\Retention-in-Grade-3.xlsx" rel="noopener noreferrer" target="_blank">Grade 3</a>
                    </div>
                    <div class="column is-one-third has-text-centered">
                        <a href="assets\downloads\2017-2018\Student-Retention\Retention-(by-grade)\Retention-in-Grade-4.xlsx" rel="noopener noreferrer" target="_blank">Grade 4</a>
                    </div>
                    <div class="column is-one-third has-text-centered">
                        <a href="assets\downloads\2017-2018\Student-Retention\Retention-(by-grade)\Retention-in-Grade-5.xlsx" rel="noopener noreferrer" target="_blank">Grade 5</a>
                    </div>
                </div>
                <div class="columns is-multiline is-gapless">
                    <div class="column is-one-third has-text-centered">
                        <a href="assets\downloads\2017-2018\Student-Retention\Retention-(by-grade)\Retention-in-Grade-6.xlsx" rel="noopener noreferrer" target="_blank">Grade 6</a>
                    </div>
                    <div class="column is-one-third has-text-centered">
                        <a href="assets\downloads\2017-2018\Student-Retention\Retention-(by-grade)\Retention-in-Grade-7.xlsx" rel="noopener noreferrer" target="_blank">Grade 7</a>
                    </div>
                    <div class="column is-one-third has-text-centered">
                        <a href="assets\downloads\2017-2018\Student-Retention\Retention-(by-grade)\Retention-in-Grade-8.xlsx" rel="noopener noreferrer" target="_blank">Grade 8</a>
                    </div>
                </div>
                <div class="columns is-multiline is-gapless">
                    <div class="column is-one-third has-text-centered">
                        <a href="assets\downloads\2017-2018\Student-Retention\Retention-(by-grade)\Retention-in-Grade-9.xlsx" rel="noopener noreferrer" target="_blank">Grade 9</a>
                    </div>
                    <div class="column is-one-third has-text-centered">
                        <a href="assets\downloads\2017-2018\Student-Retention\Retention-(by-grade)\Retention-in-Grade-10.xlsx" rel="noopener noreferrer" target="_blank">Grade 10</a>
                    </div>
                    <div class="column is-one-third has-text-centered">
                        <a href="assets\downloads\2017-2018\Student-Retention\Retention-(by-grade)\Retention-in-Grade-11.xlsx" rel="noopener noreferrer" target="_blank">Grade 11</a>
                    </div>
                </div>
                <div class="columns is-multiline is-gapless">
                    <div class="column is-one-third has-text-centered">
                        <a href="assets\downloads\2017-2018\Student-Retention\Retention-(by-grade)\Retention-in-Grade-12.xlsx" rel="noopener noreferrer" target="_blank">Grade 12</a>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>

        <br />

        <span class="has-text-weight-bold">School Staff</span>
        <mat-expansion-panel>
            <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
                <mat-panel-title>
                    Classroom Teachers
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="accordion-panel-body">
                <p class="has-text-centered is-size-6 has-text-weight-bold">Classroom Teachers</p>
                <p>
                    These Excel files contain data on classroom teachers, including full-time equivalency counts, certification,
                    and years of experience for all states. Each file contains one spreadsheet for total teachers.
                </p>
                <div class="columns is-gapless">
                    <div class="column is-full has-text-centered">
                        <a href="assets\downloads\2017-2018\School-Staff\Classroom-Teachers\Teacher-Certification-and-Years-of-Experience.xlsx" rel="noopener noreferrer" target="_blank">
                            Teacher Certification and Years of Experience
                        </a>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel>
            <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
                <mat-panel-title>
                    School Support Staff
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="mat-expansion-panel-content-padding accordion-panel-body">
                <p class="has-text-centered is-size-6 has-text-weight-bold">
                    School Support Staff
                </p>
                <p>
                    This set of Excel files contains data on full-time equivalent support staff, and schools with support staff,
                    by type of staff and for all states. Each file contains one spreadsheet for elementary, middle, or high schools.
                </p>
                <div class="columns is-gapless">
                    <div class="column is-full has-text-centered">
                        <a href="assets\downloads\2017-2018\School-Staff\School-Support-Staff\Counselors_High-School.xlsx" rel="noopener noreferrer" target="_blank">
                            Counselors High School
                        </a>
                    </div>
                    <!--<div class="column is-one-third">
                        <a href="assets/downloads/2015-2016/FTE-Support-Staff-Middle-School.xlsx" rel="noopener noreferrer" target="_blank">
                            Counselors Middle School
                        </a>
                    </div>
                    <div class="column is-one-third">
                        <a href="assets/downloads/2015-2016/FTE-Support-Staff-High-School.xlsx" rel="noopener noreferrer" target="_blank">
                            Counselors Elementary School
                        </a>
                    </div>-->
                </div>
            </div>
        </mat-expansion-panel>

        <br />

        <span class="has-text-weight-bold">Discipline</span>
        <mat-expansion-panel>
            <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
                <mat-panel-title>
                    Discipline
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="accordion-panel-body">
                <p class="has-text-centered is-size-6 has-text-weight-bold">
                    Discipline by Discipline Type
                </p>
                <p>
                    This set of Excel files contains data for all states, presented by discipline type.
                    For each discipline type, there are nine spreadsheets: total students with and without disabilities;
                    male students with and without disabilities; female students with and without disabilities;
                    total students with disabilities; male students with disabilities; female students with disabilities;
                    total students without disabilities; male students without disabilities; and female students without diabilities.
                </p>
                <div class="columns is-gapless is-multiline">
                    <div class="column is-half has-text-centered">
                        <a href="assets\downloads\2017-2018\Discipline\Discipline\Corporal-Punishment\Corporal-Punishment_by-Disability-and-no.xlsx" rel="noopener noreferrer" target="_blank">Corporal punishment</a>
                    </div>
                    <div class="column is-half has-text-centered">
                        <a href="assets\downloads\2017-2018\Discipline\Discipline\One-or-More-In-School-Suspensions\One-or-More-In-School-Suspensions_by-disability-and-no.xlsx" rel="noopener noreferrer" target="_blank">In-school suspension</a>
                    </div>
                </div>
                <div class="columns is-gapless is-multiline">
                    <div class="column is-half has-text-centered">
                        <a href="assets\downloads\2017-2018\Discipline\Discipline\One-Out-of-School Suspensions\One-Out-of-School-Suspensions_by-disability-and-no.xlsx" rel="noopener noreferrer" target="_blank">One out-of-school suspension</a>
                    </div>
                    <div class="column is-half has-text-centered">
                        <a href="assets\downloads\2017-2018\Discipline\Discipline\More-Than-One-Oos-Suspensions\More-Than-One-OoS-Suspensions_by-disability-and-no.xlsx" rel="noopener noreferrer" target="_blank">More than one out-of-school suspension</a>
                    </div>
                </div>
                <div class="columns is-gapless is-multiline">
                    <div class="column is-half has-text-centered">
                        <a href="assets\downloads\2017-2018\Discipline\Discipline\One-or-More-Oos-Suspensions\One-or-More-OoS-Suspensions_by-disability-and-no.xlsx" rel="noopener noreferrer" target="_blank">One or more out-of-school suspensions</a>
                    </div>
                    <div class="column is-half has-text-centered">
                        <a href="assets\downloads\2017-2018\Discipline\Discipline\Expulsions-with-ed-service\Expulsions-with-ed-service_by-disability-and-no.xlsx" rel="noopener noreferrer" target="_blank">Expulsions with educational services</a>
                    </div>
                </div>
                <div class="columns is-gapless is-multiline">
                    <div class="column is-half has-text-centered">
                        <a href="assets\downloads\2017-2018\Discipline\Discipline\Expulsions-without-ed-service\Expulsions-without-ed-service_by-disability-and-no.xlsx" rel="noopener noreferrer" target="_blank">Expulsions without educational services</a>
                    </div>
                    <div class="column is-half has-text-centered">
                        <a href="assets\downloads\2017-2018\Discipline\Discipline\Expulsions-w-and-wo-ed-service\Expulsions-w-and-wo-ed-service_by-disability-and-no.xlsx" rel="noopener noreferrer" target="_blank">Expulsions with and without educational services</a>
                    </div>
                </div>
                <br /><br />

                <p class="has-text-centered is-size-6 has-text-weight-bold">
                    Days Missed Due to Out-of-School Suspensions
                </p>
                <p>This Excel file contains data on school days missed by students due to out-of-school-suspensions, for all states.</p>
                <div class="columns is-gapless is-multiline has-text-centered">
                    <div class="column">
                        <a href="assets\downloads\2017-2018\Discipline\Discipline\Days-missed-due-to-OoS-Suspensions.xlsx" rel="noopener noreferrer" target="_blank">Days missed due to out-of-school suspensions</a>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
                <mat-panel-title>
                    Preschool Discipline
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="accordion-panel-body">
                <p class="has-text-centered is-size-6 has-text-weight-bold">
                    Preschool Discipline by Discipline Type
                </p>
                <p>
                    This set of Excel files contains data for all states, presented by discipline type. For each discipline type,
                    there are three spreadsheets: total preschool students, male preschool students, and female preschool students.
                </p>
                <div class="columns is-gapless is-multiline">
                    <div class="column is-half has-text-centered">
                        <a href="assets\downloads\2017-2018\Discipline\Discipline\Preschool-Corporal-Punishment.xlsx" rel="noopener noreferrer" target="_blank">Preschool corporal punishment</a>
                    </div>
                    <div class="column is-half has-text-centered">
                        <a href="assets\downloads\2017-2018\Discipline\Discipline\Preschool-One-Out-of-School-Suspension.xlsx" rel="noopener noreferrer" target="_blank">Preschool one out-of-school suspension</a>
                    </div>
                </div>
                <div class="columns is-gapless is-multiline">
                    <div class="column is-half has-text-centered">
                        <a href="assets\downloads\2017-2018\Discipline\Discipline\Preschool-More-than-One-Out-of-School-Suspension.xlsx" rel="noopener noreferrer" target="_blank">Preschool more than one out-of-school suspension</a>
                    </div>
                    <div class="column is-half has-text-centered">
                        <a href="assets\downloads\2017-2018\Discipline\Discipline\Preschool-One-or-More-Out-of-School-Suspensions.xlsx" rel="noopener noreferrer" target="_blank">Preschool one or more out-of-school suspensions</a>
                    </div>
                </div>
                <div class="columns is-gapless is-multiline">
                    <div class="column is-half has-text-centered">
                        <a href="assets\downloads\2017-2018\Discipline\Discipline\Preschool-Expulsions.xlsx" rel="noopener noreferrer" target="_blank">Preschool expulsions</a>
                    </div>
                </div>

                <br /><br />

                      <p class="has-text-centered is-size-6 has-text-weight-bold">
                          Preschool Incidents of Corporal Punishment and Out-of-School Suspensions
                      </p>
                      <p>
                          This Excel file contains data on incidents of preschool corporal punishment and preschool out-of-school suspension for all states.
                      </p>
                <div class="columns is-gapless is-multiline has-text-centered">
                    <div class="column">
                        <a href="assets\downloads\2017-2018\Discipline\Discipline\Preschool-Discipline.xlsx" rel="noopener noreferrer" target="_blank">Incidents of Preschool corporal punishment and suspension</a>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>

        <br />

        <span class="has-text-weight-bold">
            Restraint and Seclusion
        </span>
        <mat-expansion-panel>
            <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
                <mat-panel-title>
                    Restraint and Seclusion
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="accordion-panel-body">
                <p class="has-text-centered is-size-6 has-text-weight-bold">Restraint and Seclusion</p>
                <p>
                    This set of Excel files contains mechanical restraint, physical restraint, and seclusion data on students for all states.
                    Each file contains nine spreadsheets: total students served under IDEA and not served under IDEA; male students served under
                    IDEA and not served under IDEA; female students served under IDEA and not served under IDEA; total students served under IDEA;
                    male students served under IDEA; female students served under IDEA; total students not served under IDEA; male students
                    not served under IDEA; and female students not served under IDEA.
                </p>
                <div class="columns is-gapless is-multiline">
                    <div class="column is-one-third has-text-centered">
                        <a href="assets\downloads\2017-2018\Restraint-and-Seclusion\Restraint-and-Seclusion\Restraint-or-Seclusion.Students-under-IDEA-or-not_mech.xlsx" rel="noopener noreferrer" target="_blank">Mechanical restraint IDEA/Non IDEA</a>
                    </div>
                    <div class="column is-one-third has-text-centered">
                        <a href="assets\downloads\2017-2018\Restraint-and-Seclusion\Restraint-and-Seclusion\Restraint-or-Seclusion.Students-under-IDEA-or-not_phys.xlsx" rel="noopener noreferrer" target="_blank">Physical restraint IDEA/Non IDEA</a>
                    </div>
                    <div class="column is-one-third has-text-centered">
                        <a href="assets\downloads\2017-2018\Restraint-and-Seclusion\Restraint-and-Seclusion\Restraint-or-Seclusion.Students-under-IDEA-or-not_secl.xlsx" rel="noopener noreferrer" target="_blank">Seclusion IDEA/Non IDEA</a>
                    </div>
                </div>
                <div class="columns is-gapless is-multiline">
                    <div class="column is-one-third has-text-centered">
                        <a href="assets\downloads\2017-2018\Restraint-and-Seclusion\Restraint-and-Seclusion\Restraint-or-Seclusion.Students-served-under-IDEA_mech.xlsx" rel="noopener noreferrer" target="_blank">Mechanical restraint IDEA</a>
                    </div>
                    <div class="column is-one-third has-text-centered">
                        <a href="assets\downloads\2017-2018\Restraint-and-Seclusion\Restraint-and-Seclusion\Restraint-or-Seclusion.Students-served-under-IDEA_phys.xlsx" rel="noopener noreferrer" target="_blank">Physical restraint IDEA</a>
                    </div>
                    <div class="column is-one-third has-text-centered">
                        <a href="assets\downloads\2017-2018\Restraint-and-Seclusion\Restraint-and-Seclusion\Restraint-or-Seclusion.Students-served-under-IDEA_seclusion.xlsx" rel="noopener noreferrer" target="_blank">Seclusion IDEA</a>
                    </div>
                </div>
                <div class="columns is-gapless is-multiline">
                    <div class="column is-one-third has-text-centered">
                        <a href="assets\downloads\2017-2018\Restraint-and-Seclusion\Restraint-and-Seclusion\Restraint-or-Seclusion.Students-not-served-under-IDEA_mech.xlsx" rel="noopener noreferrer" target="_blank">Mechanical restraint Non IDEA</a>
                    </div>
                    <div class="column is-one-third has-text-centered">
                        <a href="assets\downloads\2017-2018\Restraint-and-Seclusion\Restraint-and-Seclusion\Restraint-or-Seclusion.Students-not-served-under-IDEA_phys.xlsx" rel="noopener noreferrer" target="_blank">Physical restraint Non IDEA</a>
                    </div>
                    <div class="column is-one-third has-text-centered">
                        <a href="assets\downloads\2017-2018\Restraint-and-Seclusion\Restraint-and-Seclusion\Restraint-or-Seclusion.Students-not-served-under-IDEA_seclusion.xlsx" rel="noopener noreferrer" target="_blank">Seclusion Non IDEA</a>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>

        <br />

        <span class="has-text-weight-bold">
            School Climate
        </span>
        <mat-expansion-panel>
            <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
                <mat-panel-title>
                    Harassment or Bullying
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="accordion-panel-body">
                <p class="has-text-centered is-size-6 has-text-weight-bold">Harassment or Bullying by Basis Category</p>
                <p>
                    This set of Excel files contains data on students reported as harassed or bullied or disciplined for
                    harassment or bullying on the basis of sex, race, or disability category for all states. Each file contains three spreadsheets:
                    total students, male students, and female students.
                </p>

                <div class="columns is-gapless is-multiline">
                    <div class="column is-one-third has-text-centered">
                        <a href="assets\downloads\2017-2018\School-Climate\Harassment-or-Bullying\Harassment-Bullying-on-basis-of-sex_reported.xlsx" rel="noopener noreferrer" target="_blank">On basis of sex - reported</a>
                    </div>
                    <div class="column is-one-third has-text-centered">
                        <a href="assets\downloads\2017-2018\School-Climate\Harassment-or-Bullying\Harassment-Bullying-on-basis-of-race_reported.xlsx" rel="noopener noreferrer" target="_blank">On basis of race - reported</a>
                    </div>
                    <div class="column is-one-third has-text-centered">
                        <a href="assets\downloads\2017-2018\School-Climate\Harassment-or-Bullying\Harassment-Bullying-on-basis-of-disability_reported.xlsx" rel="noopener noreferrer" target="_blank">On basis of disability - reported</a>
                    </div>
                </div>
                <div class="columns is-gapless is-multiline">
                    <div class="column is-one-third has-text-centered">
                        <a href="assets\downloads\2017-2018\School-Climate\Harassment-or-Bullying\Harassment-Bullying-on-basis-of-sex_disciplined.xlsx" rel="noopener noreferrer" target="_blank">On basis of sex - disciplined</a>
                    </div>
                    <div class="column is-one-third has-text-centered">
                        <a href="assets\downloads\2017-2018\School-Climate\Harassment-or-Bullying\Harassment-Bullying-on-basis-of-race_disciplined.xlsx" rel="noopener noreferrer" target="_blank">On basis of race - disciplined</a>
                    </div>
                    <div class="column is-one-third has-text-centered">
                        <a href="assets\downloads\2017-2018\School-Climate\Harassment-or-Bullying\Harassment-Bullying-on-basis-of-disability_discplined.xlsx" rel="noopener noreferrer" target="_blank">On basis of disability - disciplined</a>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
                <mat-panel-title>
                    Arrests
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="accordion-panel-body">
                <p class="has-text-centered is-size-6 has-text-weight-bold">
                    Referred to Law Enforcement
                </p>
                <p>
                    This Excel file contains data on student referrals to law enforcement by disability for all states.
                </p>

                <div class="columns is-gapless is-multiline">
                    <div class="column is-one-third has-text-centered">
                        <a href="assets\downloads\2017-2018\School-Climate\Arrests\Referred-to-Law-Enforcement\Referred-to-Law-Enforcement_by-disability.xlsx" rel="noopener noreferrer" target="_blank">
                            Referred to Law Enforcement by disability
                        </a>
                    </div>
                    <div class="column is-one-third has-text-centered">
                        <a href="assets\downloads\2017-2018\School-Climate\Arrests\Referred-to-Law-Enforcement\Referred-to-Law-Enforcement_by-disability-and-no.xlsx" rel="noopener noreferrer" target="_blank">
                            Referred to Law Enforcement with and without disability
                        </a>
                    </div>
                    <div class="column is-one-third has-text-centered">
                        <a href="assets\downloads\2017-2018\School-Climate\Arrests\Referred-to-Law-Enforcement\Referred-to-Law-Enforcement_by-no-disability.xlsx" rel="noopener noreferrer" target="_blank">
                            Referred to Law Enforcement without disability
                        </a>
                    </div>
                </div>
                <p class="has-text-centered is-size-6 has-text-weight-bold">
                    School-Related Arrests
                </p>
                <p>
                    This Excel file contains data on student-related arrests by disability for all states.
                </p>

                <div class="columns is-gapless is-multiline">
                    <div class="column is-one-third has-text-centered">
                        <a href="assets\downloads\2017-2018\School-Climate\Arrests\School-Related-Arrest\School-Related-Arrest_by-disability.xlsx" rel="noopener noreferrer" target="_blank">
                            School-Related Arrests by disability
                        </a>
                    </div>
                    <div class="column is-one-third has-text-centered">
                        <a href="assets\downloads\2017-2018\School-Climate\Arrests\School-Related-Arrest\School-Related-Arrest_by-disability-and-no.xlsx" rel="noopener noreferrer" target="_blank">
                            School-Related Arrests with and without disability
                        </a>
                    </div>
                    <div class="column is-one-third has-text-centered">
                        <a href="assets\downloads\2017-2018\School-Climate\Arrests\School-Related-Arrest\School-Related-Arrest_by-no-disability.xlsx" rel="noopener noreferrer" target="_blank">
                            School-Related Arrests without disability
                        </a>
                    </div>
                </div>


            </div>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
                <mat-panel-title>
                    Offenses
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="accordion-panel-body">
                <p class="has-text-centered is-size-6 has-text-weight-bold">
                    Incidents of Offenses
                </p>
                <p>
                    This Excel file contains data on incidents of offenses by type of offense for all states.
                </p>
                <div class="columns is-gapless">
                    <div class="column is-full has-text-centered">
                        <a href="assets\downloads\2017-2018\School-Climate\Offenses\Offenses.xlsx" rel="noopener noreferrer" target="_blank">Offenses</a>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>



    </mat-accordion>

    <document-viewer docType="PDF"></document-viewer>
</div>
