import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-accessibility-statement',
  templateUrl: './accessibility-statement.component.html',
  styleUrls: ['./accessibility-statement.component.scss']
})
export class AccessibilityStatementComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
