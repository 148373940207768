<nav class="navbar has-shadow is-fixed-top is-justify-content-center" style="z-index: 100" role="navigation"
    aria-label="Civil Rights Data collection">
    <div class="navbar-container navbar" role="menubar">
        <div class="navbar-brand" role="none">
            <a class="navbar-item" routerLink="/" role="menuitem" (click)="toggleMobileNavbar(true)">
                <img src="assets/images/logo_new.png" alt="Department of Education logo" style="max-height: 50px"
                    viewbox="0 0 75 75" />
            </a>
            <div class="navbar-item logo-type" role="menuitem">
                <div routerLink="/" class="logo-title" (click)="toggleMobileNavbar(true)" tabindex="-1"
                    aria-hidden="true">
                    Civil Rights Data Collection
                    <br>
                    Office for Civil Rights
                    <br>
                    <span class="logo-subtitle">U.S. Department of Education</span>
                </div>
            </div>
        </div>

        <div *ngIf="isMobile" class="navbar-menu" role="none">
            <div class="navbar-end">
                <button (click)="toggleMobileNavbar()" role="menuitem" mat-icon-button aria-label="Mobile menu toggle"
                    aria-haspopup="true" [attr.aria-expanded]="isMobileMenuVisible">
                    <mat-icon *ngIf="!isMobileMenuVisible">menu</mat-icon>
                    <mat-icon *ngIf="isMobileMenuVisible">close</mat-icon>
                </button>

                <button *ngIf="isLoggedIn" role="menuitem" mat-icon-button aria-label="Mobile show account"
                    [matMenuTriggerFor]="accountMenu">
                    <mat-icon>account_circle</mat-icon>
                </button>
                <mat-menu #accountMenu="matMenu" class="user-account-menu">
                    <div><strong>Name:</strong>Ben</div>
                    <button mat-menu-item>
                        <mat-icon>login</mat-icon>
                        <span>login</span>
                    </button>
                    <button mat-menu-item disabled>
                        <mat-icon>logout</mat-icon>
                        <span>Logout</span>
                    </button>
                </mat-menu>

                <button *ngIf="!isLoggedIn" mat-icon-button role="menuitem" routerLink="/data-tool-login-landing"
                    aria-haspopup="true" [attr.aria-expanded]="isMobileMenuVisible">
                    <!--                        <mat-icon>no_accounts</mat-icon>-->
                    <mat-icon>login</mat-icon>
                </button>


            </div>
        </div>

        <div id="navbarMenu" *ngIf="!isMobile" #navMenu class="navbar-menu">
            <ul class="navbar-end" role="menubar">
                <li class="navbar-item has-dropdown is-hoverable main-item" role="none"
                    (mouseenter)="expand('about', 'true')" (mouseleave)="expand('about', 'false')"
                    (keydown)="onKeyDown($event, 'about')">
                    <a #about id="about" class="navbar-item" role="menuitem" aria-haspopup="true" aria-expanded="false"
                        routerLink="/about" style="text-decoration: none;" (focus)="expand('about', 'true')"
                        (blur)="expand('about', 'false')" onclick="this.blur()">
                        About
                    </a>
                    <ul #aboutSubMenu class="navbar-dropdown" role="menu" aria-label="About">
                        <li role="none">
                            <a class="navbar-item dropdownSelection" role="menuitem" routerLink="/about/ocr"
                                style="text-decoration: none;" (focus)="expand('about', 'true')"
                                (blur)="expand('about', 'false')" (keydown)="onKeyDown($event, 'aboutSubMenu')"
                                onclick="this.blur()" tabindex="-1">
                                Office For Civil Rights
                            </a>
                        </li>

                        <li role="none">
                            <a class="navbar-item dropdownSelection" role="menuitem" routerLink="/about/crdc"
                                style="text-decoration: none;" (focus)="expand('about', 'true')"
                                (blur)="expand('about', 'false')" (keydown)="onKeyDown($event, 'aboutSubMenu')"
                                onclick="this.blur()" tabindex="-1">
                                Civil Rights Data Collection
                            </a>
                        </li>

                        <li role="none">
                            <a class="navbar-item dropdownSelection" role="menuitem" routerLink="/about/faqs"
                                style="text-decoration: none;" (focus)="expand('about', 'true')"
                                (blur)="expand('about', 'false')" (keydown)="onKeyDown($event, 'aboutSubMenu')"
                                onclick="this.blur()" tabindex="-1">
                                Frequently Asked Questions
                            </a>
                        </li>

                    </ul>
                </li>
                <li class="navbar-item has-dropdown is-hoverable main-item" role="none"
                    (mouseenter)="expand('exploreData', 'true')" (mouseleave)="expand('exploreData', 'false')">
                    <a #exploreData id="exploreData" class="navbar-item" role="menuitem" aria-haspopup="true"
                        aria-expanded="false" routerLink="/view" style="text-decoration: none;"
                        (focus)="expand('exploreData', 'true')" (blur)="expand('exploreData', 'false')"
                        (keydown)="onKeyDown($event, 'exploreData')" onclick="this.blur()">
                        Explore the Data
                    </a>
                    <ul #exploreDataSubMenu class="navbar-dropdown" role="menu" aria-label="Explore the Data">
                        <li role="none">
                            <a class="navbar-item dropdownSelection" role="menuitem" routerLink="/view"
                                style="text-decoration: none;" (focus)="expand('exploreData', 'true')"
                                (blur)="expand('exploreData', 'false')"
                                (keydown)="onKeyDown($event, 'exploreDataSubMenu')" onclick="this.blur()" tabindex="-1">
                                Browse by State
                            </a>
                        </li>

                        <li role="none">
                            <a class="navbar-item dropdownSelection" role="menuitem" routerLink="/profile/us"
                                [queryParams]="{surveyYear: '2020'}" style="text-decoration: none;"
                                (focus)="expand('exploreData', 'true')" (blur)="expand('exploreData', 'false')"
                                (keydown)="onKeyDown($event, 'exploreDataSubMenu')" onclick="this.blur()" tabindex="-1">
                                Browse National Data
                            </a>
                        </li>

                        <li role="none">
                            <a class="navbar-item dropdownSelection" role="menuitem" routerLink="/data"
                                style="text-decoration: none;" (focus)="expand('exploreData', 'true')"
                                (blur)="expand('exploreData', 'false')"
                                (keydown)="onKeyDown($event, 'exploreDataSubMenu')" onclick="this.blur()" tabindex="-1">
                                Download Data Files
                            </a>
                        </li>

                        <li role="none">
                            <a class="navbar-item dropdownSelection" role="menuitem" href="/archive" target="_blank"
                                rel="noreferrer noopener" style="text-decoration: none;"
                                (focus)="expand('exploreData', 'true')" (blur)="expand('exploreData', 'false')"
                                (keydown)="onKeyDown($event, 'exploreDataSubMenu')" onclick="this.blur()" tabindex="-1">
                                Access Historical Data
                            </a>
                        </li>

                        <!-- <a class="navbar-item dropdownSelection" role="menuitem" routerLink="/data-quality"
                            style="text-decoration: none;" onclick="this.blur();"
                            (focus)="expand('exploreData', 'true')" (blur)="expand('exploreData', 'false')">
                            Data Quality
                        </a> -->
                    </ul>
                </li>
                <li class="navbar-item has-dropdown is-hoverable main-item" role="none">
                    <a class="navbar-item" role="menuitem" routerLink="/publications" style="text-decoration: none;"
                        onclick="this.blur()">
                        Publications
                    </a>
                </li>
                <li class="navbar-item main-item" role="none">
                    <app-entity-search role="menuitem"></app-entity-search>
                    <!--                    <button mat-menu-item (click)="logUserOut()" style="color: white;">-->
                    <!--                        <mat-icon>logout</mat-icon>-->
                    <!--                        <span>Logout</span>-->
                    <!--                    </button>-->
                </li>
                <li *ngIf="!production && isLoggedIn" class="navbar-item main-item" role="none">
                    <!-- <li class="navbar-item main-item" role="none"> -->
                    <button (click)="showSideNav()"
                        class="compare navbar-item is-flex is-flex-direction-column is-align-content-center unbuttonize">
                        <div class="school-count is-flex is-justify-content-center">
                            <p class="small-body has-text-white is-align-self-center">{{ numSchools }}</p>
                        </div>
                        <img src="assets/images/compare.svg" class="filter-light-blue" alt="" width="22.75px"
                            height="21.12px" />
                        <p> Compare</p>
                    </button>
                </li>
                <li *ngIf="!production" class="navbar-item" role="none">
                    <button *ngIf="isLoggedIn" mat-icon-button [matMenuTriggerFor]="accountMenu" tooltip="User Profile"
                        aria-label="view account">
                        <mat-icon>account_circle</mat-icon>
                    </button>
                    <mat-menu #accountMenu="matMenu" class="user-account-menu">
                        <div><strong>Name: </strong> {{userFullName}}</div>
                        <div><strong>Email:</strong> {{userEmail}}</div>

                        <button mat-menu-item (click)="logUserOut()">
                            <mat-icon>logout</mat-icon>
                            <span>Logout</span>
                        </button>
                    </mat-menu>

                    <!-- routerLink="/data-tool-login-landing" routerLink="/data-tool" -->
                    <button *ngIf="!isLoggedIn" mat-icon-button role="menuitem" (click)="gotToLoginPage()"
                        tooltip="Login">
                        <mat-icon>no_accounts</mat-icon>
                        <!--                        <mat-icon>login</mat-icon>-->
                    </button>
                </li>

                <!--                <li class="navbar-item main-item" role="none" *ngIf="!isLoggedIn">-->
                <!--                    <a class="navbar-item" role="menuitem" routerLink="/data-tool-login-landing"-->
                <!--                        style="text-decoration: none;" onclick="this.blur()">-->
                <!--                        Login-->
                <!--                    </a>-->
                <!--                </li>-->

            </ul>
        </div>
    </div>
</nav>

<nav *ngIf="isMobile && isMobileMenuVisible" class="navbar mobile-navbar" role="navigation"
    aria-label="main navigation">
    <aside class="menu">
        <app-entity-search #navbarSearch (isSearched)="toggleMobileNavbar(false)"></app-entity-search>
        <p class="menu-label">
            About
        </p>
        <ul class="menu-list" role="menu">
            <li><a class="no-underline" (click)="toggleMobileNavbar(false)" role="menuitem"
                    routerLink="/about/ocr">Office For Civil Rights</a></li>
            <li><a class="no-underline" (click)="toggleMobileNavbar(false)" role="menuitem"
                    routerLink="/about/crdc">Civil Rights Data Collection</a></li>
            <li><a class="no-underline" (click)="toggleMobileNavbar(false)" role="menuitem"
                    routerLink="/about/faqs">Frequently Asked Questions</a></li>
        </ul>
        <p class="menu-label">
            Explore the Data
        </p>
        <ul class="menu-list" role="menu">
            <li><a class="no-underline" (click)="toggleMobileNavbar()" role="menuitem" routerLink="/view">Browse by
                    State</a></li>
            <li><a class="no-underline" (click)="toggleMobileNavbar()" role="menuitem" routerLink="/profile/us"
                    [queryParams]="{surveyYear: '2020'}">Browse National Data</a></li>
            <li><a class="no-underline" (click)="toggleMobileNavbar()" role="menuitem" routerLink="/data">Download Data
                    Files</a></li>
            <li><a class="no-underline" (click)="toggleMobileNavbar()" role="menuitem" href="/archive" target="_blank"
                    rel="noreferrer noopener">Access Historical Data</a></li>
            <!-- <li *ngIf="!production && isLoggedIn"> -->
            <li><a class="no-underline" (click)="toggleMobileNavbar()" role="menuitem"
                    (click)="showSideNav()">Compare</a>
            </li>

            <!--            <li *ngIf="!isLoggedIn"><a class="no-underline" (click)="toggleMobileNavbar()" role="menuitem"-->
            <!--                    routerLink="/data-tool-login-landing">Login</a></li>-->
            <!-- <li><a class="no-underline" (click)="toggleMobileNavbar()" routerLink="/data-quality">Data Quality</a></li> -->
        </ul>
        <a class="menu-label no-underline" (click)="toggleMobileNavbar()" role="menuitem" routerLink="/publications">
            Publications
        </a>
    </aside>
</nav>


<app-modal *ngIf="showLogoutConfirmModal" class="confirm-logout-model" [showModal]="showLogoutConfirmModal"
    [preventClosingOnClickingAway]="true">
    <div class="is-flex is-flex-direction-column is-align-items-center">
        <div class="contents-header is-flex is-justify-content-center">
            <h6 id="dialogLabel" class="has-text-white has-text-centered" style="font-family: 'RALEWAY';">Confirm Logout
            </h6>
        </div>
        <div class="contents-body is-flex is-flex-direction-column is-align-items-center">

            <p class="large-body has-text-primary">Are you sure you want to logout?</p>

            <hr class="modal-footer-divider">
            <ul class="contents-body-navigation-buttons">
                <li>
                    <button class="button is-rounded is-outlined primary-button is-regular-button"
                        (click)="confirmLogout()">Yes</button>
                </li>
                <li>
                    <button class="button is-rounded is-outlined outline-button is-regular-button"
                        (click)="declineLogout()">No</button>
                </li>
            </ul>
        </div>
    </div>
</app-modal>
