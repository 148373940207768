<form [formGroup]="formGroup">
    <div class="data-tool-step-one-container is-flex is-flex-direction-column">
        <fieldset class="data-tool-step-two-container-field">
            <legend class="is-sr-only">Select the primary school or local educational agency (LEA) you wish to use as
                the benchmark for comparing data in the next step.</legend>
            <div #entityTypeSelection role="radiogroup" class="data-tool-step-one-container-radio-buttons is-flex">
                <div *ngFor="let entityType of entityTypes"
                    class="data-tool-step-one-container-radio-buttons-selection is-flex">
                    <input type="radio" [id]="entityType" name="entityType" [value]="entityType"
                        formControlName="entityType">
                    <label class="large-body has-text-primary" [for]="entityType">{{ entityType }}</label>
                </div>
            </div>
        </fieldset>

        <app-entity-search [entityType]="entityType.value" (entity)="onEntityChange($event)" (showLoadingEmitter)="showLoading($event)"></app-entity-search>

        <div *ngIf="entityProfile" class="data-tool-step-one-container-selected-entity">
            <h5 class="has-text-primary">{{ entityName }}</h5>
            <div class="data-tool-step-one-container-selected-entity-locality is-flex">
                <ng-container *ngIf="entityType.value === EntityType.SCHOOL">
                    <p class="large-body has-text-primary">{{ entityProfile.leaName }}</p>
                    <span class="dot"></span>
                    <p class="large-body has-text-primary">{{ entityProfile.schState }}</p>
                </ng-container>

                <p *ngIf="entityType.value === EntityType.DISTRICT" class="large-body has-text-primary">
                    {{ entityProfile.leaState }}</p>
            </div>
            <p class="large-body has-text-primary">Students: {{ studentCount }}</p>
            <p class="large-body has-text-primary">Grades offered: {{ entityProfile.gradesOffered }}</p>
        </div>
    </div>
</form>
