<div class="find-nearby-container">
    <div class="find-nearby-header-container">
        <div class="find-nearby-header is-flex is-flex-direction-column">
            <h4 class="has-text-white">{{ headerText }}</h4>
            <p *ngIf="entityType !== 'National'" class="has-text-white small-body">{{ subHeaderText }}</p>
        </div>
    </div>
    <div *ngIf="dataLoaded" class="table-container is-flex is-flex-direction-column">
        <table *ngIf="entityType !== 'State'" matSort (matSortChange)="sortData($event)" class="table">
            <tr class="header-row">
                <th mat-sort-header="ncesId" id="ncesId" class="large-body has-text-primary">NCES ID</th>
                <th mat-sort-header="school" id="schoolName" class="large-body has-text-primary">School Name</th>
                <th mat-sort-header="district" id="district" class="large-body has-text-primary">District</th>
                <th mat-sort-header="enrollment" id="studentsEnrolled" class="large-body has-text-primary">Students Enrolled</th>
            </tr>

            <tr *ngFor="let school of sortedData" (click)="routeSchool(school)">
                <td class="table-value">{{school.ncesId}}</td>
                <td class="table-value">{{school.school}}</td>
                <td class="table-value">{{school.district}}</td>
                <td class="table-value">{{school.enrollment?.toLocaleString('en-US')}}</td>
            </tr>
        </table>
        <table *ngIf="entityType === 'State'" matSort (matSortChange)="sortData($event)" class="table">
            <tr class="header-row">
                <th mat-sort-header="ncesId" id="ncesId" class="large-body has-text-primary">NCES ID</th>
                <th mat-sort-header="distict" id="districtName" class="large-body has-text-primary">District Name</th>
                <th mat-sort-header="numSchools" id="numberOfSchools" class="large-body has-text-primary" style="min-width: 8.5rem">Number of Schools</th>
                <th mat-sort-header="enrollment" id="studentsEnrolled" class="large-body has-text-primary">Students Enrolled</th>
                <th mat-sort-header="gradesOffered" id="gradesOffered" class="large-body has-text-primary">Grades Offered</th>
            </tr>

            <tr *ngFor="let district of sortedData" (click)="routeDistrict(district)">
                <td class="table-value">{{district.ncesId}}</td>
                <td class="table-value">{{district.district}}</td>
                <td class="table-value">{{district.numSchools?.toLocaleString('en-US')}}</td>
                <td class="table-value">{{district.enrollment?.toLocaleString('en-US')}}</td>
                <td class="table-value">{{district.gradesOffered}}</td>
            </tr>
        </table>
    </div>
</div>
