import { NgModule } from '@angular/core';

// Shared Modules
import { SharedModule } from '../../shared/modules/shared.module';

// Feature Routing
import { EstimationsRoutingModule } from './estimations-routing.module';

// Feature Services

// Container Component
import { EstimationsComponent } from './estimations.component';

// Sub Feature Components
import { Estimations20202021Component } from './2020-2021/estimations-2020-2021.component';
import { Estimations20172018Component } from './2017-2018/estimations-2017-2018.component';
import { Estimations20152016Component } from './2015-2016/estimations-2015-2016.component';
import { Estimations20132014Component } from './2013-2014/estimations-2013-2014.component';
import { Estimations20112012Component } from './2011-2012/estimations-2011-2012.component';
import { Estimations20092010Component } from './2009-2010/estimations-2009-2010.component';
import { Estimations2006Component } from './2006/estimations-2006.component';
import { Estimations2004Component } from './2004/estimations-2004.component';
import { Estimations2000Component } from './2000/estimations-2000.component';



@NgModule({
    declarations: [
        EstimationsComponent,
        Estimations20202021Component,
        Estimations20172018Component,
        Estimations20152016Component,
        Estimations20132014Component,
        Estimations20112012Component,
        Estimations20092010Component,
        Estimations2006Component,
        Estimations2004Component,
        Estimations2000Component
    ],
    imports: [
        EstimationsRoutingModule,
        SharedModule
    ],
    providers: [
    ]
})
export class EstimationsModule { }
