import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

  // @Input() isDataTool: boolean = false;
  @Input() showModal: boolean;
  @Input() preventClosingOnClickingAway: boolean = false;

  @Output() isClosed: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private logger: NGXLogger) {

  }

  ngOnInit() {
  }

  public open() {
    this.isClosed.emit(true);
    this.showModal = true;
  }

  public close() {
    this.logger.debug('closing modal, preventClosingOnClickingAway: ', this.preventClosingOnClickingAway);

    if (!this.preventClosingOnClickingAway) {
      this.showModal = false;
      this.isClosed.emit(true);
    }

  }

}
