<div *ngIf="!message" class="spinner-overlay is-flex" [style.width]="width" [style.height]="height"
    [style.background-color]="backgroundColor">
    <div class="spinner-container" aria-live="polite" id="spinner">
        <img src="assets/images/loading.gif" alt="loading spinner">
    </div>
</div>

<div *ngIf="message" id="loading-spinner" class="background-overlay">

        <div class="center">
            <div class="spinner-message" *ngIf="message">{{message}}</div>
        </div>
        <div class="center">
            <mat-spinner diameter="50" mode="indeterminate" color="#00233f"></mat-spinner>
<!--            <div class="spinner-border" style="color: blue;" role="status"></div>-->
        </div>

</div>
