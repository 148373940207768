import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Components
import { HomeComponent } from './features/home/home.component';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { UrlGuard } from './core/auth/guards/url-guard';
import { AccessibilityStatementComponent } from './features/accessibility-statement/accessibility-statement.component';
import { WildcardInterceptorComponent } from './shared/components/wildcard-interceptor/wildcard-interceptor.component';
import { RecentDataFilesComponent } from './features/recent-data-files/recent-data-files.component';
import { PublicationsComponent } from './features/publications/publications.component';
import { SearchComponent } from './features/search/search.component';
import { DataQualityComponent } from './features/data-quality/data-quality.component';
import { ProfileReportComponent } from './features/profile-report/profile-report.component';
import { EnforcementDataToolComponent } from './features/enforcement-data-tool/enforcement-data-tool.component';
import {DataToolLoginLandingComponent} from '@shared/components/data-tool-login-landing/data-tool-login-landing.component';
import { AuthGuard } from '@core/auth/guards/auth.guard';


const appRoutes: Routes = [

    {
        path: '',
        component: HomeComponent,
        canActivate: [UrlGuard],
        title: 'Civil Rights Data | U.S. Department of Education',
        data: {
            title: 'Civil Rights Data | U.S. Department of Education',
            description: 'Understand trends in student opportunity, security, and diversity.',
            ogImage: 'assets/images/about-ocr-image.png'
        }
    },
    {
        path: 'data',
        component: RecentDataFilesComponent,
        canActivate: [UrlGuard],
        title: 'Download Recent Data | Civil Rights Data',
        data: {
            title: 'Download Recent Data | Civil Rights Data',
            description: 'Find resources to help you make the most of Civil Rights Education Data.',
            ogImage: 'assets/images/about-ocr-image.png'
        }
    },
    {
        path: 'data-quality',
        component: DataQualityComponent,
        canActivate: [UrlGuard],
        title: 'Data Quality | Civil Rights Data',
        data: {
            title: 'Data Quality | Civil Rights Data',
            description: 'Find resources to help you make the most of Civil Rights Education Data.',
            ogImage: 'assets/images/about-ocr-image.png'
        }
    },
    {
        path: 'publications',
        component: PublicationsComponent,
        canActivate: [UrlGuard],
        title: 'Publications | Civil Rights Data',
        data: {
            title: 'Publications | Civil Rights Data',
            description: 'Find resources to help you make the most of Civil Rights Education Data.',
            ogImage: 'assets/images/about-ocr-image.png'
        }
    },
    {
        path: 'data-tool',
        component: EnforcementDataToolComponent,
        canActivate: [UrlGuard, AuthGuard],
        title: 'Data Tool | Civil Rights Data',
        data: {
            title: 'Data Tool | Civil Rights Data',
            description: 'Compare school or lea profile data.',
            ogImage: 'assets/images/about-ocr-image.png'
        }
    },
    {
        path: 'data-tool-login-landing',
        component: DataToolLoginLandingComponent,
        canActivate: [],
        title: 'Terms and Conditions: Data Tool | Civil Rights Data',
        data: {
            title: 'Data Tool | Civil Rights Data',
            description: 'Compare school or lea profile data.',
            ogImage: 'assets/images/about-ocr-image.png'
        }
    },
    {
        path: 'profile/us',
        component: ProfileReportComponent,
        canActivate: [UrlGuard],
        title: 'National Data | Civil Rights Data',
        data: {
            title: 'National Data | Civil Rights Data',
            description: 'Assess the state of civil rights in education through this national snapshot.',
            ogImage: 'assets/images/about-ocr-image.png'
        }
    },
    {
        path: 'profile/us/:state',
        component: ProfileReportComponent,
        canActivate: [UrlGuard],
        title: 'View State Data | Civil Rights Data',
        data: {
            title: 'View State Data | Civil Rights Data',
            description: 'Assess the state of civil rights in education through this state data.',
            ogImage: 'assets/images/about-ocr-image.png'
        }
    },
    {
        path: 'profile/us/:state/:district',
        component: ProfileReportComponent,
        canActivate: [UrlGuard],
        title: 'View District Data | Civil Rights Data',
        data: {
            title: 'View District Data | Civil Rights Data',
            description: 'Assess the state of civil rights in education at the district level.',
            ogImage: 'assets/images/about-ocr-image.png'
        },
    },
    {
        path: 'profile/us/:state/:district/:school',
        component: ProfileReportComponent,
        canActivate: [UrlGuard],
        title: 'View School Data | Civil Rights Data',
        data: {
            title: 'View School Data | Civil Rights Data',
            description: 'Assess the state of civil rights in education at the school level.',
            ogImage: 'assets/images/about-ocr-image.png'
        }
    },
    {
        path: 'accessibility_statement',
        component: AccessibilityStatementComponent,
        canActivate: [UrlGuard],
        title: 'Accessibility | Civil Rights Data',
        data: {
            title: 'Accessibility | Civil Rights Data',
            description: 'Find resources to help you access the information on the Civil Rights Data website.',
            ogImage: 'assets/images/about-ocr-image.png'
        }
    },
    {
        path: 'view',
        component: SearchComponent,
        canActivate: [UrlGuard],
        title: 'View State Profiles | Civil Rights Data',
        data: {
            title: 'View State Profiles | Civil Rights Data',
            description: 'Find civil rights profiles for your state, district, and school.',
            ogImage: 'assets/images/about-ocr-image.png'
        }
    },
    {
        path: '404',
        component: NotFoundComponent,
        title: '404 Error | Civil Rights Data',
        data: {
            title: '404 Error | Civil Rights Data',
            description: 'This page cannot be found.',
            ogImage: 'assets/images/about-ocr-image.png'
        }
    },

    // Feature - About
    {
        path: 'about',
        loadChildren: () => import('./features/about/about.module').then(m => m.AboutModule),
        data: { preload: true },
        canActivate: [UrlGuard]
    },

    // Feature - Estimations
    {
        path: 'estimations',
        loadChildren: () => import('./features/estimations/estimations.module').then(m => m.EstimationsModule),
        data: { preload: true },
        canActivate: [UrlGuard]
    },

    { path: '**', component: WildcardInterceptorComponent }

];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes)
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
