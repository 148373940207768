<div class="container content">
  <page-title title="2000 State and National Estimations"></page-title>
  <p>
    These files are state and national projections for the Civil Rights Data Collection. The 2000 projections are based on the 14,716 public school
    districts and 88,882 schools in these school districts that responded to the 2000 survey of all the nation's school districts. The state and
    national estimations were prepared for OCR in accordance with statistical methodology for the Civil Rights data collection. Documentation is
    available from OCR which describes the procedures used for the estimations, including weighting of the sample, imputation for item non-response,
    standard errors, and quality control procedures. In addition, documentation is available from OCR for estimations that should be used with caution
    due to large statistical uncertainty in the estimate, including factors which contributed to the extent of this statistical uncertainty for the
    Civil Rights Data Collection. This hardcopy documentation, available upon request, is contained in " Civil Rights Data Collection (CRDC)
    Estimations and Documentation."
  </p>

  <div class="has-text-centered">
    <a href="assets/downloads/2000/2000-nation-projection.xls" rel="noopener noreferrer" target="_blank">National total</a>
  </div>
  <br />
  <div class="columns is-gapless is-multiline">
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2000/2000-alabama-projection.xls" rel="noopener noreferrer" target="_blank">Alabama</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2000/2000-illinois-projection.xls" rel="noopener noreferrer" target="_blank">Illinois</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2000/2000-montana-projection.xls" rel="noopener noreferrer" target="_blank">Montana</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2000/2000-rhodeisland-projection.xls" rel="noopener noreferrer" target="_blank">Rhode Island</a>
    </div>
  </div>
  <div class="columns is-gapless is-multiline">
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2000/2000-alaska-projection.xls" rel="noopener noreferrer" target="_blank">Alaska</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2000/2000-indiana-projection.xls" rel="noopener noreferrer" target="_blank">Indiana</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2000/2000-nebraska-projection.xls" rel="noopener noreferrer" target="_blank">Nebraska</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2000/2000-southcarolina-projection.xls" rel="noopener noreferrer" target="_blank">South Carolina</a>
    </div>
  </div>
  <div class="columns is-gapless is-multiline">
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2000/2000-arizona-projection.xls" rel="noopener noreferrer" target="_blank">Arizona</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2000/2000-iowa-projection.xls" rel="noopener noreferrer" target="_blank">Iowa</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2000/2000-nevada-projection.xls" rel="noopener noreferrer" target="_blank">Nevada</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2000/2000-southdakota-projection.xls" rel="noopener noreferrer" target="_blank">South Dakota</a>
    </div>
  </div>
  <div class="columns is-gapless is-multiline">
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2000/2000-arkansas-projection.xls" rel="noopener noreferrer" target="_blank">Arkansas</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2000/2000-kansas-projection.xls" rel="noopener noreferrer" target="_blank">Kansas</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2000/2000-newhampshire-projection.xls" rel="noopener noreferrer" target="_blank">New Hampshire</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2000/2000-tennessee-projection.xls" rel="noopener noreferrer" target="_blank">Tennessee</a>
    </div>
  </div>
  <div class="columns is-gapless is-multiline">
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2000/2000-california-projection.xls" rel="noopener noreferrer" target="_blank">California</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2000/2000-kentucky-projection.xls" rel="noopener noreferrer" target="_blank">Kentucky</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2000/2000-newjersey-projection.xls" rel="noopener noreferrer" target="_blank">New Jersey</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2000/2000-texas-projection.xls" rel="noopener noreferrer" target="_blank">Texas</a>
    </div>
  </div>
  <div class="columns is-gapless is-multiline">
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2000/2000-colorado-projection.xls" rel="noopener noreferrer" target="_blank">Colorado</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2000/2000-louisiana-projection.xls" rel="noopener noreferrer" target="_blank">Louisiana</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2000/2000-newmexico-projection.xls" rel="noopener noreferrer" target="_blank">New Mexico</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2000/2000-utah-projection.xls" rel="noopener noreferrer" target="_blank">Utah</a>
    </div>
  </div>
  <div class="columns is-gapless is-multiline">
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2000/2000-connecticut-projection.xls" rel="noopener noreferrer" target="_blank">Connecticut</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2000/2000-maine-projection.xls" rel="noopener noreferrer" target="_blank">Maine</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2000/2000-newyork-projection.xls" rel="noopener noreferrer" target="_blank">New York</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2000/2000-vermont-projection.xls" rel="noopener noreferrer" target="_blank">Vermont</a>
    </div>
  </div>
  <div class="columns is-gapless is-multiline">
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2000/2000-delaware-projection.xls" rel="noopener noreferrer" target="_blank">Delaware</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2000/2000-maryland-projection.xls" rel="noopener noreferrer" target="_blank">Maryland</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2000/2000-northcarolina-projection.xls" rel="noopener noreferrer" target="_blank">North Carolina</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2000/2000-virginia-projection.xls" rel="noopener noreferrer" target="_blank">Virginia</a>
    </div>
  </div>
  <div class="columns is-gapless is-multiline">
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2000/2000-districtofcolumbia-projection.xls" rel="noopener noreferrer" target="_blank">District of Columbia</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2000/2000-massachusetts-projection.xls" rel="noopener noreferrer" target="_blank">Massachusetts</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2000/2000-northdakota-projection.xls" rel="noopener noreferrer" target="_blank">North Dakota</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2000/2000-washington-projection.xls" rel="noopener noreferrer" target="_blank">Washington</a>
    </div>
  </div>
  <div class="columns is-gapless is-multiline">
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2000/2000-florida-projection.xls" rel="noopener noreferrer" target="_blank">Florida</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2000/2000-michigan-projection.xls" rel="noopener noreferrer" target="_blank">Michigan</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2000/2000-ohio-projection.xls" rel="noopener noreferrer" target="_blank">Ohio</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2000/2000-westvirginia-projection.xls" rel="noopener noreferrer" target="_blank">West Virginia</a>
    </div>
  </div>
  <div class="columns is-gapless is-multiline">
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2000/2000-georgia-projection.xls" rel="noopener noreferrer" target="_blank">Georgia</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2000/2000-minnesota-projection.xls" rel="noopener noreferrer" target="_blank">Minnesota</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2000/2000-oklahoma-projection.xls" rel="noopener noreferrer" target="_blank">Oklahoma</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2000/2000-wisconsin-projection.xls" rel="noopener noreferrer" target="_blank">Wisconsin</a>
    </div>
  </div>
  <div class="columns is-gapless is-multiline">
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2000/2000-hawaii-projection.xls" rel="noopener noreferrer" target="_blank">Hawaii</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2000/2000-mississippi-projection.xls" rel="noopener noreferrer" target="_blank">Mississippi</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2000/2000-oregon-projection.xls" rel="noopener noreferrer" target="_blank">Oregon</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2000/2000-wyoming-projection.xls" rel="noopener noreferrer" target="_blank">Wyoming</a>
    </div>
  </div>
  <div class="columns is-gapless is-multiline">
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2000/2000-idaho-projection.xls" rel="noopener noreferrer" target="_blank">Idaho</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2000/2000-missouri-projection.xls" rel="noopener noreferrer" target="_blank">Missouri</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2000/2000-pennsylvania-projection.xls" rel="noopener noreferrer" target="_blank">Pennsylvania</a>
    </div>
  </div>
  <p>
    <em>Note for users of assistive technology</em>:
    For 2000, each Microsoft Excel file includes two sheets labeled “Page 1” and “Page 2” Column headings are included in
    each sheet in rows 6, 7, and 8. Row headings are included in columns B and C. You may wish to adjust the settings of
    your assistive technology accordingly.
  </p>
  
  <document-viewer docType="PDF"></document-viewer>
</div>
