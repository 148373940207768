<!--<button *ngIf="!isDataTool" mat-mini-fab color="#133559" aria-label="Open table of contents modal" class="toggle-button" (click)="open()">-->
<!--    <mat-icon>menu</mat-icon>-->
<!--</button>-->

<!--<div *ngIf="(showModal && !isClicked) || (isDataTool && isClicked)">-->
<div *ngIf="showModal">
    <div class="modal-container" (click)="close()" role="dialog" data-toggle="modal" data-backdrop="static" data-bs-backdrop="static">
        <div class="modal-container-content" (click)="$event.stopPropagation()" aria-labelledby="dialogLabel"
            aria-modal="true" cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
            <ng-content></ng-content>
        </div>
    </div>
    <div class="modal-background"></div>
</div>
