import { Injectable } from "@angular/core";
import { UrlSegment } from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class UrlSerice {
    private previousUrl: UrlSegment[];

    setPreviousUrl(urlSegment: UrlSegment[]) {
        this.previousUrl = urlSegment;
    }

    getPreviousUrl(): UrlSegment[] {
        return this.previousUrl;
    }
}