export class EntitySearchQuery {
    entityName: string;
    entityType: string;
    SYK_CutOff: number = 6;         // by default
}


export class EntitySearchStep2Query {
    entityType: string;
    entityID: number;
    surveyYearKeys: string;
    geoUnit: string;    // State: S, National: N, District: D
    stateCode?: string; // Optional, only use when geoUnit = S, sample values: CA, VA, GA
    entityLevel: string;
    grades_Offered: string;
    criteria: string;
    criteria_Quantity: string;
}

export class Step3ModulesQuery {
    entityType: string;
}

export class EntitySearchStep4Query {
    surveyYearKeys: string;
    entityType: string;
    entityIDs: string;
    modules: string;
    analysisTypes?: string;
}
