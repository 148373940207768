<form [formGroup]="formGroup">
    <div class="data-tool-step-two-container is-flex is-flex-direction-column">
        <fieldset class="data-tool-step-two-container-field">
            <div style="gap: .5rem;" class="is-flex">
                <legend id="surveyYearsLabel" class="subheader-2 has-text-primary">Survey Years <span
                        class="small-body has-text-red">(Required)</span> </legend>
                <mat-icon class="ed-slate" tabindex="0" aria-labelledby="tooltip-popup"
                    tooltip="To retrieve data earlier than the 2015-16 survey year, please contact the CRDC Team at OCRData&#64;ed.gov">
                    info_outline</mat-icon>
            </div>
            <ul class="data-tool-step-two-container-field-list is-flex" aria-labelledby="surveyYearsLabel">
                <li #surveyYearSelection *ngFor="let surveyYear of entitySurveyYears"
                    class="data-tool-step-two-container-field-list-selection">
                    <input type="checkbox" [id]="surveyYear.yearKey" name="surveyYears"
                        (change)="onCheck($event, surveyYear.yearKey, surveyYears)">
                    <label class="data-tool-step-two-container-field-list-selection-label large-body has-text-primary"
                        [for]="surveyYear.yearKey">{{ surveyYear.period }}</label>
                </li>
            </ul>
        </fieldset>

        <fieldset class="data-tool-step-two-container-field">
            <legend class="subheader-2 has-text-primary">{{ entityType }}s within the same <span
                    class="small-body has-text-red">(Required)</span></legend>
            <div role="radiogroup" class="data-tool-step-two-container-field-options is-flex is-align-items-center">
                <div *ngFor="let option of localityOptions"
                    class="data-tool-step-two-container-field-options-selection is-flex">
                    <input type="radio" [id]="option.value" name="locality" [value]="option.value"
                        formControlName="locality">
                    <label class="large-body has-text-primary" [for]="option.value">{{ option.label }}</label>
                </div>

                <mat-form-field *ngIf="states && locality.value === 'st'">
                    <mat-label>State</mat-label>
                    <mat-select [formControl]="selectedState">
                        <mat-option *ngFor="let state of states" [value]="state">
                            {{state.stateName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </fieldset>

        <fieldset *ngIf="entityType === EntityType.SCHOOL" class="data-tool-step-two-container-field">
            <legend id="schoolTypesLabel" class="subheader-2 has-text-primary">Type of school <span
                    class="small-body has-text-red">(Required)</span></legend>
            <ul class="data-tool-step-two-container-field-list is-flex" aria-labelledby="schoolTypesLabel">
                <li *ngFor="let schoolType of schoolTypeValues"
                    class="data-tool-step-two-container-field-list-selection">
                    <input type="checkbox" [id]="schoolType.value" name="schoolTypes"
                        (change)="onCheck($event, schoolType.value, schoolTypes)">
                    <label class="data-tool-step-two-container-field-list-selection-label large-body has-text-primary"
                        [for]="schoolType.value">{{ schoolType.label }}</label>
                </li>
            </ul>
        </fieldset>

        <fieldset *ngIf="entityType === EntityType.SCHOOL" class="data-tool-step-two-container-field">
            <legend id="schoolLevelsLabel" class="subheader-2 has-text-primary">Level of School</legend>
            <ul class="data-tool-step-two-container-field-list is-flex" aria-labelledby="schoolLevelsLabel">
                <li *ngFor="let schoolLevel of schoolLevelValues"
                    class="data-tool-step-two-container-field-list-selection">
                    <input type="checkbox" [id]="schoolLevel.value" name="grades"
                        (change)="onCheck($event, schoolLevel.value, grades)">
                    <label class="data-tool-step-two-container-field-list-selection-label large-body has-text-primary"
                        [for]="schoolLevel.value">{{ schoolLevel.label }}</label>
                </li>
            </ul>
        </fieldset>

        <div aria-describedby="validRangeError">
            <app-data-tool-criteria-filters formControlName="criteria"></app-data-tool-criteria-filters>
            <div class="help">
                <p role="alert" aria-live="polite" id="validRangeError"
                    [hidden]="!(criteria?.errors?.['requiredRange'] || criteria.touched)"> Value must be between 0 and
                    100 with no decimals.
                </p>
            </div>
        </div>

        <app-data-tool-entity-selection [entities]="entities" [entityTotal]="entityTotal" [entityType]="entityType"
            formControlName="selectedEntities">
        </app-data-tool-entity-selection>
    </div>
</form>
