import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {catchError, map, tap} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {MessageService} from '../core/message.service';
import {BaseService} from '../core/base.service';
import {DataService} from '../core/data.service';
import {Observable, of} from 'rxjs';
import {EntitySearchQuery, EntitySearchStep2Query, EntitySearchStep4Query} from '../../queries/entity-search-query';
import {EntitySearchModel, EntitySearchResult, EntitySearchStep2Model} from '../../query-models/entity-search-model';
import {NGXLogger} from 'ngx-logger';
import {ToastService} from '@shared/components/toast/toast.service';
import {
    EntitySearchStep4PreView,
    EntitySearchStep4PreViewModel,
    MEASURE_TYPE,
    Step4Entity,
    Step4Measure,
    Step4Module,
    Step4SYKData,
    SYK_Data
} from '@shared/models/entity-search-step4-pre-view.model';
import {EntityDataQuery} from '../../queries/entity-data-query';
import {SchoolProfileQueryModel} from '../../query-models/school-profile-query-model';
import {DistrictProfileQueryModel} from '../../query-models/district-profile-query-model';

@Injectable()
export class DataToolService extends BaseService {

  private apiUrl = environment.api_url; // URL to web api

  constructor(
    private http: HttpClient,
    logger: NGXLogger,
    toastService: ToastService,
    messageService: MessageService,
    dataService: DataService
  ) {
    super(logger, toastService, messageService, dataService);
  }
    /** GET **/
    getSchoolProfileDT(query: EntityDataQuery): Observable<SchoolProfileQueryModel> {

        this.setHeaders();

        const url = this.apiUrl + 'GetSchoolProfileDT';

        var params = new HttpParams();
        params = params.append('survey_Year_Key', query.surveyYearKey.toString());
        params = params.append('Entity_Id', query.entityId.toString());
        params = params.append('School_Or_District', "s");

        return this.http.get<SchoolProfileQueryModel>(
            url,
            {
                params: params,
                observe: 'response',
                headers: this.headers,
                withCredentials: true,  // so we can send cookies
            })
            .pipe(
                map(resp => {
                    if (resp != undefined || resp != null) {
                        return resp.body;
                    }

                    return null;
                }),
                tap(resp => {
                    this.log(`fetched`);
                }),
                catchError(this.handleError('get', null))
            );
    }

    getDistrictProfileDT(query: EntityDataQuery): Observable<DistrictProfileQueryModel> {
        this.setHeaders();
        const url = this.apiUrl + 'GetDistrictProfileDT';


        var params = new HttpParams();
        params = params.append('survey_Year_Key', query.surveyYearKey.toString());
        params = params.append('Entity_Id', query.entityId.toString());
        params = params.append('School_Or_District', "D");

        return this.http.get<DistrictProfileQueryModel>(
            url,
            {
                params: params,
                observe: 'response',
                headers: this.headers,
                withCredentials: true,  // so we can send cookies
            })
            .pipe(
                map(resp => {
                    if (resp != undefined || resp != null) {
                        return resp.body;
                    }

                    return null;
                }),
                tap(resp => {
                    this.log(`fetched`);
                }),
                catchError(this.handleError('get', null))
            );
    }

    getEntitySearchStep1(query: EntitySearchQuery): Observable<EntitySearchResult[]> {

        this.logger.debug('Inside getEntitySearchStep1, query: ', query);

        this.setHeaders();
        const url = this.apiUrl + 'EntitySearchStep1';

        var params = new HttpParams();
        params = params.append('EntityName', query.entityName);
        params = params.append('EntityType', query.entityType);
        params = params.append("SYK_CutOff", query.SYK_CutOff);

        return this.http.get<EntitySearchModel>(
            url,
            {
                params: params,
                observe: 'response',
                headers: this.headers,
                withCredentials: true,  // so we can send cookies
            })
            .pipe(
                map(resp => {
                    if (resp?.body?.entitySearchResults) {
                        return resp.body.entitySearchResults;
                    }

                    return null;
                }),
                tap(resp => {
                    this.log(`fetched`);
                }),
                catchError(this.handleError('get', null))
            );
    }

    EntitySearchStep2(query: EntitySearchStep2Query) {
        this.setHeaders();
        const url = this.apiUrl + 'EntitySearchStep2';

        var params = new HttpParams();
        params = params.append('EntityType', query.entityType);
        params = params.append('EntityID', query.entityID);
        params = params.append('Survey_Year_Keys', query.surveyYearKeys);
        params = params.append('GeoUnit', query.geoUnit);   // State: (st) ALL (n), District (d)

        if (query.stateCode){
            params = params.append('stateCode', query.stateCode);   // State: CA VA GA,  ALL (N), District (D)
        }

        params = params.append('EntityLevel', query.entityLevel);
        params = params.append('Grades_Offered', query.grades_Offered);

        // criteria is optional
        if (query.criteria) {
            params = params.append('Criteria', query.criteria);
        }

        // is optional
        if (query.criteria_Quantity) {
            params = params.append('Criteria_Quantity', query.criteria_Quantity);
        }

        this.logger.debug('query params: ', params);

        return this.http.get<EntitySearchStep2Model>(
            url,
            {
                params: params,
                observe: 'response',
                headers: this.headers,
                withCredentials: true,  // so we can send cookies
            })
            .pipe(
                map(resp => {
                    if (resp != undefined || resp != null) {
                        return resp.body;
                    }

                    return null;
                }),
                tap(resp => {
                    this.log(`fetched`);
                }),
                catchError(this.handleError('get', null))
            );
    }

    // return list of modules
    getStep3Modules(entityType: string): Observable<any> {

        this.logger.debug('Inside getStep3Modules, entityType: ', entityType);
        this.setHeaders();
        const url = this.apiUrl + 'Step3Modules';

        var params = new HttpParams();
        params = params.append('EntityType', entityType);

        return this.http.get<any>(
            url,
            {
                params: params,
                observe: 'response',
                headers: this.headers,
                withCredentials: true,  // so we can send cookies
            })
            .pipe(
                map(resp => {
                    if (resp != undefined || resp != null) {
                        return resp.body;
                    }

                    return null;
                }),
                tap(resp => {
                    this.log(`fetched`);
                }),
                catchError(this.handleError('get', null))
            );
    }

    // return list of analyses types
    getStep3AnalysesTypes(): Observable<any> {

        this.setHeaders();
        const url = this.apiUrl + 'Step3AnalysesTypes';

        return this.http.get<any>(
            url,
            {
                observe: 'response',
                headers: this.headers,
                withCredentials: true,  // so we can send cookies
            })
            .pipe(
                map(resp => {
                    if (resp != undefined || resp != null) {
                        return resp.body;
                    }

                    return null;
                }),
                tap(resp => {
                    this.log(`fetched`);
                }),
                catchError(this.handleError('get', null))
            );
    }

    loadEntitySearchStep4Preview(searchQuery: EntitySearchStep4Query): Observable<EntitySearchStep4PreView> {

      this.logger.debug('loadEntitySearchStep4Preview, searchQuery: ', searchQuery);

      this.setHeaders();

      if (!searchQuery) {
        return of(<EntitySearchStep4PreView>{});
      }

      const url = this.apiUrl + 'EntitySearchStep4Preview';

      var params = new HttpParams();
      params = params.append('SurveyYearKeys', searchQuery.surveyYearKeys);
      params = params.append('EntityType', searchQuery.entityType);
      params = params.append('EntityIDs', searchQuery.entityIDs);
      params = params.append('Modules', searchQuery.modules);

        return this.http.get<any>(
            url,
            {
                params: params,
                observe: 'response',
                headers: this.headers,
                withCredentials: true,  // so we can send cookies
            })
            .pipe(
                map(resp => {
                    if (resp != undefined || resp != null) {
                        return resp.body;
                    }

                    return null;
                }),
                tap(resp => {
                    this.log(`fetched`);
                }),
                catchError(this.handleError('get', null))
            );
    }

    loadEntitySearchStep4Data(searchQuery: EntitySearchStep4Query): Observable<EntitySearchStep4PreViewModel> {

      this.logger.debug('Inside loadEntitySearchStep4Data, searchQuery: ', searchQuery);

      this.setHeaders();

      if (!searchQuery) {
          return of(<EntitySearchStep4PreViewModel>{});
      }

        const url = this.apiUrl + 'EntitySearchStep4Data';


        // for testing
        // searchQuery.surveyYearKeys = '9,10';
        // searchQuery.entityType = 'a';
        // searchQuery.entityIDs = '276238,276192'; //'276238,276192,276244,276233,548204';
        // searchQuery.modules = '8,11,12,25';   //'8,11,12,14,15,16,21,22,23,24,25,26';
        // searchQuery.analysisTypes = '2,5,6';

        let params = new HttpParams();
        params = params.append('surveyYearKeys', searchQuery.surveyYearKeys);
        params = params.append('entityType', searchQuery.entityType);
        params = params.append('entityIDs', searchQuery.entityIDs);
        params = params.append('modules', searchQuery.modules);

        if (searchQuery.analysisTypes){
            params = params.append('analysisTypes', searchQuery.analysisTypes);
        }

        return this.http.get<EntitySearchStep4PreViewModel>(
            url,
            {
                withCredentials: true,  // so we can send cookies
                params: params,
                observe: 'response',
                headers: this.headers,
            })
            .pipe(
                map(resp => {
                    if (resp.body) {

                        this.logger.debug('found module data, resp.body: ', resp.body);

                        this.convertSykData(resp.body.result);

                        return resp.body;
                    }

                    return null;
                }),
                tap(resp => {
                    this.log(`fetched`);
                }),
                catchError(this.handleError('get', null))
            );

    }

    private convertSykData(results: Step4Module[]){
        this.logger.debug('Inside convertSykData');

        // now we have to convert the entity data string to a sykData object
        results.forEach(module => {

            module.data.forEach(measure => {

                try {
                    this.convertMeasureData(measure);
                }
                catch (e) {
                    this.logger.error('Error trying to convert measure['+measure.measure_Title+'].data, for module: ', module.module_Title);
                    throw e;
                }

            });
        });
    }

    private convertMeasureData(measure: Step4Measure) {
        this.logger.debug('Inside convertMeasureData, mesure: ', measure.measure_Title);

        measure.data.forEach(entity => {

            entity.data.forEach(surveyYear => {

                this.convertSurveyYearData(measure.location, entity, surveyYear);

            });
        });
    }

    private convertSurveyYearData(measureType: MEASURE_TYPE, entity: Step4Entity, surveyYear: Step4SYKData) {
        this.logger.debug('Inside convertSurveyYearData, measureType: ', measureType);

        let sykDataNameValueList: string[];

        try {
            this.logger.debug('For surveyPeriod['+surveyYear.surveyPeriod+'] surveyYear.data: ', surveyYear);

            //{"SYK":10,"surveyPeriod":"2020-21","data":"studentEnrollment:1917,B_AME:count=3; ratio=-1,B_ASI:count=117; ratio=-1,B_HIS:count=1176; ratio=-1,B_BLA:count=95; ratio=-1,B_WHI:count=491; ratio=-1,B_HI_PAC:count=0; ratio=-1,B_MORE:count=35; ratio=-1,B_TOT:count=1917; ratio=-1"}
            if (surveyYear?.data){
                sykDataNameValueList = surveyYear.data.split(',');
            }
            else {
                this.logger.error('Error trying to read entity['+entity.entity_Name+'] surveyYear.data, for surveyYear: ', surveyYear);
                throw Error('Invalid entity['+entity.entity_Name+'] surveyYear.data for surveyYear: ' + surveyYear.surveyPeriod);
            }

        }
        catch (e) {
            this.logger.error('Error trying to convert entity['+entity.entity_Name+'].data, for surveyYear: ', surveyYear);
            throw e;
        }

        if (!sykDataNameValueList || !(sykDataNameValueList?.length > 0) ){
            this.logger.error('Error trying to convert entity.data, for surveyYear: ', surveyYear);
            throw Error('Invalid entity['+entity.entity_Name+'] sykDataNameValueList for surveyYear: ' + surveyYear.surveyPeriod);
        }

        surveyYear.sykData = <SYK_Data>{} ;

        sykDataNameValueList.forEach(attr => {

            try {

                // get: key values, e.g: studentEnrollment:1917 or //F_TOT:count=335; ratio=-1
                //studentEnrollment:count=706,M_TOT:count=371; ratio=-1,F_TOT:count=335; ratio=-1,B_TOT:count=706; ratio=-1
                const attrNameValue = attr.split(':');
                this.logger.debug('attrNameValue: ', attrNameValue);

                attrNameValue[0] = attrNameValue[0].trim();

                if (measureType === MEASURE_TYPE.DETAILS){

                    if (!surveyYear.sykData.questionAnswers){
                        surveyYear.sykData.questionAnswers = [];
                    }

                    surveyYear.sykData.questionAnswers.push({question: attrNameValue[0], answer: attrNameValue[1]});

                }
                else {
                    surveyYear.sykData[attrNameValue[0]] = { count: -1};

                    // now get: count=3; ratio=-1
                    const childNameValues = attrNameValue[1].split(';');

                    //this.logger.debug('childNameValues: ', childNameValues);

                    childNameValues.forEach(childAttr => {
                        //this.logger.debug('Reading childAttr: ', childAttr);

                        //['M_TOT', 'count=371; ratio=-1']

                        const childNameValue = childAttr.split('=');

                        //this.logger.debug('childNameValue: ', childNameValue);

                        surveyYear.sykData[attrNameValue[0]][childNameValue[0].trim()] = +childNameValue[1];  // the + converts it to a number

                    });
                }

                this.logger.debug('surveyYear.sykData: ', surveyYear.sykData);
                delete surveyYear.data; // not needed after conversion
            }
            catch (e) {
                this.logger.error('Error trying to convert entity['+entity.entity_Name+'].data sykDataNameValueList, for attr: ', attr);
                throw e;
            }

        });

        this.logger.debug('Completed surveyPeriod['+surveyYear.surveyPeriod+']: converted sykData: ', surveyYear.sykData);
    }
}
