export enum ScreenType {
    DESKTOP = 'desktop',
    TABLET = 'tablet',
    MOBILE = 'mobile'
}

export interface Area {
    alt: string;
    coords: string;
    tooltip: string;
}