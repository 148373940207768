import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { FormControl, FormGroup } from '@angular/forms';
import { ModuleL1 } from '@shared/models/entity-search-step4-pre-view.model';
import { ComparisonType, DemographicType } from '@shared/models/entity-search-step3-model';
import { DataToolModuleOptionsSelectionComponent } from '../data-tool-module-options-selection/data-tool-module-options-selection.component';
import { LabelValuePair } from '@shared/models/profile-model';

@Component({
    selector: 'app-data-tool-step-three',
    templateUrl: './data-tool-step-three.component.html',
    styleUrls: ['./data-tool-step-three.component.scss']
})
export class DataToolStepThreeComponent implements OnInit, AfterViewInit {

    @Input() formGroup: FormGroup;
    @Input() moduleOptions: ModuleL1[];
    @Input() analysisOptions: ModuleL1[];
    @Input() scrollItem: string;

    /**
     * Represents the form response output that sets the step 3 form group in the parent component.
     */
    @Output() formResponse: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

    @ViewChild('modules') moduleOptionsSelection: DataToolModuleOptionsSelectionComponent;
    @ViewChild('radioInput') radioInput: ElementRef;

    protected expanded: boolean = true;
    protected numSelectedItems: string;
    protected readonly demographicLabelValuePairs: LabelValuePair[] = [
        { label: 'Count and percentage distribution by sex', value: DemographicType.SEX },
        { label: 'Count and percentage distribution by race/ethnicity', value: DemographicType.RACE_ETHNICITY },
        { label: 'Count and percentage distribution by sex and race/ethnicity', value: DemographicType.SEX_RACE_ETHNICITY },
        { label: 'Count and percentage distribution by race/ethnicty and relative risk ratio', value: DemographicType.RACE_ETHNICITY_RELATIVE_RISK },
    ];
    protected readonly otherDistributions: ModuleL1 = {
        dTool_ID: 2, title: 'Other Distributions', data: [
            {
                dTool_ID: ComparisonType.EL_STATUS,
                title: 'Count and percentage distribution by English learner status'
            },
            // { dTool_ID: ComparisonType.IDEA_504, title: 'Count and percentage distribution by disability (IDEA and Section 504)' },
            {
                dTool_ID: ComparisonType.IDEA,
                title: 'Count and percentage distribution by disability (IDEA)'
            },
            {
                dTool_ID: ComparisonType.SECTION504,
                title: 'Count and percentage distribution by disability (Section 504 only)'
            },
        ]
    };

    constructor(
        private logger: NGXLogger,
    ) { }

    ngOnInit() {
        this.logger.debug('Inside ngOnInit for DataToolStepThreeComponent');

        this.formGroup?.valueChanges.subscribe(() => {
            this.logger.debug('current formGroup step 3: ', this.formGroup);
            this.onFormChanges();
        });

        this.numSelectedItems = this.getNumSelectedItems();

        // const comparisons: FormGroup = this.selectedComparisons.value;
        // const sexComparison = comparisons.get(`${ComparisonType.SEX}`);
        // const raceComparison = comparisons.get(`${ComparisonType.RACE_ETHNICITY}`);
        // const riskRatioComparison = comparisons.get(`${ComparisonType.RELATIVE_RISK}`);

        // If risk ration is enabled, then sex comparison should always be disabled
        // if (riskRatioComparison.value) {
        //     this.logger.debug('Disabling sex comparison');
        //     setTimeout(() => sexComparison.disable(), 1);
        // }

        // If race comparison is enabled and sex comparison is disabled, then enable risk ratio comparison.
        // Otherwise, disable risk ratio comparison.
        // if (raceComparison.value && !sexComparison.value) {
        //     this.logger.debug('Enabling risk ratio comparison');
        //     setTimeout(() => riskRatioComparison.enable(), 1);
        // } else {
        //     this.logger.debug('Disabling risk ratio comparison');
        //     setTimeout(() => riskRatioComparison.disable(), 1);
        // }

        // When race comparison changes, and the value is true and sex comparison is false, then enable risk ratio comparison.
        // Otherwise, disable risk ratio comparison.
        // raceComparison.valueChanges.subscribe(() => {
        //     if (raceComparison.value && !sexComparison.value) {
        //         riskRatioComparison.enable({ emitEvent: false });
        //     } else {
        //         riskRatioComparison.disable({ emitEvent: false });
        //     }
        // });

        // When sex comparison changes, and the value is true, then disable risk ratio comparison.
        // Otherwise, if race comparison is true, then enable risk ratio comparison.
        // sexComparison.valueChanges.subscribe(() => {
        //     if (sexComparison.value) {
        //         riskRatioComparison.disable({ emitEvent: false });
        //     } else if (raceComparison.value) {
        //         riskRatioComparison.enable({ emitEvent: false });
        //     }
        // });

        // When risk ratio comparison changes, and the value is true, then disable sex comparison.
        // Otherwise enable sex comparisson.
        // riskRatioComparison.valueChanges.subscribe(() => riskRatioComparison.value ? sexComparison.disable({ emitEvent: false }) : sexComparison.enable({ emitEvent: false }));

    }

    ngAfterViewInit(): void {
        this.logger.debug('Inside ngAfterViewInit for DataToolStepThreeComponent');
        this.logger.debug('Scroll item: ', this.scrollItem);

        if (!this.scrollItem) {
            this.moduleOptionsSelection.focusFirstElement();
        } else if (this.scrollItem === 'Analyses Types') {
            this.radioInput.nativeElement.focus();
            const scrollElement = document.getElementById(this.scrollItem);
            scrollElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }

        // TODO: Scroll to the selected item when analyses types are selected
    }

    /**
    * Handles the form changes and emits the form group and loads data based on the provided key.
    *
    * @param key - The key used to load the data.
    */
    onFormChanges(): void {
        this.logger.debug('Inside onFormChanges for DataToolStepThreeComponent');
        this.formResponse.emit(this.formGroup);
        this.numSelectedItems = this.getNumSelectedItems();
    }

    /**
     * Toggles the analyses types dropdown open and closed.
     */
    toggleDropdown(): void {
        this.expanded = !this.expanded;
    }

    /**
     * Calculates the number of selected items to display in the header of the analyses types dropdown.
     */
    getNumSelectedItems(): string {
        this.logger.debug('Inside getNumSelectedItems');
        // Should always have 1 selected because the demographic selection is required
        let selectedItems: number = 1;

        this.logger.debug('ELP: ', this.elp.value);
        if (this.elp.value) {
            selectedItems++;
        }

        this.logger.debug('IDEA: ', this.disabilityIDEA.value);
        if (this.disabilityIDEA.value) {
            selectedItems++;
        }

        this.logger.debug('504: ', this.disability504.value);
        if (this.disability504.value) {
            selectedItems++;
        }

        return `${selectedItems}/4 selected`;
    }

    get selectedModules() {
        return this.formGroup.get('selectedModules') as FormControl<FormGroup>;
    }

    // get selectedComparisons() {
    //     return this.formGroup.get('selectedComparisons') as FormControl<FormGroup>;
    // }

    get demographicSelection() {
        return this.formGroup.get('demographicSelection') as FormControl<DemographicType>;
    }

    get elp() {
        return this.formGroup.get(ComparisonType.EL_STATUS.toString()) as FormControl<boolean>;
    }

    get disabilityIDEA() {
        return this.formGroup.get(ComparisonType.IDEA.toString()) as FormControl<boolean>;
    }

    get disability504() {
        return this.formGroup.get(ComparisonType.SECTION504.toString()) as FormControl<boolean>;
    }

}
