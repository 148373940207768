import { AfterViewInit, Component, HostListener, Input, OnInit, Renderer2 } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import * as d3 from 'd3';
import { NumberValue } from 'd3';
import { ChartDataCardComponent } from 'src/app/shared/components/chart-data-card/chart-data-card.component';
import { Tooltip } from 'src/app/shared/models/charts-model';
import { ScreenType } from 'src/app/shared/models/shared-model';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})

/**
 * This class renders a bar chart with the given data
 */
export class BarChartComponent implements OnInit, AfterViewInit {

  @Input() data;
  @Input() sectionName: string;

  private svg: any;
  private svgWidth: number = 603;
  private svgHeight: number = 640;
  public ScreenType = ScreenType;
  public screenType: ScreenType = ScreenType.DESKTOP;
  private margin: any = { top: 20, right: 140, bottom: 0, left: 0 };
  private colorRange: string[] = ["rgba(177, 0, 93, 0.5)", "rgba(19, 53, 89, 0.5)", "rgba(202, 170, 82, 0.5)", "rgba(96, 179, 156, 0.5)",
    "rgba(253, 161, 114, 0.5)", "rgba(119, 186, 43, 0.5)", "rgba(0, 100, 168, 0.5)", "rgba(202, 170, 82, 0.3)", "rgba(96, 179, 156, 0.3)", "rgba(19, 53, 89, 0.3)"];

  constructor(private bottomSheet: MatBottomSheet, private renderer: Renderer2) { }

  /**
   * This is the entry point function to run everything
   */
  ngOnInit(): void {
    this.sectionName = this.sectionName.replace(/\s+/g, '-') + '-bar';
    this.data.forEach((race, i) => { race['color'] = this.colorRange[i]; });

    if (window.innerWidth <= 1120 && window.innerWidth > 925) {
      this.svgWidth = 500;
      this.svgHeight = 640;
      this.screenType = ScreenType.TABLET;
    } else if (window.innerWidth <= 925 && window.innerWidth > 768) {
      this.svgWidth = 435;
      this.svgHeight = 640;
      this.screenType = ScreenType.TABLET;
    } else if (window.innerWidth <= 768) {
      this.svgWidth = 360;
      this.svgHeight = 640;
      this.screenType = ScreenType.MOBILE;
    } else {
      this.svgWidth = 603;
      this.svgHeight = 640;
      this.screenType = ScreenType.DESKTOP;
    }
  }

  ngAfterViewInit(): void {
    this.createSvg();
    this.drawBarChart();
    this.drawLegend();

    const tooltips: Tooltip[] = this.data['tooltips'];

    this.data.forEach((category) => {
      let categoryName: string = category.category;
      let barId: string = categoryName.replace(/\s+/g, '_') + this.sectionName;
      let textId: string = categoryName.replace(/\s+/g, '_') + this.sectionName + '-text'
      let barElement: HTMLElement = document.getElementById(barId);
      let textElement: HTMLElement = document.getElementById(textId);

      if (barElement) {
        this.renderer.listen(barElement, 'click', (e: Event) => {
          let tooltip: Tooltip = tooltips.find((tooltip: Tooltip) => tooltip.label === categoryName)
          this.openBottomSheet(tooltip);
        });
      }

      if (textElement) {
        this.renderer.listen(textElement, 'click', (e: Event) => {
          let tooltip: Tooltip = tooltips.find((tooltip: Tooltip) => tooltip.label === categoryName)
          this.openBottomSheet(tooltip);
        });
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  async onResize(event) {

    this.renderer.destroy();

    let windowWidth: number = event.target.innerWidth;

    if (windowWidth <= 1190 && windowWidth > 985) {
      this.svgWidth = 500;
      this.svgHeight = 640;
      this.screenType = ScreenType.TABLET;
    } else if (windowWidth <= 985 && windowWidth > 855) {
      this.svgWidth = 435;
      this.svgHeight = 640;
      this.screenType = ScreenType.TABLET;
    } else if (windowWidth <= 855) {
      this.svgWidth = 360;
      this.svgHeight = 640;
      this.screenType = ScreenType.MOBILE;
    } else {
      this.svgWidth = 603;
      this.svgHeight = 640;
      this.screenType = ScreenType.DESKTOP;
    }

    d3.select(`figure#${this.sectionName}`).select("svg").remove();
    d3.select(`figure#${this.sectionName}`).selectAll('.bar-chart-tooltip').remove();

    this.createSvg();
    this.drawBarChart();
    this.drawLegend();

    const tooltips: Tooltip[] = this.data['tooltips'];

    this.data.forEach((category, i) => {
      let categoryName: string = category.category;
      let id: string = categoryName.replace(/\s+/g, '_') + this.sectionName;
      let element: HTMLElement = document.getElementById(id);

      if (element) {
        this.renderer.listen(element, 'click', (e: Event) => {
          let tooltip: Tooltip = tooltips.find((tooltip: Tooltip) => tooltip.label === categoryName)
          this.openBottomSheet(tooltip);
        });
      }
    });
  }

  /**
   * This function sets up the svg
   */
  private createSvg(): void {
    this.svg = d3.select(`figure#${this.sectionName}`).append("svg")
      .attr("width", this.svgWidth).attr("height", this.svgHeight)
      .append("g").attr("transform", "translate(" + this.margin.left + "," + this.margin.top + ")");
  }

  /**
   * This function renders the bar chart
   */
  private drawBarChart(): void {
    // Sets up the data to be used
    let columns = this.data.columns.slice(1);
    let localData: any = this.data;

    this.data.sort(function compare(race1, race2) {
      return d3.descending(race1['sortColumn'], race2['sortColumn']);
    });

    let sources = columns.map(function (label) {
      return {
        label: label,
        values: localData.map(function (d) {
          return { category: d.category, value: d[label] };
        })
      }
    });

    let chartWidth = this.svgWidth - this.margin.right - this.margin.left;
    let chartHeight = this.svgHeight - this.margin.top - this.margin.bottom;

    // Sets up the color scheme
    let sortedColors = this.data.map(race => { return race['color']; });
    this.colorRange.forEach((color, i) => {
      if (i > 6) {
        sortedColors.push(color);
      }
    });

    let color = d3.scaleOrdinal().domain(this.getColorDomain(sources)).range(sortedColors);

    // This sets up the x and y axis
    let x = d3.scaleLinear().domain(this.getXDomain()).range([0, chartWidth]);
    let y = d3.scaleBand().domain(this.getYDomain()).range([0, chartHeight]).paddingInner(.55).paddingOuter(.1).align(1);

    let xAxis = d3.axisBottom(x).tickSize(0);
    let yAxis = d3.axisLeft(y).ticks(0);

    // This sets up the y sub groups
    let ySubgroup = d3.scaleBand().domain(this.getSubGroups()).range([0, y.bandwidth()]).paddingInner(.4);

    // This renders the axes
    this.svg.append("g").attr("class", "axis axis--x").attr("transform", "translate(0," + chartHeight + ")").call(customXAxis);
    this.svg.append("g").attr("class", "axis rightAxis--y").call(customYAxis);

    // This renders the bar chart
    this.createBars(x, y, ySubgroup, color);
    this.createText(y, ySubgroup);
    this.scaleBars(ySubgroup.bandwidth());

    /**
     * This funciton customizes the x axis
     * @param g the svg
     */
    function customXAxis(g) {
      g.call(xAxis);
      g.select(".domain").remove();
      g.selectAll(".tick line").remove();
      g.selectAll(".tick text").remove();
    }

    /**
     * This function customizes the y axis
     * @param g the svg
     */
    function customYAxis(g) {
      g.call(yAxis);
      g.select(".domain").remove();
      g.selectAll(".tick text").remove();
    }
  }

  /**
   * This function renders the bars into the svg
   * @param x the x axis
   * @param y the y axis
   * @param ySubgroup the y subgroup axis
   * @param color the color for the variables
   */
  private createBars(x, y, ySubgroup, color): void {
    let columns = this.data.columns.slice(1);

    var tooltip = d3.select(`figure#${this.sectionName}`).append("div")
      .attr("class", "bar-chart-tooltip")
      .style("visibility", "hidden")
      .style("position", "absolute");

    let tooltips: Tooltip[] = this.data['tooltips'];

    let svgSectionName = this.sectionName;
    let screen = this.screenType;

    let categories = this.data.map(category => category.category);

    this.svg.append("g").selectAll("g").data(this.data).enter()
      .append("g").attr("transform", function (d) { return "translate(0," + y(d.category) + ")"; })
      .selectAll("rect")
      .data(function (d) { return columns.map(function (label, i) { return { label: label, value: d[label], category: d.category, index: i }; }); }).enter()
      .append("rect")
      .attr("id", function (d) { if (d.index == 0) return d.category.replace(/\s+/g, '_') + svgSectionName; else return d.label; })
      .attr("x", x(0))
      .attr("y", function (d) { return ySubgroup(d.label); })
      .attr("width", function (d) { return x(d.value); })
      .attr("height", ySubgroup.bandwidth())
      .attr("fill", function (d) {
        if (d.index == 0) {
          return color(d.category);
        } else {
          return color(d.label);
        }
      })
      .on("mouseover", function (event, d) {
        if (screen === ScreenType.DESKTOP) {
          let currentTooltip: Tooltip = tooltips.find((tooltip: Tooltip) => tooltip.label === d.category);
          tooltip.style("visibility", "visible");
          tooltip.html(currentTooltip.html);
        }
      })
      .on("mousemove", function (event, d) {
        if (screen === ScreenType.DESKTOP) {
          let svgElement: HTMLElement = document.getElementById(svgSectionName);
          let svgElementOffsetTop: number = svgElement.offsetTop;
          let svgElementOffsetLeft: number = svgElement.offsetLeft;
          let tooltipElement: Element = document.getElementsByClassName("chart-tooltip-container")[0];
          let tooltipHeight: number = tooltipElement.clientHeight;
          let categoryIndex: number = categories.indexOf(d.category);
          let paddingOuter: number = y.step() * .25 * 2;
          let top: number = svgElementOffsetTop + paddingOuter + (categoryIndex * y.step()) - tooltipHeight - 60;
  
          tooltip.style("top", (top) + "px").style("left", (svgElementOffsetLeft) + "px");
        }
      })
      .on("mouseout", function (event, d) {
        tooltip.style("visibility", "hidden");
      });
  }

  /**
   * This function creates the text labels for the bars
   * @param bandwith the bandwith of the subgroup
   * @param y the y scale
   * @param ySubgroup the y subgroup scale
   */
  private createText(y, ySubgroup) {
    let columns = this.data.columns.slice(1);
    let tooltips: Tooltip[] = this.data['tooltips'];
    let svgSectionName = this.sectionName;
    let screen = this.screenType;
    let categories = this.data.map(category => category.category);

    var tooltip = d3.select(`figure#${this.sectionName}`).append("div")
    .attr("class", "bar-chart-tooltip")
    .style("visibility", "hidden")
    .style("position", "absolute");

    this.svg.append("g").selectAll("g").data(this.data).enter()
      .append("g").attr("transform", function (d) { return "translate(0," + y(d.category) + ")"; })
      .selectAll("rect").data(function (d) { return columns.map(function (label, i) { return { label: label, value: d[label], category: d.category, index: i }; }); }).enter()
      .append("text")
      .attr("id", function (d) { return d.category.replace(/\s+/g, '_') + svgSectionName + '-text'; })
      .attr("x", 10)
      .attr("y", function (d) { return ySubgroup(d.label); })
      .attr("dy", function () {
        let newWidth = ySubgroup.bandwidth() * 5;
        let newYPos = -(newWidth - ySubgroup.bandwidth());

        return "" + newYPos;
      })
      .text(function (d) {
         if (d.index == 0) {
          let currValue = d.value < 1 && d.value > 0 ? '<1.0' : d.value.toFixed(1);
          return `${d.category} ${currValue}%`;
         } else {
          return '';
         }; 
        })
      .style("fill", "rgba(19, 53, 89, 1)")
      .style("font-family", "Raleway, san-serif")
      .style("font-size", "14px")
      .on("mouseover", function (event, d) {
        if (screen === ScreenType.DESKTOP) {
          let currentTooltip: Tooltip = tooltips.find((tooltip: Tooltip) => tooltip.label === d.category);
          tooltip.style("visibility", "visible");
          tooltip.html(currentTooltip.html);
        }
      })
      .on("mousemove", function (event, d) {
        if (screen === ScreenType.DESKTOP) {
          let svgElement: HTMLElement = document.getElementById(svgSectionName);
          let svgElementOffsetTop: number = svgElement.offsetTop;
          let svgElementOffsetLeft: number = svgElement.offsetLeft;
          let tooltipElement: Element = document.getElementsByClassName("chart-tooltip-container")[0];
          let tooltipHeight: number = tooltipElement.clientHeight;
          let categoryIndex: number = categories.indexOf(d.category);
          let paddingOuter: number = y.step() * .25 * 2;
          let top: number = svgElementOffsetTop + paddingOuter + (categoryIndex * y.step()) - tooltipHeight - 60;
  
          tooltip.style("top", (top) + "px").style("left", (svgElementOffsetLeft) + "px");
        }
      })
      .on("mouseout", function (event, d) {
        tooltip.style("visibility", "hidden");
      });;
  }

  /**
   * This function draws the legend for the bar chart.
   */
  private drawLegend(): void {
    let domain = this.data.columns.slice(2).reverse();
    domain = domain.filter(column => !column.includes('pad')).reverse();

    let colorRange = ["rgba(202, 170, 82, 0.3)", "rgba(96, 179, 156, 0.3)", "rgba(19, 53, 89, 0.3)"];

    let colorRangeLength = colorRange.length;
    let domainLength = domain.length;
    let elementToDelete = colorRangeLength - domainLength;

    colorRange.splice(domainLength, elementToDelete);

    let color = d3.scaleOrdinal().domain(domain).range(colorRange);

    let z = color;

    domain.forEach((column, i) => {
      // if ()
      let bottomPadding = 4.245;
      let verticalPadding = 16.77 * i
      let rightSidePadding = 88.67;
      let textPadding = 30

      let x = this.svgWidth - rightSidePadding;
      let y = this.svgHeight - 55 - bottomPadding + verticalPadding;

      this.svg.append("rect").attr("width", 16.33).attr("height", 4.23).attr("x", x).attr("y", y).style("fill", z(column));
      this.svg.append("text").attr("x", x + textPadding).attr("y", y + 4).text(column).style("font-size", "12px").attr("fill", "#133559");
    });

    function wrap(text, width) {
      text.each(function () {
        var text = d3.select(this),
          words = text.text().split(" ").reverse(),
          word,
          line = [],
          lineNumber = 0,
          lineHeight = 17,
          x = text.attr("x"),
          y = text.attr("y"),
          tspan = text.text(null).append("tspan").attr("x", x).attr("y", y);
        while (word = words.pop()) {
          line.push(word);
          tspan.text(line.join(" "));
          if (tspan.node().getComputedTextLength() > width) {
            line.pop();
            tspan.text(line.join(" "));
            line = [word];
            tspan = text.append("tspan").attr("x", x).attr("y", y).attr("dy", ++lineNumber * lineHeight + "px").text(word);
          }
        }
      });
    }
  }

  /**
   * This function gets the domain for all the colors.
   * @param sources the sources of the data given the columns
   * @returns the domain over which the colors will be placed
   */
  private getColorDomain(sources: any): Iterable<string> {
    let domain = [];

    this.data.forEach(element => {
      domain.push(element.category);
    });

    sources.splice(1, sources.length).forEach(element => {
      domain.push(element.label);
    });

    return domain;
  }

  /**
   * This function gets the subgroups of the data delineated in the columns.
   * @returns the subgroups given the columns of the data
   */
  private getSubGroups(): string[] {
    let subgroups = this.data.columns.slice(1);

    return subgroups;
  }

  /**
   * This function gets the y domain given the data.
   * @returns the y domain as an array of strings
   */
  private getYDomain(): string[] {
    let domain = [];

    this.data.forEach(element => {
      domain.push(element.category);
    });

    return domain;
  }

  /**
   * This function gets the x domain given the data.
   * @returns the x domain as an array of NumberValue
   */
  private getXDomain(): NumberValue[] {
    let data = this.data;
    let sources = this.data.columns.slice(1).map(function (label) {
      return {
        label: label,
        values: data.map(function (d) {
          return { category: d.category, value: d[label] };
        })
      }
    });

    let maxXValue = d3.max(sources, function (c: any) {
      let max = d3.max(c.values, function (d: any) {
        return d.value;
      });

      return max;
    });

    return [0, +maxXValue];
  }

  /**
   * This function scales and moves the first bars of each group.
   * @param bandwith the bandwith of an unscaled bar
   */
  private scaleBars(bandwith): void {
    let newWidth = bandwith * 4;
    let newYPos = bandwith - newWidth;

    this.getYDomain().forEach(element => {
      let elementName: string = element.replace(/\s+/g, '_') + this.sectionName;
      this.svg.select(`#${elementName}`)
        .attr("height", newWidth)
        .attr("transform", "translate(0," + newYPos + ")")
    });
  }

  openBottomSheet(tooltip: Tooltip): void {
    if (this.screenType !== ScreenType.DESKTOP) {
      this.bottomSheet.open(ChartDataCardComponent, {
        data: tooltip
      });
    }
  }
}
