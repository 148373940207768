import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-share-report',
  templateUrl: './share-report.component.html',
  styleUrls: ['./share-report.component.scss']
})
export class ShareReportComponent implements OnInit {

  reportDate: string;
  displayTooltip: boolean = false;

  constructor() { }

  ngOnInit(): void {
    const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    let today = new Date();
    let day: number = today.getDate();
    let month: string = monthNames[today.getMonth()];
    let year: number = today.getFullYear();

    this.reportDate = `${month} ${day}, ${year}`
  }

  copyURL() {
    let url: string = window.location.href;
    navigator.clipboard.writeText(url);
    this.displayTooltip = true;
    setTimeout(()=>{ this.displayTooltip = false }, 2000);
  }

  printReport() {
    window.print();
  }

}
