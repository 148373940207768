import { LOCALE_ID, Inject, Component, HostListener, Input, OnInit, ViewEncapsulation } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { throwError } from 'rxjs';
import { ChartDto, ChartTypesDto, GroupValueDto } from '../../models/charts-model';
import { ScreenType } from '../../models/shared-model';
import { EntityType, Genders, Measure, ProfileBlockDto, SecondaryMetrics, State } from '../../models/profile-model';
import { EnrollmentDataQuery } from '../../queries/enrollment-data-query';
import { MiscFieldDataQuery } from '../../queries/misc-field-data-query';
import { StateNationalEtimationQuery } from '../../queries/state-national-estimation-query';
import { DistrictProfileQueryModel } from '../../query-models/district-profile-query-model';
import { SchoolProfileQueryModel } from '../../query-models/school-profile-query-model';
import { EnrollmentDataService } from '../../services/data/enrollment-data.service';
import { MiscService } from '../../services/data/misc.service';
import { StateNationalEstimationService } from '../../services/data/state-national-estimation.service';
import { ProfileService } from '../../services/helpers/profile.service';
import { formatNumber } from '@angular/common';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'app-profile-block',
  templateUrl: './profile-block.component.html',
  styleUrls: ['./profile-block.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProfileBlockComponent implements OnInit {

  @Input() profileBlock: ProfileBlockDto;
  @Input() direction: string;

  public chartDataIsLoaded: boolean = false;
  public entityDataIsLoaded: boolean = false;
  private chartLoadIndex: number = 0;
  public miscMeasureLoaded: boolean = false;
  public dataRequestFailed: boolean = false;
  public dataReportedTotalZero: boolean = false;
  public dataNotAvailable: boolean = false;
  public dataQualityStandardsNotMet: boolean = false;
  public dataTimedOut: boolean = false;

  public miscMeasure: string;
  public selectedMeasure: any;
  public selectedSubcategory: any;
  public selectedGender: Genders;
  public selectedChart: ChartTypesDto;
  public selectedSecondaryMetric: SecondaryMetrics;

  public sectionTitle: string;
  public sectionSubtitle: string;
  public categories: any[] = [];
  public subCategories: any[] = [];
  public measures: Measure[] = [];
  public genders: Genders[];
  public chartTypes: ChartTypesDto[];
  public secondaryMetrics: SecondaryMetrics[];

  ScreenType = ScreenType;
  public screenType: ScreenType = ScreenType.DESKTOP;

  chartData: ChartDto = {
    categories: [
      { groupName: 'American Indian or Alaska Native', values: [] },
      { groupName: 'Asian', values: [] },
      { groupName: 'Black or African American', values: [] },
      { groupName: 'Hispanic or Latino of any race', values: [] },
      { groupName: 'Native Hawaiian or Other Pacific Islander', values: [] },
      { groupName: 'Two or more races', values: [] },
      { groupName: 'White', values: [] }
    ],
    enrollmentData: null,
    selectedGender: null,
    entityType: null,
    measure: null
  };

  constructor(
    private readonly enrollmentDataService: EnrollmentDataService,
    private readonly miscDataService: MiscService,
    private readonly stateNationalEstimationService: StateNationalEstimationService,
    private profileService: ProfileService,
    @Inject(LOCALE_ID) public locale: string
  ) { }

  ngOnInit(): void {
    this.loadProfileBlock();
    this.screenType = window.innerWidth <= 768 ? ScreenType.MOBILE : ScreenType.DESKTOP;
  }

  @HostListener('window:resize', ['$event'])
  async onResize(event) {
    let width: number = event.target.innerWidth;
    this.screenType = width <= 768 ? ScreenType.MOBILE : ScreenType.DESKTOP;
  }

  loadProfileBlock() {
    let section: any = this.profileBlock.section;

    this.selectedGender = Genders.ALL_STUDENTS;
    this.genders = [Genders.ALL_STUDENTS, Genders.MALE, Genders.FEMALE];
    this.chartTypes = [ChartTypesDto.BUBBLE_CHART, ChartTypesDto.BAR_CHART];
    this.selectedChart = this.direction === 'left' ? ChartTypesDto.BUBBLE_CHART : ChartTypesDto.BAR_CHART;
    this.sectionTitle = section.title;
    this.sectionSubtitle = section.subtitle;
    this.categories = section.data;
    this.subCategories = this.categories[0].data;
    this.selectedSubcategory = this.subCategories[0];
    this.measures = this.selectedSubcategory.data;
    this.selectedMeasure = this.measures[0];
    this.downloadChartData(this.selectedMeasure);

    setTimeout(() => {
      this.dataTimedOut = true;
    }, 1000);
  }

  async onSubCategoryChange(subCategory: any, element: HTMLElement) {
    this.chartLoadIndex = 0;
    this.dataRequestFailed = false;
    this.dataReportedTotalZero = false;
    this.dataNotAvailable = false;
    this.dataQualityStandardsNotMet = false;
    this.entityDataIsLoaded = false;
    this.chartDataIsLoaded = false;
    this.miscMeasureLoaded = false;
    this.dataTimedOut = false;
    this.selectedMeasure = null;
    this.selectedSubcategory = subCategory;
    this.measures = this.selectedSubcategory.data;
    this.selectedMeasure = this.measures[0];
    this.downloadChartData(this.selectedMeasure);

    setTimeout(() => {
      this.dataTimedOut = true;
    }, 1000);

    if (this.screenType === ScreenType.MOBILE) {
      element.offsetParent.scrollTop = element.offsetTop - 40;
    }
  }

  onMeasureChange() {
    this.chartLoadIndex = 0;
    this.dataRequestFailed = false;
    this.dataReportedTotalZero = false;
    this.dataNotAvailable = false;
    this.dataQualityStandardsNotMet = false;
    this.entityDataIsLoaded = false;
    this.chartDataIsLoaded = false;
    this.miscMeasureLoaded = false;
    this.dataTimedOut = false;
    this.downloadChartData(this.selectedMeasure);

    setTimeout(() => {
      this.dataTimedOut = true;
    }, 1000);
  }

  onGenderChange() {
    this.chartLoadIndex = 0;
    this.dataRequestFailed = false;
    this.dataReportedTotalZero = false;
    this.dataNotAvailable = false;
    this.dataQualityStandardsNotMet = false;
    this.entityDataIsLoaded = false;
    this.chartDataIsLoaded = false;
    this.dataTimedOut = false;
    this.downloadChartData(this.selectedMeasure);

    setTimeout(() => {
      this.dataTimedOut = true;
    }, 1000);
  }

  async downloadChartData(measure: Measure): Promise<any> {
    if (!isNaN(measure.measure)) {
      let entityType = this.profileBlock.entityType;
      let entityId = this.profileBlock.entityId;

      switch (entityType) {
        case EntityType.SCHOOL:
          let schoolProfile: SchoolProfileQueryModel = JSON.parse(sessionStorage.getItem('schoolProfile'));
          let leaId: number = schoolProfile.lea_Id;

          return await Promise.all([
            this.getDistrictSchoolData(entityId, entityType, measure),
            this.getDistrictSchoolData(leaId, EntityType.DISTRICT, measure),
            this.getStateNationalData(EntityType.STATE, measure, schoolProfile.stateId),
            this.getStateNationalData(EntityType.NATIONAL, measure)]);
        case EntityType.DISTRICT:
          let districtProfile: DistrictProfileQueryModel = JSON.parse(sessionStorage.getItem('districtProfile'));
          let stateCode: string = districtProfile.leaState;
          let state: State = this.profileService.getStateIds().find(state => state.state_Code === stateCode);
          let stateId: number = state.state_Id;

          return await Promise.all([
            this.getDistrictSchoolData(entityId, entityType, measure),
            this.getStateNationalData(EntityType.STATE, measure, stateId),
            this.getStateNationalData(EntityType.NATIONAL, measure)]);
        case EntityType.STATE:
          return await Promise.all([
            this.getStateNationalData(EntityType.STATE, measure, entityId),
            this.getStateNationalData(EntityType.NATIONAL, measure)
          ])
        case EntityType.NATIONAL:
          return [this.getStateNationalData(EntityType.NATIONAL, measure)];
      }
    } else {
      return [this.getMiscData(measure)];
    }
  }

  generateGroupData(data: any, entityType: EntityType, measure: Measure) {
    let profileEntityType: EntityType = this.profileBlock.entityType;
    let groupData: GroupValueDto[] = [];
    let dataValues: string[] = [];
    let total: number;

    switch (this.selectedGender) {
      case Genders.ALL_STUDENTS:
        if (entityType === EntityType.SCHOOL || entityType === EntityType.DISTRICT) {
          dataValues = ['b_AME', 'b_ASI', 'b_BLA', 'b_HIS', 'b_HI_PAC', 'b_MORE', 'b_WHI'];
          total = data.b_TOT;
        } else {
          dataValues = ['b_AmeCount', 'b_AsiCount', 'b_BlaCount', 'b_HisCount', 'b_HiPacCount', 'b_MoreCount', 'b_WhiCount'];
          total = data.b_Count;
        }
        break;
      case Genders.FEMALE:
        if (entityType === EntityType.SCHOOL || entityType === EntityType.DISTRICT) {
          dataValues = ['f_AME', 'f_ASI', 'f_BLA', 'f_HIS', 'f_HI_PAC', 'f_MORE', 'f_WHI'];
          total = data.f_TOT;
        } else {
          dataValues = ['f_AmeCount', 'f_AsiCount', 'f_BlaCount', 'f_HisCount', 'f_HiPacCount', 'f_MoreCount', 'f_WhiCount'];
          total = data.f_Count;
        }
        break;
      case Genders.MALE:
        if (entityType === EntityType.SCHOOL || entityType === EntityType.DISTRICT) {
          dataValues = ['m_AME', 'm_ASI', 'm_BLA', 'm_HIS', 'm_HI_PAC', 'm_MORE', 'm_WHI'];
          total = data.m_TOT;
        } else {
          dataValues = ['m_AmeCount', 'm_AsiCount', 'm_BlaCount', 'm_HisCount', 'm_HiPacCount', 'm_MoreCount', 'm_WhiCount'];
          total = data.m_Count;
        }
        break;
    }

    if (total > 0) {
      dataValues.forEach((value: string) => {
        let groupValue: GroupValueDto = {
          label: entityType,
          value: data[value]
        }

        groupData.push(groupValue);
      });

      if (!this.entityDataIsLoaded && (profileEntityType === entityType)) {
        this.chartData.enrollmentData = data;
        this.chartData.measure = measure;
        this.chartData.selectedGender = this.selectedGender;
        this.chartData.entityType = this.profileBlock.entityType;
        this.entityDataIsLoaded = true;
      }
    } else if (!this.entityDataIsLoaded && profileEntityType === entityType) {
      if (total === 0) {
        this.dataReportedTotalZero = true;
      } else if (total == -11) {
        this.dataQualityStandardsNotMet = true;
      } else {
        this.dataNotAvailable = true;
      }
    }

    let index: number = 0;

    switch (profileEntityType) {
      case EntityType.SCHOOL:
        break;
      case EntityType.DISTRICT:
        index--;
        break;
      case EntityType.STATE:
        index = index - 2;
        break;
      case EntityType.NATIONAL:
        index = index - 3;
        break;
    }

    this.chartData.categories.forEach((category, i) => {
      switch (entityType) {
        case EntityType.SCHOOL:
          category.values[0 + index] = groupData[i];
          break;
        case EntityType.DISTRICT:
          category.values[1 + index] = groupData[i];
          break;
        case EntityType.STATE:
          category.values[2 + index] = groupData[i];
          break;
        case EntityType.NATIONAL:
          category.values[3 + index] = groupData[i];
          break;
      }
    });

    switch (profileEntityType) {
      case EntityType.SCHOOL:
        this.chartDataIsLoaded = this.chartLoadIndex === 4;
        break;
      case EntityType.DISTRICT:
        this.chartDataIsLoaded = this.chartLoadIndex === 3;
        break;
      case EntityType.STATE:
        this.chartDataIsLoaded = this.chartLoadIndex === 2;
        break;
      case EntityType.NATIONAL:
        this.chartDataIsLoaded = this.chartLoadIndex === 1;
        break;
    }
  }

  async getDistrictSchoolData(enittyId: number, entityType: EntityType, measure: Measure): Promise<void> {
    const query: EnrollmentDataQuery = new EnrollmentDataQuery();
    query.surveyYearKey = this.profileBlock.surveyYear;
    query.entityId = enittyId;
    query.measureId = measure.measure;

    this.enrollmentDataService.getEnrollmentData(query).subscribe(data => {
      this.chartLoadIndex++;

      if (data) {
        return Promise.resolve(this.generateGroupData(data, entityType, measure));
      } else {
        if (entityType === this.profileBlock.entityType) {
          this.dataRequestFailed = true;
        }

        return Promise.reject(new Error(`Could not load ${entityType} data.`));
      }
    });
  }

  async getStateNationalData(entityType: EntityType, measure: Measure, stateId?: number): Promise<void> {
    const stateQuery: StateNationalEtimationQuery = new StateNationalEtimationQuery();
    stateQuery.surveyYearKey = this.profileBlock.surveyYear;
    stateQuery.measureId = measure.measure;

    if (stateId) {
      stateQuery.stateId = stateId;

      this.stateNationalEstimationService.getStateEstimation(stateQuery).subscribe(data => {
        this.chartLoadIndex++;

        if (data) {
          return Promise.resolve(this.generateGroupData(data, entityType, measure));
        } else {
          if (entityType === this.profileBlock.entityType) {
            this.dataRequestFailed = true;
          }

          return Promise.reject(new Error(`Could not load ${entityType} data.`));
        }
      });
    } else {
      this.stateNationalEstimationService.getNationalEstimation(stateQuery).subscribe(data => {
        this.chartLoadIndex++;

        if (data) {
          return Promise.resolve(this.generateGroupData(data, entityType, measure));
        } else {
          if (entityType === this.profileBlock.entityType) {
            this.dataRequestFailed = true;
          }

          return Promise.reject(new Error(`Could not load ${entityType} data.`));
        }
      });
    }


  }

  async getMiscData(measure: Measure): Promise<void> {
    let entityType: EntityType = this.profileBlock.entityType;

    if (entityType === EntityType.SCHOOL || entityType === EntityType.DISTRICT) {
      let query: MiscFieldDataQuery = new MiscFieldDataQuery();

      query.entityId = this.profileBlock.entityId;
      query.surveyYearKey = this.profileBlock.surveyYear;
      query.field = measure.measure;
      query.isSchoolOrDistrict = entityType === EntityType.SCHOOL ? 's' : 'd';

      this.miscDataService.getMiscField(query).subscribe(data => {
        if (data) {
          if (data.value && (data.value.length > 0)) {
            let miscValue: number = Number(data.value);

            if (isNaN(miscValue)) {
              this.miscMeasure = data.value;
              this.miscMeasureLoaded = true;
            } else if (miscValue === -11) {
              this.miscMeasure = 'ǂ'
              this.miscMeasureLoaded = true;
            } else if (miscValue < 0) {
              this.miscMeasure = '—';
              this.miscMeasureLoaded = true;
            } else {
              this.miscMeasure = formatNumber(miscValue, this.locale, measure.dMeasure_Format);
              this.miscMeasureLoaded = true;
            }
          } else {
            this.miscMeasure = '—';
            this.miscMeasureLoaded = true;
          }
        } else {
          this.dataRequestFailed = true;
          throwError("Could not load misc data");
        }
      });
    } else {
      let query: StateNationalEtimationQuery = new StateNationalEtimationQuery();

      query.measureId = 1;
      query.surveyYearKey = this.profileBlock.surveyYear;

      if (entityType === EntityType.STATE) {
        query.stateId = this.profileBlock.entityId;
        this.stateNationalEstimationService.getStateEstimation(query).subscribe(data => {
          if (data) {
            let miscValue: any = data['st' + measure.measure];

            if (miscValue == null) {
              this.miscMeasure = '—'
              this.miscMeasureLoaded = true;
            } else if (isNaN(miscValue)) {
              this.miscMeasure = data.value;
              this.miscMeasureLoaded = true;
            } else if (miscValue === -11) {
              this.miscMeasure = 'ǂ'
              this.miscMeasureLoaded = true;
            } else if (miscValue < 0) {
              this.miscMeasure = '—';
              this.miscMeasureLoaded = true;
            } else {
              this.miscMeasure = formatNumber(miscValue, this.locale, measure.dMeasure_Format);
              this.miscMeasureLoaded = true;
            }
          } else {
            this.dataRequestFailed = true;
            throwError("Could not load misc data");
          }
        });
      } else {
        this.stateNationalEstimationService.getNationalEstimation(query).subscribe(data => {
          if (data) {
            let miscValue: any = data['n' + measure.measure];

            if (miscValue == null) {
              this.miscMeasure = '—'
              this.miscMeasureLoaded = true;
            } else if (isNaN(miscValue)) {
              this.miscMeasure = data.value;
              this.miscMeasureLoaded = true;
            } else if (miscValue === -11) {
              this.miscMeasure = 'ǂ'
              this.miscMeasureLoaded = true;
            } else if (miscValue < 0) {
              this.miscMeasure = '—';
              this.miscMeasureLoaded = true;
            } else {
              this.miscMeasure = formatNumber(miscValue, this.locale, measure.dMeasure_Format);
              this.miscMeasureLoaded = true;
            }
          } else {
            this.dataRequestFailed = true;
            throwError("Could not load misc data");
          }
        });
      }
    }
  }

  getChartLabel(chartType: ChartTypesDto): string {
    switch (chartType) {
      case ChartTypesDto.BAR_CHART:
        return 'Bar Chart';
      case ChartTypesDto.BUBBLE_CHART:
        return 'Bubble Chart';
    }
  }

  downloadExcelFile() {
    const excelFileName: string = this.selectedMeasure.title;
    let chartJson: any[] = this.chartData.categories.map(category => {
      let group: any = { 'Group': category.groupName };

      category.values.forEach(value => {
        group[value.label] = this.profileService.getFormattedCount(value.value);
      });

      return group;
    });

    let femaleValues: string[];
    let maleValues: string[];
    let entityType: EntityType = this.profileBlock.entityType;

    if (entityType === EntityType.SCHOOL || entityType === EntityType.DISTRICT) {
      femaleValues = ['f_AME', 'f_ASI', 'f_BLA', 'f_HIS', 'f_HI_PAC', 'f_MORE', 'f_WHI'];
      maleValues = ['m_AME', 'm_ASI', 'm_BLA', 'm_HIS', 'm_HI_PAC', 'm_MORE', 'm_WHI'];
    } else {
      femaleValues = ['f_AmeCount', 'f_AsiCount', 'f_BlaCount', 'f_HisCount', 'f_HiPacCount', 'f_MoreCount', 'f_WhiCount'];
      maleValues = ['m_AmeCount', 'm_AsiCount', 'm_BlaCount', 'm_HisCount', 'm_HiPacCount', 'm_MoreCount', 'm_WhiCount'];
    }

    femaleValues.forEach((value, i) => {
      chartJson[i][entityType + ' Female'] = this.profileService.getFormattedCount(this.chartData.enrollmentData[value]);
    });

    maleValues.forEach((value, i) => {
      chartJson[i][entityType + ' Male'] = this.profileService.getFormattedCount(this.chartData.enrollmentData[value]);
    });

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(chartJson);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }
}
