import { Injectable } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";

@Injectable({ providedIn: 'root' })
export class UrlGuard  {
    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        for (const obj in route.queryParams) {
            if (route.queryParams[obj].includes("<script>") || route.queryParams[obj].includes("</script>")) {
                const param = route.queryParams[obj].replace('<script>', '').replace('</script>', '');

                const queryParams = { }
                queryParams[obj] = param;
                const url = state.url.split('?')[0];
                this.router.navigate([url], {
                    queryParams,
                    queryParamsHandling: 'merge'
                });
            }
        }
        
        return true;
    }
}
