import { Component, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UrlSerice } from '../../services/helpers/url-service';

@Component({
  selector: 'app-wildcard-interceptor',
  templateUrl: './wildcard-interceptor.component.html',
  styleUrls: ['./wildcard-interceptor.component.scss']
})
export class WildcardInterceptorComponent implements OnInit {

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private urlService: UrlSerice) {
    const url: Observable<UrlSegment[]> = activatedRoute.url;

    url.subscribe(url => {
      urlService.setPreviousUrl(url);
      this.router.navigate(['/404']);
    });
  }

  async ngOnInit() { }

}
