<footer class="footer pb-0 is-flex is-justify-content-center">
    <div class="footer-container">
        <div class="tile is-ancestor">
            <div class="tile is-parent">
                <div
                    class="tile is-child is-flex is-flex-direction-column is-justify-content-left has-text-centered-mobile">
                    <h6 class="footer-header">About</h6>
                    <a class="footer-item" routerLink="/about/ocr">Office For Civil Rights</a>
                    <a class="footer-item" routerLink="/about/crdc">Civil Rights Data Collection</a>
                    <a class="footer-item" routerLink="/about/faqs">Frequently Asked Questions</a>
                </div>
            </div>
            <div class="tile is-parent">
                <div
                    class="tile is-child is-flex is-flex-direction-column is-justify-content-left has-text-centered-mobile">
                    <h6 class="footer-header">Explore the Data</h6>
                    <a class="footer-item" routerLink="/view">Browse by State</a>
                    <a class="footer-item" routerLink="/profile/us" [queryParams]="{surveyYear: '2020'}">Browse National
                        Data</a>
                    <a class="footer-item" routerLink="/data">Download Data Files</a>
                    <a class="footer-item" href="/archive" target="_blank" rel="noreferrer noopener">Access Historical
                        Data</a>
                </div>
            </div>
            <div class="tile is-parent">
                <div
                    class="tile is-child is-flex is-flex-direction-column is-justify-content-left has-text-centered-mobile">
                    <h6 class="footer-header">Office for Civil Rights</h6>
                    <a class="footer-item" href="https://www2.ed.gov/about/offices/list/ocr/newsroom.html"
                        target="_blank" rel="noreferrer noopener">OCR Newsroom</a>
                    <a class="footer-item" href="https://www2.ed.gov/about/offices/list/ocr/data.html" target="_blank"
                        rel="noreferrer noopener">OCR CRDC Page</a>
                    <a class="footer-item"
                        href="https://www2.ed.gov/about/offices/list/ocr/reports-resources.html#:~:text=OCR%20Reports%20and%20Resources&text=The%20Reports%20and%20Resources%20lists,%2C%20sex%2C%20disability%20and%20age."
                        target="_blank" rel="noreferrer noopener">OCR Reports & Resources</a>
                    <a class="footer-item" href="https://www2.ed.gov/about/offices/list/ocr/docs/howto.html"
                        target="_blank" rel="noreferrer noopener">File Discrimination Complaint</a>
                </div>
            </div>
            <div *ngIf="!production" class="tile is-parent">
                <div
                    class="tile is-child is-flex is-flex-direction-column is-justify-content-left has-text-centered-mobile">
                    <h6 class="footer-header">Enforcement Officers</h6>
                    <a class="footer-item" role="menuitem" [routerLink]="isLoggedIn ? '/data-tool' : '/data-tool-login-landing'" rel="noreferrer noopener">Data Compare Tool</a>
                </div>
            </div>
            <div class="tile is-parent">
                <div
                    class="tile is-child is-flex is-flex-direction-column is-justify-content-left has-text-centered-mobile">
                    <h6 class="footer-header">Contact</h6>
                    <a class="footer-item" href="mailto:civilrightsdata&#64;ed.gov">CRDC Questions</a>
                    <a class="footer-item" href="https://www2.ed.gov/about/offices/list/oco/contacts.html"
                        target="_blank" rel="noreferrer noopener">Media Inquiries</a>
                    <a class="footer-item" href="https://ocrcas.ed.gov/contact-ocr" target="_blank"
                        rel="noreferrer noopener">Contact OCR</a>
                    <a class="footer-item" href="https://twitter.com/edcivilrights" target="_blank"
                        rel="noreferrer noopener">
                        <img src="assets/icons/twitter.svg" alt="CRDC Twitter" width="18px" height="14.4px" />
                    </a>
                </div>
            </div>
        </div>
        <div
            class="is-hidden-touch bottom-content is-flex is-align-items-end is-flex-direction-row is-justify-content-space-between">
            <div class="mission-logo-container is-flex is-flex-direction-column is-justify-content-left">
                <p class="footer-mission">The mission of the Office for Civil Rights is to ensure equal access to
                    education and to promote educational excellence throughout the nation through vigorous enforcement
                    of civil rights.</p>
                <div class="is-flex is-flex-direction-row">
                    <img class="new-logo" src="assets/images/logo_new.png" alt="Department of Education logo"/>
                    <div class="is-flex-direction-column pl-3">
                        <p class="logo-title">Civil Rights Data Collection</p>
                        <p class="logo-title">Office for Civil Rights</p>
                        <p class="logo-subtitle">U.S. Department of Education</p>
                    </div>
                </div>
            </div>
            <div class="site-update-container is-flex is-flex-direction-row" style="gap:5px;">
                <p class="site-update small-body">Site last updated: {{lastUpdatedDate}}</p>
                <p class="site-update small-body">|</p>
                <a [routerLink]="['/accessibility_statement']" class="accessibility-statement small-body">Accessibility
                    Statement</a>
                <p class="site-update small-body">|</p>
                <a href="https://www2.ed.gov/notices/privacy/index.html" target="_blank" rel="noreferrer noopener"
                    class="accessibility-statement small-body">Privacy Policy</a>
                <p class="site-update small-body">|</p>
                <div class="dropdown is-hoverable is-up">
                    <div class="dropdown-trigger">
                        <button class="button small-body has-text-white" aria-haspopup="true"
                            aria-controls="dropdown-menu4">
                            <span>Language Assistance</span>
                            <span class="icon is-small">
                                <i class="fas fa-angle-down" aria-hidden="true"></i>
                            </span>
                        </button>
                    </div>
                    <div class="dropdown-menu" id="dropdown-menu4" role="menu">
                        <div class="dropdown-content">
                            <a href="http://www.ed.gov/notices/english-la" target="_blank" rel="noreferrer noopener"
                                class="dropdown-item body has-text-black" style="text-decoration:none">
                                English
                            </a>
                            <a href="http://www.ed.gov/notices/espanol-la" target="_blank" rel="noreferrer noopener"
                                class="dropdown-item body has-text-black" style="text-decoration:none">
                                Español
                            </a>
                            <a href="http://www.ed.gov/notices/chinese-la" target="_blank" rel="noreferrer noopener"
                                class="dropdown-item body has-text-black" style="text-decoration:none">
                                中文: 繁體版
                            </a>
                            <a href="http://www.ed.gov/notices/vietnamese-la" target="_blank" rel="noreferrer noopener"
                                class="dropdown-item body has-text-black" style="text-decoration:none">
                                Việt-ngữ
                            </a>
                            <a href="http://www.ed.gov/notices/korean-la" target="_blank" rel="noreferrer noopener"
                                class="dropdown-item body has-text-black" style="text-decoration:none">
                                한국어
                            </a>
                            <a href="http://www.ed.gov/notices/tagalog-la" target="_blank" rel="noreferrer noopener"
                                class="dropdown-item body has-text-black" style="text-decoration:none">
                                Tagalog
                            </a>
                            <a href="http://www.ed.gov/notices/russian-la" target="_blank" rel="noreferrer noopener"
                                class="dropdown-item body has-text-black" style="text-decoration:none">
                                Русский
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <img src="assets/icons/dept_of_ed_seal.svg" alt="Department of Education seal" />
        </div>
        <div
            class="bottom-content-mobile is-hidden-desktop is-flex is-align-items-end is-flex-direction-column is-align-content-center is-justify-content-center">
            <img src="assets/icons/dept_of_ed_seal.svg" alt="Department of Education seal"
                class="is-align-self-center" />
            <div class="mission-logo-container-mobile is-flex is-flex-direction-column is-align-self-center">
                <p class="footer-mission-mobile has-text-centered">The mission of the Office for Civil Rights is to
                    ensure equal access to education and to promote educational excellence throughout the nation through
                    vigorous enforcement of civil rights.</p>
                <div class="is-flex is-flex-direction-row is-align-self-center">
                    <img class="new-logo" src="assets/images/logo_new.png" alt="Department of Education logo"/>
                    <div class="is-flex-direction-column pl-3">
                        <p class="logo-title">Civil Rights Data Collection</p>
                        <p class="logo-title">Office for Civil Rights</p>
                        <p class="logo-subtitle">U.S. Department of Education</p>
                    </div>
                </div>
            </div>
            <div class="site-update-container is-flex-direction-column has-text-centered is-align-self-center">
                <p class="site-update small-body">Site last updated: {{lastUpdatedDate}}</p>
                <a [routerLink]="['/accessibility_statement']" class="accessibility-statement small-body">Accessibility
                    Statement</a>
                <a href="https://www2.ed.gov/notices/privacy/index.html" target="_blank" rel="noreferrer noopener"
                    class="accessibility-statement small-body">Privacy Policy</a>
                <div class="dropdown is-hoverable is-up">
                    <div class="dropdown-trigger">
                        <button class="button small-body has-text-white" aria-haspopup="true"
                            aria-controls="dropdown-menu4">
                            <span>Language Assistance</span>
                            <span class="icon is-small">
                                <i class="fas fa-angle-down" aria-hidden="true"></i>
                            </span>
                        </button>
                    </div>
                    <div class="dropdown-menu" id="dropdown-menu4" role="menu">
                        <div class="dropdown-content">
                            <a href="http://www.ed.gov/notices/english-la" target="_blank" rel="noreferrer noopener"
                                class="dropdown-item body has-text-black" style="text-decoration:none">
                                English
                            </a>
                            <a href="http://www.ed.gov/notices/espanol-la" target="_blank" rel="noreferrer noopener"
                                class="dropdown-item body has-text-black" style="text-decoration:none">
                                Español
                            </a>
                            <a href="http://www.ed.gov/notices/chinese-la" target="_blank" rel="noreferrer noopener"
                                class="dropdown-item body has-text-black" style="text-decoration:none">
                                中文: 繁體版
                            </a>
                            <a href="http://www.ed.gov/notices/vietnamese-la" target="_blank" rel="noreferrer noopener"
                                class="dropdown-item body has-text-black" style="text-decoration:none">
                                Việt-ngữ
                            </a>
                            <a href="http://www.ed.gov/notices/korean-la" target="_blank" rel="noreferrer noopener"
                                class="dropdown-item body has-text-black" style="text-decoration:none">
                                한국어
                            </a>
                            <a href="http://www.ed.gov/notices/tagalog-la" target="_blank" rel="noreferrer noopener"
                                class="dropdown-item body has-text-black" style="text-decoration:none">
                                Tagalog
                            </a>
                            <a href="http://www.ed.gov/notices/russian-la" target="_blank" rel="noreferrer noopener"
                                class="dropdown-item body has-text-black" style="text-decoration:none">
                                Русский
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
