import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EntitySearchStep4Query } from '../../queries/entity-search-query';
import { DataToolService } from '../../services/data/datatool.service';
import { NGXLogger } from 'ngx-logger';
import { ToastService } from '@shared/components/toast/toast.service';
import { MatTableDataSource } from '@angular/material/table';
import {
    ModuleDef,
    ModuleL1,
    Step4MeasurePreview
} from '@shared/models/entity-search-step4-pre-view.model';
import { EntityType } from '@shared/models/profile-model';
import { FormControl, FormGroup } from '@angular/forms';
import { EntitySearchResult, EntitySearchStep2Result } from '@shared/query-models/entity-search-model';
import { ProfileService } from '@shared/services/helpers/profile.service';

@Component({
    selector: 'app-data-tool-step-four',
    templateUrl: './data-tool-step-four.component.html',
    styleUrls: ['./data-tool-step-four.component.scss']
})
export class DataToolStepFourComponent implements OnInit {

    @Input() formGroup: FormGroup;
    @Input() moduleOptions: ModuleL1[];
    @Input() analysisOptions: ModuleL1[];

    @Output() showLoadingEmitter: EventEmitter<{ show: boolean, message?: string }> = new EventEmitter<{ show: boolean, message: string }>();
    @Output() routeToStepEmitter: EventEmitter<string> = new EventEmitter<string>();

    protected readonly EntityType = EntityType;

    displayedColumns = ['DATA POINT'];

    /**
     *  Data supporting table derived from entities
     */
    dataSource: MatTableDataSource<Step4MeasurePreview>;

    numDataItems: number;
    schoolYearsString: string;
    selectedTarget: string;
    selectedComparisons: string;
    selectedModuleOptionsReadOnly: ModuleL1[];
    analysisOptionsReadOnly: ModuleL1[];

    dataExpanded: boolean = true;
    targetSchoolName: string;

    constructor(private logger: NGXLogger,
        private toastService: ToastService,
        private readonly dataToolService: DataToolService,
        private readonly profileService: ProfileService,

    ) {

    }

    ngOnInit() {

        this.logger.debug('Inside ngOnInit for DataToolStepFourComponent, formGroup: ', this.formGroup);
        this.logger.debug('moduleOptions: ', this.moduleOptions);

        const moduleOptionsCopy = JSON.parse(JSON.stringify(this.moduleOptions));
        const analysisOptionsCopy = JSON.parse(JSON.stringify(this.analysisOptions));

        // Filter out any modules with no options selected
        this.selectedModuleOptionsReadOnly = moduleOptionsCopy.filter((module: ModuleL1) => {
            return module.data.some((datum: ModuleDef) => this.selectedModuleOptions.value.get(datum.dTool_ID.toString()).value);
        });

        // Filter out the unselected options from each module
        this.selectedModuleOptionsReadOnly = this.selectedModuleOptionsReadOnly.map((module: ModuleL1) => {
            module.data = module.data.filter((datum: ModuleDef) => this.selectedModuleOptions.value.get(datum.dTool_ID.toString()).value);
            return module;
        });

        // Filter out any modules with no options selected
        this.analysisOptionsReadOnly = analysisOptionsCopy.filter((module: ModuleL1) => {
            return module.data.some((datum: ModuleDef) => this.selectedComparisonOptions.value.get(datum.dTool_ID.toString()).value);
        });

        // Filter out the unselected options from each module
        this.analysisOptionsReadOnly = this.analysisOptionsReadOnly.map((module: ModuleL1) => {
            module.data = module.data.filter((datum: ModuleDef) => this.selectedComparisonOptions.value.get(datum.dTool_ID.toString()).value);
            return module;
        });

        const surveyYears = this.selectedSurveyYears.value;

        const schoolYears: string[] = [];
        // converting numbers to strings
        surveyYears.forEach(schoolYear => {
            schoolYears.push(this.profileService.getSurveyYearPeriod(schoolYear)); // reportYearPeriod
        });

        // To the the number of items we add the number of school years, the target, and the comparison entities
        this.numDataItems = schoolYears.length + 1 + this.selectedEntitiesStep4.value.length;

        this.schoolYearsString = schoolYears.join(', ');

        this.selectedTarget = this.selectedTargetEntityStep4?.value?.entity_Name;

        this.selectedComparisons = this.selectedEntitiesStep4?.value.map((e: EntitySearchStep2Result) => e.entity_Name).join(', ');

        // if starting step 4 go get the data now so that we have all the user input requirements
        // to load the data and display the data in the final module 4
        this.loadDataPointsTableDate();
    }

    private showLoading(show: boolean, message: string = null) {

        if (show) {
            this.showLoadingEmitter.emit({ show: true, message: message ?? 'Update Claim Assignment ...' })
        } else {
            this.showLoadingEmitter.emit({ show: false })
        }
    }

    public toggleDataDropdown(): void {
        this.dataExpanded = !this.dataExpanded;
    }

    public routeToStep(component?: string): void {
        this.routeToStepEmitter.emit(component);
    }

    loadDataPointsTableDate(): void {

        this.logger.debug('Inside loadDataPointsTableDate');

        this.showLoading(true, 'Loading Data Points ...');

        const targetSchoolId = this.getTargetSchoolId();
        let searchQuery: EntitySearchStep4Query = new EntitySearchStep4Query();

        if (this.selectedSurveyYears.value) {
            searchQuery.surveyYearKeys = this.selectedSurveyYears.value.join(',');
        }
        else {
            this.toastService.warning('No Survey Year Data');
        }

        if (this.selectedEntityType.value) {
            searchQuery.entityType = this.selectedEntityType.value;
        }
        else {
            this.toastService.warning('No Entity Data');
        }

        if (this.selectedEntitiesStep4.value) {
            searchQuery.entityIDs = this.selectedEntitiesStep4.value.map((e: EntitySearchStep2Result) => e.entity_ID).join(',');

            searchQuery.entityIDs = targetSchoolId + ',' + searchQuery.entityIDs;

            this.logger.debug('searchQuery.entityIDs: ', searchQuery.entityIDs, targetSchoolId);

        }
        else {
            this.toastService.warning('No Entities');
        }

        if (this.selectedModuleOptions.value) {
            let modules: number[] = [];

            this.selectedModuleOptionsReadOnly.forEach((module: ModuleL1) => {
                module.data.forEach((datum: ModuleDef) => {
                    modules.push(datum.dTool_ID);
                });
            });

            searchQuery.modules = modules.join(',');
        }
        else {
            this.toastService.warning('No Module Data');
        }

        // api call
        this.dataToolService.loadEntitySearchStep4Preview(searchQuery).subscribe(previewDataResults => {

            this.showLoading(false);

            this.logger.debug('response previewDataResults: ', previewDataResults);
            if (previewDataResults?.result) {

                previewDataResults?.result[0].data.forEach((entity, index) => {
                    // now add the column headers
                    if (entity.entity_Name) {
                         this.displayedColumns.push(entity.entity_Name.toUpperCase());

                        if (entity.entity_ID === targetSchoolId){
                            this.setTargetSchoolName(entity.entity_Name);
                        }
                    }
                    else {
                        this.logger.error('Missing school name for index: ', index)
                    }

                });

                this.logger.debug('displayedColumns: ', this.displayedColumns);

                this.dataSource = new MatTableDataSource<Step4MeasurePreview>(previewDataResults.result);

            } else {
                this.logger.error('No data for preview');
                this.toastService.error('No Data for Preview');
            }
        });

    }

    getFormattedCount(count: number): any {
        if (count === -11) {
            return 'ǂ'
        } else if (count < 0 || count === null) {
            return '—';
        } else {
            return count;
        }
    }

    // survey years
    get selectedSurveyYears() {
        return this.formGroup.get('selectedSurveyYears') as FormControl<number[]>;
    }

    get selectedEntityType() {
        return this.formGroup.get('selectedEntityType') as FormControl<string>;
    }

    // List of enrollment values for each race/ethnicity
    get selectedEnrollment() {
      return this.formGroup.get('selectedEnrollment') as FormControl<string>;
    }

    // target
    get selectedTargetEntityStep4() {
        return this.formGroup.get('selectedTargetEntityStep4') as FormControl<EntitySearchResult>;
    }

    getTargetSchoolId(): number{
        return this.selectedTargetEntityStep4.value.entity_ID;
    }

    setTargetSchoolName(name: string){
        //this.targetSchoolName = this.selectedTargetEntityStep4.value.entity_Name.toUpperCase();
        //const name = this.targetSchoolName.split(',')[0];

        this.logger.debug('target school name: ' + name);
        if (name){
            this.targetSchoolName = name.toUpperCase();
        }
    }

    // comparisons
    get selectedEntitiesStep4() {
        return this.formGroup.get('selectedEntitiesStep4') as FormControl<EntitySearchStep2Result[]>;
    }

    // modules
    get selectedModuleOptions() {
        return this.formGroup.get('selectedModuleOptions') as FormControl<FormGroup>;
    }

    get selectedComparisonOptions() {
        return this.formGroup.get('selectedComparisonOptions') as FormControl<FormGroup>;
    }
}
