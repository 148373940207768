import {NGXLogger} from 'ngx-logger';
import {Observable, Subject} from 'rxjs';
import {ToastService} from '@shared/components/toast/toast.service';
import {inject, Injectable} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {environment} from '@environments/environment';


@Injectable({
    providedIn: 'root'
})
export class SessionTimerService {

    millsPerMinute = 60*1000;

    //origStartTime: number;  // milliseconds

    // 1 min in milliseconds: mills * seconds * minutes
    timerInterval: number = 1000*60*1;    // 0.1 = every 6 seconds
    timerReference: any;
    timeTrackerSubject: Subject<number> = new Subject<number>();
    secondsBeforeExpire: number = 0; // seconds before session expire
    expireAt: number = 0;   // date/time the session will expire in milli seconds
    showWarningMessage: boolean = true;
    sessionEndWarningTime: number;

    cookieService = inject(CookieService);

    constructor(
        private logger: NGXLogger,
        private toastService: ToastService,){

        this.sessionEndWarningTime = environment.sessionEndWarningTime | 5;

    }

    public isRunning() {
        let isRunning = false;
        if (this.timerReference){
            isRunning = true;
        }

        this.logger.debug('Inside isRunning: ', isRunning);
        return isRunning;

    }

    // hoursBeforeExpire: is an integer number for example 1, 2 or 3 indicating the number of hours to go by before an endorsement time window expires
    public startTimer(expireAt: number) {

        const now = new Date().getTime();

        this.logger.debug('Inside startTimer, expireAt: ', expireAt, ', now: ', now, ', diff: ', (expireAt - now)/1000, new Date(expireAt));

        //this.origStartTime = expireAt - expiresIn*1000;

        this.expireAt = expireAt;
        this.secondsBeforeExpire = (expireAt - now)/1000;
        this.logger.debug('secondsBeforeExpire: ', this.secondsBeforeExpire);

        // Nichole want to force a 30min session timeout
        if (this.secondsBeforeExpire > 1800){
            this.secondsBeforeExpire = 1800; // 30 min. session timout
        }

        // if (secondsBeforeExpire > 0){
        //
        //     //const millsPerHour = 1000 * 60 * 60;
        //     //const millsHoursBeforeExpire = hoursBeforeExpire * millsPerHour;
        //     //const expireTime = new Date().getTime() + (secondsBeforeExpire * millsPerHour); // new Date(Date.now()).getTime() + (hoursBeforeExpire * millsPerHour);
        //
        //     this.secondsBeforeExpire = secondsBeforeExpire;
        // }
        // else if (expireAt > 0){
        //
        //     this.secondsBeforeExpire = (expireAt - this.origStartTime)/1000;
        //     this.logger.debug('secondsBeforeExpire: ', this.secondsBeforeExpire);
        //
        // }

        // stop and remove any previous timers if exist
        if (this.timerReference){
            this.removeTimer();
        }
        // start the timer to fire every 1 minute
        this.timerReference = setInterval(this.validateSessionTimer, this.timerInterval);

    }

    private validateSessionTimer = (): any => {

        //this.logger.debug('Inside validateSessionTimer, secondsBeforeExpire: ', this.secondsBeforeExpire, 'origStartTime: ', this.origStartTime);

        //const dateNow = new Date().getTime();   // new Date(Date.now()).getTime()
        // gets update after every 1 seconds this is more accurate than:
        // const timeLeft = millsHourBeforeExpire - 1000;

        //let millsBeforeExpire = this.secondsBeforeExpire * 1000;
        /*
        const expiresInSeconds = this.getAuthSessionExpireTimeFromCookie();

        if (expiresInSeconds === -1){
            this.logger.debug('No cookie to read so use original expires_in value: ', expiresInSeconds);
        }
        else {
            millsBeforeExpire = expiresInSeconds * 1000;
        }

         */

       //this.logger.debug('millsBeforeExpire: ', millsBeforeExpire);

        const now = new Date().getTime();

        this.logger.debug('now: ', now,  new Date(now));
        //const timeLapsed = now - this.origStartTime;
        //const expireTime = this.origStartTime + millsBeforeExpire;
        const timeLeft = this.expireAt - now;  // we need to get the time left from the cookie

        // this.logger.debug('now: ', now);
        //this.logger.debug('timeLapsed: ', timeLapsed);
        this.logger.debug('expireAt: ', this.expireAt);
        // this.logger.debug('timeLeft: ', timeLeft);
        this.logger.debug('timeLeft: ', (timeLeft/1000)/60 );
        console.log('timeLeft: ', (timeLeft/1000)/60 );

        // notify everybody who is listening to the timer notifications
        this.timeTrackerSubject.next(timeLeft);

        //when the timer expire stop the timer
        if (timeLeft <= 0) {

            this.removeTimer();

            // show confirmation
            this.showTimerExpiredWarningMessage();

        }
        else {
            // now check to see if we need to show a warning message
            this.checkWarningTimers(timeLeft);
        }
    }

    /** show a warning message 5 min before the session expire
     *
     * @param millsBeforeExpire
     * @private
     */
    private checkWarningTimers(millsBeforeExpire: number){
        this.logger.debug('Inside checkWarningTimers, millsBeforeExpire: ', millsBeforeExpire, ', warning time: ', this.sessionEndWarningTime);

        // check if we are within 5 minute range
        if (this.showWarningMessage && (millsBeforeExpire <= this.millsPerMinute*this.sessionEndWarningTime)){

            this.showTimerWarningMessage(millsBeforeExpire/this.millsPerMinute);
            this.showWarningMessage = false;    // we only show the warning once
        }

    }

    private showTimerWarningMessage(minLeft: number){
        this.logger.debug('Inside showTimerWarningMessage, minLeft: ', minLeft);

        this.toastService.warning(`This session will expire in ${Math.round(minLeft)} minutes.`,
            'Session Timer Warning', 30000);

    }

    private showTimerExpiredWarningMessage(){
        this.logger.debug('Inside showTimerExpiredWarningMessage');

        this.toastService.warning('This session has expired','Session Timer Warning');
    }

    removeTimer(): void {

        this.logger.debug('Inside removeTimer');

        if (this.timerReference) {
            clearInterval(this.timerReference);
        }

    }

    // subscribe to the timer service and get the time left before the timer expired
    getTimer(): Observable<number> {
        return this.timeTrackerSubject.asObservable();

    }

    public refreshExpireTime(secondsBeforeExpire: number) {
        this.logger.debug('Inside refreshExpireTime');

        this.secondsBeforeExpire = secondsBeforeExpire;
    }

    public getAuthSessionExpireTimeFromCookie(): number {
        this.logger.debug('Inside getAuthSessionExpireTimeFromCookie', document.cookie);

        // we can only read the cookie if the "SameSite" flag is not on as well as the HttpOnly flag is not set
        // only cookies with the same domain as the website can be read
        const sessionInfo = this.cookieService.get('crdc-session');

        const allCookies = this.cookieService.getAll();

        this.logger.debug('sessionInfo: ', sessionInfo, allCookies);

        const info = sessionInfo.split(':');
        const sessionId = info[0];

        if (info?.length > 1){

            const expiresIn = info[1];

            this.logger.debug('expiresIn: ', expiresIn);

            return +expiresIn;   // + for converting a string to a number
        }

        return -1;   // expired or not logged in

    }
}
