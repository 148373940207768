import { EntityType, Genders, Measure } from "./profile-model";

export interface ChartDto {
    categories: CategoryDetailDto[];
    enrollmentData: any;
    selectedGender: Genders;
    entityType: EntityType;
    measure: Measure;
}

export interface CategoryDetailDto {
    groupName: string;
    values: GroupValueDto[];
}

export interface GroupValueDto {
    label: string;
    value: number;
}

export enum ChartTypesDto {
    AREA_CHART = 'areaChart',
    BAR_CHART = 'barChart',
    BUBBLE_CHART = 'bubbleChart',
    NATIONAL_CHART = 'nationalChart',
    STACKED_BAR_CHART = 'stackedBarChart',
    STACKED_BUBBLE_CHART = 'stackedBubbleChart'
}

export interface StackedBubbleChartDto {
    name: string;
    children: StackedBubbleChartChildDto[];
}

export interface StackedBubbleChartChildDto {
    name: string;
    fill: boolean;
    outline: boolean;
    children: StackedBubbleChartCategoryDto[]
}

export interface StackedBubbleChartCategoryDto {
    name: string;
    fill: boolean;
    outline: boolean;
    size: number;
}

export interface TooltipData {
    label: string;
    male: string;
    female: string;
    total: string;
    studentPercent: any[];
}

export interface Tooltip {
    label: string;
    html: string;
}
