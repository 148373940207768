<div class="is-flex is-flex-direction-column">
    <div class="legend-container is-flex is-flex-direction-column">
        <figure class="is-hidden-mobile is-align-self-flex-end" id="legend"></figure>
        <figure class="is-hidden-tablet is-align-self-center" id="mobile-legend"></figure>
    </div>
    <div class="scroll-x">
        <div class="area-chart-container">
            <figure id="area-chart"></figure>
        </div>
    </div>

    <div class="tooltip">
        <button mat-icon-button>
            <mat-icon class="area-info-icon">info</mat-icon>
        </button>
        <span class="tooltip-text small-body">Data are typically collected every two years. Most recent update was for School Year 2017-18.</span>
    </div>
</div>
