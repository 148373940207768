<div [ngClass]="[ direction === 'right' ? 'is-flex-direction-row-reverse' : 'is-flex-direction-row' ]"
    class="profile-block-container is-flex">
    <div fieldset [ngClass]="[ miscMeasureLoaded && dataTimedOut ? 'misc-data' : 'data' ]" #dataElement
        class="is-flex is-flex-direction-column is-justify-content-flex-start">
        <legend>
            <h5 class="has-text-primary">{{ selectedSubcategory.title }}</h5>
        </legend>
        <p *ngIf="((chartDataIsLoaded && entityDataIsLoaded) || miscMeasureLoaded) && dataTimedOut"
            class="visually-hidden" role="status">{{ selectedSubcategory.title }} data loaded</p>
        <div [ngClass]="[ miscMeasureLoaded ? 'misc-grid-container' : 'grid-container' ]">
            <mat-form-field class="filter-dropdown" floatLabel="never" [style.width.rem]=7.5>
                <mat-select [(ngModel)]="selectedMeasure" (selectionChange)="onMeasureChange()" name="measure">
                    <mat-option style="color:#133559" *ngFor="let measure of measures" [value]="measure">
                        {{measure.title}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div class="is-flex is-flex-direction-row" style="gap:15px; padding-left:15px;">
                <mat-form-field *ngIf="!(selectedMeasure.is_In_Misc || dataRequestFailed)" class="filter-dropdown"
                    floatLabel="never" [style.width.rem]=7.5>
                    <mat-select [(ngModel)]="selectedGender" (selectionChange)="onGenderChange()" name="gender">
                        <mat-option style="color:#133559" *ngFor="let gender of genders" [value]="gender">
                            {{ gender }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field *ngIf="!(selectedMeasure.is_In_Misc || dataRequestFailed)" class="filter-dropdown"
                    floatLabel="never" [style.width.rem]=7.5>
                    <mat-select [(ngModel)]="selectedChart" name="chart">
                        <mat-option style="color:#133559" *ngFor="let chart of chartTypes" [value]="chart">
                            {{ getChartLabel(chart) }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <app-charts *ngIf="chartDataIsLoaded && entityDataIsLoaded && dataTimedOut" [sectionName]="sectionTitle"
            [chartType]="selectedChart" [data]="chartData" [entityType]="profileBlock.entityType"></app-charts>
        <div *ngIf="miscMeasureLoaded && dataTimedOut"
            class="is-align-self-flex-center is-flex is-flex-direction-column is-align-items-center">
            <p class="has-text-primary h1-text">{{ miscMeasure }}</p>
            <div class="misc-footer is-flex is-flex-direction-column is-align-items-center">
                <p class="small-body has-text-primary has-text-centered">— Not available.</p>
                <p class="small-body has-text-primary has-text-centered">ǂ Data quality standards not met.</p>
            </div>
        </div>
        <div *ngIf="dataReportedTotalZero && dataTimedOut" class="notification is-align-self-flex-center">
            <p class="body has-text-black">While data were reported, all values were reported as 0.</p>
        </div>
        <div *ngIf="dataNotAvailable && dataTimedOut" class="notification is-align-self-flex-center">
            <p class="body has-text-black">Data not available.</p>
        </div>
        <div *ngIf="dataQualityStandardsNotMet && dataTimedOut" class="notification is-align-self-flex-center">
            <p class="body has-text-black">Data quality standards not met.</p>
        </div>
        <div *ngIf="dataRequestFailed && dataTimedOut" class="notification is-align-self-flex-center">
            <p class="body has-text-black">Data request failed. <a (click)="onMeasureChange()">Click here</a> to retry.
            </p>
        </div>
        <app-loading-spinner
            [ngClass]="[ ((chartDataIsLoaded && entityDataIsLoaded) || miscMeasureLoaded || dataReportedTotalZero || dataNotAvailable || dataQualityStandardsNotMet) && dataTimedOut ? 'is-hidden' : '' ]"
            width="100%" height="20rem" backgroundColor="white"></app-loading-spinner>
    </div>
    <div class="is-align-self-center text is-flex is-flex-direction-column">
        <h4 class="content-block-header has-text-primary">{{ sectionTitle }}</h4>
        <hr class="content-divider">
        <div class="content discipline-link">
            <ul class="has-text-primary">
                <ng-container *ngFor="let category of categories">
                    <li *ngFor="let subCategory of category.data" class="is-underlined">
                        <button class="sub-category-button"
                            (click)="onSubCategoryChange(subCategory, dataElement)">{{ subCategory.title }}</button>
                </ng-container>
            </ul>
        </div>
        <button *ngIf="chartDataIsLoaded && entityDataIsLoaded"
            class="button is-rounded is-outlined outline-button is-regular-button"
            (click)="downloadExcelFile()">Download Chart Data</button>
    </div>
</div>
