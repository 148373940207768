<div class="data-tool-step-three-container is-flex is-flex-direction-column" style="gap: 1.5rem">

    <div class="card data-tool-card">
        <header class="card-header is-justify-content-space-between" (click)="toggleDataDropdown()" onkeypress="">
            <div class="is-flex" style="gap: 1rem;">
                <p class="subheader-2 has-text-primary">Data</p>
                <button class="link-button body" type="button" (click)="routeToStep()"
                    aria-label="edit data selections">Edit</button>
            </div>
            <p class="selected body has-text-primary">{{ numDataItems }} items</p>
            <button type="button" class="card-header-icon search-button" aria-label="toggle show data"
                [attr.aria-expanded]="dataExpanded" mat-icon-button>
                <i *ngIf="!dataExpanded" aria-hidden="true" class="material-icons">keyboard_arrow_down</i>
                <!-- arrow_drop_down arrow_drop_up -->
                <i *ngIf="dataExpanded" aria-hidden="true" class="material-icons">keyboard_arrow_up</i>
            </button>
        </header>

        <div *ngIf="dataExpanded" class="card-content is-flex is-flex-direction-column" style="gap: 1rem;">
            <fieldset>
                <legend class="subheader-2 has-text-primary">Survey Years</legend>
                <p class="large-body has-text-primary">{{ schoolYearsString }}</p>
            </fieldset>

            <fieldset>
                <legend class="subheader-2 has-text-primary">Target</legend>
                <p class="large-body has-text-primary">{{ selectedTarget }}</p>
            </fieldset>

            <fieldset>
                <legend class="subheader-2 has-text-primary">Selected Comparisons</legend>
                <p class="large-body has-text-primary">{{ selectedComparisons }}</p>
            </fieldset>

            <fieldset *ngIf="selectedEnrollment.value">
                <legend class="subheader-2 has-text-primary">Enrollment</legend>
                <p class="large-body has-text-primary">{{ selectedEnrollment.value }}</p>
            </fieldset>
        </div>
    </div>

    <app-data-tool-module-options-selection [modules]="selectedModuleOptionsReadOnly" [label]="'Modules'"
        [readonly]="true" [expanded]="true" (onEdit)="routeToStep('Modules')"></app-data-tool-module-options-selection>

    <app-data-tool-module-options-selection [modules]="analysisOptionsReadOnly" [label]="'Analyses Types'"
        [readonly]="true" [expanded]="true" (onEdit)="routeToStep('Analyses Types')"></app-data-tool-module-options-selection>

    <fieldset>
        <div style="display: flex;">
            <legend style="margin-bottom: .5rem" class="subheader-2 has-text-primary">School Data Points</legend>
            <i style="margin-left: auto;font-size: smaller;">scroll up/down for more data</i>
        </div>
        <!-- need this div to allow us to have a fixed header row and a fixed first column like a pivot table-->
        <div class="data-preview-background">
            <table mat-table [dataSource]="dataSource" class="data-preview compact-table" tabindex="0">

                <ng-container *ngFor="let columnName of displayedColumns; let colIndex = index" matColumnDef="{{columnName}}">
                    <th scope="col" class="large-body school-header" *matHeaderCellDef [ngClass]="[ colIndex === 0 ? 'module-header' : '' ]">
                        <div>{{ targetSchoolName ===  columnName ? columnName + ' (Target)' : columnName }}</div>
                    </th>
                    <ng-container *matCellDef="let measure">
                        <th scope="row" *ngIf="colIndex === 0" class="module-header">{{ measure.measure_Title }}</th>
                        <td *ngIf="colIndex > 0" [ngClass]="[ targetSchoolName ===  columnName ? 'target-school' : 'other-school' ]">{{ getFormattedCount(measure.data[colIndex-1].value) }}</td>
                    </ng-container>
                    <!-- <td mat-cell *matCellDef="let measure" [ngClass]="[ targetSchoolName ===  columnName ? 'target-school' : 'other-school' ]"> -->

                </ng-container>

                <!-- Table rows -->
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>

    </fieldset>
</div>
