import { ComparisonType } from "./entity-search-step3-model";

export interface EntitySearchStep4PreViewModel {
    result: Step4Module[]
}

export interface Step4Module {
    module_Title: string;
    data: Step4Measure[];
}

export interface Step4Measure {
    measure_Title: string;
    location?: MEASURE_TYPE;   // MISC or ENR
    data: Step4Entity[];
    //misc?: boolean;
}

export interface Step4Entity {
    entity_ID: number;
    entity_Name: string;
    data: Step4SYKData[];
}

export interface Step4SYKData {
    syk: number;
    surveyPeriod?: string; //"2017-18"
    sykData?: SYK_Data;
    data?: string;   // same as sykData but in string format, e.g: "StudentEnrollment:1765,B_AME:0,B_ASI:6,B_HIS:9,B_BLA:0,B_WHI:2,B_HI_PAC:0,B_MORE:0,B_TOT:17"
}

export interface SYK_Data {
    studentEnrollment: SYK_Data_Child;  // student count

    B_AME?: SYK_Data_Child;
    B_ASI?: SYK_Data_Child;
    B_HIS?: SYK_Data_Child;
    B_BLA?: SYK_Data_Child;
    B_WHI?: SYK_Data_Child;
    B_HI_PAC?: SYK_Data_Child;
    B_MORE?: SYK_Data_Child;
    B_LEP?: SYK_Data_Child;
    B_DIS?: SYK_Data_Child;
    B_DIS5?: SYK_Data_Child;
    B_TOT?: SYK_Data_Child;

    F_AME?: SYK_Data_Child;
    F_ASI?: SYK_Data_Child;
    F_HIS?: SYK_Data_Child;
    F_BLA?: SYK_Data_Child;
    F_WHI?: SYK_Data_Child;
    F_HI_PAC?: SYK_Data_Child;
    F_MORE?: SYK_Data_Child;
    F_LEP?: SYK_Data_Child;
    F_DIS?: SYK_Data_Child;
    F_DIS5?: SYK_Data_Child;
    F_TOT?: SYK_Data_Child;

    M_AME?: SYK_Data_Child;
    M_ASI?: SYK_Data_Child;
    M_HIS?: SYK_Data_Child;
    M_BLA?: SYK_Data_Child;
    M_WHI?: SYK_Data_Child;
    M_HI_PAC?: SYK_Data_Child;
    M_MORE?: SYK_Data_Child;
    M_LEP?: SYK_Data_Child;
    M_DIS?: SYK_Data_Child;
    M_DIS5?: SYK_Data_Child;
    M_TOT?: SYK_Data_Child;

    questionAnswers: QuestionAnswer[];

}

export interface SYK_Data_Child {
    count: number;
    ratio?: number;
}

export interface QuestionAnswer {
    question: string;
    answer: string;
}

export interface EntitySearchStep4PreView {
    result: Step4MeasurePreview[]
    //result: Step4MeasurePreviewEx[]
}

export interface Step4MeasurePreview {
    measure_Title: string;
    data: Step4EntityPreview[];

}


export interface Step4EntityPreview {
    entity_ID: number;
    entity_Name: string;
    value: string;
}

export interface ModuleViewModel {
    result: ModuleL1[];
    moduleNames?: string[]; // UI only
}

export interface ModuleL1 {
    dTool_ID: number;
    title: string;
    data: ModuleDef[];
    dataString?: string; // UI only
}

export interface ModuleDef {
    dTool_ID: number | ComparisonType; // ComparisonType for UI only
    title: string;
    tooltip?: string; // UI only
}

export enum GENDER_TYPE  {
    MALE = 'Male',
    FEMALE = 'Female',
    BOTH =  'BOTH',
}

export enum MEASURE_TYPE  {
    MISC = 'MISC',
    ENR = 'ENR',
    DETAILS = 'DETAILS'
}

export class RACE_TYPE {

    static readonly AME: string = 'American Indian or Alaska Native';
    static readonly ASI: string = 'Asian';
    static readonly HIS: string = 'Hispanic or Latino of any race';
    static readonly BLA: string = 'Black or African American';
    static readonly WHI: string = 'White';
    static readonly HI_PAC: string = 'Native Hawaiian or Other Pacific Islander';
    static readonly MORE: string = 'Two or more races';
    static readonly LEP: string = 'English Learner';
    static readonly DIS: string = 'IDEA';
    static readonly DIS5: string = 'Section 504-only';
    static readonly TOT: string = 'All';
}

