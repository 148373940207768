import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss']
})
export class LoadingSpinnerComponent implements OnInit {

  @Input() message: string; // (optional) - message to be displayed next to spinner
  @Input() width: string = '100vw'; // (optional)
  @Input() height: string = '40rem'; // (optional)
  @Input() backgroundColor: string = '#133559'; // (optional)

  constructor() { }

  ngOnInit(): void {
  }

}
