import { Component, OnInit } from '@angular/core';
import {Router, UrlSegment} from '@angular/router';
import { ProfileService } from '../../services/helpers/profile.service';
import { UrlSerice } from '../../services/helpers/url-service';
import {NGXLogger} from 'ngx-logger';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  previousURL: string = location.origin;
  previousURLLoaded: boolean = false;

  constructor(
      private logger: NGXLogger,
      private urlService: UrlSerice,
      private profileService: ProfileService,
      private router: Router,
  ) { }

  ngOnInit(): void {

      this.logger.debug('Inside ngOnInit');

      let url: string = this.router.url;

      this.logger.debug('url: ', url);

      // we need to look for any oauth errors in the query parameters
      //error=redirect_uri_mismatch&error_description

    let notFoundLink: string = this.profileService.getNotFoundLink();
    let urlSegments: UrlSegment[] = this.urlService.getPreviousUrl();

    if (urlSegments && urlSegments.length > 0) {
      urlSegments.forEach(segment => {
        this.previousURL = `${this.previousURL}/${segment.path}`;
      });

      this.previousURLLoaded = true;
    } else if (notFoundLink.length > 0) {
      this.previousURL = notFoundLink;
      this.previousURLLoaded = true;
    }
  }
}
