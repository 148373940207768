import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EntityType, LabelValuePair, State, SurveyYearModel } from '../../models/profile-model';
import { EntitySearchResult, EntitySearchStep2Result } from '../../query-models/entity-search-model';
import { ProfileService } from '../../services/helpers/profile.service';
import { DataToolService } from '../../../shared/services/data/datatool.service';
import { EntitySearchStep2Query } from '../../queries/entity-search-query';
import { NGXLogger } from 'ngx-logger';
import { CriteriaDTO } from '../../models/entity-search-step2-model';
import { Subscription } from 'rxjs';
import { ComparisonService } from '@shared/services/helpers/comparison.service';
import {ToastService} from '@shared/components/toast/toast.service';

@Component({
  selector: 'app-data-tool-step-two',
  templateUrl: './data-tool-step-two.component.html',
  styleUrls: ['./data-tool-step-two.component.scss']
})
/**
 * Represents the Data Tool Step Two component.
 * This component is responsible for displaying and managing the second step of the data tool.
 * It handles user input, form validation, and data loading based on the selected options.
 */
export class DataToolStepTwoComponent implements OnInit, AfterViewInit, OnDestroy {

  EntityType = EntityType;

  @Input() formGroup: FormGroup;
  @Input() entitySurveyYears: SurveyYearModel[];
  @Input() selectedEntity: FormControl<EntitySearchResult>;
  @Input() entityType: EntityType;

  @Output() formResponse: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  @Output() showLoadingEmitter: EventEmitter<{ show: boolean, message?: string }> = new EventEmitter<{ show: boolean, message: string }>();

  @ViewChild('surveyYearSelection') surveyYearSelection: ElementRef;

  public localityOptions: LabelValuePair[];
  public schoolTypeValues: LabelValuePair[];
  public schoolLevelValues: LabelValuePair[];
  public entities: EntitySearchStep2Result[];
  public entityTotal: number;
  public states: State[];

  private deleteItemSubscription: Subscription;



  constructor(
    private readonly logger: NGXLogger,
    private toastService: ToastService,
    private readonly dataToolService: DataToolService,
    private readonly profileService: ProfileService,
    private readonly comparisonService: ComparisonService,
    private readonly cdRef: ChangeDetectorRef,
  ) {
    this.schoolTypeValues = this.profileService.getSchoolTypeValues();
    this.schoolLevelValues = this.profileService.getGradeLevels();
  }

  /**
   * Initializes the locality options based on entity type, subscribes to value changes for each
   * provided FormControl from the formGroup, and loads the data.
   */
  ngOnInit(): void {
    this.logger.debug("Inside DataToolStepTwoComponent ngOnInit, formGroup: ", this.formGroup);
    // Initializes the locality options based on the entity type
    this.localityOptions = [
      ...(this.entityType === EntityType.SCHOOL ? [{ value: 'd', label: 'LEA' }] : []),
      { value: 'n', label: 'Nation' },
      { value: 'st', label: 'State' },
    ];

    this.states = this.profileService.getStateIds();

    if (this.locality.value === 'st') {
      this.selectedState.addValidators([Validators.required]);
    } else {
      this.selectedState.removeValidators([Validators.required]);
    }

    // Subscribes to value changes for each FormControl in the formGroup because each key acts
    // slightly differently
    Object.keys(this.formGroup.controls).forEach(key => {
      this.formGroup.get(key).valueChanges.subscribe(this.onFormChanges.bind(this, key));
    });

    // Listens for the delete item event and removes the item from the selected entities list
    this.deleteItemSubscription = this.comparisonService.deleteItemUpdate.subscribe((element: EntitySearchStep2Result) => {
      this.logger.debug("delete item: ", element);
      this.logger.debug("selected entities: ", this.selectedEntities.value);
      this.selectedEntities.setValue(this.selectedEntities.value.filter((entity: EntitySearchStep2Result) => entity.entity_ID !== element.entity_ID));
    });

    // Try to load the data if the form is valid
    this.loadData();
  }

  /**
   * Sets the checked attribute for any elements that should be defaulted to checked
   */
  ngAfterViewInit(): void {
    this.surveyYearSelection.nativeElement.children[0].focus();
    this.surveyYears.value.forEach((surveyYear: number) => {
      document.getElementById(surveyYear.toString()).setAttribute('checked', 'true');
    });

    if (this.entityType === EntityType.SCHOOL) {
      this.schoolTypes.value.forEach((schoolType: string) => document.getElementById(schoolType).setAttribute('checked', 'true'));
      this.grades.value.forEach((grade: string) => document.getElementById(grade).setAttribute('checked', 'true'));
    }
  }

  /**
   * Unsubscribes from the delete item subscription.
   */
  ngOnDestroy(): void {
    if (this.deleteItemSubscription) this.deleteItemSubscription.unsubscribe();
  }

  /**
   * Handles the form changes and emits the form group and loads data based on the provided key.
   *
   * @param key - The key used to load the data.
   */
  onFormChanges(key): void {
    this.logger.debug("form changes: ", this.formGroup);

    // If the locality is set to st, then the selected state is required, otherwise it should not be required
    if (key === 'locality' && this.locality.value === 'st') {
      this.selectedState.addValidators([Validators.required]);
    } else {
      this.selectedState.removeValidators([Validators.required]);
    }

    this.cdRef.detectChanges();

    if (key !== 'selectedEntities') {
      this.selectedEntities.setValue([]);
      this.loadData();
    }

    this.formResponse.emit(this.formGroup);
  }

  private showLoading(show: boolean, message: string = null) {

    if (show) {
      this.showLoadingEmitter.emit({ show: true, message: message ? message : 'Update Claim Assignment ...' })
    } else {
      this.showLoadingEmitter.emit({ show: false })
    }
  }

  /**
   * Loads data based on the specified key. If no key and valid data, we know the form was just
   * initialized, and we should load the data. Will only load the data for the entities if fields
   * that would change the list of entities are changed.
   *
   * @param key - The key to determine the type of data to load.
   */
  loadData(): void {
      this.logger.debug('Inside loadData');

    const validSchool: boolean = this.entityType === EntityType.SCHOOL
      && this.surveyYears.value?.length > 0
      && this.locality.value
      && this.schoolTypes.value?.length > 0
      && this.criteria.valid;

    const validDistrict: boolean = this.entityType === EntityType.DISTRICT
      && this.locality.value
      && this.surveyYears.value?.length > 0
      && this.criteria.valid;

    if (validSchool || validDistrict) {
      // If the form is valid and the key is not selected entities, then we need to load the entities
      // If the key is selected entities, then we don't need to load the entities because the user is
      // just selected entities to compare and not changing the serach criteria
      const entityType: string = this.entityType === EntityType.SCHOOL ? 'a' : 'b';
      const entityId: number = this.selectedEntity.value.entity_ID;
      const surveyYears: string = this.surveyYears.value.join(',');
      const entityLevel: string = this.schoolTypes.value.join(',');
      const grades: string = this.grades.value.join(',');
      const filteredCriteria: string[] = Object.keys(this.criteria.value).filter((key: string) => {
        return this.criteria.value[key].lowerBound > 0 && this.criteria.value[key].lowerBound < 100 || this.criteria.value[key].upperBound < 100;
      });
      const criteria: string = filteredCriteria.join(',');
      const criteriaQuantities: string = filteredCriteria.map((key: string) => {
        return `BETWEEN ${this.criteria.value[key].lowerBound} AND ${this.criteria.value[key].upperBound}`;
      }).join(',');
      const query: EntitySearchStep2Query = new EntitySearchStep2Query();

      query.entityType = entityType;
      query.entityID = entityId;
      query.surveyYearKeys = surveyYears;
      query.geoUnit = this.locality.value;
      query.criteria = criteria;
      query.criteria_Quantity = criteriaQuantities;

      if (this.locality.value === 'st') {
        query.stateCode = this.selectedState.value.state_Code;
      }

      if (this.entityType === EntityType.SCHOOL) {
        query.entityLevel = entityLevel;
        query.grades_Offered = grades;
      }

      this.showLoading(true, 'Loading Entity Data ...');

      this.logger.debug("search query: ", query);
      this.dataToolService.EntitySearchStep2(query).subscribe((data) => {
        this.logger.debug("EntitySearchStep2 response data: ", data);

        this.showLoading(false);
        this.entities = data?.entitySearchStep2Results;
        this.entityTotal = data?.totalCount;
      });

    } else {
      // If a change happens that would make the form invalid, then we need to clear the entities
      this.entities = [];
      this.entityTotal = 0;
    }
  }

  /**
   * Handles the checkbox change event.
   *
   * @param event - The checkbox change event.
   * @param value - The value associated with the checkbox.
   * @param control - The form control for the checkbox group.
   */
  onCheck(event: Event, value: string, control: FormControl<string[]>): void {
    if ((event.target as HTMLInputElement).checked) {
      control.setValue([...control.value, value]);
    } else {
      control.value.splice(control.value.indexOf(value), 1)
      control.setValue(control.value);
    }
  }

  /**
   * Gets the list of survey years.
   */
  get surveyYears() {
    return this.formGroup.get('surveyYears') as FormControl<number[]>;
  }

  /**
   * Gets the locality.
   */
  get locality() {
    return this.formGroup.get('locality') as FormControl<string>;
  }

  /**
   * Gets the selected state.
   */
  get selectedState() {
    return this.formGroup.get('selectedState') as FormControl<State>;
  }

  /**
   * Gets the list of school types.
   */
  get schoolTypes() {
    return this.formGroup.get('schoolTypes') as FormControl<string[]>;
  }

  /**
   * Gets the list of grades.
   */
  get grades() {
    return this.formGroup.get('grades') as FormControl<string[]>;
  }

  /**
   * Gets the list of criteria.
   */
  get criteria() {
    return this.formGroup.get('criteria') as FormControl<CriteriaDTO>;
  }

  /**
   * Gets the list of selected entities
   */
  get selectedEntities() {
    return this.formGroup.get('selectedEntities') as FormControl<EntitySearchStep2Result[]>;
  }
}
