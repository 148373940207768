<div class="publications-container">
    <app-hero heroHeader="Publications" heroSubHeader="Resources"
        heroDescription="Data reports published by OCR, relying on the CRDC, are valuable resources for other Department of Education offices and Federal agencies, policymakers and researchers, educators and school officials, parents and students, and the public who seek data summaries on student access to educational opportunities."
        heroImageSrc="assets/images/publications.jpg"
        heroImageAlt="mother and young child with a backpack walking out the front door of a house">
    </app-hero>
    <div class="resource-content">
        <div class="content-container is-flex is-flex-direction-row is-justify-content-space-between" sytle="gap:3rem">
            <div class="resource-links is-flex is-flex-direction-column is-align-items-center">
                <div class="resource-list is-flex is-flex-direction-row">
                    <div class="is-flex is-flex-direction-row is-hidden-touch" style="max-width: 45%;">
                        <img src="assets/images/publications_2021.jpg" class="rounded-corners is-align-self-center"
                            alt="kids using stairs to get to class" />
                    </div>

                    <div class="resource-list-content">
                        <h4 class="has-text-primary">2020-21 CRDC Reports and Snapshots</h4>
                        <hr class="content-divider">
                        <ul>
                            <li *ngFor="let download of reportsAndSnapshots; let i = index"
                                class="is-flex is-flex-direction-row is-justify-content-space-between content-download"
                                style="gap: 30px;">
                                <p class="large-body has-text-primary">{{ download.title }}</p>
                                <div class="is-flex is-flex-direction-row" style="gap: 10px;">
                                    <a class="is-underlined large-body has-text-primary"
                                        [ngClass]="[ download.hasSpanish ? '' : 'no-spanish' ]" [href]="download.file"
                                        [attr.aria-label]="download.title" target="_blank"
                                        rel="noreferrer noopener">PDF</a>
                                    <a *ngIf="download.hasSpanish" class="is-underlined large-body has-text-primary"
                                        [href]="download.spanishFile" [attr.aria-label]="download.spanishTitle"
                                        target="_blank" rel="noreferrer noopener">Spanish</a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="resource-list is-flex is-flex-direction-row">
                    <div class="is-flex is-flex-direction-row is-hidden-touch" style="max-width: 45%;">
                        <img src="assets/images/discipline_practices.jpg" class="rounded-corners is-align-self-center"
                            alt="High school kids walking to school" />
                    </div>

                    <div class="resource-list-content">
                        <h4 class="has-text-primary">2017-18 CRDC Series on School Discipline Practices</h4>
                        <hr class="content-divider">
                        <ul>
                            <li *ngFor="let download of disciplinePractices; let i = index"
                                class="is-flex is-flex-direction-row is-justify-content-space-between content-download"
                                style="gap: 30px;">
                                <p class="large-body has-text-primary">{{ download.title }}</p>
                                <div class="is-flex is-flex-direction-row" style="gap: 10px;">
                                    <a class="is-underlined large-body has-text-primary"
                                        [ngClass]="[ download.hasSpanish ? '' : 'no-spanish' ]" [href]="download.file"
                                        [attr.aria-label]="download.title" target="_blank"
                                        rel="noreferrer noopener">PDF</a>
                                    <a *ngIf="download.hasSpanish" class="is-underlined large-body has-text-primary"
                                        [href]="download.spanishFile" [attr.aria-label]="download.spanishTitle"
                                        target="_blank" rel="noreferrer noopener">Spanish</a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="resource-list is-flex is-flex-direction-row">
                    <div class="is-flex is-flex-direction-row is-hidden-touch" style="max-width: 45%;">
                        <img src="assets/images/publications-1.png" class="rounded-corners is-align-self-center"
                            alt="a person analyzing a sheet of data and graphs" />
                    </div>

                    <div class="resource-list-content">
                        <h4 class="has-text-primary">Reports and Findings</h4>
                        <hr class="content-divider">
                        <ul>
                            <li *ngFor="let download of reportsAndFindings; let i = index"
                                class="is-flex is-flex-direction-row is-justify-content-space-between content-download"
                                style="gap: 30px;">
                                <p class="large-body has-text-primary">{{ download.title }}</p>
                                <div class="is-flex is-flex-direction-row" style="gap: 10px;">
                                    <a class="is-underlined large-body has-text-primary"
                                        [ngClass]="[ download.hasSpanish ? '' : 'no-spanish' ]" [href]="download.file"
                                        [attr.aria-label]="download.title" target="_blank"
                                        rel="noreferrer noopener">PDF</a>
                                    <a *ngIf="download.hasSpanish" class="is-underlined large-body has-text-primary"
                                        [href]="download.spanishFile" [attr.aria-label]="download.spanishTitle"
                                        target="_blank" rel="noreferrer noopener">Spanish</a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="resource-list is-flex is-flex-direction-row">
                    <div class="is-flex is-flex-direction-row is-hidden-touch" style="max-width: 45%;">
                        <img src="assets/images/Archives Snapshot Image.jpg" class="rounded-corners is-align-self-center"
                            alt="black and white setting with children doing math on a chalkboard" />
                    </div>

                    <div class="resource-list-content">
                        <h4 class="has-text-primary">CRDC Data Archives</h4>
                        <hr class="content-divider">
                        <ul>
                            <li *ngFor="let download of dataArchives; let i = index"
                                class="is-flex is-flex-direction-row is-justify-content-space-between content-download"
                                style="gap: 30px;">
                                <p class="large-body has-text-primary">{{ download.title }}</p>
                                <div class="is-flex is-flex-direction-row" style="gap: 10px;">
                                    <a class="is-underlined large-body has-text-primary"
                                        [ngClass]="[ download.hasSpanish ? '' : 'no-spanish' ]" [href]="download.file"
                                        [attr.aria-label]="download.title" target="_blank"
                                        rel="noreferrer noopener">PDF</a>
                                    <a *ngIf="download.hasSpanish" class="is-underlined large-body has-text-primary"
                                        [href]="download.spanishFile" [attr.aria-label]="download.spanishTitle"
                                        target="_blank" rel="noreferrer noopener">Spanish</a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
