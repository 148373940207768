<div class="container content">
    <page-title title="2015-16 State and National Tables"></page-title>
    <p>The 2015-16 tables are based on data collected from all of the nation’s school districts and schools—approximately 17,300 school districts and 96,300 schools.</p>

    <mat-accordion>

        <span class="has-text-weight-bold">Student Enrollment</span>
        <mat-expansion-panel>
            <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
                <mat-panel-title>
                    All Enrollment
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="accordion-panel-body">
                <p class="has-text-centered is-size-6 has-text-weight-bold">Tables for Enrollment</p>
                <p>
                    This set of Excel files contains data for all states on overall enrollment, enrollment
                    of students served under IDEA, enrollment of students served under Section 504, and enrollment of students who
                    are English language learners. For each category, there are three spreadsheets: total students, male
                    students, and female students.
                </p>
                <div class="columns is-gapless is-multiline">
                    <div class="column is-one-quarter has-text-centered">
                        <a href="assets/downloads/2015-2016/Enrollment-Overall.xlsx" rel="noopener noreferrer" target="_blank">Enrollment</a>
                    </div>
                    <div class="column is-one-quarter has-text-centered">
                        <a href="assets/downloads/2015-2016/Enrollment-IDEA.xlsx" rel="noopener noreferrer" target="_blank">IDEA enrollment</a>
                    </div>
                    <div class="column is-one-quarter has-text-centered">
                        <a href="assets/downloads/2015-2016/Enrollment-Section-504-only.xlsx" rel="noopener noreferrer" target="_blank">Section 504 enrollment</a>
                    </div>
                    <div class="column is-one-quarter has-text-centered">
                        <a href="assets/downloads/2015-2016/Enrollment-ELL.xlsx" rel="noopener noreferrer" target="_blank">ELL Enrollment</a>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
        <br />

        <span class="has-text-weight-bold">Pathways to College and Career Readiness</span>
        <mat-expansion-panel>
            <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
                <mat-panel-title>
                    Preschool Enrollment
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="accordion-panel-body">
                <p class="has-text-centered is-size-6 has-text-weight-bold">Preschool Enrollment</p>
                <p>This Excel file contains preschool enrollment data for all states. The file contains three spreadsheets: total children, male children, and female children.</p>
                <div class="columns is-gapless is-multiline">
                    <div class="column is-full has-text-centered">
                        <a href="assets/downloads/2015-2016/Preschool-Enrollment.xlsx" rel="noopener noreferrer" target="_blank">Preschool Enrollment</a>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel>
            <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
                <mat-panel-title>
                    English Language Instruction Educational Programs
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="accordion-panel-body">
                <p class="has-text-centered is-size-6 has-text-weight-bold">English Language Instruction Program Enrollment</p>
                <p>This Excel file contains data for English language learners enrolled in English language instruction educational programs for all states. The file contains three spreadsheets: total children, male children, and female children.</p>
                <div class="columns is-gapless is-multiline">
                    <div class="column is-full has-text-centered">
                        <a href="assets/downloads/2015-2016/English-Language-Instruction-Programs.xlsx" rel="noopener noreferrer" target="_blank">English language instruction educational program enrollment</a>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel>
            <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
                <mat-panel-title>
                    Gifted and Talented
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="accordion-panel-body">
                <p class="has-text-centered is-size-6 has-text-weight-bold">Gifted and Talented Enrollment</p>
                <p>
                    This Excel file contains data on students enrolled in gifted and talented programs for all states. The file contains three spreadsheets: total students, male students, and female students.
                </p>
                <div class="columns is-gapless is-multiline">
                    <div class="column is-full has-text-centered">
                        <a href="assets/downloads/2015-2016/Gifted-Talented-Enrollment.xlsx" rel="noopener noreferrer" target="_blank">Gifted and talented enrollment</a>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel>
            <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
                <mat-panel-title>
                    Retention
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="accordion-panel-body">
                <p class="has-text-centered is-size-6 has-text-weight-bold">Retention by Grade</p>
                <p>
                    This set of Excel files contains student retention data for all states, presented by grade. For each grade, there are three spreadsheets: total students, male students, and female students.
                </p>
                <div class="columns is-multiline is-gapless">
                    <div class="column is-one-third has-text-centered">
                        <a href="assets/downloads/2015-2016/Retention-in-Kindergarten.xlsx" rel="noopener noreferrer" target="_blank">Kindergarten</a>
                    </div>
                    <div class="column is-one-third has-text-centered">
                        <a href="assets/downloads/2015-2016/Retention-in-Grade-1.xlsx" rel="noopener noreferrer" target="_blank">Grade 1</a>
                    </div>
                    <div class="column is-one-third has-text-centered">
                        <a href="assets/downloads/2015-2016/Retention-in-Grade-2.xlsx" rel="noopener noreferrer" target="_blank">Grade 2</a>
                    </div>
                </div>
                <div class="columns is-multiline is-gapless">
                    <div class="column is-one-third has-text-centered">
                        <a href="assets/downloads/2015-2016/Retention-in-Grade-3.xlsx" rel="noopener noreferrer" target="_blank">Grade 3</a>
                    </div>
                    <div class="column is-one-third has-text-centered">
                        <a href="assets/downloads/2015-2016/Retention-in-Grade-4.xlsx" rel="noopener noreferrer" target="_blank">Grade 4</a>
                    </div>
                    <div class="column is-one-third has-text-centered">
                        <a href="assets/downloads/2015-2016/Retention-in-Grade-5.xlsx" rel="noopener noreferrer" target="_blank">Grade 5</a>
                    </div>
                </div>
                <div class="columns is-multiline is-gapless">
                    <div class="column is-one-third has-text-centered">
                        <a href="assets/downloads/2015-2016/Retention-in-Grade-6.xlsx" rel="noopener noreferrer" target="_blank">Grade 6</a>
                    </div>
                    <div class="column is-one-third has-text-centered">
                        <a href="assets/downloads/2015-2016/Retention-in-Grade-7.xlsx" rel="noopener noreferrer" target="_blank">Grade 7</a>
                    </div>
                    <div class="column is-one-third has-text-centered">
                        <a href="assets/downloads/2015-2016/Retention-in-Grade-8.xlsx" rel="noopener noreferrer" target="_blank">Grade 8</a>
                    </div>
                </div>
                <div class="columns is-multiline is-gapless">
                    <div class="column is-one-third has-text-centered">
                        <a href="assets/downloads/2015-2016/Retention-in-Grade-9.xlsx" rel="noopener noreferrer" target="_blank">Grade 9</a>
                    </div>
                    <div class="column is-one-third has-text-centered">
                        <a href="assets/downloads/2015-2016/Retention-in-Grade-10.xlsx" rel="noopener noreferrer" target="_blank">Grade 10</a>
                    </div>
                    <div class="column is-one-third has-text-centered">
                        <a href="assets/downloads/2015-2016/Retention-in-Grade-11.xlsx" rel="noopener noreferrer" target="_blank">Grade 11</a>
                    </div>
                </div>
                <div class="columns is-multiline is-gapless">
                    <div class="column is-one-third has-text-centered">
                        <a href="assets/downloads/2015-2016/Retention-in-Grade-12.xlsx" rel="noopener noreferrer" target="_blank">Grade 12</a>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel>
            <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
                <mat-panel-title>
                    Student Absenteeism
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="accordion-panel-body">
                <p class="has-text-centered is-size-6 has-text-weight-bold">Student Absenteeism</p>
                <p>
                    This Excel file contains data on chronic student absenteeism - students absent 15 or more days during the school year - for all states. The file contains three spreadsheets: total students, male students, and female students.
                </p>
                <div class="columns is-gapless is-multiline">
                    <div class="column is-full has-text-centered">
                        <a href="assets/downloads/2015-2016/Chronic-Absenteeism.xlsx" rel="noopener noreferrer" target="_blank">Student absenteeism</a>
                    </div>
                </div>

            </div>
        </mat-expansion-panel>

        <br />

        <span class="has-text-weight-bold">College and Career Readiness</span>
        <mat-expansion-panel>
            <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
                <mat-panel-title>
                    Mathematics and Science
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="accordion-panel-body">
                <p class="has-text-centered is-size-6 has-text-weight-bold">Schools Offering Mathematics and Science Courses</p>
                <p>This set of Excel files contains data for schools offering courses in mathematics and science for all states. Each file contains one spreadsheet for total schools.</p>
                <div class="columns is-gapless is-multiline">
                    <div class="column has-text-centered">
                        <a href="assets/downloads/2015-2016/Middle-Schools-Offering-Mathematics-Classes-Grade-7-and-8.xlsx" rel="noopener noreferrer" target="_blank">Middle schools offering mathematics courses</a>
                    </div>
                    <div class="column has-text-centered">
                        <a href="assets/downloads/2015-2016/High-Schools-Offering-Mathematics-Classes.xlsx" rel="noopener noreferrer" target="_blank">High schools offering mathematics courses</a>
                    </div>
                    <div class="column has-text-centered">
                        <a href="assets/downloads/2015-2016/High-Schools-Offering-Science-Classes.xlsx" rel="noopener noreferrer" target="_blank">High schools offering science courses</a>
                    </div>
                </div>

                <p class="has-text-centered is-size-6 has-text-weight-bold">Mathematics and Science Classes Taught by Certified Teachers</p>
                <p>This set of Excel files contains data for school classes in mathematics and science taught by certified teachers for all states.  Each file contains one spreadsheet with classes data by type of course.</p>
                <div class="columns is-gapless is-multiline">
                    <div class="column has-text-centered">
                        <a href="assets/downloads/2015-2016/Middle-School-Math-Classes-Certified-Teachers.xlsx" rel="noopener noreferrer" target="_blank">Middle school mathematics classes taught by certified teachers</a>
                    </div>
                    <div class="column has-text-centered">
                        <a href="assets/downloads/2015-2016/High-School-Math-Classes-Certified-Teachers.xlsx" rel="noopener noreferrer" target="_blank">High school mathematics classes taught by certified teachers</a>
                    </div>
                    <div class="column has-text-centered">
                        <a href="assets/downloads/2015-2016/High-School-Science-Classes-Certified-Teachers.xlsx" rel="noopener noreferrer" target="_blank">High school science classes taught by certified teachers</a>
                    </div>
                </div>

                <br /><br />
                <p class="has-text-centered is-size-6 has-text-weight-bold">Mathematics Tables by Course</p>
                <p>
                    This set of Excel files contains student enrollment data for all states, presented by mathematics course. For
                    each mathematics course, except Algebra I Grade 7 and Geometry Grade 8, there
                    are three spreadsheets: total students, male students, and female students.  For each Algebra I Grade 7 and
                    Geometry Grade 8 course, there is one spreadsheet for total students.
                </p>
                <div class="columns is-multiline is-gapless">
                    <div class="column is-one-fourth has-text-centered">
                        <a href="assets/downloads/2015-2016/Enrollment-in-Algebra-I-Grade-7.xlsx" rel="noopener noreferrer" target="_blank">Algebra I Grade 7</a>
                    </div>
                    <div class="column is-one-fourth has-text-centered">
                        <a href="assets/downloads/2015-2016/Enrollment-in-Algebra-I-Grade-8.xlsx" rel="noopener noreferrer" target="_blank">Algebra I Grade 8</a>
                    </div>
                    <div class="column is-one-fourth has-text-centered">
                        <a href="assets/downloads/2015-2016/Enrollment-in-Algebra-I-Grades-9-10.xlsx" rel="noopener noreferrer" target="_blank">Algebra I Grades 9-10</a>
                    </div>
                    <div class="column is-one-fourth has-text-centered">
                        <a href="assets/downloads/2015-2016/Enrollment-in-Algebra-I-Grades-11-12.xlsx" rel="noopener noreferrer" target="_blank">Algebra I Grades 11-12</a>
                    </div>
                </div>
                <div class="columns is-multiline is-gapless">
                    <div class="column is-one-fourth has-text-centered">
                        <a href="assets/downloads/2015-2016/Enrollment-in-Geometry-in-Grade-8.xlsx" rel="noopener noreferrer" target="_blank">Geometry Grade 8</a>
                    </div>
                    <div class="column is-one-fourth has-text-centered">
                        <a href="assets/downloads/2015-2016/Enrollment-in-Geometry-in-High-School.xlsx" rel="noopener noreferrer" target="_blank">Geometry in high school</a>
                    </div>
                    <div class="column is-one-fourth has-text-centered">
                        <a href="assets/downloads/2015-2016/Enrollment-in-Algebra-II.xlsx" rel="noopener noreferrer" target="_blank">Algebra II</a>
                    </div>
                    <div class="column is-one-fourth has-text-centered">
                        <a href="assets/downloads/2015-2016/Enrollment-in-Advanced-Mathematics.xlsx" rel="noopener noreferrer" target="_blank">Advanced mathematics</a>
                    </div>
                </div>
                <div class="columns is-multiline is-gapless has-text-centered">
                    <div class="column">
                        <a href="assets/downloads/2015-2016/Enrollment-in-Calculus.xlsx" rel="noopener noreferrer" target="_blank">Calculus</a>
                    </div>
                </div>

                <br /><br />
                <p class="has-text-centered is-size-6 has-text-weight-bold">Science Tables by Course</p>
                <p style="padding: 5px;">
                    This set of Excel files contains student enrollment data for all states, presented
                    by science course. For each science course, there are three spreadsheets: total
                    students, male students, and female students.
                </p>
                <div class="columns is-multiline is-gapless">
                    <div class="column is-one-third has-text-centered">
                        <a href="assets/downloads/2015-2016/Enrollment-in-Biology.xlsx" rel="noopener noreferrer" target="_blank">Biology</a>
                    </div>
                    <div class="column is-one-third has-text-centered">
                        <a href="assets/downloads/2015-2016/Enrollment-in-Chemistry.xlsx" rel="noopener noreferrer" target="_blank">Chemistry</a>
                    </div>
                    <div class="column is-one-third has-text-centered">
                        <a href="assets/downloads/2015-2016/Enrollment-in-Physics.xlsx" rel="noopener noreferrer" target="_blank">Physics</a>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel>
            <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
                <mat-panel-title>
                    Advanced Placement
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="accordion-panel-body">
                <p class="has-text-centered is-size-6 has-text-weight-bold">Advanced Placement Total Enrollment</p>
                <p>
                    This Excel file contains student enrollment in Advanced Placement for all states.
                    There are three spreadsheets: total students, male students, and female students.
                </p>
                <div class="has-text-centered">
                    <a href="assets/downloads/2015-2016/Advanced-Placement-Enrollment.xlsx" rel="noopener noreferrer" target="_blank">Advanced placement</a>
                </div>

                <br /><br />
                <p class="has-text-centered is-size-6 has-text-weight-bold">Advanced Placement Enrollment by Subject</p>
                <p>
                    This set of Excel files contains student enrollment data for all states, presented by Advanced
                    Placement (AP) subject. For each AP subject, there are three spreadsheets: total students, male students, and female students.
                </p>
                <div class="columns is-gapless is-multiline">
                    <div class="column is-one-third has-text-centered">
                        <a href="assets/downloads/2015-2016/Advanced-Placement-Mathematics-Enrollment.xlsx" rel="noopener noreferrer" target="_blank">AP mathematics</a>
                    </div>
                    <div class="column is-one-third has-text-centered">
                        <a href="assets/downloads/2015-2016/Advanced-Placement-Science-Enrollment.xlsx" rel="noopener noreferrer" target="_blank">AP science</a>
                    </div>
                    <div class="column is-one-third has-text-centered">
                        <a href="assets/downloads/2015-2016/Advanced-Placement-Other-Subjects-Enrollment.xlsx" rel="noopener noreferrer" target="_blank">AP other subjects</a>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel>
            <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
                <mat-panel-title>
                    International Baccalaureate (IB)
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="accordion-panel-body">
                <p class="has-text-centered is-size-6 has-text-weight-bold">International Baccalaureate Enrollment</p>
                <p>
                    This Excel file contains data on student enrollment in International Baccalaureate (IB)
                    programs for all states. The file contains three spreadsheets: total students, male students, and female students.
                </p>
                <div class="columns is-gapless">
                    <div class="column is-full has-text-centered">
                        <a href="assets/downloads/2015-2016/IB-Enrollment-by-State.xlsx" rel="noopener noreferrer" target="_blank">IB Enrollment</a>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel>
            <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
                <mat-panel-title>
                    Advanced Placement Exams
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="accordion-panel-body">
                <p class="has-text-centered is-size-6 has-text-weight-bold">Advanced Placement Exam Taking</p>
                <p>
                    This set of Excel files contains data on Advanced Placement exam taking for all
                    states. Each file contains three spreadsheets: total students, male students, and female students.
                </p>
                <div class="columns is-gapless is-multiline has-text-centered">
                    <div class="column is-half">
                        <a href="assets/downloads/2015-2016/Advanced-Placement-Participation-by-State-Did-Not-Take-Exam.xlsx" rel="noopener noreferrer" target="_blank">Did not take AP Exam</a>
                    </div>
                    <div class="column is-half">
                        <a href="assets/downloads/2015-2016/Advanced-Placement-Participation-by-State-Took-Exam.xlsx" rel="noopener noreferrer" target="_blank">Took AP Exam</a>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel>
            <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
                <mat-panel-title>
                    SAT/ACT
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="accordion-panel-body">
                <p class="has-text-centered is-size-6 has-text-weight-bold">SAT/ACT Participation</p>
                <p>
                    This Excel file contains SAT/ACT student participation data for all states. The
                    file contains three spreadsheets: total students, male students, and female students.
                </p>
                <div class="columns is-gapless">
                    <div class="column is-full has-text-centered">
                        <a href="assets/downloads/2015-2016/SAT-or-ACT-Participation-by-State.xlsx" rel="noopener noreferrer" target="_blank">SAT/ACT participation</a>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>

        <br />

        <span class="has-text-weight-bold">School Staff</span>
        <mat-expansion-panel>
            <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
                <mat-panel-title>
                    Classroom Teachers
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="accordion-panel-body">
                <p class="has-text-centered is-size-6 has-text-weight-bold">Classroom Teachers</p>
                <p>
                    These Excel files contain data on classroom teachers, including full-time
                    equivalency counts, certification, years of experience, and retention for all states. Each
                    file contains one spreadsheet for total teachers.
                </p>
                <div class="columns is-gapless is-multiline has-text-centered">
                    <div class="column is-half">
                        <a href="assets/downloads/2015-2016/Teacher-Certification-and-Years-of-Experience.xlsx" rel="noopener noreferrer" target="_blank">Classroom teachers</a>
                    </div>
                    <div class="column is-half">
                        <a href="assets/downloads/2015-2016/Teacher-Retention.xlsx" rel="noopener noreferrer" target="_blank">Teacher Retention</a>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel>
            <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
                <mat-panel-title>
                    School Support Staff
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="mat-expansion-panel-content-padding accordion-panel-body">
                <p class="has-text-centered is-size-6 has-text-weight-bold">School Support Staff</p>
                <p>
                    This set of Excel files contains data on full-time equivalent support staff, and schools with support staff,
                    by type of staff and for all states.  Each file contains one spreadsheet for elementary, middle, or high schools.
                </p>
                <div class="columns is-gapless is-multiline has-text-centered">
                    <div class="column is-one-third">
                        <a href="assets/downloads/2015-2016/FTE-Support-Staff-Elementary-School.xlsx" rel="noopener noreferrer" target="_blank">Support staff in elementary schools</a>
                    </div>
                    <div class="column is-one-third">
                        <a href="assets/downloads/2015-2016/FTE-Support-Staff-Middle-School.xlsx" rel="noopener noreferrer" target="_blank">Support staff in middle schools</a>
                    </div>
                    <div class="column is-one-third">
                        <a href="assets/downloads/2015-2016/FTE-Support-Staff-High-School.xlsx" rel="noopener noreferrer" target="_blank">Support staff in high schools</a>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>

        <br />

        <span class="has-text-weight-bold">Discipline, Harassment or Bullying, Restraint and Seclusion, and Offenses</span>
        <mat-expansion-panel>
            <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
                <mat-panel-title>
                    Discipline
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="accordion-panel-body">
                <p class="has-text-centered is-size-6 has-text-weight-bold">Discipline by Discipline Type</p>
                <p>
                    This set of Excel files contains data for all states, presented by discipline type. For each discipline
                    type, there are nine spreadsheets: total students with and without disabilities; male students with and without disabilities;
                    female students with and without disabilities; total students with disabilities; male students with disabilities; female students
                    with disabilities; total students without disabilities; male students without disabilities; and female students without diabilities.
                </p>
                <div class="columns is-gapless is-multiline">
                    <div class="column is-half has-text-centered">
                        <a href="assets/downloads/2015-2016/Corporal-Punishment.xlsx" rel="noopener noreferrer" target="_blank">Corporal punishment</a>
                    </div>
                    <div class="column is-half has-text-centered">
                        <a href="assets/downloads/2015-2016/In-school-suspensions.xlsx" rel="noopener noreferrer" target="_blank">In-school suspension</a>
                    </div>
                </div>
                <div class="columns is-gapless is-multiline">
                    <div class="column is-half has-text-centered">
                        <a href="assets/downloads/2015-2016/One-out-of-school-suspension.xlsx" rel="noopener noreferrer" target="_blank">One out-of-school suspension</a>
                    </div>
                    <div class="column is-half has-text-centered">
                        <a href="assets/downloads/2015-2016/More-than-one-out-of-school-suspension.xlsx" rel="noopener noreferrer" target="_blank">More than one out-of-school suspension</a>
                    </div>
                </div>
                <div class="columns is-gapless is-multiline">
                    <div class="column is-half has-text-centered">
                        <a href="assets/downloads/2015-2016/One-or-more-out-of-school-suspensions.xlsx" rel="noopener noreferrer" target="_blank">One or more out-of-school suspensions</a>
                    </div>
                    <div class="column is-half has-text-centered">
                        <a href="assets/downloads/2015-2016/Expulsions-with-educational-services.xlsx" rel="noopener noreferrer" target="_blank">Expulsions with educational services</a>
                    </div>
                </div>
                <div class="columns is-gapless is-multiline">
                    <div class="column is-half has-text-centered">
                        <a href="assets/downloads/2015-2016/Expulsions-without-educational-services.xlsx" rel="noopener noreferrer" target="_blank">Expulsions without educational services</a>
                    </div>
                    <div class="column is-half has-text-centered">
                        <a href="assets/downloads/2015-2016/Expulsions-with-and-without-educational-services.xlsx" rel="noopener noreferrer" target="_blank">Expulsions with and without educational services</a>
                    </div>
                </div>
                <div class="columns is-gapless is-multiline">
                    <div class="column is-half has-text-centered">
                        <a href="assets/downloads/2015-2016/Expulsions-due-to-zero-tolerance-policies.xlsx" rel="noopener noreferrer" target="_blank">Expulsions under zero tolerance policies</a>
                    </div>
                    <div class="column is-half has-text-centered">
                        <a href="assets/downloads/2015-2016/Referral-to-law-enforcement.xlsx" rel="noopener noreferrer" target="_blank">Referrals to law enforcement</a>
                    </div>
                </div>
                <div class="columns is-gapless is-multiline">
                    <div class="column is-half has-text-centered">
                        <a href="assets/downloads/2015-2016/School-related-arrests.xlsx" rel="noopener noreferrer" target="_blank">School-related arrests</a>
                    </div>
                    <div class="column is-half has-text-centered">
                        <a href="assets/downloads/2015-2016/Transferred-to-alternative-school.xlsx" rel="noopener noreferrer" target="_blank">Transfers to alternative schools</a>
                    </div>
                </div>

                <br /><br />

                <p class="has-text-centered is-size-6 has-text-weight-bold">Days Missed Due to Out-of-School Suspensions</p>
                <p>This Excel file contains data on school days missed by students due to out-of-school-suspensions, for all states.</p>
                <div class="columns is-gapless is-multiline has-text-centered">
                    <div class="column">
                        <a href="assets/downloads/2015-2016/Days-missed-due-to-out-of-school-suspensions.xlsx" rel="noopener noreferrer" target="_blank">Days missed due to out-of-school suspensions</a>
                    </div>
                </div>

                <br /><br />
                <p class="has-text-centered is-size-6 has-text-weight-bold">Preschool Discipline by Discipline Type</p>
                <p>
                    This set of Excel files contains data for all states, presented by discipline
                    type. For each discipline type, there are three spreadsheets: total preschool students, male preschool
                    students, and female preschool students.
                </p>
                <div class="columns is-gapless is-multiline">
                    <div class="column is-half has-text-centered">
                        <a href="assets/downloads/2015-2016/Preschool-Corporal-Punishment.xlsx" rel="noopener noreferrer" target="_blank">Preschool corporal punishment</a>
                    </div>
                    <div class="column is-half has-text-centered">
                        <a href="assets/downloads/2015-2016/Preschool-One-out-of-school-suspension.xlsx" rel="noopener noreferrer" target="_blank">Preschool one out-of-school suspension</a>
                    </div>
                </div>
                <div class="columns is-gapless is-multiline">
                    <div class="column is-half has-text-centered">
                        <a href="assets/downloads/2015-2016/Preschool-More-than-one-out-of-school-suspension.xlsx" rel="noopener noreferrer" target="_blank">Preschool more than one out-of-school suspension</a>
                    </div>
                    <div class="column is-half has-text-centered">
                        <a href="assets/downloads/2015-2016/Preschool-One-or-More-out-of-school-suspensions.xlsx" rel="noopener noreferrer" target="_blank">Preschool one or more out-of-school suspensions</a>
                    </div>
                </div>
                <div class="columns is-gapless is-multiline">
                    <div class="column is-half has-text-centered">
                        <a href="assets/downloads/2015-2016/Preschool-Expulsions.xlsx" rel="noopener noreferrer" target="_blank">Preschool expulsions</a>
                    </div>
                </div>

                <br /><br />

                      <p class="has-text-centered is-size-6 has-text-weight-bold">Preschool Incidents of Corporal Punishment and Out-of-School Suspensions</p>
                      <p>
                          This Excel file contains data on incidents of preschool
                          corporal punishment and preschool out-of-school suspension for all states.
                      </p>
                <div class="columns is-gapless is-multiline has-text-centered">
                    <div class="column">
                        <a href="assets/downloads/2015-2016/Preschool-Discipline.xlsx" rel="noopener noreferrer" target="_blank">Incidents of Preschool corporal punishment and suspension</a>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel>
            <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
                <mat-panel-title>
                    Harassment or Bullying
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="accordion-panel-body">
                <p class="has-text-centered is-size-6 has-text-weight-bold">Harassment or Bullying by Basis Category</p>
                <p>
                    This set of Excel files contains data on students reported as harassed or bullied or disciplined for
                    harassment or bullying on the basis of sex, race, or disability category for all states. Each file contains three spreadsheets:
                    total students, male students, and female students.
                </p>

                <div class="columns is-gapless is-multiline">
                    <div class="column is-one-third has-text-centered">
                        <a href="assets/downloads/2015-2016/Harassment-Bullying-on-basis-of-sex-reported.xlsx" rel="noopener noreferrer" target="_blank">On basis of sex - reported</a>
                    </div>
                    <div class="column is-one-third has-text-centered">
                        <a href="assets/downloads/2015-2016/Harassment-Bullying-on-basis-of-race-reported.xlsx" rel="noopener noreferrer" target="_blank">On basis of race - reported</a>
                    </div>
                    <div class="column is-one-third has-text-centered">
                        <a href="assets/downloads/2015-2016/Harassment-Bullying-on-basis-of-disability-reported.xlsx" rel="noopener noreferrer" target="_blank">On basis of disability - reported</a>
                    </div>
                </div>
                <div class="columns is-gapless is-multiline">
                    <div class="column is-one-third has-text-centered">
                        <a href="assets/downloads/2015-2016/Harassment-Bullying-on-basis-of-sex-disciplined.xlsx" rel="noopener noreferrer" target="_blank">On basis of sex - disciplined</a>
                    </div>
                    <div class="column is-one-third has-text-centered">
                        <a href="assets/downloads/2015-2016/Harassment-Bullying-on-basis-of-race-disciplined.xlsx" rel="noopener noreferrer" target="_blank">On basis of race - disciplined</a>
                    </div>
                    <div class="column is-one-third has-text-centered">
                        <a href="assets/downloads/2015-2016/Harassment-Bullying-on-basis-of-disability-disciplined.xlsx" rel="noopener noreferrer" target="_blank">On basis of disability - disciplined</a>
                    </div>
                </div>

                <br /><br />
                      <p class="has-text-centered is-size-6 has-text-weight-bold">Allegations of Harassment or Bullying</p>
                      <p>
                          This Excel file contains data on allegations of harassment or bullying by type of
                          allegations for all states.
                      </p>
                <div class="columns is-gapless is-multiline">
                    <div class="column is-full has-text-centered">
                        <a href="assets/downloads/2015-2016/Allegations-of-Harassment-or-Bullying.xlsx" rel="noopener noreferrer" target="_blank">Allegations of harassment or bullying</a>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel>
            <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
                <mat-panel-title>
                    Restraint and Seclusion
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="accordion-panel-body">
                <p class="has-text-centered is-size-6 has-text-weight-bold">Restraint and Seclusion</p>
                <p>
                    This set of Excel files contains mechanical restraint, physical restraint, and seclusion data on students for
                    all states. Each file contains nine spreadsheets: total students served under IDEA and not served under IDEA;
                    male students served under IDEA and not served under IDEA; female students served under IDEA and not served under
                    IDEA; total students served under IDEA; male students served under IDEA; female students served under IDEA; total
                    students not served under IDEA; male students not served under IDEA; and female students not served under IDEA.
                </p>
                <div class="columns is-gapless is-multiline">
                    <div class="column is-one-third has-text-centered">
                        <a href="assets/downloads/2015-2016/Mechanical-Restraint.xlsx" rel="noopener noreferrer" target="_blank">Mechanical restraint</a>
                    </div>
                    <div class="column is-one-third has-text-centered">
                        <a href="assets/downloads/2015-2016/Physical-Restraint.xlsx" rel="noopener noreferrer" target="_blank">Physical restraint</a>
                    </div>
                    <div class="column is-one-third has-text-centered">
                        <a href="assets/downloads/2015-2016/Seclusion.xlsx" rel="noopener noreferrer" target="_blank">Seclusion</a>
                    </div>
                </div>

                <br /><br />
                      <p class="has-text-centered is-size-6 has-text-weight-bold">Incidents of Offenses</p>
                      <p>This Excel file contains data on incidents of offenses by type of offense for all states.</p>
                <div class="columns is-gapless is-multiline">
                    <div class="column is-full has-text-centered">
                        <a href="assets/downloads/2015-2016/Offenses.xlsx" rel="noopener noreferrer" target="_blank">Offenses</a>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>

    </mat-accordion>

    <document-viewer docType="PDF"></document-viewer>
</div>
