import { Injectable } from '@angular/core';
import { EntityType, LabelValuePair, ProfileBlockTypes, State, SurveyYearModel } from '../../models/profile-model';
import { SurveyYearService } from '../data/survey-year.service';
import { StatesService } from '../data/states.service';
import { Router } from '@angular/router';
import { EntityMeasuresQuery } from '../../queries/entity-measures-query';
import { MeasureService } from '../data/measure.service';
import { EntityDataQuery } from '../../queries/entity-data-query';
import { SchoolProfileQueryModel } from '../../query-models/school-profile-query-model';
import { DistrictProfileQueryModel } from '../../query-models/district-profile-query-model';
import { firstValueFrom } from 'rxjs';
import {NGXLogger} from 'ngx-logger';
import { DataToolService } from '../data/datatool.service';

@Injectable({
    providedIn: 'root'
})
export class ProfileService {
    private entityId: number;
    private notFoundLink: string = '';
    private surveyYearKeys: SurveyYearModel[];
    private sections: any;
    private states: State[];

    constructor(
        private logger: NGXLogger,
        private readonly surveyYearService: SurveyYearService,
        private readonly statesService: StatesService,
        private readonly measureService: MeasureService,
        private readonly dataToolService: DataToolService,
        private router: Router) {
    }

    setEntityId(id: number) {
        this.entityId = id;
    }

    getEnttityId() {
        return this.entityId;
    }

    getNotFoundLink(): string {
        return this.notFoundLink;
    }

    setNotFoundLink(route: string) {
        this.notFoundLink = route;
    }

    public getSurveyYearPeriod(SYK: number): string {

        //const surveyYears: SurveyYearModel[] = await this.loadSurveyYearKeys();

        if (this.surveyYearKeys) {
            return this.surveyYearKeys.find(y => y.yearKey === SYK)?.period;
        }
        else {
            this.logger.error('Need to load SurveyYears')
        }

    }

    getSurveyYearKeys(entityType: EntityType) {
        if (entityType === EntityType.SCHOOL || entityType === EntityType.DISTRICT) {
            let syk: any = JSON.parse(sessionStorage.getItem('entitySurveyYearKeys'));
            let filteredSyk: any = syk.SYK === undefined ? [] : syk.SYK.filter(year => year.Survey_Year_Key > 5);

            let surveyYears: SurveyYearModel[] = filteredSyk.map(year => {
                let currentYear: string = year.SURVEY_YEAR;
                return this.surveyYearKeys.find(surveyYear => currentYear === surveyYear.surveyYear);
            }).reverse();

            return surveyYears;
        } else {
            return this.surveyYearKeys ? this.surveyYearKeys.filter(syk => syk.yearKey > 5) : [];
        }
    }

    getFormattedSurveyYearKeys(syk: string) {
        let surveyYears: SurveyYearModel[] = [];

        if (syk) {
            surveyYears = JSON.parse(syk)['SYK'].map(year => {
                const surveyYearKey: SurveyYearModel = {
                    surveyYear: year['SURVEY_YEAR'],
                    yearKey: year['Survey_Year_Key'],
                    period: year['SURVEY_PERIOD'],
                    surveyDescription: ''
                };

                return surveyYearKey;
            });
        }

        return surveyYears;
    }

    setSurveyYearKeys(surveyYearKeys: SurveyYearModel[]) {
        this.surveyYearKeys = surveyYearKeys;

        sessionStorage.setItem('allSurveyYearKeys', JSON.stringify(surveyYearKeys));
    }

    async loadSurveyYearKeys(): Promise<SurveyYearModel[]> {

        this.logger.debug('Inside loadSurveyYearKeys');

        // use what we have in memory, and if not use what we have in session storage
        if (!this.surveyYearKeys) {
            this.surveyYearKeys = JSON.parse(sessionStorage.getItem('allSurveyYearKeys'));
        }

        // if nothing in memory load from the backend
        if (!this.surveyYearKeys) {

            const data = await this.surveyYearService.getSurveyYearsList(10).toPromise()
                .catch((error) => {

                    const msg = 'Unable to retrieve SurveyYearsList, error: ';
                    this.logger.error(msg, error);
                    return null;

                });

            this.logger.debug('found SurveyYearKeys, data: ', data);

            if (data) {
                this.setSurveyYearKeys(data.surveyYearsModels);
            }
        }

        return this.surveyYearKeys;

    }

    getMeasures(): any {
        return this.sections;
    }

    setMeasures(measures: any) {
        this.sections = measures;
    }

    loadMeasures(entityType: EntityType, surveyYearKey: number): Promise<void> {
        const emq: EntityMeasuresQuery = new EntityMeasuresQuery();

        switch (entityType) {
            case EntityType.SCHOOL:
                emq.entityType = 's';
                break;
            case EntityType.DISTRICT:
                emq.entityType = 'd';
                break;
            case EntityType.STATE:
                emq.entityType = 'st';
                break;
            case EntityType.NATIONAL:
                emq.entityType = 'n';
        }

        emq.entityId = entityType !== EntityType.NATIONAL ? this.entityId : 0;
        emq.surveyYearKey = surveyYearKey;

        return new Promise(resolve => {
            this.measureService.getEntityMeasures(emq).subscribe(data => {
                if (data) {
                    this.setMeasures(data.result);
                }

                resolve();
            });
        });
    }

    getStateIds(): State[] {
        return this.states;
    }

    setStateIds(states: State[]): void {
        this.states = states;
    }

    loadStateList(): Promise<void> {
        return new Promise(resolve => {
            const stateList = JSON.parse(sessionStorage.getItem('stateList'));

            if (stateList) {
                this.setStateIds(stateList);
                resolve();
            } else {
                this.statesService.getStatesList().subscribe(data => {
                    if (data) {
                        this.setStateIds(data.statesModels);
                        sessionStorage.setItem('stateList', JSON.stringify(data.statesModels));
                    }

                    resolve();
                });
            }
        });
    }

    // This function encodes the following non-escaped characters including the existing encoding function characters ! ~ * ' ( )
    encodeCrdUri(uri: string) {
        let encodedUri: string = encodeURIComponent(uri);
        encodedUri = encodedUri.replace(/\!/g, '%21');
        encodedUri = encodedUri.replace(/\~/g, '%7E');
        encodedUri = encodedUri.replace(/\*/g, '%2A');
        encodedUri = encodedUri.replace(/\'/g, '%27');
        encodedUri = encodedUri.replace(/\(/g, '%28');
        encodedUri = encodedUri.replace(/\)/g, '%29');

        return encodedUri;
    }

    decodeCrdUri(uri: string) {
        let decodedUri: string = decodeURIComponent(uri);
        decodedUri = decodedUri.replace(/\%21/g, '!');
        decodedUri = decodedUri.replace(/\%7E/g, '~');
        decodedUri = decodedUri.replace(/\%2A/g, '*');
        decodedUri = decodedUri.replace(/\%27/g, '\'');
        decodedUri = decodedUri.replace(/\%28/g, '(');
        decodedUri = decodedUri.replace(/\%29/g, ')');

        return decodedUri;
    }

    routeTo404(): void {
        this.setNotFoundLink(window.location.href);
        this.router.navigateByUrl('/404');
    }

    convertToProfileBlockEnum(str: string): ProfileBlockTypes | undefined {
        const profileBlockType = ProfileBlockTypes[str as keyof typeof ProfileBlockTypes];
        return profileBlockType;
    }

    /**
     * Takes in a count of students and returns the proper formatting
     * for how it should be displayed in the front end should the
     * number be negative.
     * @param count given count of students
     * @returns a properly formatted string or number
     */
    getFormattedCount(count: number): any {
        if (count === -11) {
            return 'ǂ'
        } else if (count < 0 || count === null) {
            return '—';
        } else {
            return count;
        }
    }

    async getDataToolSchoolOrDistictProfile(entityId: number, entityType: EntityType, surveyYearKey: number): Promise<SchoolProfileQueryModel | DistrictProfileQueryModel> {
        return new Promise(async resolve => {
            const query: EntityDataQuery = new EntityDataQuery();

            query.entityId = entityId;
            query.surveyYearKey = surveyYearKey;

            if (entityType === EntityType.SCHOOL) {
                const data = await firstValueFrom(this.dataToolService.getSchoolProfileDT(query))

                resolve(data);
            } else if (entityType === EntityType.DISTRICT) {
                const data = await firstValueFrom(this.dataToolService.getDistrictProfileDT(query))

                resolve(data);
            } else {
                throw new Error('Invalid entity type');
            }
        });

    }

    getSchoolTypeValues(): LabelValuePair[] {
        return [
            { value: 'c', label: 'Charter' },
            { value: 'jj', label: 'Justice Facility' },
            { value: 'm', label: 'Magnet' },
            { value: 'SpEd', label: 'Special Education' },
            { value: 'a', label: 'Alternative' },
            { value: 'g', label: 'General Education' }
        ];
    }

    getGradeLevels(): LabelValuePair[] {
        return [
            { value: 'GPRE', label: 'Preschool' },
            { value: 'GK', label: 'K' },
            { value: 'G1', label: '1' },
            { value: 'G2', label: '2' },
            { value: 'G3', label: '3' },
            { value: 'G4', label: '4' },
            { value: 'G5', label: '5' },
            { value: 'G6', label: '6' },
            { value: 'G7', label: '7' },
            { value: 'G8', label: '8' },
            { value: 'G9', label: '9' },
            { value: 'G10', label: '10' },
            { value: 'G11', label: '11' },
            { value: 'G12', label: '12' },
            { value: 'GUNG', label: 'Ungraded' }
        ];
    }
}
