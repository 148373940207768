import { AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, Renderer2 } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import * as d3 from 'd3';
import { ChartDataCardComponent } from 'src/app/shared/components/chart-data-card/chart-data-card.component';
import { Tooltip } from 'src/app/shared/models/charts-model';
import { ScreenType } from 'src/app/shared/models/shared-model';

@Component({
  selector: 'app-bubble-chart',
  templateUrl: './bubble-chart.component.html',
  styleUrls: ['./bubble-chart.component.scss']
})

/**
 * This class renders a bubble chart given the data
 */
export class BubbleChartComponent implements OnInit, AfterViewInit {

  @Input() data;
  @Input() sectionName: string;

  private svg: any;
  private svgWidth: number = 557;
  private svgHeight: number = 498;
  private margin: any = { top: 0, right: 0, bottom: 0, left: 0 };
  private colorRange: string[] = ["rgba(177, 0, 93, 0.3)", "rgba(19, 53, 89, 0.3)", "rgba(202, 170, 82, 0.3)", "rgba(96, 179, 156, 0.3)",
    "rgba(253, 161, 114, 0.3)", "rgba(119, 186, 43, 0.3)", "rgba(0, 100, 168, 0.3)"];

  private legendSvg: any;
  private legendSvgWidth: number = 557;
  private legendSvgHeight: number = 245
  private legendName: string;

  public ScreenType = ScreenType;
  public screenType: ScreenType;
  public chartTotal: number = 0;

  constructor(private bottomSheet: MatBottomSheet, private elementRef: ElementRef, private renderer: Renderer2) {
  }

  /**
   * This is the entry point function
   */
  ngOnInit(): void {
    this.sectionName = this.sectionName.replace(/\s+/g, '-') + '-bubble';
    this.legendName = this.sectionName + '-legend';
    this.data.forEach(category => {
      this.chartTotal += category.value;
    });

    let windowWidth = window.innerWidth;

    if (windowWidth <= 1150 && windowWidth > 1024) {
      this.svgWidth = 557;
      this.svgHeight = 498;
      this.legendSvgWidth = 400;
      this.legendSvgHeight = 333;
      this.screenType = ScreenType.TABLET;
    } else if (windowWidth <= 1024 && windowWidth > 768) {
      this.svgWidth = 400;
      this.svgHeight = 333;
      this.legendSvgWidth = 380;
      this.legendSvgHeight = 333;
      this.screenType = ScreenType.TABLET;
    } else if (windowWidth <= 768) {
      this.svgWidth = 400;
      this.svgHeight = 333;
      this.legendSvgWidth = 360;
      this.legendSvgHeight = 285;
      this.screenType = ScreenType.MOBILE;
    } else {
      this.svgWidth = 557;
      this.svgHeight = 498;
      this.legendSvgWidth = 557;
      this.legendSvgHeight = 245;
      this.screenType = ScreenType.DESKTOP;
    }
  }

  ngAfterViewInit() {
    this.createSvg(window.innerWidth);
    this.drawBubbleChart();
    this.createLegendSvg(window.innerWidth);
    this.drawLegend();

    const tooltips: Tooltip[] = this.data['tooltips'];

    this.data.forEach((category, i) => {
      let id: string = category.category;
      let element: HTMLElement = document.getElementById(id + this.sectionName);
      let textElement: HTMLElement = document.getElementById(id + 'Text' + this.sectionName);
      let legendElement: HTMLElement = document.getElementById(id + 'legend' + this.sectionName);
      let legendTextElement: HTMLElement = document.getElementById('bubbleLegendText-' + id + this.sectionName);

      if (element) {
        this.renderer.listen(element, 'click', (e: Event) => {
          this.openBottomSheet(tooltips[i]);
        });
      }

      if (textElement) {
        this.renderer.listen(textElement, 'click', (e: Event) => {
          this.openBottomSheet(tooltips[i]);
        });
      }

      if (legendElement) {
        this.renderer.listen(legendElement, 'click', (e: Event) => {
          this.openBottomSheet(tooltips[i]);
        });
      }

      if (legendTextElement) {
        this.renderer.listen(legendTextElement, 'click', (e: Event) => {
          this.openBottomSheet(tooltips[i]);
        });
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  async onResize(event) {
    this.renderer.destroy()

    let windowWidth: number = event.target.innerWidth;

    if (windowWidth <= 1150 && windowWidth > 1024) {
      this.svgWidth = 557;
      this.svgHeight = 498;
      this.legendSvgWidth = 400;
      this.legendSvgHeight = 333;
      this.screenType = ScreenType.TABLET;
    } else if (windowWidth <= 1024 && windowWidth > 768) {
      this.svgWidth = 400;
      this.svgHeight = 333;
      this.legendSvgWidth = 380;
      this.legendSvgHeight = 333;
      this.screenType = ScreenType.TABLET;
    } else if (windowWidth <= 768) {
      this.svgWidth = 400;
      this.svgHeight = 333;
      this.legendSvgWidth = 360;
      this.legendSvgHeight = 285;
      this.screenType = ScreenType.MOBILE;
    } else {
      this.svgWidth = 557;
      this.svgHeight = 498;
      this.legendSvgWidth = 557;
      this.legendSvgHeight = 245;
      this.screenType = ScreenType.DESKTOP;
    }

    d3.select(`figure#${this.sectionName}`).select("svg").remove();
    d3.select(`#${this.legendName}`).select("svg").remove();
    d3.select(`figure#${this.sectionName}`).selectAll('.bubble-chart-tooltip').remove();
    d3.select(`#${this.legendName}`).selectAll('.bubble-chart-tooltip').remove();

    this.createSvg(windowWidth);
    this.drawBubbleChart();
    this.createLegendSvg(windowWidth);
    this.drawLegend();

    const tooltips: Tooltip[] = this.data['tooltips'];

    this.data.forEach((category, i) => {
      let id: string = category.category;
      let element: HTMLElement = document.getElementById(id);
      let textElement: HTMLElement = document.getElementById(id + 'Text');
      let legendElement: HTMLElement = document.getElementById(id + 'legend');

      if (element) {
        this.renderer.listen(element, 'click', (e: Event) => {
          this.openBottomSheet(tooltips[i]);
        });
      }

      if (textElement) {
        this.renderer.listen(textElement, 'click', (e: Event) => {
          this.openBottomSheet(tooltips[i]);
        });
      }

      if (legendElement) {
        this.renderer.listen(legendElement, 'click', (e: Event) => {
          this.openBottomSheet(tooltips[i]);
        });
      }
    });
  }

  /**
   * This function sets up the svg
   */
  private createSvg(width: number): void {
    this.svg = d3.select(`figure#${this.sectionName}`).append("svg")
      .attr("width", this.svgWidth + 'px')
      .attr("height", this.svgHeight + 'px')
      .attr("class", "bubble")
      .append("g").attr("transform", "translate(" + this.margin.left + "," + this.margin.top + ")");
  }

  private createLegendSvg(width: number): void {
    this.legendSvg = d3.select(`#${this.legendName}`)
      .append("svg").attr("style", "outline: 1px solid #B0B8D1;")
      .attr("width", this.legendSvgWidth)
      .attr("height", this.legendSvgHeight)
      .append("g");
  }

  /**
   * This function renders the bubble chart
   */
  private drawBubbleChart(): void {
    // This sets up the color scheme
    let color = d3.scaleOrdinal().domain(this.getColorDomain()).range(this.colorRange);

    // This sets up the bubble chart packing and the nodes
    let bubble = d3.pack();

    bubble.size([this.svgWidth, this.svgHeight]).padding(1.5);

    let dataTotal = 0;

    this.data.forEach(category => {
      dataTotal += category.value;
    });

    let mockData = this.data.map((d: any) => {
      let percent = d.value / dataTotal;
      let dataValue = percent >= .01 || percent == 0 ? d.value : dataTotal * .01;
      return {
        category: d.category,
        value: dataValue
      }
    });
    
    let root = d3.hierarchy({ children: mockData }).sum(function (d: any) { return d.value; }).sort(function (a, b) { return -(a.value - b.value); });

    bubble(root);

    let bubbles = this.svg.selectAll(".bubble").data(root.children).enter();

    let tooltip = d3.select(`figure#${this.sectionName}`).append("div")
      .attr("class", "bubble-chart-tooltip")
      .style("visibility", "hidden")
      .style("position", "absolute");

    let tooltips: Tooltip[] = this.data['tooltips'];
    let svgSectionName = this.sectionName;
    let screen: ScreenType = this.screenType;

    //Remove all bubbles with radius 0
    bubbles._groups[0] = bubbles._groups[0].filter(enterNode => enterNode.__data__.value / this.chartTotal > 0);

    // Renders the bubbles
    bubbles.append("circle")
      .attr("class", "circle")
      .attr("id", function (d) { return d.data.category + svgSectionName; })
      .attr("r", function (d) { return d.r; })
      .attr("cx", function (d) { return d.x; })
      .attr("cy", function (d) { return d.y; })
      .style("fill", function (d) { return color(d.data.category); })
      .on("mouseover", function (event, d) {
        if (screen === ScreenType.DESKTOP) {
          let currentTooltip: Tooltip = tooltips.find((tooltip: Tooltip) => tooltip.label === d.data.category);
          tooltip.style("visibility", "visible");
          tooltip.html(currentTooltip.html);
        }
      })
      .on("mousemove", function (event, d) {
        if (screen === ScreenType.DESKTOP) {
          let svgElement: HTMLElement = document.getElementById(svgSectionName);
          let svgElementOffsetTop: number = svgElement.offsetTop;
          let svgParent: HTMLElement = svgElement.parentElement;
          let svgParentOffsetLeft: number = svgParent.offsetLeft;
          let tooltipOffsetLeft: number = d.x + d.r;
          let tooltipPageOffsetLeft: number = tooltipOffsetLeft + svgParentOffsetLeft;
          let tooltipX: number = tooltipPageOffsetLeft + 240 > window.innerWidth ? tooltipOffsetLeft - (2 * d.r) - 240 : tooltipOffsetLeft;

          tooltip.style("top", (d.y - 200 + svgElementOffsetTop) + "px").style("left", tooltipX + "px");
        }
      })
      .on("mouseout", function (event, d) {
        tooltip.style("visibility", "hidden");
      });

    let localData = this.data;
    // Renders the text on the bubbles
    bubbles.append("text")
      .attr("id", function (d) { return d.data.category + 'Text' + svgSectionName; })
      .attr("x", function (d) { return d.x; })
      .attr("y", function (d) { return d.y + 5; })
      .attr("text-anchor", "middle")
      .text(function (d) {
        let realValue = localData.find((data: any) => data.category === d.data.category).value;
        let percent: number = (realValue / dataTotal) * 100;
        let percentAsString: string;
        percentAsString = percent < 1 && percent > 0 ? '<1.0%' : percent.toFixed(1) + '%';

        return percentAsString;
      })
      .style("fill", "#133559")
      .style("font-family", "Raleway")
      .style("font-size", "18px")
      .style("line-height", "24px")
      .style("font-weight", "600")
      .on("mouseover", function (event, d) {
        if (screen === ScreenType.DESKTOP) {
          let currentTooltip: Tooltip = tooltips.find((tooltip: Tooltip) => tooltip.label === d.data.category);
          tooltip.style("visibility", "visible");
          tooltip.html(currentTooltip.html);
        }
      })
      .on("mousemove", function (event, d) {
        let svgElement: HTMLElement = document.getElementById(svgSectionName);
        let svgElementOffsetTop: number = svgElement.offsetTop;
        let svgParent: HTMLElement = svgElement.parentElement;
        let svgParentOffsetLeft: number = svgParent.offsetLeft;
        let tooltipOffsetLeft: number = d.x + d.r;
        let tooltipPageOffsetLeft: number = tooltipOffsetLeft + svgParentOffsetLeft;
        let tooltipX: number = tooltipPageOffsetLeft + 240 > window.innerWidth ? tooltipOffsetLeft - (2 * d.r) - 240 : tooltipOffsetLeft;

        tooltip.style("top", (d.y - 200 + svgElementOffsetTop) + "px").style("left", tooltipX + "px");
      })
      .on("mouseout", function (event, d) {
        tooltip.style("visibility", "hidden");
      });
  }

  private drawLegend(): void {
    let color = d3.scaleOrdinal().domain(this.getColorDomain()).range(this.colorRange);
    let legendSectionName = this.legendName;
    let tooltips: Tooltip[] = this.data['tooltips'];

    let tooltip = d3.select(`figure#${legendSectionName}`).append("div")
      .attr("class", "bubble-chart-tooltip")
      .style("visibility", "hidden")
      .style("position", "absolute");

    this.data.forEach((group, i) => {
      let name: string = group.category;
      let svgSectionName: string = this.sectionName;
      let screen: ScreenType = this.screenType;
      let verticalDelta: number;
      let bottomPadding: number;
      let horizontalDelta: number;
      let rightPadding: number;
      let percent: number = (group.value / this.chartTotal) * 100;
      let percentAsString: string;

      if (this.screenType === ScreenType.DESKTOP) {
        verticalDelta = 68;
        bottomPadding = Math.floor(i / 3) * verticalDelta;
        horizontalDelta = 175;
        rightPadding = (i % 3) * horizontalDelta;
      } else if (this.screenType === ScreenType.TABLET) {
        verticalDelta = 68;
        bottomPadding = Math.floor(i / 2) * verticalDelta;
        horizontalDelta = 175;
        rightPadding = (i % 2) * horizontalDelta;
      } else {
        verticalDelta = 68;
        bottomPadding = Math.floor(i / 2) * verticalDelta;
        horizontalDelta = 175;
        rightPadding = (i % 2) * horizontalDelta;
      }

      percentAsString = percent < 1 && percent > 0 ? '<1.0%' : percent.toFixed(1) + '%';

      this.legendSvg.append("circle")
        .attr("class", `bubbleLegend-${name}`)
        .attr("id", function () { return name + 'legend' + svgSectionName })
        .attr("cx", 26 + rightPadding)
        .attr("cy", 26 + bottomPadding)
        .attr("r", 7)
        .style("fill", color(name))
        .on("mouseover", function (event, d) {
          if (screen === ScreenType.DESKTOP) {
            let currentTooltip: Tooltip = tooltips.find((tooltip: Tooltip) => { return tooltip.label === name });
            tooltip.style("visibility", "visible");
            tooltip.html(currentTooltip.html);
          }
        })
        .on("mousemove", function (event, d) {
          let svgElement = document.getElementById(legendSectionName);
          let svgElementOffsetTop = svgElement.offsetTop;
          let tooltipOffsetLeft: number = rightPadding + 30 + svgElement.offsetLeft;
          let tooltipX: number = tooltipOffsetLeft + 240 > window.innerWidth ? tooltipOffsetLeft - 250 : tooltipOffsetLeft;

          tooltip.style("top", (30 + bottomPadding + svgElementOffsetTop) + "px").style("left", tooltipX + "px");
        })
        .on("mouseout", function (event, d) {
          tooltip.style("visibility", "hidden");
        });

      this.legendSvg.append("text")
        .attr("class", "bubbleLegendText")
        .attr("id", `bubbleLegendText-${name + svgSectionName}`)
        .attr("x", 47 + rightPadding)
        .attr("y", 26 + bottomPadding)
        .text(name)
        .style("font-size", "14px")
        .style("font-size", "14px:")
        .style("line-height", "17px")
        .style("font-family", "Raleway")
        .style("font-weight", "500")
        .attr("alignment-baseline", "middle")
        .attr("fill", "#133559")
        .on("mouseover", function (event, d) {
          if (screen === ScreenType.DESKTOP) {
            let currentTooltip: Tooltip = tooltips.find((tooltip: Tooltip) => { return tooltip.label === name });
            tooltip.style("visibility", "visible");
            tooltip.html(currentTooltip.html);
          }
        })
        .on("mousemove", function (event, d) {
          let svgElement = document.getElementById(legendSectionName);
          let svgElementOffsetTop = svgElement.offsetTop;
          let tooltipOffsetLeft: number = rightPadding + 30 + svgElement.offsetLeft;
          let tooltipX: number = tooltipOffsetLeft + 240 > window.innerWidth ? tooltipOffsetLeft - 250 : tooltipOffsetLeft;

          tooltip.style("top", (30 + bottomPadding + svgElementOffsetTop) + "px").style("left", tooltipX + "px");
        })
        .on("mouseout", function (event, d) {
          tooltip.style("visibility", "hidden");
        });

      this.legendSvg.append("text")
        .attr("id", `bubbleLegendText-${name}`)
        .attr("x", 47 + rightPadding)
        .attr("y", 64 + bottomPadding)
        .text(percentAsString)
        .style("font-size", "14px")
        .style("font-size", "14px:")
        .style("line-height", "17px")
        .style("font-family", "Raleway")
        .style("font-weight", "500")
        .attr("alignment-baseline", "middle")
        .attr("fill", "#133559");

    });

    this.legendSvg.append("text")
      .attr("class", "bubble-data-legend")
      .attr("x", this.legendSvgWidth - 190)
      .attr("y", this.legendSvgHeight - 30)
      .text("— Not available.")
      .style("font-size", "12px")
      .attr("fill", "#133559");
    this.legendSvg.append("text")
      .attr("class", "bubbledata-legend")
      .attr("x", this.legendSvgWidth - 190)
      .attr("y", this.legendSvgHeight - 10)
      .text("ǂ Data quality standards not met.")
      .style("font-size", "12px").attr("fill", "#133559");

    this.legendSvg.selectAll(".bubbleLegendText").call(wrap, 156);

    function wrap(text, width) {
      text.each(function () {
        var text = d3.select(this),
          words = text.text().split(" ").reverse(),
          word,
          line = [],
          lineNumber = 0,
          lineHeight = 17,
          x = text.attr("x"),
          y = text.attr("y"),
          tspan = text.text(null).append("tspan").attr("x", x).attr("y", y);
        while (word = words.pop()) {
          line.push(word);
          tspan.text(line.join(" "));
          if (tspan.node().getComputedTextLength() > width) {
            line.pop();
            tspan.text(line.join(" "));
            line = [word];
            tspan = text.append("tspan").attr("x", x).attr("y", y).attr("dy", ++lineNumber * lineHeight + "px").text(word);
          }
        }
      });
    }
  }

  /**
   * This function gets the domain on which the colors will apply
   * @returns an array of strings
   */
  private getColorDomain(): string[] {
    let domain: string[] = [];

    this.data.forEach(group => {
      domain.push(group.category);
    });

    return domain;
  }

  openBottomSheet(tooltip: Tooltip): void {
    if (this.screenType !== ScreenType.DESKTOP) {
      this.bottomSheet.open(ChartDataCardComponent, {
        data: tooltip
      });
    }
  }
}
