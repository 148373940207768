<div class="view-data-container is-flex is-flex-direction-column">
    <div class="map-container">
        <div class="map-size-container is-flex"
            [ngClass]="{ 'is-flex-direction-column-reverse' : screenType !== ScreenType.DESKTOP }">
            <div class="card" [ngClass]="{'is-flex is-justify-content-center' : screenType !== ScreenType.DESKTOP}">
                <div class="card-content is-flex is-flex-direction-column">
                    <div class="is-flex is-flex-direction-row is-justify-content-space-between">
                        <app-survey-year-dropdown [surveyYear]="surveyYearKey"
                            (surveyYearUpdate)="updateSurveyYear($event)" class="survey-year-dropdown">
                        </app-survey-year-dropdown>
                    </div>
                    <hr class="collection-year-divider">
                    <p class="body has-text-white">Data Spotlight</p>
                    <h2 class="has-text-white">View State Profiles</h2>
                    <hr class="data-divider">
                    <div class="is-flex is-flex-direction-row">
                        <p class="district-fact bold-body has-text-white">Number of Districts:</p>
                        <p *ngIf="dataLoaded" class="body has-text-white">{{ numberOfDistricts }}</p>
                    </div>
                    <div class="is-flex is-flex-direction-row">
                        <p class="school-fact bold-body has-text-white">Number of Schools:</p>
                        <p *ngIf="dataLoaded" class="body has-text-white">{{ numberOfSchools }}</p>
                    </div>
                    <p class="district-section large-body has-text-white">Largest States by Enrollment</p>
                    <div class="content">
                        <div class="state-list-table-container">
                            <table *ngIf="dataLoaded" class="state-list-table">
                                <tr class="state-list-header">
                                    <th class="bold-body has-text-white">State</th>
                                    <th class="bold-body has-text-white">Enrollment</th>
                                </tr>

                                <tr *ngFor="let state of stateSpotlight; let i = index">
                                    <td class="is-flex is-flex-direction-row table-state">
                                        <p class="district-number bold-body has-text-white">{{ i + 1 }}.</p>
                                        <a class="body is-underlined has-text-white"
                                            [href]="getStateRoute(state.state)">{{ state.state }}</a>
                                    </td>
                                    <td class="body has-text-white">{{ state.enrollment.toLocaleString('en-US') }}</td>
                                </tr>
                            </table>
                        </div>

                    </div>
                    <div class="is-flex is-flex-direction-row">
                        <button class="button is-rounded primary-button is-regular-button"
                            [routerLink]="['/profile/us']" [queryParams]="{surveyYear}">View National Data</button>
                        <!-- <button *ngIf="selectedArea != 'National'"
                            class="button is-rounded is-outlined outline-button is-regular-button">Add to
                            Compare</button> -->
                    </div>
                </div>
            </div>
            <div class="select-state-container is-flex is-justify-content-center">
                <div *ngIf="states" class="select-header is-flex is-flex-direction-column is-align-self-center">
                    <p class="subheader-2 has-text-white has-text-centered">Select a State or Province/Territory to
                        Browse Data</p>
                    <mat-form-field appearance="outline" class="state-select is-align-self-center" floatLabel="never"
                        [ngStyle]="{'width' : screenType !== ScreenType.MOBILE ? '18.625rem' : '12rem'}">
                        <mat-label>
                            <h4 class="has-text-white is-underlined">Select State</h4>
                        </mat-label>
                        <mat-select [(ngModel)]="selectedState" (selectionChange)="onStateChange()" name="states">
                            <mat-option *ngFor="let state of states" [value]="state.stateName">
                                {{state.stateName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <img id="background" alt="Map of United States and Puerto Rico" class="is-align-self-center"
                    src="/assets/us-states.png">
            </div>
        </div>
    </div>
</div>
