import { LiveAnnouncer } from '@angular/cdk/a11y';
import {Injectable} from '@angular/core'; 
import { Meta, Title } from '@angular/platform-browser';

// This code is used from stackoverflow https://stackoverflow.com/questions/48330535/dynamically-add-meta-description-based-on-route-in-angular
@Injectable({
    providedIn: 'root'
})
export class SEOService {
  constructor(private title: Title, private meta: Meta, private liveAnnounce: LiveAnnouncer) { }

  updateTitle(title: string) {
    this.title.setTitle(title);
    this.liveAnnounce.announce(title, 'assertive');
  }

  updateOgUrl(url: string) {
    this.meta.updateTag({ property: 'og:url', content: url });
  }

  updateOgTitle(title: string) {
    this.meta.updateTag({ property: 'og:title', content: title });
  }

  updateOgImage(img: string) {
    this.meta.updateTag({ property: 'og:img', content: img });
  }

  updateOgDescription(desc: string) {
    this.meta.updateTag({ name: 'description', content: desc });
    this.meta.updateTag({ property: 'og:description', content: desc });
  }
}