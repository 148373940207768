<app-size-detector></app-size-detector>
<a class="skip-to-content-link" [href]="skipLinkPath">Skip to content</a>
<app-navbar [isMobile]="isMobile" [isLoggedIn]="isLoggedIn" [userFullName]="userFullName" [userEmail]="userEmail"></app-navbar> <!-- (logoutEmitter)="loggingOut($event)" -->
<mat-sidenav-container  hasBackdrop="false" class="site--container">
    <!-- [mode]="modeType" -->
    <mat-sidenav position="end" #sidenav [opened]="sideNavOpened" class="side-nav--container">
        <side-nav (keydown.escape)="close('escape')" (closePressed)="close($event)"></side-nav>
    </mat-sidenav>
    <mat-sidenav-content id="content" class="site-content--container">
        <section class="site-content">
            <router-outlet (activate)="scrollToTop()"></router-outlet>
            <app-footer [isLoggedIn]="isLoggedIn"></app-footer>
        </section>
    </mat-sidenav-content>
</mat-sidenav-container>

<app-toasts id="toasts" aria-live="polite" aria-atomic="true"></app-toasts>
