import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CarouselItemDirective } from './carousel-item.directive';
import { CarouselComponent, CarouselItemElement } from './carousel.component';
import { MaterialModule } from '../../modules/material.module';

@NgModule({
    declarations: [
        CarouselComponent,
        CarouselItemDirective,
        CarouselItemElement
    ],
    exports: [
        CarouselComponent,
        CarouselItemDirective,
        CarouselItemElement
    ],
    imports: [
        CommonModule,
        MaterialModule
    ]
})
export class CarouselComponentModule { }
