<div class=" has-text-centered page-title-container">
  <h1 *ngIf="this.title" class="title is-marginless is-3 is-size-5-mobile">
    <span *ngIf="this.title" class="has-text-primary">
      {{this.title}}
    </span>
  </h1>
  <h2 *ngIf="this.subtitle" class="subtitle is-marginless is-5 is-size-7-mobile">
    <span *ngIf="this.subtitle" class="has-text-info">
      {{this.title}}
    </span>
  </h2>
</div>
