<!-- <div class="section has-text-centered">
  <h1 class="title has-text-danger">
    Sorry, that page doesn't exist
  </h1>
  <div>
    <a [routerLink]="'/'">
      Return to home
    </a>
  </div>
</div> -->

<div class="not-found-container is-flex is-flex-direction-column is-align-items-center" style="gap:30px">
    <p class="large-body has-text-primary">Error: 404</p>
    <h1 class="title has-text-primary has-text-centered">Oops! Looks like that page doesn't exist.</h1>
    <p *ngIf="previousURLLoaded" class="sub-title large-body has-text-primary has-text-centered">We couldn't find a page
        at {{ previousURL }}. Go back, or head over to the CRDC home page to choose a new direction.</p>
    <p *ngIf="!previousURLLoaded" class="sub-title large-body has-text-primary has-text-centered">We couldn't find the
        page you were looking for. Go back, or head over to the <a>CRDC home page</a> to choose a new direction.</p>
    <button class="button is-rounded primary-button is-large-button" routerLink=""
        style="text-decoration: none;">Return to Home</button>
</div>
