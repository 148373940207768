import { SEARCH_STEP } from '@shared/query-models/step-search.model';
import { NgxLoggerLevel } from 'ngx-logger';

export const environment = {
    type: 'stage',
    production: true,
    api_url: './api/v1.0/',
    // NgxLoggerLevels: TRACE|DEBUG|INFO|LOG|WARN|ERROR|FATAL|OFF
    logLevel: NgxLoggerLevel.OFF,
    maxEntities: 20,
    dataToolMaxSteps: SEARCH_STEP.STEP_FOUR,
    protectDataCompareTool: true, // this is used to force users to either login to get to compare tool or not
    useAuthorizationCodeFlow: false,  // if we need to use Implicit Flow set this to false and set to true for auth code flow
    authorizationIssuer: 'icam',    // can be: icam or google. can only use icam in prod
    sessionEndWarningTime: 5,       // the number of minutes before the session will timeout and a warning message will be displayed
    icamClientId: 'b617b66e-a1e9-4b30-b5a6-29b3ded62b95',
    icamIssuer: 'https://login.microsoftonline.com/bd5d4514-84de-4928-a9fd-6ae10bbad677/v2.0',
    icamLogoutUrl: 'https://login.microsoftonline.com/bd5d4514-84de-4928-a9fd-6ae10bbad677/oauth2/v2.0/logout',
    icamLoginDotGovLogoutUrl: ''    // not used in staging
};
