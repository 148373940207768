<div class="accessibility-statement-container content">
    <h1 class="has-text-primary">Accessibility Statement</h1>
    <div class="accessibility-content">
        <h3 class="has-text-primary">Section 508 of the Rehabilitation Act of 1973, as amended (29 U.S.C. § 794d)</h3>
        <p class="body has-text-black">
            The U.S. Department of Education is committed to making its electronic and information technologies
            accessible to individuals with disabilities by meeting or exceeding the requirements of Section 508 of the
            Rehabilitation Act (29 U.S.C. 794d), as amended in 1998. Section 508 is a federal law that requires agencies
            to provide individuals with disabilities equal access to electronic information and data comparable to those
            who do not have disabilities, unless an undue burden would be imposed on the agency. The Section 508
            Standards are the technical requirements and criteria that are used to measure conformance within this law.
            More information on Section 508 and the technical standards can be found at
            <a href="http://www.section508.gov/summary-section508-standards" target="_blank"
                rel="noreferrer noopener">http://www.section508.gov/summary-section508-standards</a>.<br><br>If you wish
            to report an issue related to the accessibility of any content on a Department of Education website,
            including a complaint about the accessibility of a student loan and servicing website, document, form, or
            statement -- regardless of whether the websites or documents are maintained or distributed by the Department
            of Education or one of its student loan servicers, you may do so in any of the following ways:
        </p>
        <ol>
            <li class="body has-text-black">Complete the <a href="http://blog.ed.gov/accessibility-feedback/"
                    target="_blank" rel="noreferrer noopener">online form</a>. Please include the web address or URL
                along with a
                detailed description of the problems you have encountered.
            </li>
            <li class="body has-text-black">
                Email <a href="mailto:Section508&#64;ed.gov">Section508&#64;ed.gov</a>. Please include the web address or URL
                along with a detailed description of the problems you have encountered.
            </li>
            <li class="body has-text-black">
                Submit written correspondence to: Section 508 Coordinator, PCP 10010, 550 12th Street, SW, Washington
                DC, 20202-7100. In your correspondence, please include the web address or URL along with a detailed
                description of the problems you have encountered.
            </li>
        </ol>
        <h3 class="has-text-primary">
            Architectural Barriers Act of 1968 (42 U.S.C. §§ 4151--57)
        </h3>
        <p class="body has-text-black">
            The Architectural Barriers Act (ABA) requires access to facilities that are designed, built, altered, or
            leased with Federal funds. The Access Board is the federal agency responsible for enforcing the ABA. The
            Access Board's accessibility standards are available on their website at <a
                href="www.access-board.gov/guidelines-and-standards/buildings-and-sites/about-the-aba-standards"
                target="_blank"
                rel="noreferrer noopener">www.access-board.gov/guidelines-and-standards/buildings-and-sites/about-the-aba-standards</a>,
            and information about filing a complaint may be found at <a
                href="www.access-board.gov/aba-enforcement/file-a-complaint" target="_blank"
                rel="noreferrer noopener">www.access-board.gov/aba-enforcement/file-a-complaint</a>. The Department's
            contact for Architectural Barrier Act inquiries is <a
                href="mailto:Mark.Hochberg&#64;ed.gov">Mark.Hochberg&#64;ed.gov</a>.
        </p>
    </div>
</div>
