import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { AuthorizationService } from "@core/auth/services/authorization.service";
import { NGXLogger } from "ngx-logger";
import { Observable } from "rxjs";
import { environment } from "@environments/environment";
import {ToastService} from '@shared/components/toast/toast.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

    constructor(
        private logger: NGXLogger,
        private toastService: ToastService,
        private router: Router,
        private authorizationService: AuthorizationService
    ) {

    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        this.logger.debug('AuthGuard: canActivate');    //, ActivatedRouteSnapshot: ', route, ', RouterStateSnapshot: ', state);

        return new Observable<boolean>((observer) => {
            this.logger.debug('AuthGuard: getIsAuthenticated');

            this.authorizationService.performAuthentication(route).subscribe(
                (isAuthenticated) => {

                    this.logger.debug('Auth Guard: performAuthentication, isAuthenticated: ', isAuthenticated);

                    if (!isAuthenticated) {
                        this.logger.error('Authentication failed!');
                        this.toastService.warning('Access Denied! Please contact your system administrator.');
                        //this.router.navigate(['/data-tool-login-landing']);
                        this.router.navigate(['/']);
                    }

                    //TODO - remove environment.production when going to production
                    observer.next(isAuthenticated && !environment.production);
                    observer.complete();
                },
            );

        });
    }
}
