import { Component } from '@angular/core';

@Component({
    selector: 'estimations-2013-2014',
    templateUrl: './estimations-2013-2014.component.html',
    styleUrls: [ './estimations-2013-2014.component.scss' ]
})

export class Estimations20132014Component {


}
