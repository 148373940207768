import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Routing
import { AppRoutingModule } from './app-routing.module';

// Container Component
import { AppComponent } from './app.component';

// Sub Feature Components
import { HomeComponent } from './features/home/home.component';

// Shared Modules
import { SharedModule } from './shared/modules/shared.module';
import { MaterialModule } from './shared/modules/material.module';

// Shared Components
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { SideNavComponent } from './shared/components/side-nav/side-nav.component';

// Core Services
import { DataService } from './shared/services/core/data.service';
import { MessageService } from './shared/services/core/message.service';

// Data Services
import { DistrictProfileService } from './shared/services/data/district-profile.service';
import { SchoolProfileService } from './shared/services/data/school-profile.service';
import { StateNationalEstimationService } from './shared/services/data/state-national-estimation.service';
import { EnrollmentDataService } from './shared/services/data/enrollment-data.service';
import { MeasureService } from './shared/services/data/measure.service';
import { EntitySearchService } from './shared/services/data/entity-search.service';
import { DataSpotlightService } from './shared/services/data/data-spotlight.service';
import { SurveyYearService } from './shared/services/data/survey-year.service';
import { StatesService } from './shared/services/data/states.service';
import { DataToolService } from './shared/services/data/datatool.service';

// Interceptors
import { HttpErrorInterceptor } from './shared/services/core/error.interceptor';
import { AboutModule } from './features/about/about.module';
import { AccessibilityStatementComponent } from './features/accessibility-statement/accessibility-statement.component';
import { SearchComponent } from './features/search/search.component';
import { RecentDataFilesComponent } from './features/recent-data-files/recent-data-files.component';
import { PublicationsComponent } from './features/publications/publications.component';
import { EstimationsModule } from './features/estimations/estimations.module';
import { DataQualityComponent } from './features/data-quality/data-quality.component';
import { ProfileReportComponent } from './features/profile-report/profile-report.component';
import { A11yModule } from '@angular/cdk/a11y';
import { EnforcementDataToolComponent } from './features/enforcement-data-tool/enforcement-data-tool.component';
import { CoreModule } from './core/core.module';
import { DataToolLoginLandingComponent } from './shared/components/data-tool-login-landing/data-tool-login-landing.component';
import { AuthorizationService } from '@core/auth/services/authorization.service';
import { OAuthModule } from 'angular-oauth2-oidc';


@NgModule({
    declarations: [
        AppComponent,
        SideNavComponent,
        HomeComponent,
        NotFoundComponent,
        AccessibilityStatementComponent,
        RecentDataFilesComponent,
        PublicationsComponent,
        SearchComponent,
        DataQualityComponent,
        ProfileReportComponent,
        EnforcementDataToolComponent,
        DataToolLoginLandingComponent,
    ],
    imports: [
        CoreModule,
        SharedModule,
        MaterialModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        AboutModule,
        EstimationsModule,
        A11yModule,
        HttpClientModule,
        OAuthModule.forRoot(),
    ],
    providers: [
        MessageService,
        DataService,
        SchoolProfileService,
        DistrictProfileService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true
        },
        StateNationalEstimationService,
        EnrollmentDataService,
        MeasureService,
        EntitySearchService,
        DataSpotlightService,
        SurveyYearService,
        StatesService,
        DataToolService,
        AuthorizationService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
