<div class="container content">
  <page-title title="2004 National and State Estimations"></page-title>
  <p>
    These files are state and national estimations for the Civil Rights Data Collection. The 2004 estimations are based on a rolling stratified sample of approximately 6,000 districts and 60,000 schools, and on reported data from those districts that responded to the survey. Documentation is available from OCR which describes the procedures used for the estimations, including weighting of the sample, imputation for item non-response, standard errors, and quality control procedures. In addition, documentation is available from OCR for estimations that should be used with caution due to large statistical uncertainty in the estimate, including factors which contributed to the extent of this statistical uncertainty for the Civil Rights Data Collection. This hardcopy documentation, available upon request, is contained in "Civil Rights Data Collection (CRDC) Estimations and Documentation."
  </p>

  <div class="accordion-panel-body">
    <div class="has-text-centered">
      <a href="assets/downloads/2004/2004-nation-projection.xls" rel="noopener noreferrer" target="_blank">National total</a>
    </div>
    <br />
    <div class="columns is-gapless is-multiline">
      <div class="column is-one-quarter has-text-centered">
        <a href="assets/downloads/2004/2004-alabama-projection.xls" rel="noopener noreferrer" target="_blank">Alabama</a>
      </div>
      <div class="column is-one-quarter has-text-centered">
        <a href="assets/downloads/2004/2004-alaska-projection.xls" rel="noopener noreferrer" target="_blank">Alaska</a>
      </div>
      <div class="column is-one-quarter has-text-centered">
        <a href="assets/downloads/2004/2004-arizona-projection.xls" rel="noopener noreferrer" target="_blank">Arizona</a>
      </div>
      <div class="column is-one-quarter has-text-centered">
        <a href="assets/downloads/2004/2004-arkansas-projection.xls" rel="noopener noreferrer" target="_blank">Arkansas</a>
      </div>
    </div>
    <div class="columns is-gapless is-multiline">
      <div class="column is-one-quarter has-text-centered">
        <a href="assets/downloads/2004/2004-california-projection.xls" rel="noopener noreferrer" target="_blank">California</a>
      </div>
      <div class="column is-one-quarter has-text-centered">
        <a href="assets/downloads/2004/2004-colorado-projection.xls" rel="noopener noreferrer" target="_blank">Colorado</a>
      </div>
      <div class="column is-one-quarter has-text-centered">
        <a href="assets/downloads/2004/2004-connecticut-projection.xls" rel="noopener noreferrer" target="_blank">Connecticut</a>
      </div>
      <div class="column is-one-quarter has-text-centered">
        <a href="assets/downloads/2004/2004-delaware-projection.xls" rel="noopener noreferrer" target="_blank">Delaware</a>
      </div>
    </div>
    <div class="columns is-gapless is-multiline">
      <div class="column is-one-quarter has-text-centered">
        <a href="assets/downloads/2004/2004-districtofcolumbia-projection.xls" rel="noopener noreferrer" target="_blank">District of Columbia</a>
      </div>
      <div class="column is-one-quarter has-text-centered">
        <a href="assets/downloads/2004/2004-florida-projection.xls" rel="noopener noreferrer" target="_blank">Florida</a>
      </div>
      <div class="column is-one-quarter has-text-centered">
        <a href="assets/downloads/2004/2004-georgia-projection.xls" rel="noopener noreferrer" target="_blank">Georgia</a>
      </div>
      <div class="column is-one-quarter has-text-centered">
        <a href="assets/downloads/2004/2004-hawaii-projection.xls" rel="noopener noreferrer" target="_blank">Hawaii</a>
      </div>
    </div>
    <div class="columns is-gapless is-multiline">
      <div class="column is-one-quarter has-text-centered">
        <a href="assets/downloads/2004/2004-idaho-projection.xls" rel="noopener noreferrer" target="_blank">Idaho</a>
      </div>
      <div class="column is-one-quarter has-text-centered">
        <a href="assets/downloads/2004/2004-illinois-projection.xls" rel="noopener noreferrer" target="_blank">Illinois</a>
      </div>
      <div class="column is-one-quarter has-text-centered">
        <a href="assets/downloads/2004/2004-indiana-projection.xls" rel="noopener noreferrer" target="_blank">Indiana</a>
      </div>
      <div class="column is-one-quarter has-text-centered">
        <a href="assets/downloads/2004/2004-iowa-projection.xls" rel="noopener noreferrer" target="_blank">Iowa</a>
      </div>
    </div>
    <div class="columns is-gapless is-multiline">
      <div class="column is-one-quarter has-text-centered">
        <a href="assets/downloads/2004/2004-kansas-projection.xls" rel="noopener noreferrer" target="_blank">Kansas</a>
      </div>
      <div class="column is-one-quarter has-text-centered">
        <a href="assets/downloads/2004/2004-kentucky-projection.xls" rel="noopener noreferrer" target="_blank">Kentucky</a>
      </div>
      <div class="column is-one-quarter has-text-centered">
        <a href="assets/downloads/2004/2004-louisiana-projection.xls" rel="noopener noreferrer" target="_blank">Louisiana</a>
      </div>
      <div class="column is-one-quarter has-text-centered">
        <a href="assets/downloads/2004/2004-maine-projection.xls" rel="noopener noreferrer" target="_blank">Maine</a>
      </div>
    </div>
    <div class="columns is-gapless is-multiline">
      <div class="column is-one-quarter has-text-centered">
        <a href="assets/downloads/2004/2004-maryland-projection.xls" rel="noopener noreferrer" target="_blank">Maryland</a>
      </div>
      <div class="column is-one-quarter has-text-centered">
        <a href="assets/downloads/2004/2004-massachusetts-projection.xls" rel="noopener noreferrer" target="_blank">Massachusetts</a>
      </div>
      <div class="column is-one-quarter has-text-centered">
        <a href="assets/downloads/2004/2004-michigan-projection.xls" rel="noopener noreferrer" target="_blank">Michigan</a>
      </div>
      <div class="column is-one-quarter has-text-centered">
        <a href="assets/downloads/2004/2004-minnesota-projection.xls" rel="noopener noreferrer" target="_blank">Minnesota</a>
      </div>
    </div>
    <div class="columns is-gapless is-multiline">
      <div class="column is-one-quarter has-text-centered">
        <a href="assets/downloads/2004/2004-mississippi-projection.xls" rel="noopener noreferrer" target="_blank">Mississippi</a>
      </div>
      <div class="column is-one-quarter has-text-centered">
        <a href="assets/downloads/2004/2004-missouri-projection.xls" rel="noopener noreferrer" target="_blank">Missouri</a>
      </div>
      <div class="column is-one-quarter has-text-centered">
        <a href="assets/downloads/2004/2004-montana-projection.xls" rel="noopener noreferrer" target="_blank">Montana</a>
      </div>
      <div class="column is-one-quarter has-text-centered">
        <a href="assets/downloads/2004/2004-nebraska-projection.xls" rel="noopener noreferrer" target="_blank">Nebraska</a>
      </div>
    </div>
    <div class="columns is-gapless is-multiline">
      <div class="column is-one-quarter has-text-centered">
        <a href="assets/downloads/2004/2004-nevada-projection.xls" rel="noopener noreferrer" target="_blank">Nevada</a>
      </div>
      <div class="column is-one-quarter has-text-centered">
        <a href="assets/downloads/2004/2004-newhampshire-projection.xls" rel="noopener noreferrer" target="_blank">New Hampshire</a>
      </div>
      <div class="column is-one-quarter has-text-centered">
        <a href="assets/downloads/2004/2004-newjersey-projection.xls" rel="noopener noreferrer" target="_blank">New Jersey</a>
      </div>
      <div class="column is-one-quarter has-text-centered">
        <a href="assets/downloads/2004/2004-newmexico-projection.xls" rel="noopener noreferrer" target="_blank">New Mexico</a>
      </div>
    </div>
    <div class="columns is-gapless is-multiline">
      <div class="column is-one-quarter has-text-centered">
        <a href="assets/downloads/2004/2004-newyork-projection.xls" rel="noopener noreferrer" target="_blank">New York</a>
      </div>
      <div class="column is-one-quarter has-text-centered">
        <a href="assets/downloads/2004/2004-northcarolina-projection.xls" rel="noopener noreferrer" target="_blank">North Carolina</a>
      </div>
      <div class="column is-one-quarter has-text-centered">
        <a href="assets/downloads/2004/2004-northdakota-projection.xls" rel="noopener noreferrer" target="_blank">North Dakota</a>
      </div>
      <div class="column is-one-quarter has-text-centered">
        <a href="assets/downloads/2004/2004-ohio-projection.xls" rel="noopener noreferrer" target="_blank">Ohio</a>
      </div>
    </div>
    <div class="columns is-gapless is-multiline">
      <div class="column is-one-quarter has-text-centered">
        <a href="assets/downloads/2004/2004-oklahoma-projection.xls" rel="noopener noreferrer" target="_blank">Oklahoma</a>
      </div>
      <div class="column is-one-quarter has-text-centered">
        <a href="assets/downloads/2004/2004-oregon-projection.xls" rel="noopener noreferrer" target="_blank">Oregon</a>
      </div>
      <div class="column is-one-quarter has-text-centered">
        <a href="assets/downloads/2004/2004-pennsylvania-projection.xls" rel="noopener noreferrer" target="_blank">Pennsylvania</a>
      </div>
      <div class="column is-one-quarter has-text-centered">
        <a href="assets/downloads/2004/2004-rhodeisland-projection.xls" rel="noopener noreferrer" target="_blank">Rhode Island</a>
      </div>
    </div>
    <div class="columns is-gapless is-multiline">
      <div class="column is-one-quarter has-text-centered">
        <a href="assets/downloads/2004/2004-southcarolina-projection.xls" rel="noopener noreferrer" target="_blank">South Carolina</a>
      </div>
      <div class="column is-one-quarter has-text-centered">
        <a href="assets/downloads/2004/2004-southdakota-projection.xls" rel="noopener noreferrer" target="_blank">South Dakota</a>
      </div>
      <div class="column is-one-quarter has-text-centered">
        <a href="assets/downloads/2004/2004-tennessee-projection.xls" rel="noopener noreferrer" target="_blank">Tennessee</a>
      </div>
      <div class="column is-one-quarter has-text-centered">
        <a href="assets/downloads/2004/2004-texas-projection.xls" rel="noopener noreferrer" target="_blank">Texas</a>
      </div>
    </div>
    <div class="columns is-gapless is-multiline">
      <div class="column is-one-quarter has-text-centered">
        <a href="assets/downloads/2004/2004-utah-projection.xls" rel="noopener noreferrer" target="_blank">Utah</a>
      </div>
      <div class="column is-one-quarter has-text-centered">
        <a href="assets/downloads/2004/2004-vermont-projection.xls" rel="noopener noreferrer" target="_blank">Vermont</a>
      </div>
      <div class="column is-one-quarter has-text-centered">
        <a href="assets/downloads/2004/2004-virginia-projection.xls" rel="noopener noreferrer" target="_blank">Virginia</a>
      </div>
      <div class="column is-one-quarter has-text-centered">
        <a href="assets/downloads/2004/2004-washington-projection.xls" rel="noopener noreferrer" target="_blank">Washington</a>
      </div>
    </div>
    <div class="columns is-gapless is-multiline">
      <div class="column is-one-quarter has-text-centered">
        <a href="assets/downloads/2004/2004-westvirginia-projection.xls" rel="noopener noreferrer" target="_blank">West Virginia</a>
      </div>
      <div class="column is-one-quarter has-text-centered">
        <a href="assets/downloads/2004/2004-wisconsin-projection.xls" rel="noopener noreferrer" target="_blank">Wisconsin</a>
      </div>
      <div class="column is-one-quarter has-text-centered">
        <a href="assets/downloads/2004/2004-wyoming-projection.xls" rel="noopener noreferrer" target="_blank">Wyoming</a>
      </div>
    </div>
  </div>
  <document-viewer docType="PDF"></document-viewer>
</div>
