<div *ngIf="dataLoaded" class="dropdown-container is-flex is-flex-direction-row">
    <mat-icon class="ed-slate" tabindex="0" aria-labelledby="tooltip-popup"
        [tooltip]="'Data are typically collected every two years. Most recent update was for School Year 2020-21.'">
        help_outline</mat-icon>

    <label id="collectionYear" class="body ed-slate">Collection Year:</label>

    <mat-form-field class="collection-year-dropdown" floatLabel="never" [style.width.rem]=6.5>
        <mat-select [(ngModel)]="surveyYear" (selectionChange)="onYearChange()" name="period"
            aria-labelledby="collectionYear">
            <mat-option style="color:#133559; overflow: none;" *ngFor="let year of surveyYears" [value]="year.yearKey">
                {{year.period}}
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>
