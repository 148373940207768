import { AfterViewInit, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-featured-reports',
  templateUrl: './home-featured-reports.component.html',
  styleUrls: ['./home-featured-reports.component.scss']
})
export class HomeFeaturedReportsComponent implements OnInit {

  items: any[];

  constructor() {

  }

  ngOnInit(): void {
    this.items = [
      {
        src: 'assets/images/school_climate.jpg',
        header: 'A First Look Report',
        file: 'https://www2.ed.gov/about/offices/list/ocr/docs/crdc-educational-opportunities-report.pdf',
        alt: 'exterior of a school building and campus'
      },
      {
        src: 'assets/images/exclusionary_discipline.png',
        header: 'Student Discipline and School Climate Report',
        file: 'https://www2.ed.gov/about/offices/list/ocr/docs/crdc-discipline-school-climate-report.pdf',
        alt: 'empty school classroom with tables, chairs, and a white board'
      },
      {
        src: 'assets/images/corporal_punishment.jpg',
        header: 'Sexual Violence and Sex-based Harassment or Bullying Snapshot',
        file: 'https://www2.ed.gov/about/offices/list/ocr/docs/crdc-sexual-violence-snapshot.pdf',
        alt: 'empty school hallway with rows of lockers'
      }
    ];
  }
}
