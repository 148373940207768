import { Component, Input, OnInit } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-stacked-bar-chart',
  templateUrl: './stacked-bar-chart.component.html',
  styleUrls: ['./stacked-bar-chart.component.scss']
})

/**
 * This class creates a rendering of a stacked bar chart with the given data
 */
export class StackedBarChartComponent implements OnInit {

  @Input() data;
  @Input() sectionName: string;

  private svg;
  private margin = { top: 0, right: 0, bottom: 89, left: 0 };
  private width = 576;
  private height = 513;

  constructor() {
  }

  /**
   * Entry function that allows the stacked bar chart to be rendered.
   */
  ngOnInit(): void {
    this.sectionName = this.sectionName.replace(/\s+/g, '-');
    this.createSvg();
    this.drawStackedBarChart();
  }

  /**
   * This function creates the svg in which the chart will be rendered.
   */
  private createSvg(): void {
    this.svg = d3.select(`figure#${this.sectionName}`).append("svg")
      .attr("width", this.width).attr("height", this.height)
      .append("g").attr("transform", "translate(" + this.margin.left + "," + this.margin.top + ")");
  }

  /**
   * This function fully renders the stacked bar chart
   */
  private drawStackedBarChart(): void {
    let chartWidth = this.width - this.margin.right - this.margin.left;
    let chartHeight = this.height - this.margin.top - this.margin.bottom;

    // Establishes the coloring scheme
    let colorRange = ["rgba(202, 170, 82, 0.5)", "rgba(0, 100, 168, 0.5)", "rgba(96, 179, 156, 0.5)", "rgba(19, 53, 89, 0.5)"];
    let color = d3.scaleOrdinal().domain(this.data.columns.slice(1)).range(colorRange);

    // Establishes the x and y scales and axes
    let x = d3.scaleLinear().domain([0, 1]).range([0, chartWidth]);
    let y = d3.scaleBand().domain(this.getGroups()).range([0, chartHeight]).paddingInner(.4);

    let xAxis = d3.axisBottom(x).ticks(0);
    let yAxis = d3.axisLeft(y).ticks(0);

    //Generates the stacked data that gets put on top of each other
    let stackGen = d3.stack()
      .keys(this.data.columns.slice(1));
    let stackedData = stackGen(this.data);

    // Adds the x and y axis to the svg
    this.svg.append("g").attr("class", "axis axis--x").attr("transform", "translate(0," + chartHeight + ")").call(customXAxis);
    this.svg.append("g").attr("class", "axis axis--y").call(customYAxis);

    this.drawBars(stackedData, x, y, color);
    this.drawLegend();

    /**
     * This function customizes the x axis
     * @param g the associated svg
     */
    function customXAxis(g) {
      g.call(xAxis);
      g.select(".domain").remove();
      g.selectAll(".tick line").remove();
      g.selectAll(".tick text").remove();
    }

    /**
     * This function customizes the y axis
     * @param g the associated svb
     */
    function customYAxis(g) {
      g.call(yAxis);
      g.select(".domain").remove();
      g.selectAll(".tick text").attr("dx", 50).attr("fill", "#133559").style("font-size", "12px");
    }
  }

  /**
   * This function retrieves the groups of the the given chart data
   * @returns a string array with the groups of the chart
   */
  private getGroups(): Iterable<string> {
    let domain = [];

    this.data.forEach(element => {
      let str = element.category;
      domain.push(str);
    });

    return domain;
  }

  /**
   * This function draws all of the stacked bars
   * @param stackedData the data to be rendered
   * @param x the x axis
   * @param y the y axis
   * @param color the associated colors for the rendering
   */
  private drawBars(stackedData, x, y, color): void {
    this.svg.append("g").selectAll("g").data(stackedData).enter()
      .append("g").attr("fill", function (d) { return color(d.key); })
      .selectAll("rect").data(function (d) { return d; }).enter()
      .append("rect")
      .attr("x", function (d) { return x(d[0]); })
      .attr("y", function (d) { return y(d.data.category); })
      .attr("width", function (d) { return x(d[1]) - x(d[0]); })
      .attr("height", y.bandwidth())
  }

  /**
   * This funciton draws the legend
   */
  private drawLegend(): void {
    let domain = this.data.columns.slice(1).reverse();
    let colorRange = ["rgba(19, 53, 89, 0.5)", "rgba(0, 100, 168, 0.5)", "rgba(96, 179, 156, 0.5)", "rgba(202, 170, 82, 0.5)"]

    let colorRangeLength = colorRange.length;
    let domainLength = domain.length;

    let color = d3.scaleOrdinal().domain(domain).range(colorRange.splice(colorRangeLength - domainLength, domainLength));

    let z = color;

    domain.forEach((column, i) => {
      let bottomPadding = 25;
      let verticalPadding = 36 * Math.floor(i / 2);
      let rightSidePadding = 254 + 148 * (i % 2);
      let textPadding = 35

      let x = this.width - rightSidePadding;
      let y = this.height - bottomPadding - verticalPadding;

      this.svg.append("rect").attr("width", 20).attr("height", 20).attr("x", x).attr("y", y).style("fill", z(column));
      this.svg.append("text").attr("x", x + textPadding).attr("y", y + 13).text(column).style("font-size", "12px").attr("fill", "#133559");
    });
  }
}
