<nav class="side-nav is-flex is-flex-direction-column">
    <div class="side-nav-header-container is-flex is-justify-content-space-between">
        <div class="is-flex is-align-items-center" style="gap:1.25rem">
            <img src="assets/images/compare.svg" class="filter-navy-blue" width="22.75px" height="21.12px"
                alt="Compare report" />
            <h3 class="subheader-1 has-text-primary">Data Comparison Tool</h3>
        </div>
        <button aria-label="close" mat-icon-button (click)="closeSideNav()">
            <mat-icon class="close-icon">close</mat-icon>
        </button>
    </div>
    <div class="side-nav-divider">
        <h6 class="subheader-2 has-text-primary">Target School or LEA</h6>
    </div>
    <div class="side-nav-content">
        <p *ngIf="targetItem" class="has-text-primary large-body">{{ targetItem.entity_Name }}</p>
    </div>
    <div class="side-nav-divider">
        <h6 class="subheader-2 has-text-primary">Items</h6>
    </div>
    <div class="side-nav-content">
        <ol class="list">
            <li *ngFor="let item of itemsArray">
                <div class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center">
                    <p class="has-text-primary large-body">{{ item.entity_Name }}</p>
                    <button [hidden]="!isEditable" [ariaLabel]="'remove ' + item.entity_Name"
                        class="delete-button has-text-red has-text-weight-bold" mat-icon-button
                        (click)="removeCompareItem(item)">
                        Remove
                    </button>
                </div>
            </li>
        </ol>
    </div>
    <hr *ngIf="itemsArray.length > 0" class="bottom-divider">
    <ul class="side-nav-buttons">
        <li>
            <button class="button is-rounded is-outlined outline-button is-regular-button" routerLink="data-tool"
                (click)="closeSideNav()">Resume</button>
        </li>
        <li>
            <button class="generate-report-button button is-rounded is-outlined primary-button is-regular-button"
                (click)="openResetReportConfigurationModal()">New Report</button>
        </li>
    </ul>
</nav>

<app-modal class="modal-container" [showModal]="showResetReportConfigurationModal"
    (isClosed)="closeResetReportConfigurationModal()">
    <div class="contents is-flex is-flex-direction-column is-align-items-center">
        <div class="contents-header is-flex is-justify-content-center">
            <h6 id="dialogLabel" class="has-text-white has-text-centered">Confirm</h6>
        </div>
        <div class="contents-body is-flex is-flex-direction-column is-align-items-center">
            <p class="large-body has-text-primary">Your previous selections will be removed. Do you want to continue?
            </p>
            <ul class="contents-body-navigation-buttons">
                <li>
                    <button class="button is-rounded is-outlined primary-button is-regular-button"
                        (click)="confirmResetReportConfiguration()">Confirm</button>
                </li>
                <li>
                    <button class="button is-rounded is-outlined outline-button is-regular-button"
                        (click)="closeResetReportConfigurationModal()">Cancel</button>
                </li>
            </ul>
        </div>

    </div>
</app-modal>
