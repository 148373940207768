import { Component } from '@angular/core';

@Component({
    selector: 'estimations-2017-2018',
    templateUrl: './estimations-2017-2018.component.html',
    styleUrls: [ './estimations-2017-2018.component.scss' ]
})

export class Estimations20172018Component {


}
