import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

import { MessageService } from '../core/message.service';

import { BaseService } from '../core/base.service';
import { DataService } from '../core/data.service';

import { EntityMeasuresQuery } from '../../queries/entity-measures-query';
import { Observable } from 'rxjs';
import {NGXLogger} from 'ngx-logger';
import {ToastService} from '@shared/components/toast/toast.service';

@Injectable()
export class MeasureService extends BaseService {

  private apiUrl = environment.api_url; // URL to web api

  constructor(
    private http: HttpClient,
    logger: NGXLogger,
    toastService: ToastService,
    messageService: MessageService,
    dataService: DataService
  ) {
    super(logger, toastService, messageService, dataService);
  }

  /** getMeasuresDropDown **/
  getMeasuresDropDown() {
    this.setHeaders();
    const url = this.apiUrl + 'MeasuresDropDown';

    var params = new HttpParams();

    return this.http.get(
      url,
      {
        params: params,
        observe: 'response',
        headers: this.headers
      })
      .pipe(
        map(resp => {
          if (resp != undefined || resp != null) {
            return resp.body;
          }

          return null;
        }),
        tap(resp => {
          this.log(`fetched`);
        }),
        catchError(this.handleError('get', null))
      );
  }

  /** getMeasuresJSON **/
  getMeasuresJSON() {
    this.setHeaders();
    const url = this.apiUrl + 'MeasuresJSON';

    return this.http.get(
      url,
      {
        observe: 'response',
        headers: this.headers
      })
      .pipe(
        map(resp => {
          if (resp != undefined || resp != null) {
            return resp.body;
          }

          return null;
        }),
        tap(resp => {
          this.log(`fetched`);
        }),
        catchError(this.handleError('get', null))
      );
    }

    /** GET **/
    getEntityMeasures(query: EntityMeasuresQuery): Observable<any> {
        this.setHeaders();
        const url = this.apiUrl + 'EntityMeasures';

        var params = new HttpParams();
        params = params.append('surveyYearKey', query.surveyYearKey.toString());
        params = params.append('entityId', query.entityId.toString());
        params = params.append('entityType', query.entityType);

        return this.http.get<any>(
            url,
            {
                params: params,
                observe: 'response',
                headers: this.headers,
                withCredentials: true,  // so we can send cookies
            })
            .pipe(
                map(resp => {
                    if (resp != undefined || resp != null) {
                        return resp.body;
                    }

                    return null;
                }),
                tap(resp => {
                    this.log(`fetched`);
                }),
                catchError(this.handleError('get', null))
            );
    }


}
