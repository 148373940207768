<div class="navigation-tree-container is-flex is-flex-direction-row is-justify-content-center">
    <a *ngIf="state" class="bold-body has-text-white" [href]="routeEntity('nation')">U.S.</a>
    <p *ngIf="!state" class="body selected">U.S.</p>

    <mat-icon *ngIf="state" class="arrow">keyboard_arrow_right</mat-icon>

    <a *ngIf="leaName" class="bold-body has-text-white" [href]="routeEntity('state')">{{ state }}</a>
    <p *ngIf="!leaName && state" class="body selected">{{ state }}</p>

    <mat-icon *ngIf="leaName" class="arrow">keyboard_arrow_right</mat-icon>

    <a *ngIf="schName" class="bold-body has-text-white" [href]="routeEntity('district')">{{ leaName }}</a>
    <p *ngIf="!schName && leaName" class="body selected">{{ leaName }}</p>

    <mat-icon *ngIf="schName" class="arrow">keyboard_arrow_right</mat-icon>
    
    <p *ngIf="schName" class="body selected">{{ schName }}</p>
</div>
