import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/scrolling';
import { Subscription } from 'rxjs/internal/Subscription';
import { ScreenType } from 'src/app/shared/models/shared-model';

@Component({
  selector: 'app-about-main',
  templateUrl: './about-main.component.html',
  styleUrls: ['./about-main.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AboutMainComponent implements OnInit, OnDestroy {

  mapState: MapState;
  scrollDispatcherSub: Subscription;

  ScreenType = ScreenType;
  screenType: ScreenType = ScreenType.DESKTOP;

  constructor(private scrollDispatcher: ScrollDispatcher, private cdRef: ChangeDetectorRef) {
    this.scrollDispatcherSub = this.scrollDispatcher.scrolled().subscribe((event: CdkScrollable) => {
      this.handleScrollChange(event.measureScrollOffset("top"));
    });
  }

  ngOnInit(): void {
    if (window.innerWidth < 1024 && window.innerWidth >= 768) {
      this.screenType = ScreenType.TABLET;
    } else if (window.innerWidth < 768) {
      this.screenType = ScreenType.MOBILE;
    } else {
      this.screenType = ScreenType.DESKTOP;
    }
  }

  @HostListener('window:resize', ['$event'])
  async onResize(event) {
    if (window.innerWidth < 1024 && window.innerWidth >= 768) {
      this.screenType = ScreenType.TABLET;
    } else if (window.innerWidth < 768) {
      this.screenType = ScreenType.MOBILE;
    } else {
      this.screenType = ScreenType.DESKTOP;
    }
  }

  ngOnDestroy(): void {
    this.scrollDispatcherSub.unsubscribe();
  }

  handleScrollChange(yOffset: number) {
    let mapFact: HTMLElement = document.getElementById("mapFact");
    let stateMapFact: HTMLElement = document.getElementById("stateMapFact");
    let agencyMapFact: HTMLElement = document.getElementById("agencyMapFact");
    let schoolMapFact: HTMLElement = document.getElementById("schoolMapFact");

    let mapFactPos: number = mapFact.offsetTop;
    let stateMapFactPos: number = stateMapFact.offsetTop;
    let agencyMapFactPos: number = agencyMapFact.offsetTop;
    let schoolMapFactPos: number = schoolMapFact.offsetTop;

    let firstMapDelta: number = stateMapFactPos - mapFactPos;
    let secondMapDelta: number = agencyMapFactPos - stateMapFactPos;
    let thirdMapDelta: number = schoolMapFactPos - agencyMapFactPos;

    let firstTransistion: number = stateMapFactPos - (firstMapDelta / 2);
    let secondTransition: number = agencyMapFactPos - (secondMapDelta / 2);
    let thirdTransition: number = schoolMapFactPos - (thirdMapDelta / 2);

    if (yOffset < firstTransistion) {
      this.mapState = MapState.MAP;
    } else if (yOffset >= firstTransistion && yOffset < secondTransition) {
      this.mapState = MapState.STATE_MAP;
    } else if (yOffset >= secondTransition && yOffset < thirdTransition) {
      this.mapState = MapState.AGENCY_MAP;
    } else if (yOffset >= thirdTransition) {
      this.mapState = MapState.SCHOOL_MAP;
    }

    this.cdRef.detectChanges();
  }
}

enum MapState {
  MAP = 'map',
  STATE_MAP = 'stateMap',
  AGENCY_MAP = 'agencyMap',
  SCHOOL_MAP = 'schoolMap'
}
