<div *ngIf="items.length > 0" class="featured-reports is-flex is-flex-direction-column">
    <p class="large-body has-text-primary is-align-self-center has-text-centered">Featured Reports</p>

    <div class="block-content-container tile is-ancestor is-align-self-center is-hidden-touch">
        <div *ngFor="let item of items" class="tile is-parent is-4">
            <div class="tile-container tile is-child is-flex is-flex-direction-column is-align-items-center">
                <a [href]="item.file" target="_blank" rel="noreferrer noopener" tabindex="-1" aria-hidden="true"><img
                        [src]="item.src" class="crop rounded-corners" [alt]="item.alt" /></a>
                <h6 class="block-content-header has-text-primary has-text-centered"><a [href]="item.file"
                        target="_blank" rel="noreferrer noopener">{{ item.header }}</a></h6>
            </div>
        </div>
    </div>

    <app-carousel class="block-content-container is-hidden-desktop is-align-self-center">
        <ng-container *ngFor="let item of items">
            <ng-container class="tile is-parent" *appCarouselItem>
                <div
                    class="tile-mobile-container tile is-child item is-flex is-flex-direction-column is-align-self-center">
                    <a [href]="item.file" target="_blank" rel="noreferrer noopener" tabindex="-1"
                        aria-hidden="true"><img [src]="item.src" class="crop rounded-corners" [alt]="item.alt" /></a>
                    <h6 class="block-content-header has-text-primary has-text-centered"><a [href]="item.file"
                            target="_blank" rel="noreferrer noopener">{{ item.header }}</a></h6>
                </div>
            </ng-container>
        </ng-container>
    </app-carousel>

    <button class="button is-rounded is-outlined outline-button is-regular-button is-align-self-center"
        routerLink="publications">More Reports</button>
</div>
