import { Component, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { ScreenType } from 'src/app/shared/models/shared-model';
import { EntityType, State, SurveyYearModel } from 'src/app/shared/models/profile-model';
import { DataSpotlightQuery } from 'src/app/shared/queries/data-spotlight-query';
import { DataSpotlightService } from 'src/app/shared/services/data/data-spotlight.service';
import { ProfileService } from 'src/app/shared/services/helpers/profile.service';
import { NGXLogger } from 'ngx-logger';
@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SearchComponent implements OnInit {

    public numberOfDistricts: string;
    public numberOfSchools: string;

    public stateSpotlight: any[];

    public states: State[];
    public selectedState: string;

    public surveyYearKey: number;
    public surveyYear: string;

    public dataLoaded: boolean = false;
    public screenType: ScreenType = ScreenType.DESKTOP;
    public ScreenType = ScreenType;

    constructor(
        private readonly DataSpotlightService: DataSpotlightService,
        private profileService: ProfileService,
        private router: Router,
        private titleService: Title,
        private logger: NGXLogger
    ) {
        this.titleService.setTitle('View State Profiles | Civil Rights Data');
    }

    async ngOnInit(): Promise<void> {
        this.dataLoaded = false;

        await this.profileService.loadStateList();
        await this.profileService.loadSurveyYearKeys();

        const surveyYears: SurveyYearModel[] = this.profileService.getSurveyYearKeys(EntityType.NATIONAL);

        this.states = this.profileService.getStateIds();
        this.logger.debug('States: ', this.states);

        if (surveyYears.length === 0 || this.states === undefined) {
            this.routeTo404();
            return;
        }

        const currentSurveyYear: SurveyYearModel = surveyYears[0];

        this.surveyYear = currentSurveyYear.surveyYear;
        this.surveyYearKey = currentSurveyYear.yearKey;
        this.getDataSpotlight();

        if (window.innerWidth < 1024 && window.innerWidth >= 768) {
            this.screenType = ScreenType.TABLET;
        } else if (window.innerWidth < 768) {
            this.screenType = ScreenType.MOBILE;
        } else {
            this.screenType = ScreenType.DESKTOP;
        }
    }

    @HostListener('window:resize', ['$event'])
    async onResize(event) {
        if (window.innerWidth < 1024 && window.innerWidth >= 768) {
            this.screenType = ScreenType.TABLET;
        } else if (window.innerWidth < 768) {
            this.screenType = ScreenType.MOBILE;
        } else {
            this.screenType = ScreenType.DESKTOP;
        }
    }

    updateSurveyYear(yearKey: number) {
        this.dataLoaded = false;
        this.surveyYearKey = yearKey;
        this.surveyYear = this.profileService.getSurveyYearKeys(EntityType.NATIONAL).find(year => year.yearKey === this.surveyYearKey).surveyYear;
        this.getDataSpotlight();
    }

    getDataSpotlight() {
        let query: DataSpotlightQuery = new DataSpotlightQuery();

        query.surveyYearKey = this.surveyYearKey;
        query.stateId = 0;

        this.DataSpotlightService.get(query)
            .subscribe(data => {
                if (data) {
                    this.numberOfDistricts = data.leAs.toLocaleString('en-US');
                    this.numberOfSchools = data.schools.toLocaleString('en-US');
                    this.stateSpotlight = data.statesStatistics;
                    this.dataLoaded = true;
                } else {
                    throw new Error("Could not get data spotlight.");
                }
            });
    }

    onStateChange(): void {
        this.router.navigateByUrl(this.getStateRoute(this.selectedState));
    }

    getStateRoute(stateName: string): string {
        const state = this.profileService.getStateIds().find(state => state.stateName === stateName);
        const stateCode = state.state_Code.toLowerCase();
        return `/profile/us/${stateCode}?surveyYear=${this.surveyYear}`
    }

    private routeTo404(): void {
        this.profileService.setNotFoundLink(window.location.href);
        this.router.navigateByUrl('/404');
    }
}
