import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

// Core Services
import { MessageService } from './message.service';

// Models

// State Models

// Value Objects
import { WebServiceError } from '../../value-objects/web-service-error';
import { SchoolProfileQueryModel } from '../../query-models/school-profile-query-model';
import { DistrictProfileQueryModel } from '../../query-models/district-profile-query-model';

@Injectable()
export class DataService {

    private messageSource = new BehaviorSubject('default message');
    private entitySource = new BehaviorSubject(null);

    currentMessage = this.messageSource.asObservable();
    deletedEntity = this.entitySource.asObservable();
    addedEntityCount: number = 0;
    maxDataSetCount: number;
    searchType: string;

    constructor(
        private messageService: MessageService
    ) {}

    setSearchType(type: string) {
        this.searchType = type;
    }

    getSearchType() {
        return this.searchType;
    }

    setEntityCount(cnt: number) {
        this.addedEntityCount = cnt;
    }

    getEntityCount() {
        return this.addedEntityCount;
    }

    setMaxDataSetCount(cnt: number) {
        this.maxDataSetCount = cnt;
    }

    getMaxDataSetCount() {
        return this.maxDataSetCount;
    }

    sendMessage(message: string) {
        this.messageSource.next(message);
    }

    deletedEntityNotification(entity: any) {
        this.entitySource.next(entity);
    }

    // Error Helper Functions
    //////////////////////////

    private webServiceErrorSource = new BehaviorSubject<WebServiceError>(null);
    private webServiceError$ = this.webServiceErrorSource.asObservable();
    setWebServiceError(webServiceError: WebServiceError) {
        this.webServiceErrorSource.next(webServiceError);
    }
    getWebServiceError(): Observable<WebServiceError> {
        return this.webServiceError$;
    }


    getErrorMessage(errors: any): string {

        if (errors.required) {
            return "This field is required.";
        }

        if (errors.email) {
            return "Please enter a valid email address.";
        }

        return "Value is invalid";
    }



    // School Profile
    //////////////////////////

    private schoolProfileSource = new BehaviorSubject<SchoolProfileQueryModel>(null);
    private schoolProfile$ = this.schoolProfileSource.asObservable();
    setSchoolProfile(schoolProfile: SchoolProfileQueryModel) {
        this.schoolProfileSource.next(schoolProfile);
    }
    getSchoolProfile(): Observable<SchoolProfileQueryModel> {
        return this.schoolProfile$;
    }



    // District Profile
    //////////////////////////

    private districtProfileSource = new BehaviorSubject<DistrictProfileQueryModel>(null);
    private districtProfile$ = this.districtProfileSource.asObservable();
    setDistrictProfile(districtProfile: DistrictProfileQueryModel) {
        this.districtProfileSource.next(districtProfile);
    }
    getDistrictProfile(): Observable<DistrictProfileQueryModel> {
        return this.districtProfile$;
    }

        // page Title
    //////////////////////////
    private pageTitleSource = new BehaviorSubject<string>(null);
    private pageTitle$ = this.pageTitleSource.asObservable();
    setPageTitle(pageTitle: string) {
        this.pageTitleSource.next(pageTitle);
    }
    getPageTitle(): Observable<string> {
        return this.pageTitle$;
    }

}
