import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Area } from 'src/app/shared/models/shared-model';

@Component({
  selector: 'app-data-quality',
  templateUrl: './data-quality.component.html',
  styleUrls: ['./data-quality.component.scss']
})
export class DataQualityComponent implements OnInit {

  @ViewChild('flowChart') private flowChart: ElementRef;

  private currentimageWidth: number;
  private absoluteImageWidth: number = 1000;
  private absoluteCoords: string[] = [ '500,140,140', '860,281,140', '860,660,140', '500,799,140', '140,660,140', '140,281,140' ];
  public imageMap: Area[] = [
    {
      alt: 'Research and Evaluation',
      coords: '500,140,140',
      tooltip: 'OCR conducts various research and evaluation projects to support improvements for future collections.'
    },
    {
      alt: 'Survey Planning',
      coords: '860,281,140',
      tooltip: 'OCR plans for the collection by gathering feedback from members of the public through listening sessions and information collection requests, and prepares training materials and other resources for the upcoming collection.'
    },
    {
      alt: 'Pre-collection',
      coords: '860,660,140',
      tooltip: 'All school districts verify contact information to ensure they are included.'
    },
    {
      alt: 'Data Collection',
      coords: '500,799,140',
      tooltip: 'School districts submit and certify data in the CRDC online submission system.'
    },
    {
      alt: 'Post-collection',
      coords: '140,660,140',
      tooltip: 'OCR reviews all submitted data and reaches out to select school districts regarding data accuracy.'
    },
    {
      alt: 'Analysis & Reporting',
      coords: '140,281,140',
      tooltip: 'OCR analyzes all submitted data and prepares public data files, documentation, and reports for public release.'
    }
  ];

  constructor() { }

  ngOnInit(): void {
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.updateImageMap();
  }

  updateImageMap(): void {
    const newWidth: number = this.flowChart.nativeElement.width;
    const resizePercent: number = newWidth / this.absoluteImageWidth;
    
    this.imageMap.forEach((area, i) => {
      const coords: string[] = this.absoluteCoords[i].split(',');
      area.coords = `${parseInt(coords[0]) * resizePercent},${parseInt(coords[1]) * resizePercent},${parseInt(coords[2]) * resizePercent}`;
    })
  }

}
