import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { EntityType } from '../../models/profile-model';
import {SEARCH_STEP} from '@shared/query-models/step-search.model';
import {NGXLogger} from 'ngx-logger';

@Component({
  selector: 'app-data-tool-status',
  templateUrl: './data-tool-status.component.html',
  styleUrls: ['./data-tool-status.component.scss']
})
export class DataToolStatusComponent implements OnInit, OnChanges{

  EntityType = EntityType;

  @Input() step: SEARCH_STEP;
  @Input() entityType: EntityType;
  @Input() selectedEntity: string;

  public statuses: string[];
  public titles: string[];
  public descriptions: string[];

  constructor(
      private logger: NGXLogger,
  ) {
    this.statuses = [
      'Select Entity',
      'Compare',
      'Choose Criteria',
      'Review & Generate'
    ];

    this.titles = [
      'Select an Initial School or LEA',
      `Choose ${this.entityType}s for Comparison`,
      'Select Criteria for Your Report',
      'Review and Export Results'
    ];

    this.descriptions = [
      'Select the primary school or local educational agency (LEA) you wish to use as the benchmark for comparing data in the next step.',
      `Select the ${this.entityType?.toLowerCase()}s you wish to compare against ${this.selectedEntity}.`,
      'Choose the relevant criteria and specific data elements for your custom comparison.',
      'Preview your selected data. If it looks correct, click \'Generate Report\' to download your custom report'
    ];
   }

    ngOnInit() {
        this.logger.debug('Inside ngOnInit for DataToolStatusComponent, step: ', this.step);

    }

  ngOnChanges(changes: SimpleChanges): void {

      if (changes.entityType?.currentValue) {
      this.titles[1] = `Choose ${this.entityType}s for Comparison`;
    }

    if (changes.selectedEntity?.currentValue) {
      this.descriptions[1] = `Select the ${this.entityType?.toLowerCase()}s you wish to compare against ${this.selectedEntity}.`;
    }
  }
}
