import {inject, Injectable} from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import { NGXLogger } from 'ngx-logger';

import {environment} from '@environments/environment';
import {ToastService} from '@shared/components/toast/toast.service';
import { CookieService } from 'ngx-cookie-service';
import {catchError, map} from 'rxjs/operators';
import {Observable, Subject, throwError} from 'rxjs';
import {SessionTimerService} from '@core/auth/services/session-timer.service';
import {TokenService} from '@core/auth/services/token.service';
import {ActivatedRoute, ActivatedRouteSnapshot} from '@angular/router';
import {Location} from '@angular/common';
import {HttpParams} from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {

    isLoggedIn: boolean = false;
    acceptedAUPROBTerms: boolean = false;

    userFullName: string;
    userEmail: string;
    expiresAt: number;
    expiresIn: number;

    cookieService = inject(CookieService);

    oAuthAuthenticationSubject: Subject<boolean> = new Subject<boolean>();

    approvedEmailAddresses: string[] = [
        'bvanderwalt@vizuri.com',
        'ahammond@vizuri.com',
        'aroessler85@gmail.com',
        'brookziz18@gmail.com',
        'bvanderwalt@vizuri.com',
        'corinnemaclean@gmail.com',
        'dschnelzer@vizuri.com',
        'emma.fete@gmail.com',
        'm.neal.fettro@gmail.com',
        'nbui@vizuri.com',
        'ocrtestteam@gmail.com',
        'wildcat966us@gmail.com',
        'zacharystarr1@gmail.com',
        'zstarr@aemcorp.com',
        'jodeebair7@gmail.com',
        'atsovma@aemcorp.com',
        'phuong.bui@ed.gov',
        'nichole.bui@aemcorp.com',
        'jay.staugler@aemcorp.com',
        'nbui@aemcorp.com',
        'phuong.bui@ed.gov',
        'bhackney@vizuri.com',
        'angela.roessler@ed.gov',
        'gil.moody@ed.gov',
        'gil.moody@aemcorp.com',
        'crystal.t.lewis@ed.gov',
        'anne.hammond@aemcorp.com',
        'anik.hossain@ed.gov',
        'harathi.guddeti@ed.gov',
        'cmaclean@aemcorp.com',
        'bneace.ctr@aemcorp.com',
        'clewis@aemcorp.com',
        'patwood@aemcorp.com',
        'efete@aemcorp.com'
    ];

  constructor(
      private readonly logger: NGXLogger,
      private toastService: ToastService,
      private sessionTimerService: SessionTimerService,
      private tokenService: TokenService,
      //private activatedRoute: ActivatedRoute,
      private routeLocation: Location,
      //private router: Router,
  ) {
        this.logger.debug('Inside AuthorizationService constructor');

          this.sessionTimerService.getTimer().subscribe(
              (timeLeft) => {

                  this.logger.debug('timer: Time left: ', timeLeft, ', isLoggedIn: ', this.isLoggedIn);

                  if (timeLeft <= 0) {
                      this.logger.debug('session time expired');
                      this.logout(true, 'session');
                  }
              },
          );
    }


    initializeAuthTokenService(){
        this.logger.debug('Inside initializeAuthTokenService');
        this.tokenService.initializeAuthTokenService();
    }

    // subscribe to the timer service and get the time left before the timer expired
    getAuthenticationListener(): Observable<boolean> {
        return this.oAuthAuthenticationSubject.asObservable();

    }

    performAuthentication(activatedRoute: ActivatedRouteSnapshot){

        this.logger.debug('Inside performAuthentication, activatedRoute: ', activatedRoute);

        return new Observable<boolean>((observer) => {

            // Either check if user is authenticated or ignore authentication
            if (environment.protectDataCompareTool) {

                //this.initializeAuthTokenService();

                if (environment.useAuthorizationCodeFlow) {

                    //this.logger.debug('state: ', this.activatedRoute.snapshot.queryParamMap.get('state'));

                    const authenticationCode = activatedRoute.queryParams['code'];

                    //this.logger.debug('authenticationCode: ', authenticationCode);
                    if (authenticationCode){

                        const codeVerifier = sessionStorage.getItem('PKCE_verifier');
                        this.logger.debug('Before PKCE_verifier: ', codeVerifier);
                        //sessionStorage.removeItem('PKCE_verifier'); //cleanup
                        //sessionStorage.removeItem('nonce'); //cleanup

                        //this.logger.debug('this.router.url: ', this.router.url);

                        //const strippedURL = this.router.url.
                        // remove any query parameters from the url so that we do not try to authenticate again
                        //this.routelocation.replaceState('/');

                        const [path, query] = this.routeLocation.path().split('?');

                        this.logger.debug('path: ', path);
                        //this.logger.debug('query: ', query);

                        if (query){
                            const params = new HttpParams({ fromString: query });
                            this.logger.debug('params: ', params.keys(), activatedRoute.queryParams);
                        }

                        //const params = new HttpParams({ fromString: query });
                        //const theValueIWant = params.get('theParamIWant');
                        //this.routelocation.replaceState(path);  //params.delete('code').toString()

                        // to bypass authentication - testing only
                        // this.setIsLoggedIn(true);
                        // observer.next(true);
                        // observer.complete();

                        // now call Andriy to go get the access token and refresh token and respond with a session cookie
                        //call https://crd-reporting-test.aem-tx.com/api/v1.0/auth?code=abc123&code_verifier=123abc
                        this.authenticateAuthCode(authenticationCode, codeVerifier).subscribe((isAuthenticated: boolean) => {

                            this.logger.debug('isLoggedIn: ', isAuthenticated);

                            observer.next(isAuthenticated);
                            observer.complete();

                        }, error => {
                            this.logger.error('Error calling authenticateAuthCode :', error);

                            this.logger.debug('After PKCE_verifier: ', sessionStorage.getItem('PKCE_verifier'));

                            observer.next(false);
                            observer.complete();
                        }).add(() => {
                            this.logger.debug('done with authenticateAuthCode');
                        });
                    }
                    else {
                        //this.isLoggedIn = true;
                        this.logger.debug('skip verifying the AuthCode, isLoggedIn: ', this.isLoggedIn);

                        let isLoggedIn = this.refreshAuthenticationState();

                        observer.next(isLoggedIn);
                        observer.complete();

                        this.logger.debug('Final isLoggedIn: ', this.isLoggedIn);
                    }

                }
                else {

                    this.initializeAuthTokenService();

                    this.getIsAuthenticated(true).subscribe(
                        (isAuthenticated) => {

                            this.logger.debug('app: isAuthenticated: ', isAuthenticated);
                            console.log('app: isAuthenticated: '+ isAuthenticated);

                            observer.next(isAuthenticated);
                            observer.complete();
                        },
                    );

                }

            }
            else {

                this.logger.debug('Nothing to protect');
                // for the public no access to the data tool
                this.setIsLoggedIn(true);   // if not protected then you can see protected content

                observer.next(true);
                observer.complete();
            }

        });
    }

    refreshAuthenticationState(): boolean {
        this.logger.debug('Inside refreshAuthenticationState');

        if (!environment.protectDataCompareTool){
            this.logger.debug('Nothing to refresh, we are not protecting anything');
            return false;
        }

        let isLoggedIn= false;


        this.loadUserSessionInfoFromCache();


        if (this.hasValidAuthSessionIdCookie()){
            isLoggedIn = true;
        }

        this.setIsLoggedIn(isLoggedIn);

        // we need to check if this was a refresh and if we need to start the timer again
        if (isLoggedIn){



            if (!this.sessionTimerService.isRunning()){
                this.sessionTimerService.startTimer(this.expiresAt);
            }

            if (environment.useAuthorizationCodeFlow){

                if (!this.acceptedAUPROBTerms && sessionStorage.getItem('accept_terms') === 'y'){
                    this.acceptedAUPROBTerms = true;
                }
            }
        }

        return isLoggedIn;
    }

    /**
     *
     * @param authorizationCode
     * @param codeVerifier is the PKCE_verifier
     */
    authenticateAuthCode(authorizationCode: string, codeVerifier: string) {
        this.logger.debug('Inside authenticateAuthCode');

        //return of(false);
        return this.tokenService.authenticateAuthCode(authorizationCode, codeVerifier).pipe(map(response => {

                    this.logger.debug('response: ', response);

                    if (response?.error || response?.errorMessage){

                        // we have an issue
                        this.logger.error('Unable to login, error: ', response?.error + ', message: ' + response?.errorMessage);
                        this.toastService.error('Authentication failed! Please contact your system administrator if you have trouble accessing this application', 'Authentication', 10000);

                    }
                    else if (this.isValidTestEmailAccount(response.email)){

                        //const foundApprovedEmailAddress = this.isValidTestEmailAccount(response.email);

                        //if (foundApprovedEmailAddress){

                            this.saveUserSessionInfo(response.name, response.email, response.acceptTerms);

                            // only for development
                            const expiresAt= this.saveExpireDates(response.expires_at, response.expires_in);

                            //const now = new Date().getTime();
                            //this.logger.debug('cal expire at locally: ', now + (response.expires_in)*1000, expiresAt, expiresAt - (now + response.expires_in*1000));
                            // now check to see if we have a session cookie
                            this.getAuthSessionIdFromCookie();

                            this.setIsLoggedIn(true);
                            this.sessionTimerService.startTimer(expiresAt);

                            return true;
                        //}


                    }
                    else {
                        this.logger.error('This email['+ response.email + '] address was not approved to have access');
                        //this.resetSession();
                        this.logout(false, 'system');
                    }

                    this.setIsLoggedIn(false);

                    return false;

                }),
                catchError(error => {
                    this.logger.error('Error calling authenticateAuthCode: ', error);
                    this.toastService.error('Unable to login!');

                    this.isLoggedIn = false;
                    return throwError(error);
                    //return of(false);
                })
            );
    }

  getDecodedAccessToken(token: string): any {
    try {
      return jwtDecode(token);
    } catch (e) {
      return null;
    }
  }

    private isValidTestEmailAccount(email: string): boolean{
        this.logger.debug('Inside isValidTestEmailAccount');

        const foundApprovedEmailAddress = this.approvedEmailAddresses.find(a => a.toLowerCase() === email.toLowerCase());

        // let foundApprovedEmailAddress = null;
        // if (environment.authorizationIssuer === 'google'){
        //     foundApprovedEmailAddress = this.approvedEmailAddresses.find(a => a === response.email);
        // }
        // // icam we do not have to validate against a hardcoded list
        // else {
        //     foundApprovedEmailAddress = 'yes';
        // }

        this.logger.debug('foundApprovedEmailAddress: ', foundApprovedEmailAddress);

        if (foundApprovedEmailAddress){
            return true;
        }
        else {
            this.logger.debug('This is not an Approved Email Address: ', email);
        }

        return false;
    }

  getIsAuthenticated(startSessionTimer: boolean = false): Observable<boolean> {
      this.logger.debug('Inside getIsAuthenticated');

      return this.tokenService.isAuthenticated().pipe(map(success => {

              this.logger.debug('success: ', success);

              if (success){
                  this.logger.debug('Successfully logged in');

                  this.saveUserSessionInfo(null, null, null);

                  const foundApprovedEmailAddress = this.approvedEmailAddresses.find(a => a === this.userEmail);

                  this.logger.debug('foundApprovedEmailAddress: ', foundApprovedEmailAddress);
                  if (foundApprovedEmailAddress){

                      if (startSessionTimer){

                          if (!environment.useAuthorizationCodeFlow){

                              // we need to check if this was a refresh and if we need to start the timer again
                              if (!this.sessionTimerService.isRunning()){

                                  // expire at date in milli seconds
                                  const expiresAt = sessionStorage.getItem('expires_at');

                                  this.logger.debug('expires_at: ', expiresAt);

                                  if (expiresAt){
                                      const expireAtDate = new Date(+expiresAt);
                                      this.logger.debug('expireAtDate: ', expireAtDate);

                                      this.sessionTimerService.startTimer(+expiresAt);

                                  }

                              }

                          }

                      }
                  }
                  else {
                      this.logger.error('Not an approved email');
                      this.resetSession();
                      //this.logout(true);
                  }

              }
              else {
                  this.logger.debug('login failed');
                  this.resetSession();

              }

              // notify app component and navbar of login status
              this.setIsLoggedIn(success);

              return success;
          }),
          catchError(error => {
              this.logger.error('Error calling authenticateAuthCode: ', error);
              this.toastService.error('Unable to login!');
              return throwError(error);
              //return of(false);
          })
      );


  }

  setAuthorizationModelFromCognito(userInfo, accessToken, idToken): void {
    const decodedToken = this.getDecodedAccessToken(accessToken);
    this.logger.debug('decodedToken', decodedToken);
  }

    public hasValidAuthSessionIdCookie(): boolean {
        this.logger.debug('Inside hasValidAuthSessionIdCookie');

        // we can only read the cookie if the "SameSite" flag is not on as well as the HttpOnly flag
        const sessionInfo = this.getAuthSessionIdFromCookie();

        // for testing only
        //const sessionInfo = 'OFRmS3Y0WWR6QkQyb1I1TDJKRlpRUnhwdmlVaFRUaEtnOGJFNE5-QS4xQ1Jp:3599:638624251019264108';
        this.logger.debug('sessionInfo: ', sessionInfo);

        //OFRmS3Y0WWR6QkQyb1I1TDJKRlpRUnhwdmlVaFRUaEtnOGJFNE5-QS4xQ1Jp:3599:638624251019264108
        if (sessionInfo?.length > 0){

            //const info = sessionInfo.split(':');   //decodeURI(sessionInfo).split(':');
            //console.log('info: ' + info);

            const info = sessionInfo.split(':');

            const sessionId = info[0];
            const expiresIn = info[1];
            const expiresAt = info[2];

            // is only used for session timer
            //this.expiresIn = new Date().getTime() - (+expiresAt);
            this.expiresAt = +expiresAt;
            //this.expiresIn = +expiresIn;

            this.logger.debug('sessionId: ', sessionId, ', expiresAt: ', expiresAt, ', expiresIn: ', expiresIn);
            //console.log('sessionId: ' + sessionId + ', expiresAt: ' + this.expiresAt);
        }
        // in dev we cannot read the cookie, so we need to read from cache
        else if (environment.type === 'dev'){
            this.logger.debug('skip checking the cookie in dev mode');
            this.loadExpiresAtFromCache();
        }

        const now = new Date().getTime();

        // if we still have time left in our session
        if (this.expiresAt - now > 0){
            this.logger.debug('Valid session expired at: ', new Date(this.expiresAt));
            return true;
        }
        else {
            this.logger.debug('Expired session expired at: ', new Date(this.expiresAt));
        }

        return false;
    }

    public getAuthSessionIdFromCookie(): string {
        this.logger.debug('Inside getAuthSessionIdFromCookie');

        // we can only read the cookie if the "SameSite" flag is not on as well as the HttpOnly flag
        const sessionId = this.cookieService.get('crdc-session');

        this.logger.debug('sessionId: ', sessionId);

        return sessionId;
    }

    // cannot delete a cookie in dev as it is cross site: localhost != crd-reporting-test.aem-tx.com
    public deleteAuthSessionIdCookie() {
        this.logger.debug('Inside deleteAuthSessionIdCookie', this.cookieService.get('crdc-session'));

        // we can only read the cookie if the "SameSite" flag is not on as well as the HttpOnly flag
        //this.cookieService.delete('crdc-session');
        this.cookieService.deleteAll();

        //this.logger.debug('After deleteAuthSessionIdCookie', this.cookieService.get('crdc-session'));

    }

    saveUserSessionInfo(userFullName: string, userEmail: string, acceptTerms: boolean){

        this.logger.debug('Inside saveUserSessionInfo');

        if (environment.useAuthorizationCodeFlow){
            this.userFullName = userFullName;
            this.userEmail = userEmail;

            // we also need to check if we have to prompt the user to accept the AUP-ROB terms
            this.setAcceptedAUPROBTerms(acceptTerms === true);
        }
        // implicit flow
        else {

            const savedTokenData = sessionStorage.getItem('id_token_claims_obj');

            //this.logger.debug('saved token Data: ', savedTokenData);

            if (savedTokenData) {

                const userSessionInfo: any = JSON.parse(savedTokenData);

                this.userFullName = userSessionInfo.name;
                this.userEmail = userSessionInfo.email;

                this.logger.debug('saved userFullName: ', this.userFullName);

            }
        }

        if (this.userFullName || this.userEmail){
            sessionStorage.setItem('user-session', JSON.stringify({name: this.userFullName, email: this.userEmail, acceptTerms: this.acceptedAUPROBTerms}));
        }

    }

    loadUserSessionInfoFromCache(){
        this.logger.debug('Inside loadUserSessionInfoFromCache');

        const userSessionInfo = JSON.parse(sessionStorage.getItem('user-session'));

        this.logger.debug('userSessionInfo: ', userSessionInfo);

        if (userSessionInfo){
            this.userFullName = userSessionInfo.name;
            this.userEmail = userSessionInfo.email;
            this.acceptedAUPROBTerms = userSessionInfo.acceptTerms;
        }
        else {
            this.userFullName = null;
            this.userEmail = null;
            this.acceptedAUPROBTerms = false;
        }
    }

    saveExpireDates(expiresAt: number, expiresIn: number){

        this.logger.debug('Inside saveExpiresAt, expiresAt: ', expiresAt, ', expiresIn: ', expiresIn);

        if (environment.useAuthorizationCodeFlow){
            this.expiresAt = expiresAt;
            this.expiresIn = expiresIn;
            sessionStorage.setItem('expires_at', expiresAt.toString());
            sessionStorage.setItem('expires_in', expiresIn.toString());
        }
        // implicit flow
        else {
            // nothing to save for implicit flow
        }

        return this.expiresAt;

    }

    loadExpiresAtFromCache(){
        this.logger.debug('Inside loadExpiresAtFromCache');

        // we only do this for developers in dev mode, otherwise this value has to be loaded from the cookie
        if (environment.type === 'dev' ){
            this.expiresAt = +sessionStorage.getItem('expires_at');
            this.expiresIn = +sessionStorage.getItem('expires_in');
            this.logger.debug('cached expiresAt: ', this.expiresAt, ', expiresIn: ', this.expiresIn);
        }
    }

    getUserFullName(): string{
        this.logger.debug('Inside getUserFullName, userFullName: ', this.userFullName);

        // if (!this.userFullName){
        //     this.loadUserSessionInfoFromCache();
        // }

        return this.userFullName;
    }

    getUserEmail(): string {
        this.logger.debug('Inside getUserEmail');

        // if (!this.userEmail){
        //     this.loadUserSessionInfoFromCache();
        // }
        return this.userEmail;
    }

    isLoginDotGovUser(){
        this.logger.debug('Inside isLoginDotGovUser, email: ', this.userEmail);

        if (this.userEmail?.toLowerCase().endsWith('ed.gov')){
            return false;
        }

        return true;
    }

    getIsLoggedIn(): boolean{
        this.logger.debug('Inside getIsLoggedIn: ', this.isLoggedIn);
        return this.isLoggedIn;
    }

    setIsLoggedIn(isLoggedIn: boolean){
        this.logger.debug('Inside setIsLoggedIn: ', isLoggedIn);
        this.isLoggedIn = isLoggedIn;

        // notify everybody who is listening to the auth notifications
        this.oAuthAuthenticationSubject.next(isLoggedIn);
    }

    getAcceptedAUPROBTerms(){
        this.logger.debug('Inside getAcceptedAUPROBTerms: ', this.acceptedAUPROBTerms);

        if (environment.useAuthorizationCodeFlow){

            // if (!this.acceptedAUPROBTerms && sessionStorage.getItem('accept_terms') === 'y'){
            //     this.acceptedAUPROBTerms = true;
            // }

            return this.acceptedAUPROBTerms;
        }

        // we do not support the terms in implicit mode
        return true;

    }

    setAcceptedAUPROBTerms(accept: boolean){
        this.logger.debug('Inside setAcceptedAUPROBTerms: ', accept);
        this.acceptedAUPROBTerms = accept;
    }

    recordUserAcceptedAUPROBTerms(){
        this.logger.debug('Inside recordUserAcceptedAUPROBTerms');

        this.setAcceptedAUPROBTerms(true);
        this.tokenService.acceptAUPROBTerms(this.userEmail, this.userFullName);

    }

    requestAuthorization() {

        this.logger.debug('Inside requestAuthorization, AuthorizationService, useAuthorizationCodeFlow: ', environment.useAuthorizationCodeFlow);

        //FIXME: To be removed, only for testing
        //this.logout();

        //this.initializeAuthTokenService();

        this.tokenService.requestTokens();

    }

    logout(redirectToHomePage: boolean = false, logoutEvent: string = 'user') {
        this.logger.debug('Inside AuthorizationService: logout, redirectToHomePage: ', redirectToHomePage);

        //TODO: remove only here for development testing
        this.hasValidAuthSessionIdCookie();

        const isLoginDotGovUser: boolean = this.isLoginDotGovUser();

        // this.tokenService.icamLogout().subscribe((response: any) => {
        //
        //     this.logger.debug('response: ', response);
        //
        //     this.resetSession();
        //
        // }, error => {
        //     this.logger.error('Error trying to logout, error: ', error);
        //     //this.toastService.error('Unable to logout');
        // }).add(() => {
        //     //Called when operation is complete (both success and error)
        //
        //     this.logger.debug('Done revoking auth token');
        //
        // });

        //this.resetSession();
        // sessionStorage.removeItem('PKCE_verifier'); //cleanup
        // sessionStorage.removeItem('nonce'); //cleanup
        //
        // sessionStorage.removeItem('user-session');
        // sessionStorage.removeItem('expires_at');
        // sessionStorage.removeItem('expires_in');

        this.tokenService.revokeAuthenticateToken(logoutEvent).subscribe((response: any) => {

            this.logger.debug('response: ', response);

        }, error => {
            this.logger.error('Error trying to logout, error: ', error);
            //this.toastService.error('Unable to logout');
        }).add(() => {
            //Called when operation is complete (both success and error)

            this.logger.debug('Done revoking auth token');

            this.resetSession();

            if (environment.authorizationIssuer === 'google'){

                // go to home page
                if (redirectToHomePage){

                    if (environment.useAuthorizationCodeFlow){

                        const [path, query] = this.routeLocation.path().split('?');

                        this.logger.debug('path: ', path);
                        this.logger.debug('query: ', query);

                        // if we are already on the home page do not redirect
                        if (path?.length > 2){
                            this.logger.debug('go to home');
                            this.goToHomePage();
                        }

                    }
                    else{
                        // make use of the logoutUrl in the outhconfig
                    }

                }
                else {
                    this.logger.debug('refresh from authorization service');
                    this.refreshAuthenticationState();
                }
            }
            else if (environment.authorizationIssuer === 'icam'){

                //this.resetSession();

                // this.setIsLoggedIn(false);
                // localStorage.clear();
                // this.sessionTimerService.removeTimer();
                // this.deleteAuthSessionIdCookie();

                this.tokenService.icamLogout(isLoginDotGovUser);
            }
        });
    }

    public resetSession(){
        this.logger.debug('Inside resetSession');

        this.setIsLoggedIn(false);

        sessionStorage.clear();
        localStorage.clear();

        this.sessionTimerService.removeTimer();

        this.deleteAuthSessionIdCookie();
    }

    private goToHomePage(){

        this.logger.debug('Inside goToHomePage');
        // go to home page - this forces a reload of the application
        window.location.href = this.tokenService.getHomeUrl();

        // this does not force a reload of the app
        //this.router.navigate(['/']);
    }

    gotToLoginPage(){
        this.logger.debug('Inside gotToLoginPage');

        window.location.href = this.tokenService.getHomeUrl() + '/data-tool-login-landing'; //'/data-tool';
    }

}
