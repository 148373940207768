import { Component, Input } from '@angular/core';

@Component({
    selector: 'document-viewer',
    templateUrl: './document-viewer.component.html',
    styleUrls: ['./document-viewer.component.scss']
})
export class DocumentViewerComponent {
    @Input() docType: string;
}
