import { Component } from '@angular/core';

@Component({
    selector: 'estimations-2004',
    templateUrl: './estimations-2004.component.html',
    styleUrls: [ './estimations-2004.component.scss' ]
})

export class Estimations2004Component {


}
