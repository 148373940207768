<app-loading-spinner *ngIf="showLoadingSpinner" [message]="spinnerMessage"></app-loading-spinner>

<div class="enforcement-data-tool-container is-flex is-flex-direction-column">
    <app-data-tool-status [step]="currentStep" [entityType]="entityType.value"
        [selectedEntity]="selectedEntity.value?.entity_Name">
    </app-data-tool-status>

    <ng-container *ngIf="initialized" [ngSwitch]="currentStep">
        <app-data-tool-step-one *ngSwitchCase="SEARCH_STEP.STEP_ONE" [formGroup]="formGroups[0]"
            [entityProfile]="entityProfile" (formResponse)="onFormResponse($event)"
            (showLoadingEmitter)="showLoading($event)">
        </app-data-tool-step-one>
        <app-data-tool-step-two *ngSwitchCase="SEARCH_STEP.STEP_TWO" [formGroup]="formGroups[1]"
            [entitySurveyYears]="entitySurveyYears" [selectedEntity]="selectedEntity" [entityType]="entityType.value"
            (formResponse)="onFormResponse($event)" (showLoadingEmitter)="showLoading($event)">
        </app-data-tool-step-two>
        <!-- <app-data-tool-step-three *ngSwitchCase="SEARCH_STEP.STEP_THREE" [formGroup]="formGroups[2]"
            [moduleOptions]="moduleOptions?.result" [analysisOptions]="analysisOptions" [scrollItem]="scrollItem"
            (formResponse)="onFormResponse($event)">
        </app-data-tool-step-three> -->
        <app-data-tool-step-three *ngSwitchCase="SEARCH_STEP.STEP_THREE" [formGroup]="formGroups[2]"
            [moduleOptions]="moduleOptions?.result" [scrollItem]="scrollItem" (formResponse)="onFormResponse($event)">
        </app-data-tool-step-three>
        <app-data-tool-step-four *ngSwitchCase="SEARCH_STEP.STEP_FOUR" [formGroup]="formGroups[3]"
            [moduleOptions]="moduleOptions?.result" [analysisOptions]="analysisOptions"
            (showLoadingEmitter)="showLoading($event)" (routeToStepEmitter)="routeToStep($event)">
        </app-data-tool-step-four>
    </ng-container>

    <ul class="enforcement-data-tool-container-navigation-buttons">
        <li *ngIf="currentStep > SEARCH_STEP.STEP_ONE">
            <button class="button is-rounded is-outlined outline-button is-regular-button"
                style="text-decoration: none;" (click)="previousStep()">Back</button>
        </li>
        <li>
            <button *ngIf="currentStep < SEARCH_STEP.STEP_FOUR && formGroups[currentStep - 1]"
                [disabled]="!formGroups[currentStep - 1].valid || spinnerActionActivated"
                class="button is-rounded is-outlined outline-button is-regular-button" style="text-decoration: none;"
                (click)="nextStep()">Continue</button>
            <button *ngIf="currentStep === SEARCH_STEP.STEP_FOUR" [disabled]="!formGroups[currentStep - 1].valid"
                class="button is-rounded is-outlined outline-button is-regular-button" style="text-decoration: none;"
                (click)="exportResults()">Export Results</button>
        </li>
    </ul>
</div>

<app-modal class="modal-container" [showModal]="showUpdateStep2Modal" (isClosed)="closeUpdateStep2Modal()">
    <div class="contents is-flex is-flex-direction-column is-align-items-center">
        <div class="contents-header is-flex is-justify-content-center">
            <h6 id="dialogLabel" class="has-text-white has-text-centered">Confirm</h6>
        </div>
        <div class="contents-body is-flex is-flex-direction-column is-align-items-center">
            <p class="large-body has-text-primary">Your previous selections will be removed. Do you want to continue?
            </p>
            <ul class="contents-body-navigation-buttons">
                <li>
                    <button class="button is-rounded is-outlined primary-button is-regular-button"
                        (click)="nextStep(true)">Confirm</button>
                </li>
                <li>
                    <button class="button is-rounded is-outlined outline-button is-regular-button"
                        (click)="closeUpdateStep2Modal()">Cancel</button>
                </li>
            </ul>
        </div>
    </div>
</app-modal>

<!-- (isClosed)="closeAUPROBModal()" -->
<app-modal *ngIf="showAUPROBModal" class="terms-model" [showModal]="showAUPROBModal"
    [preventClosingOnClickingAway]="true">
    <div class="is-flex is-flex-direction-column is-align-items-center">
        <div class="contents-header is-flex is-justify-content-center">
            <h6 id="dialogLabel" class="has-text-white has-text-centered" style="font-family: 'RALEWAY';">Confirm
                Acceptable Use Policy and Rules of Behavior</h6>
        </div>
        <div class="contents-body is-flex is-flex-direction-column is-align-items-center">

            <div #aupROBTerms class="rule-terms" (scroll)="onScrollTerms()">

                <h6 class="has-text-primary">Acceptable Use Policy (AUP)</h6>

                <ul class="rule-list">
                    <li *ngFor="let rule of acceptableUsePolicyList">{{rule}}</li>
                </ul>
                <h6 class="has-text-primary">Rules of Behavior (ROB) for General Users</h6>
                <p>As a user of the data analysis tool, I agree that:</p>
                <ul class="rule-list">
                    <li *ngFor="let rule of rulesOfBehavior">
                        <p>{{rule}}</p>
                    </li>
                </ul>

                <h6 class="has-text-primary">Additional Rules of Behavior (ROB) for Privileged Users</h6>

                <p>A Privileged User is a user who has been granted significantly elevated privileges for access to
                    protected physical or logical resources. A privileged user has the potential to compromise the three
                    security objectives of confidentiality, integrity and availability.
                </p>
                <br>
                <p>Privileged users shall read, acknowledge (in the Privileged Users signature block), and adhere to the ROB for privileged users.
                </p>

                <ul class="rule-list">
                    <li *ngFor="let rule of additionalRulesOfBehavior">{{rule}}</li>
                </ul>

                <h6 class="has-text-primary">Penalties of Non-Compliance</h6>

                <p>Users (personnel and contractors) are required to exercise “due diligence” and the highest ethical
                    standards to guide their actions in their use of the data analysis tool. These rules are based on
                    Federal laws, regulations, and ED policies. As such, there are consequences for non-compliance with
                    the AUP and ROB.
                </p>
                <br>
                <p>Depending on the severity of the violation, at the discretion of management and the
                    ISSO, and through due process of the law, penalties of non-compliance can include:
                </p>
                <ul class="rule-list">
                    <li *ngFor="let rule of penaltiesOfNonCompliance">{{rule}}</li>
                </ul>

                <h6 class="has-text-primary">Acknowledgement of AUP and ROB</h6>
                <ul class="rule-list">
                    <li *ngFor="let rule of termsAcknowledgement">{{rule}}</li>
                </ul>

            </div>
            <hr class="modal-footer-divider">
            <ul class="contents-body-navigation-buttons">
                <li>
                    <button class="button is-rounded is-outlined primary-button is-regular-button"
                        (click)="acceptTerms()" [disabled]="!enableAcceptButton">Accept</button>
                </li>
                <li>
                    <button class="button is-rounded is-outlined outline-button is-regular-button"
                        (click)="declineTerms()">Decline</button>
                </li>
            </ul>
        </div>
    </div>
</app-modal>

<app-modal *ngIf="showDeclineConfirmationAUPROBModal" class="confirm-decline-terms-model"
    [showModal]="showDeclineConfirmationAUPROBModal" [preventClosingOnClickingAway]="true">
    <div class="is-flex is-flex-direction-column is-align-items-center">
        <div class="contents-header is-flex is-justify-content-center">
            <h6 id="dialogLabel" class="has-text-white has-text-centered" style="font-family: 'RALEWAY';">Confirm
                Declining Acceptable Use Policy and Rules of Behavior</h6>
        </div>
        <div class="contents-body is-flex is-flex-direction-column is-align-items-center">

            <div>
                <p class="large-body has-text-primary">Are you sure you want to decline? If so you would not be granted
                    access to the Data Tool.</p>
            </div>

            <ul class="contents-body-navigation-buttons">
                <li>
                    <button class="button is-rounded is-outlined primary-button is-regular-button"
                        (click)="confirmDeclineTerms()">Yes</button>
                </li>
                <li>
                    <button class="button is-rounded is-outlined outline-button is-regular-button"
                        (click)="cancelConfirmationOfDeclineTerms()">No</button>
                </li>
            </ul>
        </div>
    </div>
</app-modal>
