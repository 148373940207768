import { Injectable } from '@angular/core';
import { HttpEvent, HttpErrorResponse, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { AuthorizationService } from '@core/auth/services/authorization.service';
import {ToastService} from '@shared/components/toast/toast.service';


@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(
        private router: Router,
        private logger: NGXLogger,
        private toastService: ToastService,
        private authorizationService: AuthorizationService,
    ) { }

    intercept(
        req: HttpRequest<any>, next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                //this.router.navigateByUrl('/clienterror');
                //let errMsg = '';

                //if (error.error instanceof ErrorEvent) {
                //    errMsg = `Error: ${error.error.message}`;
                //} else {
                //    errMsg = `Error Code: ${error.status},  Message: ${error.message}`;
                //}
                this.logger.debug('HttpErrorInterceptor: ', error);
                //Reset user session
                if (error.status === 401) {
                    this.authorizationService.resetSession();

                    this.logger.error('Not authorized');
                    this.toastService.error('Please login again. Your session has expired!!');

                    this.router.navigate(['/data-tool-login-landing']);
                }
                // Authentication failed
                else if (error.status === 400 && error.error){

                    //this.authorizationService.resetSession();
                    this.logger.error('Unable to log you in, error: ', error.error);
                    this.toastService.error('Failed Authentication, error: ' + error.error.error + ', description: ' + error.error.errorMessage, 'Authentication', 10000);

                    //this.router.navigate(['/data-tool-login-landing']);
                    return throwError(error);
                }
                // Access backend resources failed
                if (error.status === 403) {
                    this.authorizationService.resetSession();

                    this.logger.error('Your session has expired and you need to login again');
                    this.toastService.error('Please login again. Your session has expired!');

                    this.router.navigate(['/data-tool-login-landing']);
                }
                else {
                    this.logger.error('Found, error: ', error);
                    return throwError(error);
                }

            }) as any
        );
    }

}
