import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import * as d3 from 'd3';
// import * as topojson from 'topojson';

@Component({
  selector: 'app-national-chart',
  templateUrl: './national-chart.component.html',
  styleUrls: ['./national-chart.component.scss']
})
export class NationalChartComponent implements OnInit {

  @Output() selected = new EventEmitter<string>();
  private svg;
  private width = 975;
  private height = 610;

  constructor() { }

  ngOnInit(): void {
    this.createSvg();
    this.drawNationalChart();
  }

  private createSvg(): void {
    this.svg = d3.select("#national-chart").append("svg").attr("viewBox", `0 0 ${this.width} ${this.height}`).append("g");
  }

  private drawNationalChart(): void {
    var projection = d3.geoAlbersUsa().translate([this.width / 2, this.height / 2]).scale(1000);

    var path = d3.geoPath().projection(projection);

    d3.json("/assets/us-states.json").then((json: any) => {
      let value = null;

      // this.svg.append("g")
      //   .attr("fill", "#ccc")
      //   .selectAll("path")
      //   .data(topojson.feature(json, json.objects.states).features)
      //   .enter().append("path")
      //   .attr("d", path)
      //   .on("click", (event, d) => {
      //     const node = this.svg.node();
      //     node.value = value = value === d.id ? null : d.id;
      //     node.dispatchEvent(new Event("input", { bubbles: true }));
      //     outline.attr("d", value ? path(d) : null);
      //   });

      // this.svg.append("path")
      //   .datum(topojson.mesh(json, json.objects.states, (a, b) => a !== b))
      //   .attr("fill", "none")
      //   .attr("stroke", "white")
      //   .attr("stroke-linejoin", "round")
      //   .attr("pointer-events", "none")
      //   .attr("d", path);

      // const outline = this.svg.append("path")
      //   .attr("fill", "none")
      //   .attr("stroke", "black")
      //   .attr("stroke-linejoin", "round")
      //   .attr("pointer-events", "none");
    })
  }

  private setSelected(selectedArea: string): void {
    this.selected.emit(selectedArea);
  }

}
