export enum ComparisonType {
    SEX = 1,    //'sex',
    RACE_ETHNICITY = 2, //'raceOrEthnicity',
    EL_STATUS = 3,  //'elStatus',
    IDEA = 4,   //'idea',
    SECTION504 = 5, //'section504',
    RELATIVE_RISK = 6,  //'relativeRisk',
    //IDEA_504 = 7, //'idea504',
}

export enum DemographicType {
    SEX = 1,    //'sex',
    RACE_ETHNICITY = 2, //'raceOrEthnicity',
    SEX_RACE_ETHNICITY = 3, //'sexRaceOrEthnicity',
    RACE_ETHNICITY_RELATIVE_RISK = 4, //'raceOrEthnicityRelativeRisk',
}
