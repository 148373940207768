<div class="card data-tool-card">
    <header class="card-header" (click)="toggleDropdown($event)" onkeypress="">
        <p class="has-text-primary">Enrollment</p>
        <button type="button" class="card-header-icon search-button" aria-label="toggle show enrollment"
            [attr.aria-expanded]="expanded" mat-icon-button>
            <i *ngIf="!expanded" aria-hidden="true" class="material-icons">keyboard_arrow_down</i>
            <i *ngIf="expanded" aria-hidden="true" class="material-icons">keyboard_arrow_up</i>
        </button>
    </header>

    <div *ngIf="expanded" class="card-content is-flex is-flex-direction-column" style="gap: 1rem;">
        <div class="is-flex is-flex-direction-column" style="gap: .5rem;">
            <p id="sex" class="subheader-2 has-text-primary">Sex</p>
            <div class="is-flex is-align-items-center is-justify-content-space-between"
                *ngFor="let criterion of sexCriteria">
                <p class="large-body has-text-primary">{{ criterion.label }}</p>
                <label class="is-sr-only" for="{{ criterion.label }}-min">Lower bound, between
                    {{ criteria[criterion.value].lowerBound }} and {{ criteria[criterion.value].upperBound}} percent,
                    {{ criterion.label }}, sex</label>
                <label class="is-sr-only" for="{{ criterion.label }}-max">Upper bound, between
                    {{ criteria[criterion.value].lowerBound }} and {{ criteria[criterion.value].upperBound}} percent,
                    {{ criterion.label }}, sex</label>
                <span class="is-flex" style="gap: 1rem;">
                    <span class="large-body has-text-primary">Between</span>
                    <input type="number" pattern="[0-9]|[1-9][0-9]|100" id="{{ criterion.label }}-min"
                        name="{{ criterion.label }}-min" [min]="Bounds.LOWER" [max]="Bounds.UPPER"
                        [value]="criteria[criterion.value].lowerBound"
                        (input)="updateCriteriaLowerBound(criterion.value, $event)" [disabled]="disabled">
                    <span class="large-body has-text-primary">and</span>
                    <input type="number" pattern="[0-9]|[1-9][0-9]|100" id="{{ criterion.label }}-max"
                        name="{{ criterion.label }}-max" [min]="Bounds.LOWER" [max]="Bounds.UPPER"
                        [value]="criteria[criterion.value].upperBound"
                        (input)="updateCriteriaUpperBound(criterion.value, $event)" [disabled]="disabled">
                    <span class="large-body has-text-primary">%</span>
                </span>
            </div>
        </div>

        <div class="is-flex is-flex-direction-column" style="gap: .5rem;">
            <p class="subheader-2 has-text-primary">Race or ethnicity</p>
            <div class="is-flex is-align-items-center is-justify-content-space-between"
                *ngFor="let criterion of raceOrEthnicityCriteria">
                <p class="large-body has-text-primary">{{ criterion.label }}</p>
                <label class="is-sr-only" for="{{ criterion.label }}-min">Lower bound, between
                    {{ criteria[criterion.value].lowerBound }} and {{ criteria[criterion.value].upperBound}} percent,
                    {{ criterion.label }}, race or ethnicity</label>
                <label class="is-sr-only" for="{{ criterion.label }}-max">Upper bound, between
                    {{ criteria[criterion.value].lowerBound }} and {{ criteria[criterion.value].upperBound}} percent,
                    {{ criterion.label }}, race or ethnicity</label>
                <span class="is-flex" style="gap: 1rem;">
                    <span class="large-body has-text-primary">Between</span>
                    <input type="number" pattern="[0-9]|[1-9][0-9]|100" id="{{ criterion.label }}-min"
                        name="{{ criterion.label }}-min" [min]="Bounds.LOWER" [max]="Bounds.UPPER"
                        [value]="criteria[criterion.value].lowerBound"
                        (input)="updateCriteriaLowerBound(criterion.value, $event)" [disabled]="disabled">
                    <span class="large-body has-text-primary">and</span>
                    <input type="number" pattern="[0-9]|[1-9][0-9]|100" id="{{ criterion.label }}-max"
                        name="{{ criterion.label }}-max" [min]="Bounds.LOWER" [max]="Bounds.UPPER"
                        [value]="criteria[criterion.value].upperBound"
                        (input)="updateCriteriaUpperBound(criterion.value, $event)" [disabled]="disabled">
                    <span class="large-body has-text-primary">%</span>
                </span>
            </div>
        </div>

        <div class="is-flex is-flex-direction-column" style="gap: .5rem;">
            <p class="subheader-2 has-text-primary">Other</p>
            <div class="is-flex is-align-items-center is-justify-content-space-between"
                *ngFor="let criterion of otherCriteria">
                <p class="large-body has-text-primary">{{ criterion.label }}</p>
                <label class="is-sr-only" for="{{ criterion.label }}-min">Lower bound, between
                    {{ criteria[criterion.value].lowerBound }} and {{ criteria[criterion.value].upperBound}} percent,
                    {{ criterion.label }}, other</label>
                <label class="is-sr-only" for="{{ criterion.label }}-max">Upper bound, between
                    {{ criteria[criterion.value].lowerBound }} and {{ criteria[criterion.value].upperBound}} percent,
                    {{ criterion.label }}, other</label>
                <span class="is-flex" style="gap: 1rem;">
                    <span class="large-body has-text-primary">Between</span>
                    <input type="number" pattern="[0-9]|[1-9][0-9]|100" id="{{ criterion.label }}-min"
                        name="{{ criterion.label }}-min" [min]="Bounds.LOWER" [max]="Bounds.UPPER"
                        [value]="criteria[criterion.value].lowerBound"
                        (input)="updateCriteriaLowerBound(criterion.value, $event)" [disabled]="disabled">
                    <span class="large-body has-text-primary">and</span>
                    <input type="number" pattern="[0-9]|[1-9][0-9]|100" id="{{ criterion.label }}-max"
                        name="{{ criterion.label }}-max" [min]="Bounds.LOWER" [max]="Bounds.UPPER"
                        [value]="criteria[criterion.value].upperBound"
                        (input)="updateCriteriaUpperBound(criterion.value, $event)" [disabled]="disabled">
                    <span class="large-body has-text-primary">%</span>
                </span>
            </div>
        </div>
    </div>
</div>
