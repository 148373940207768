import { EventEmitter, Injectable } from '@angular/core';
import { EntitySearchResult, EntitySearchStep2Result } from '../../query-models/entity-search-model';
import { NGXLogger } from 'ngx-logger';
import { SEARCH_STEP } from '@shared/query-models/step-search.model';

@Injectable({
  providedIn: 'root'
})
export class ComparisonService {

  /**
   * Emits an update to the editable state
   */
  editableUpdate: EventEmitter<boolean> = new EventEmitter();

  /**
   * Emits an update to the target item
   */
  targetItemUpdate: EventEmitter<EntitySearchResult> = new EventEmitter();

  /**
   * Emits an update to the items to be compared
   */
  comparisonUpdate: EventEmitter<Array<EntitySearchStep2Result>> = new EventEmitter();

  /**
   * Emits an update to remove an item from the comparison list
   */
  deleteItemUpdate: EventEmitter<EntitySearchStep2Result> = new EventEmitter();

  /**
   * Emits an update to reset the report configuration when the user clicks on the
   * resume or new report and confirm button in the side nav
   */
  resetReportConfigurationEvent: EventEmitter<void> = new EventEmitter();

  /**
   * Emits an update to toggle the side nav
   */
  toggleSideNavEvent: EventEmitter<void> = new EventEmitter();

  /**
   * The target item to be compared
   */
  targetItem: EntitySearchResult;

  /**
   * List of items to be compared
   */
  comparisonItems: EntitySearchStep2Result[] = [];

  constructor(private logger: NGXLogger) { }

  /**
   * Gets the target item
   * @returns The target item
   */
  getTargetItem(): EntitySearchResult {
    return this.targetItem;
  }

  /**
   * Gets the lit of comparison items
   * @returns The list of comparison items
   */
  getComparisonItems(): EntitySearchStep2Result[] {
    return this.comparisonItems;
  }

  /**
   * Emits an update that contains the editable state
   * @param value Boolean value to set the editable state
   */
  setIsEditable(value: boolean): void {
    this.logger.debug('Setting editable state: ', value);
    this.editableUpdate.emit(value);
  }

  /**
   * Emits an update that contains the target item
   * @param item Item to be set as the target item
   */
  setTargetItem(item: EntitySearchResult): void {
    this.logger.debug('Setting target item: ', item);
    this.targetItem = item;
    this.targetItemUpdate.emit(item);
  }

  /**
   * Emits an update that contains the items to be compared
   * @param items Array of items to be compared
   */
  setCompareItems(items: EntitySearchStep2Result[]): void {
    this.comparisonItems = items;
    this.comparisonUpdate.emit(items);
  }

  /**
   * Emits an update to remove an item from the comparison list
   * @param item Item to be removed from comparison
   */
  removeCompareItem(item: EntitySearchStep2Result): void {
    this.logger.debug('Comparison service: Removing item: ', item);
    this.deleteItemUpdate.emit(item);
  }

  /**
   * Call to use to emit the reset report configuration event when the user
   * clicks on the resume or new report and confirm button in the side nav
   */
  triggerResetReportConfiguration(): void {
    this.logger.debug('Inside triggerResetReportConfiguration');
    this.resetReportConfigurationEvent.emit();
  }

  /**
   * Call to use to emit the toggle side nav event when the user clicks on the
   * compare button in the navbar or on the view all selections button in the
   * data tool step 2 component.
   */
  triggerToggleSideNav(): void {
    this.logger.debug('Inside triggerToggleSideNav');
    this.toggleSideNavEvent.emit();
  }

  /**
   * Clears the form data for the given step
   * @param step Form group step that should have its form data cleared
   */
  clearSessionItem(step: SEARCH_STEP): void {
    this.logger.debug('Inside clearSessionItem, step: ', step);

    if (step === SEARCH_STEP.STEP_THREE || step === SEARCH_STEP.STEP_FOUR) {
      sessionStorage.removeItem(`enforcementDataToolStep${step}Modules`);
      sessionStorage.removeItem(`enforcementDataToolStep${step}`);

      if (step === SEARCH_STEP.STEP_FOUR) {
        sessionStorage.removeItem(`enforcementDataToolStep${step}Comparisons`);
      }
    } else {
      sessionStorage.removeItem(`enforcementDataToolStep${step}`);
    }

    if (step === SEARCH_STEP.STEP_ONE) {
      sessionStorage.removeItem('enforcementDataToolEntityProfile');
    }
  }
}
