<div class="container content is-size-7-mobile">
  <page-title title="2009-10 State and National Estimations"></page-title>
  <p>
    These files are state and national estimations for the Civil Rights Data Collection. The 2009-10 estimations are based on a
    rolling stratified sample of approximately 7,000 districts and 72,000 schools, and on reported data from those districts that
    responded to the survey.
    Documentation for the 2009-10 estimations is available through these <a href="assets/downloads/EstimationDataNotes.docx">data notes</a>.
  </p>

  <div class="has-text-centered">
    <a href="assets/downloads/2009-2010/2009-10-Estimations-Nation.xls" rel="noopener noreferrer" target="_blank">National total</a>    
  </div>
  <br />
  <div class="columns is-gapless is-multiline">
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2009-2010/2009-10-Estimations-Alabama.xls" rel="noopener noreferrer" target="_blank">Alabama</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2009-2010/2009-10-Estimations-Illinois.xls" rel="noopener noreferrer" target="_blank">Illinois</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2009-2010/2009-10-Estimations-Montana.xls" rel="noopener noreferrer" target="_blank">Montana</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2009-2010/2009-10-Estimations-RhodeIsland.xls" rel="noopener noreferrer" target="_blank">Rhode Island</a>
    </div>
  </div>
  <div class="columns is-gapless is-multiline">
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2009-2010/2009-10-Estimations-Alaska.xls" rel="noopener noreferrer" target="_blank">Alaska</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2009-2010/2009-10-Estimations-Indiana.xls" rel="noopener noreferrer" target="_blank">Indiana</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2009-2010/2009-10-Estimations-Nebraska.xls" rel="noopener noreferrer" target="_blank">Nebraska</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2009-2010/2009-10-Estimations-SouthCarolina.xls" rel="noopener noreferrer" target="_blank">South Carolina</a>
    </div>
  </div>
  <div class="columns is-gapless is-multiline">
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2009-2010/2009-10-Estimations-Arizona.xls" rel="noopener noreferrer" target="_blank">Arizona</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2009-2010/2009-10-Estimations-Iowa.xls" rel="noopener noreferrer" target="_blank">Iowa</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2009-2010/2009-10-Estimations-Nevada.xls" rel="noopener noreferrer" target="_blank">Nevada</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2009-2010/2009-10-Estimations-SouthDakota.xls" rel="noopener noreferrer" target="_blank">South Dakota</a>
    </div>
  </div>
  <div class="columns is-gapless is-multiline">
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2009-2010/2009-10-Estimations-Arkansas.xls" rel="noopener noreferrer" target="_blank">Arkansas</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2009-2010/2009-10-Estimations-Kansas.xls" rel="noopener noreferrer" target="_blank">Kansas</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2009-2010/2009-10-Estimations-NewHampshire.xls" rel="noopener noreferrer" target="_blank">New Hampshire</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2009-2010/2009-10-Estimations-Tennessee.xls" rel="noopener noreferrer" target="_blank">Tennessee</a>
    </div>
  </div>
  <div class="columns is-gapless is-multiline">
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2009-2010/2009-10-Estimations-California.xls" rel="noopener noreferrer" target="_blank">California</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2009-2010/2009-10-Estimations-Kentucky.xls" rel="noopener noreferrer" target="_blank">Kentucky</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2009-2010/2009-10-Estimations-NewJersey.xls" rel="noopener noreferrer" target="_blank">New Jersey</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2009-2010/2009-10-Estimations-Texas.xls" rel="noopener noreferrer" target="_blank">Texas</a>
    </div>
  </div>
  <div class="columns is-gapless is-multiline">
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2009-2010/2009-10-Estimations-Colorado.xls" rel="noopener noreferrer" target="_blank">Colorado</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2009-2010/2009-10-Estimations-Louisiana.xls" rel="noopener noreferrer" target="_blank">Louisiana</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2009-2010/2009-10-Estimations-NewMexico.xls" rel="noopener noreferrer" target="_blank">New Mexico</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2009-2010/2009-10-Estimations-Utah.xls" rel="noopener noreferrer" target="_blank">Utah</a>
    </div>
  </div>
  <div class="columns is-gapless is-multiline">
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2009-2010/2009-10-Estimations-Connecticut.xls" rel="noopener noreferrer" target="_blank">Connecticut</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2009-2010/2009-10-Estimations-Maine.xls" rel="noopener noreferrer" target="_blank">Maine</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2009-2010/2009-10-Estimations-NewYork.xls" rel="noopener noreferrer" target="_blank">New York</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2009-2010/2009-10-Estimations-Vermont.xls" rel="noopener noreferrer" target="_blank">Vermont</a>
    </div>
  </div>
  <div class="columns is-gapless is-multiline">
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2009-2010/2009-10-Estimations-Delaware.xls" rel="noopener noreferrer" target="_blank">Delaware</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2009-2010/2009-10-Estimations-Maryland.xls" rel="noopener noreferrer" target="_blank">Maryland</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2009-2010/2009-10-Estimations-NorthCarolina.xls" rel="noopener noreferrer" target="_blank">North Carolina</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2009-2010/2009-10-Estimations-Virginia.xls" rel="noopener noreferrer" target="_blank">Virginia</a>
    </div>
  </div>
  <div class="columns is-gapless is-multiline">
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2009-2010/2009-10-Estimations-DC.xls" rel="noopener noreferrer" target="_blank">District of Columbia</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2009-2010/2009-10-Estimations-Massachusetts.xls" rel="noopener noreferrer" target="_blank">Massachusetts</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2009-2010/2009-10-Estimations-NorthDakota.xls" rel="noopener noreferrer" target="_blank">North Dakota</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2009-2010/2009-10-Estimations-Washington.xls" rel="noopener noreferrer" target="_blank">Washington</a>
    </div>
  </div>
  <div class="columns is-gapless is-multiline">
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2009-2010/2009-10-Estimations-Florida.xls" rel="noopener noreferrer" target="_blank">Florida</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2009-2010/2009-10-Estimations-Michigan.xls" rel="noopener noreferrer" target="_blank">Michigan</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2009-2010/2009-10-Estimations-Ohio.xls" rel="noopener noreferrer" target="_blank">Ohio</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2009-2010/2009-10-Estimations-WestVirginia.xls" rel="noopener noreferrer" target="_blank">West Virginia</a>
    </div>
  </div>
  <div class="columns is-gapless is-multiline">
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2009-2010/2009-10-Estimations-Georgia.xls" rel="noopener noreferrer" target="_blank">Georgia</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2009-2010/2009-10-Estimations-Minnesota.xls" rel="noopener noreferrer" target="_blank">Minnesota</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2009-2010/2009-10-Estimations-Oklahoma.xls" rel="noopener noreferrer" target="_blank">Oklahoma</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2009-2010/2009-10-Estimations-Wisconsin.xls" rel="noopener noreferrer" target="_blank">Wisconsin</a>
    </div>
  </div>
  <div class="columns is-gapless is-multiline">
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2009-2010/2009-10-Estimations-Hawaii.xls" rel="noopener noreferrer" target="_blank">Hawaii</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2009-2010/2009-10-Estimations-Mississippi.xls" rel="noopener noreferrer" target="_blank">Mississippi</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2009-2010/2009-10-Estimations-Oregon.xls" rel="noopener noreferrer" target="_blank">Oregon</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2009-2010/2009-10-Estimations-Wyoming.xls" rel="noopener noreferrer" target="_blank">Wyoming</a>
    </div>
  </div>
  <div class="columns is-gapless is-multiline">
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2009-2010/2009-10-Estimations-Idaho.xls" rel="noopener noreferrer" target="_blank">Idaho</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2009-2010/2009-10-Estimations-Missouri.xls" rel="noopener noreferrer" target="_blank">Missouri</a>
    </div>
    <div class="column is-one-quarter has-text-centered">
      <a href="assets/downloads/2009-2010/2009-10-Estimations-Pennsylvania.xls" rel="noopener noreferrer" target="_blank">Pennsylvania</a>
    </div>
  </div>
  <document-viewer docType="PDF"></document-viewer>
</div>
