export class SchoolSearchQuery {    
    sch_Address: string; // School Address
    sch_Name: string;    // School Name
    long_Nces_Id: string; // School Id
    lea_Name: string; // District Name
    leaId: string; // District Id
    lea_Id: string;
    sch_Zip: string; // School Zip
    sch_City: string; // School City
    schoolDistance: number;
    survey_Year_Key: number;
    stateKeys: string; // Comma delimited
    schoolGradesOffered: string;
    schoolTitle1: string;
    schoolMagnetSchool: string;
    schoolAltSchool: string;
    schoolJuvenileJusticeSchool: string;
    schoolCharterSchool: string;
    schoolStudentToTeacherLtGt: string;
    schoolStudentToTeacher: string;
    schoolFirstYearTeachersLtGt: string;
    schoolFirstYearTeachers: string;
    schoolCertifiedTeachersLtGt: string;
    schoolCertifiedTeachers: string;
    schoolGTProgram: string;
    schoolALG1Program: string;
    schoolBIOProgram: string;
    schoolGEOProgram: string;
    schoolCHEProgram: string;
    schoolALG2Program: string;
    schoolPHIProgram: string;
    schoolAMSProgram: string;
    schoolAPProgram: string;
    schoolCalcProgram: string;
    schoolCriteria: string;
    schoolLessMoreThan: string;
    schoolQuantity: string;
    schoolCriteriaAdvanced: string;
    schoolLessMoreThanAdvanced: string;
    schoolQuantityAdvanced: string;
}
