import { Injectable } from "@angular/core";

export const TOAST_TYPE = {
    ERROR: 'error',
    WARNING: 'warning',
    INFO: 'info',
    SUCCESS: 'success'
};

@Injectable({
    providedIn: "root",
})

// Also see ToastsContainer (toasts-container.component.ts)
export class ToastService {
    toasts: any[] = [];
    otherErrorMessages: string[] = [];

    error(message: string, header: string = "Error", delay:number = 8000) {
        this.toasts.push({ header: header, body: message, 'type': TOAST_TYPE.ERROR, delay: delay });
    }

    warning(body: string, header: string = "Warning", delay:number = 6000) {
        this.toasts.push({ header, body, 'type': TOAST_TYPE.WARNING, delay: delay });
    }

    info(body: string, header: string = "Info", delay:number = 6000) {
        this.toasts.push({ header, body, 'type': TOAST_TYPE.INFO, delay: delay });
    }

    success(body: string, header: string = "Success", delay:number = 3000) {
        this.toasts.push({ header, body, 'type': TOAST_TYPE.SUCCESS, delay: delay });
    }

    // see toastcontainer
    remove(toast) {
        this.toasts = this.toasts.filter((t) => t !== toast);
    }

    // type can be inf, waring or error - see TOAST_TYPE
    clearAllToastMessages(type: string = null){

        if (type){
            this.toasts = this.toasts.filter((t) => t.type !== type);
        }
        else {
            this.toasts = [];
        }

    }

    getOtherErrorMessages(): string[] {
        return this.otherErrorMessages;
    }

    clearOtherErrorMessages(): void {
        this.otherErrorMessages = [];
    }

    addOtherErrorMessages(errorMessage: string): void {
        if (!this.otherErrorMessages) {
            this.otherErrorMessages = [];
        }

        this.otherErrorMessages.push(errorMessage);
    }
}
