import {Component, Input, OnInit} from '@angular/core';
import { environment } from '@environments/environment';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

    @Input() isLoggedIn: boolean;

    lastUpdatedDate = 'October 22, 2024';
    production: boolean = environment.production;

    //isLoggedIn: boolean = false;

    constructor() {
    }

    ngOnInit(): void {
    }

    open(url: string) {
        window.open(url, '_blank');
    }

}
