<div class="search is-flex is-flex-direction-row">
<!--    <ng-select id="myNgSelect" name="Navbar search" class="custom" [items]="entities" bindLabel="entity_Name" bindValue="entity_ID"-->
<!--        groupBy="entity_Type" [clearable]="false" placeholder="{{ placeholder }}"-->
<!--        [(ngModel)]="selectedEntity" (keyup)="onChange($event)" [isOpen]="entities.length > 0 && ngSelectComponent.focused" (ngModelChange)="OnSearch()">-->
<!--        <ng-template ng-optgroup-tmp let-item="item">-->
<!--            {{item.entity_Type || 'Unnamed group'}}-->
<!--        </ng-template>-->
<!--    </ng-select>-->
<!--    <div class="search-button-container">-->
<!--        <button class="search-button" title="{{ placeholder }}" mat-icon-button (click)="OnSearch()">-->
<!--            <mat-icon [ngClass]="{'search-button-icon': !entityType, 'data-tool-search-button-icon': entityType}" matSuffix>search</mat-icon>-->
<!--        </button>-->
<!--    </div>-->

    <ng-select id="myNgSelect2" name="Navbar search" bindLabel="entity_Name" bindValue="entity_ID" class="custom"
               [items]="entities$ | async" [typeahead]="entitiesInput$" [minTermLength]="3" [loading]="entitiesLoading" [notFoundText]="'No entities found'"
               typeToSearchText="Please enter 3 or more characters" placeholder="{{ placeholder }}"
               groupBy="entity_Type" [clearable]="false"
               [(ngModel)]="selectedEntity" (ngModelChange)="loadEntityDetails()">
        <ng-template ng-optgroup-tmp let-item="item">
            {{item.entity_Type || 'Unnamed group'}}
        </ng-template>
    </ng-select>

</div>
