<app-hero heroHeader="Civil Rights In Education" heroSubHeader="CRDC Overview"
    heroDescription="The CRDC is a longstanding and important aspect of the U.S. Department of Education Office for Civil Rights' overall strategy for administering and enforcing civil rights laws that prohibit discrimination based on race, color, national origin, sex, disability, and age by schools, school districts and other entities that received Federal financial assistance from the Department."
    heroImageSrc="assets/images/about_hero.jpg"
    heroImageAlt="group of children sitting at a table eating lunch and smiling">
</app-hero>
<div class="primary-content-container">
    <div class="primary-content is-flex is-justify-content-center">
        <div class="tile is-ancestor">
            <div class="tile is-parent is-6">
                <div class="tile is-child"
                    [ngStyle]="screenType === ScreenType.MOBILE && { 'display': 'flex', 'justify-content' : 'center'}">
                    <img src="assets/images/tertiary_about_image.png" class="rounded-corners"
                        alt="a classroom with two young students working on art assignments">
                </div>
            </div>
            <div class="tile is-parent is-6">
                <article class="tile is-child">
                    <h4 class="primary-content-header has-text-primary">Civil Rights in Education: About the Civil
                        Rights Data Collection</h4>
                    <p class="large-body has-text-primary">Since 1968, OCR has collected civil rights data related
                        to
                        students' access and barriers to educational opportunity from early childhood through grade
                        12. These data are collected from all public schools and districts, as well as long-term
                        secure justice facilities, charter schools, alternative schools, and special
                        education schools that focus primarily on serving the educational needs of students with
                        disabilities under IDEA or section 504 of the Rehabilitation Act.<br><br>The CRDC collects
                        information about student enrollment; access to courses, programs and school staff; and
                        school climate factors, such as bullying, harassment and student discipline. Most data
                        collected by the CRDC are disaggregated by race, ethnicity, sex, disability, and English
                        Learners.<br><br>Originally known as the Elementary and Secondary School Civil Rights
                        Survey, OCR began by collecting data every year from 1968 to 1974 from a sample of school
                        districts and their schools. Over time, the schedule and approach to data collection has
                        changed. Since the 2011-12 collection, the CRDC has been administered every two years to all
                        public school districts and schools in the 50 states and Washington, D.C., and OCR added the
                        Commonwealth of Puerto Rico for the 2017-18 CRDC. Due to the COVID-19 pandemic that resulted
                        in school closures nationwide, OCR postponed the 2019-20 CRDC and instead collected data
                        from the 2020-21 school year.
                    </p>
                </article>
            </div>
        </div>
    </div>
    <div class="timeline-start"></div>
</div>
<div class="history-content-container">
    <div class="timeline-segment"></div>
    <div class="history-content">
        <div class="timeline is-flex is-flex-direction-column">
            <div class="year-marker is-flex is-align-self-center is-justify-content-center">
                <h6 class="has-text-primary is-align-self-center">1964</h6>
            </div>
            <div class="history-fact is-flex is-justify-content-space-between"
                [ngStyle]="screenType !== ScreenType.DESKTOP && {'flex-direction' : 'column-reverse'}">
                <div class="factoid is-align-self-center is-flex is-flex-direction-column is-justify-content-left">
                    <h4 class="has-text-primary">Civil Rights Act of 1964</h4>
                    <hr class="factoid-divider">
                    <p class="large-body has-text-primary">
                        On July 2, 1964, President Lyndon B. Johnson signs the Civil Rights Act of 1964 into law.
                        <a href="https://www2.ed.gov/about/offices/list/ocr/raceoverview.html" target="_blank"
                            rel="noreferrer noopener"><u>Title VI</u></a>
                        of that law prohibits discrimination on the basis of race, color, or national origin.
                    </p>
                </div>
                <div class="right-hand-image">
                    <img src="assets/images/history_1.png" class="rounded-corners"
                        alt="President Lyndon B. Johnson signing the Civil Rights Act of 1964 into law with notable people witnessing">
                </div>

            </div>
            <div class="year-marker is-flex is-align-self-center is-justify-content-center">
                <h6 class="has-text-primary is-align-self-center">1968</h6>
            </div>
            <div class="history-fact is-flex is-justify-content-space-between"
                [ngStyle]="screenType !== ScreenType.DESKTOP && {'flex-direction' : 'column'}">
                <div class="left-hand-image">
                    <img src="assets/images/history_2.jpg" class="rounded-corners"
                        alt="empty classroom with rows of empty chairs and desks">
                </div>
                <div class="factoid is-align-self-center is-flex is-flex-direction-column is-justify-content-left">
                    <h4 class="has-text-primary">Elementary and Secondary School Civil Rights Survey</h4>
                    <hr class="factoid-divider">
                    <p class="large-body has-text-primary">
                        Following the passage of the Civil Rights Act of 1964, the U.S. Department of Health,
                        Education, and Welfare (now known as the U.S. Department of Education) conducted the first
                        Elementary and Secondary School Civil Rights Survey in the fall of 1968 to collect data on
                        key education and civil rights issues in our nation's public schools from a sample of
                        schools and districts.
                    </p>
                </div>
            </div>
            <div class="year-marker is-flex is-align-self-center is-justify-content-center">
                <h6 class="has-text-primary is-align-self-center">1970s</h6>
            </div>
            <div class="history-fact is-flex is-justify-content-space-between"
                [ngStyle]="screenType !== ScreenType.DESKTOP && {'flex-direction' : 'column-reverse'}">
                <div class="factoid is-align-self-center is-flex is-flex-direction-column is-justify-content-left">
                    <h4 class="has-text-primary">Title IX and Section 504</h4>
                    <hr class="factoid-divider">
                    <p class="large-body has-text-primary">
                        In the 1970s, Congress passed two key laws to prohibit discrimination on the basis of sex
                        (Title IX of the Education Amendments of 1972) and disability (Section 504 of the
                        Rehabilitation
                        Act of 1973).
                    </p>
                </div>
                <div class="right-hand-image">
                    <img src="assets/images/history_3.jpg" class="rounded-corners"
                        alt="a diverse group of students and a teacher gathered together cheering and clapping">
                </div>
            </div>
            <div class="year-marker is-flex is-align-self-center is-justify-content-center">
                <h6 class="has-text-primary is-align-self-center">1976</h6>
            </div>
            <div class="history-fact is-flex is-justify-content-space-between"
                [ngStyle]="screenType !== ScreenType.DESKTOP && {'flex-direction' : 'column'}">
                <div class="left-hand-image is-hidden-mobile"></div>
                <div class="factoid is-align-self-center is-flex is-flex-direction-column is-justify-content-left">
                    <h4 class="has-text-primary">First Nationwide Collection</h4>
                    <hr class="factoid-divider">
                    <p class="large-body has-text-primary">
                        For the first time, instead of a sample of data, the U.S. Department of Health, Education,
                        and Welfare (now known as the U.S. Department of Education) collected civil rights data from
                        all public school districts and public schools in the 50 states and Washington, D.C.
                    </p>
                </div>
            </div>
            <div class="year-marker is-flex is-align-self-center is-justify-content-center">
                <h6 class="has-text-primary is-align-self-center">1980</h6>
            </div>
            <div class="history-fact is-flex is-justify-content-space-between"
                [ngStyle]="screenType !== ScreenType.DESKTOP && {'flex-direction' : 'column-reverse'}">
                <div class="factoid is-align-self-center is-flex is-flex-direction-column is-justify-content-left">
                    <h4 class="has-text-primary">Move to U.S. Department of Education</h4>
                    <hr class="factoid-divider">
                    <p class="large-body has-text-primary">
                        The Elementary and Secondary School Civil Rights Survey moved to the Office for Civil Rights
                        within the newly created U.S. Department of Education.
                    </p>
                </div>
                <div class="right-hand-image">
                    <img src="assets/images/history_5.jpg" class="rounded-corners"
                        alt="the exterior view of the U.S. Department of Education Federal Building 6 in Washington, D.C.">
                </div>
            </div>
            <div class="year-marker is-flex is-align-self-center is-justify-content-center">
                <h6 class="has-text-primary is-align-self-center">1990</h6>
            </div>
            <div class="history-fact is-flex is-justify-content-space-between"
                [ngStyle]="screenType !== ScreenType.DESKTOP && {'flex-direction' : 'column'}">
                <div class="left-hand-image">
                    <img src="assets/images/history_6.png" class="rounded-corners"
                        alt="A classroom with children looking foward and one child in a wheelchair smiling">
                </div>
                <div class="factoid is-align-self-center is-flex is-flex-direction-column is-justify-content-left">
                    <h4 class="has-text-primary">The Americans with Disabilities Act (ADA)</h4>
                    <hr class="factoid-divider">
                    <p class="large-body has-text-primary">
                        Congress passed the ADA in 1990, which prohibits disability discrimination. Title II of the
                        ADA prohibits discrimination based on disability by public entities, whether or not they
                        receive Federal financial assistance.
                    </p>
                </div>
            </div>
            <div class="year-marker is-flex is-align-self-center is-justify-content-center">
                <h6 class="has-text-primary is-align-self-center">2000</h6>
            </div>
            <div class="history-fact is-flex is-justify-content-space-between"
                [ngStyle]="screenType !== ScreenType.DESKTOP && {'flex-direction' : 'column-reverse'}">
                <div class="factoid is-align-self-center is-flex is-flex-direction-column is-justify-content-left">
                    <h4 class="has-text-primary">Second Nationwide Collection</h4>
                    <hr class="factoid-divider">
                    <p class="large-body has-text-primary">
                        OCR administered the second collection of civil rights data from all public school districts
                        and public schools in 50 states and Washington, D.C.
                    </p>
                </div>
                <div class="right-hand-image is-hidden-mobile"></div>
            </div>
            <div class="year-marker is-flex is-align-self-center is-justify-content-center">
                <h6 class="has-text-primary is-align-self-center">2004</h6>
            </div>
            <div class="history-fact is-flex is-justify-content-space-between"
                [ngStyle]="screenType !== ScreenType.DESKTOP && {'flex-direction' : 'column'}">
                <div class="left-hand-image">
                    <img src="assets/images/history_8.jpg" class="rounded-corners"
                        alt="a desk with an open laptop displaying an online survey">
                </div>
                <div class="factoid is-align-self-center is-flex is-flex-direction-column is-justify-content-left">
                    <h4 class="has-text-primary">Civil Rights Data Collection</h4>
                    <hr class="factoid-divider">
                    <p class="large-body has-text-primary">
                        OCR changed the name of the collection from the Elementary and Secondary School Civil Rights
                        Survey to the Civil Rights Data Collection. The CRDC transitioned from a paper survey to an
                        online collection.
                    </p>
                </div>
            </div>
            <div class="year-marker is-flex is-align-self-center is-justify-content-center">
                <h6 class="has-text-primary is-align-self-center">2011</h6>
            </div>
            <div class="history-fact is-flex is-justify-content-space-between"
                [ngStyle]="screenType !== ScreenType.DESKTOP && {'flex-direction' : 'column-reverse'}">
                <div class="factoid is-align-self-center is-flex is-flex-direction-column is-justify-content-left">
                    <h4 class="has-text-primary">Third Nationwide Collection</h4>
                    <hr class="factoid-divider">
                    <p class="large-body has-text-primary">
                        The CRDC permanently changed from a sample collection to a universe collection of all public
                        school districts and schools. Following the 2009-10 CRDC, which collected data on a sample
                        of approximately 7,000 public school districts and over 72,000 public schools, the CRDC
                        began collecting data from all public school districts and public schools in the 50 states
                        and Washington, D.C.
                    </p>
                </div>
                <div class="right-hand-image is-hidden-mobile"></div>
            </div>
            <div class="year-marker is-flex is-align-self-center is-justify-content-center">
                <h6 class="has-text-primary is-align-self-center">2017</h6>
            </div>
            <div class="history-fact is-flex is-justify-content-space-between"
                [ngStyle]="screenType !== ScreenType.DESKTOP && {'flex-direction' : 'column'}">
                <div class="left-hand-image">
                    <img src="assets/images/history_10.jpg" class="rounded-corners"
                        alt="a classroom with a Puerto Rico flag hanging on the wall">
                </div>
                <div class="factoid is-align-self-center is-flex is-flex-direction-column is-justify-content-left">
                    <h4 class="has-text-primary">Puerto Rico</h4>
                    <hr class="factoid-divider">
                    <p class="large-body has-text-primary">
                        OCR added the Commonwealth of Puerto Rico to the CRDC.
                    </p>
                </div>
            </div>
            <div class="year-marker is-flex is-align-self-center is-justify-content-center">
                <h6 class="has-text-primary is-align-self-center">2019</h6>
            </div>
            <div class="history-fact is-flex is-justify-content-space-between"
                [ngStyle]="screenType !== ScreenType.DESKTOP && {'flex-direction' : 'column-reverse'}">
                <div class="factoid is-align-self-center is-flex is-flex-direction-column is-justify-content-left">
                    <h4 class="has-text-primary">Collection Postponed</h4>
                    <hr class="factoid-divider">
                    <p class="large-body has-text-primary">
                        OCR postponed the CRDC for the 2019-20 school year in response to the global COVID-19
                        pandemic.
                    </p>
                </div>
                <div class="right-hand-image">
                    <img src="assets/images/history_11.jpg" class="rounded-corners"
                        alt="a student watching a school lesson on a laptop">
                </div>
            </div>
            <div class="year-marker is-flex is-align-self-center is-justify-content-center">
                <h6 class="has-text-primary is-align-self-center">2021</h6>
            </div>
            <div class="last-fact is-flex is-justify-content-space-between"
                [ngStyle]="screenType !== ScreenType.DESKTOP && {'flex-direction' : 'column'}">
                <div class="left-hand-image">
                    <img src="assets/images/history_12.jpg" class="rounded-corners"
                        alt="classroom full of students and a teacher, all wearing N-95 masks">
                </div>
                <div class="factoid is-align-self-center is-flex is-flex-direction-column is-justify-content-left">
                    <h4 class="has-text-primary">Collection Resumes</h4>
                    <hr class="factoid-divider">
                    <p class="large-body has-text-primary">
                        OCR administered the CRDC for the 2020-21 school year.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="timeline-segment"></div>
</div>
<div class="timeline-end-container">
    <div class="timeline-end"></div>
</div>
<div cdkScrollable class="map-infographic is-flex is-flex-direction-column">
    <h6 class="today-text has-text-primary has-text-centered">Present Day</h6>
    <div class="info-container is-flex">
        <div class="map-column-left is-flex is-flex-direction-column is-justify-content-center">
            <h4 id="mapFact" class="has-text-black has-text-centered map-fact">In 2020-21, CRDC Collected
                Data From...</h4>
            <img *ngIf="screenType === ScreenType.MOBILE" src="assets/images/map.png"
                alt="Map of US states and Puerto Rico" class="map">
            <h4 id="stateMapFact" class="has-text-black has-text-centered map-fact">All 50 States, DC, and
                Puerto Rico</h4>
            <img *ngIf="screenType === ScreenType.MOBILE" src="assets/images/map_states.png"
                alt="Map of US states and Puerto Rico with stars overlayed each state and Puerto Rico" class="map">
            <h4 id="agencyMapFact" class="has-text-black has-text-centered map-fact">17,821 Local
                Educational Agencies</h4>
            <img *ngIf="screenType === ScreenType.MOBILE" src="assets/images/map_education_agencies.png"
                alt="Map of US states and Puerto Rico with teal dots spread throughout representing local educational agencies"
                class="map">
            <h4 id="schoolMapFact" class="has-text-black has-text-centered last-map-fact">97,575 Schools
            </h4>
            <img *ngIf="screenType === ScreenType.MOBILE" src="assets/images/map_schools.png"
                alt="Map of US states and Puerto Rico with yellow dots spread throughout representing schools"
                class="map">
        </div>
        <div *ngIf="screenType !== ScreenType.MOBILE" class="map-column-right" [ngSwitch]="mapState">
            <img *ngSwitchCase="'map'" src="assets/images/map.png" class="map">
            <img *ngSwitchCase="'stateMap'" src="assets/images/map_states.png" class="map">
            <img *ngSwitchCase="'agencyMap'" src="assets/images/map_education_agencies.png" class="map">
            <img *ngSwitchCase="'schoolMap'" src="assets/images/map_schools.png" class="map">
        </div>
    </div>
</div>
