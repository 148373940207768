<div *ngIf="loadedMeasures === 6" class="quick-stats">
    <div class="quick-stats-container is-flex is-flex-direction-column is-align-items-center">
        <h2 class="large-body has-text-primary quick-stats-text">Quick Stats</h2>
        <div class="grid-container">
            <div *ngFor="let measure of formattedMeasures"
                class="quick-stats-element is-flex is-flex-direction-column is-align-items-center"
                [tooltip]="measure.toolTipVerb" tabindex="0" [attr.aria-labelledby]="replaceSpaces(measure.measure) + ' ' + replaceSpaces(measure.title) + ' tooltip-popup'">
                <p [id]="replaceSpaces(measure.measure)" class="has-text-primary h1-text">{{ measure.measure }}</p>
                <p [id]="replaceSpaces(measure.title)" class="has-text-primary has-text-centered h5-text">{{ measure.title }}</p>
            </div>
        </div>
        <div class="quick-stats-footer is-flex is-flex-direction-row is-flex-wrap-wrap is-align-self-flex-start">
            <a class="small-body has-text-primary" href="assets/downloads/2020-21 User's Manual.pdf" target="_blank"
                rel="noreferrer noopener">— Not available.</a>
            <a class="small-body has-text-primary"
                href="https://www2.ed.gov/about/offices/list/ocr/docs/crdc-quality-informational-snapshot.pdf"
                target="_blank" rel="noreferrer noopener">ǂ Data quality standards not met.</a>
        </div>
    </div>
</div>
