import { Directive, ElementRef, HostListener, Input, OnDestroy } from '@angular/core';

@Directive({
  selector: '[tooltip]'
})
// This directive is used from a medium article by Colin Broberg
export class TooltipDirective implements OnDestroy {

  @Input() tooltip = ''; // The text for the tooltip to display
  @Input() delay? = 0; // Optional delay input, in ms

  private myPopup;
  private isOpen: boolean = false;
  private timer;

  constructor(private el: ElementRef) { }

  ngOnDestroy(): void {
    if (this.myPopup) { this.myPopup.remove() }
  }

  @HostListener('mouseenter') onMouseEnter() {
    this.timer = setTimeout(() => {
      let currentElement: HTMLElement = this.el.nativeElement;
      let x: number = currentElement.getBoundingClientRect().left + currentElement.offsetWidth / 2; // Get the middle of the element
      let y: number = currentElement.getBoundingClientRect().top + currentElement.offsetHeight + 6; // Get the bottom of the element, plus a little extra

      if (this.el.nativeElement.coords) {
        let parentElement: any = currentElement.parentElement;
        let name: string = parentElement.name;
        let imageElement: HTMLElement = document.getElementById(name);

        if(imageElement) {
          let imgOffsetWidth: number = imageElement.getBoundingClientRect().left;
          let imgOffsetHeight: number = imageElement.getBoundingClientRect().top;
          let coords: string[] = this.el.nativeElement.coords.split(',');

          x = imgOffsetWidth + parseInt(coords[0]);
          y = imgOffsetHeight + parseInt(coords[1]) + parseInt(coords[2]);
        }
      }

      // check if the tooltip width would go over the right 
      x = x + 111 > window.innerWidth ? window.innerWidth - 111 : x;
      x = x - 111 < 0 ? 111 : x;

      if (this.tooltip.length > 0 && !this.isOpen) {
        this.createTooltipPopup(x, y);
      }
    }, this.delay)
  }

  @HostListener('focus') onFocus() {
    this.timer = setTimeout(() => {
      let currentElement: HTMLElement = this.el.nativeElement;
      let x: number = currentElement.getBoundingClientRect().left + currentElement.offsetWidth / 2; // Get the middle of the element
      let y: number = currentElement.getBoundingClientRect().top + currentElement.offsetHeight + 6; // Get the bottom of the element, plus a little extra

      if (this.el.nativeElement.coords) {
        let parentElement: any = currentElement.parentElement;
        let name: string = parentElement.name;
        let imageElement: HTMLElement = document.getElementById(name);

        if(imageElement) {
          let imgOffsetWidth: number = imageElement.getBoundingClientRect().left;
          let imgOffsetHeight: number = imageElement.getBoundingClientRect().top;
          let coords: string[] = this.el.nativeElement.coords.split(',');

          x = imgOffsetWidth + parseInt(coords[0]);
          y = imgOffsetHeight + parseInt(coords[1]) + parseInt(coords[2]);
        }
      }

      // check if the tooltip width would go over the right 
      x = x + 111 > window.innerWidth ? window.innerWidth - 111 : x;
      x = x - 111 < 0 ? 111 : x;

      if (this.tooltip.length > 0 && !this.isOpen) {
        this.createTooltipPopup(x, y);
      }
    }, this.delay)
  }

  @HostListener('mouseleave') onMouseLeave() {
    if (this.timer) clearTimeout(this.timer);
    if (this.myPopup) { this.myPopup.remove() }
    this.isOpen = false;
  }

  @HostListener('blur') onBlur() {
    if (this.timer) clearTimeout(this.timer);
    if (this.myPopup) { this.myPopup.remove() }
    this.isOpen = false;
  }

  private createTooltipPopup(x: number, y: number) {
    let popup = document.createElement('div');
    popup.innerHTML = this.tooltip;
    popup.setAttribute("id", "tooltip-popup");
    popup.setAttribute("class", "tooltip-container");
    popup.style.top = y.toString() + "px";
    popup.style.left = x.toString() + "px";
    document.body.appendChild(popup);
    this.myPopup = popup;
    this.isOpen = true;
    // setTimeout(() => {
    //   if (this.myPopup) this.myPopup.remove();
    // }, 5000); // Remove tooltip after 5 seconds
  }

}
