import { AfterViewInit, Component, Inject, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-chart-data-card',
  templateUrl: './chart-data-card.component.html',
  styleUrls: ['./chart-data-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChartDataCardComponent implements OnInit, AfterViewInit {

  constructor(private bottomSheetRef: MatBottomSheetRef<ChartDataCardComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private renderer: Renderer2) {

  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.renderer.listen(window, 'click', (e: Event) => {
        this.close();
      });
    }, 10);

  }

  close(): void {
    this.bottomSheetRef.dismiss();
  }

}
