import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MeasureService } from '../../../shared/services/data/measure.service';
import { EntityType, ProfileBlockDto, ProfileBlockTypes } from '../../models/profile-model';
import { EntityMeasuresQuery } from '../../queries/entity-measures-query';
import { ProfileService } from '../../services/helpers/profile.service';

@Component({
  selector: 'app-profile-page',
  templateUrl: './profile-page.component.html',
  styleUrls: ['./profile-page.component.scss']
})
export class ProfilePageComponent implements OnInit {

  @Input() entityId?: number;
  @Input() surveyYearKey: number;
  @Input() entityType: EntityType;

  @Output() surveyYearUpdate: EventEmitter<number> = new EventEmitter<number>();

  public blocksLoaded: boolean = false;
  public profileBlocks: ProfileBlockDto[] = [];
  public sections: any;
  private emq: EntityMeasuresQuery = new EntityMeasuresQuery();

  constructor(
    private readonly profileService: ProfileService
  ) { }

  async ngOnInit() {
    this.profileService.setEntityId(this.entityId);
    await this.profileService.loadMeasures(this.entityType, this.surveyYearKey);

    this.sections = this.profileService.getMeasures();

    if (this.sections === undefined) {
      this.profileService.routeTo404();
      return;
    }

    this.sections.forEach((section, i) => {
      const blockType = this.profileService.convertToProfileBlockEnum(section.title);
      this.generateProfileBlock(blockType, i);
    });

    this.blocksLoaded = true;
  }

  generateProfileBlock(blockType: ProfileBlockTypes, i: number): void {
    if (this.sections[i]) {
      let profileBlock: ProfileBlockDto = {
        blockType: blockType,
        section: this.sections[i],
        entityId: this.entityId,
        surveyYear: this.surveyYearKey,
        entityType: this.entityType
      };

      this.profileBlocks.push(profileBlock);
    }
  }

  updateSurveyYear(year: number) {
    this.surveyYearUpdate.emit(year);
  }

  onViewSchools() {
    let findNearby = document.getElementById("find-nearby");
    findNearby.scrollIntoView();
  }

}
