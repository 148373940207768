import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/shared/modules/material.module';
import { AboutFaqComponent } from './about-faq/about-faq.component';
import { AboutRoutingModule } from './about-routing.module';
import { SharedModule } from 'src/app/shared/modules/shared.module';
import { AboutMainComponent } from './about-main/about-main.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { AboutOcrComponent } from './about-ocr/about-ocr.component';

@NgModule({
  declarations: [
    AboutMainComponent,
    AboutFaqComponent,
    AboutOcrComponent,
  ],
  exports: [
  ],
  imports: [
    AboutRoutingModule,
    SharedModule,
    CommonModule,
    MaterialModule,
    ScrollingModule
  ]
})
export class AboutModule { }
