import {NGXLogger} from 'ngx-logger';
import {ToastService} from '@shared/components/toast/toast.service';
import {Injectable} from '@angular/core';

import {environment} from '@environments/environment';
import {HttpClient} from '@angular/common/http';
import {AuthorizationCodeResponse} from '@core/auth/models/authorization-code-response.model';
import {map} from 'rxjs/operators';
import {AuthConfig, OAuthService} from 'angular-oauth2-oidc';
import {Observable} from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class TokenService {

    tokenInitializedCompleted: boolean = false;
    homeUrl: string = window.location.origin;

    oAuthConfig: AuthConfig;

    googleOAuthConfig: AuthConfig = {
        issuer: 'https://accounts.google.com',
        strictDiscoveryDocumentValidation: false,
        redirectUri: window.location.origin + '/data-tool',
        clientId: '626915756760-q2h7vt028l1h9ckd3e71s70o8a7lias1.apps.googleusercontent.com',
        scope: 'openid profile email',  //offline_access  offline
        //responseType: 'code',    // for use with code authorization
        //state: 'aaaa',
        logoutUrl: window.location.origin,  // + '/data-tool-login-landing', // The logout url
        //showDebugInformation: true,
    };

    icamOAuthConfig: AuthConfig = {
        issuer: environment.icamIssuer,    //'https://edgovb2ctest.b2clogin.com/edgovb2ctest.onmicrosoft.com/b2c_1a_susi_ent_ial1_aal2_oidc/v2.0',
        redirectUri: window.location.origin + '/data-tool', //'https://crd-reporting-test.aem-tx.com/data-tool/',
        clientId: environment.icamClientId,
        scope: 'openid offline_access',  //offline_access to include an access_token add the clientId as well: 50983521-4ad5-48c6-a6f7-eb68c55b9f73
        //responseType: 'code',    // for use with code authorization
        logoutUrl: window.location.origin,  // + '/',  // + '/data-tool-login-landing', // The logout url
        showDebugInformation: true,
        strictDiscoveryDocumentValidation: false,
        skipIssuerCheck: true

    };

    constructor(
        private logger: NGXLogger,
        private http: HttpClient,
        private readonly oAuthService: OAuthService,
    ){

        this.logger.debug('Inside TokenService constructor, oAuthConfig: ', this.oAuthConfig);

        if (environment.useAuthorizationCodeFlow){

            if (environment.authorizationIssuer === 'icam'){
                this.oAuthConfig = this.icamOAuthConfig;
            }
            else {
                this.oAuthConfig = this.googleOAuthConfig;
            }

            this.oAuthConfig.responseType = 'code';

        }

    }

    initializeAuthTokenService(force: boolean = false){

        this.logger.debug('Inside initializeAuthTokenService');

        if (!this.tokenInitializedCompleted || force){

            this.logger.debug('load config');
            this.tokenInitializedCompleted = true;
            this.oAuthService.configure(this.oAuthConfig);

            this.oAuthService.loadDiscoveryDocument();
            //this.oAuthService.loadDiscoveryDocumentAndLogin();
            //this.oAuthService.loadDiscoveryDocumentAndTryLogin();
        }

    }

    getRedirectUri(){
        this.logger.debug('Inside getRedirectUri');
        return this.oAuthConfig.redirectUri;
    }

    getHomeUrl(){
        this.logger.debug('Inside getHomeUrl');
        return this.homeUrl;
    }

    requestTokens(){

        this.logger.debug('Inside requestTokens');

        this.initializeAuthTokenService();

        if (environment.useAuthorizationCodeFlow) {

            //this.oAuthService.initLoginFlow();

            this.oAuthService.initCodeFlow();

        }
        // implicit flow
        else {

            //this.oAuthService.initLoginFlow();

            this.oAuthService.tryLoginImplicitFlow().then(() => {
                this.logger.debug('try login implicit flow');

                if (!this.oAuthService.hasValidAccessToken()) {
                    this.logger.debug('init login flow');

                    this.oAuthService.initLoginFlow();
                } else {
                    this.oAuthService.loadUserProfile().then((userProfile) => {
                        this.logger.debug('user profile: ', userProfile);
                    });
                }
            });
        }
    }

    isAuthenticated(){

        this.logger.debug('Inside isAuthenticated, useAuthorizationCodeFlow: ', environment.useAuthorizationCodeFlow);

        return new Observable<boolean>((observer) => {

            if (environment.useAuthorizationCodeFlow){

                //TODO: we have to check expiration
                observer.next(true);
                observer.complete();

                // return new Promise((resolve) => {
                //
                //     this.logger.debug('Pretend to be logged in');
                //     this.setIsLoggedIn(true);
                //     resolve(true);
                //
                // });
            }
            else {

                this.logger.debug('--tryLoginImplicitFlow', this.oAuthService.hasValidAccessToken());
                this.oAuthService.tryLoginImplicitFlow().then(() => {
                    this.logger.debug('tryLoginImplicitFlow: getIsAuthenticated', this.oAuthService.hasValidAccessToken());

                    const isAuthenticated = this.oAuthService.hasValidAccessToken();

                    observer.next(isAuthenticated);
                    observer.complete();

                });

                // observer.next(true);
                // observer.complete();

            }

        });
    }

    /**
     *
     * @param authorizationCode
     * @param codeVerifier is the PKCE_verifier
     */
    authenticateAuthCode(authorizationCode: string, codeVerifier: string){
        this.logger.debug('Inside authenticateAuthCode');

        // for testing only
        //this.setIsLoggedIn(true);
        //this.sessionTimerService.startTimer('123abc', 100);

        // only for testing
        //return of(true);

        // check to see if we are already logged in
        // if (this.hasValidAuthSessionIdCookie()) {
        //     //this.logout();
        //     this.toastService.success('You are already logged in as: ', this.userFullName);
        //     return;
        // }

        const {api_url, authorizationIssuer} = environment;

        //https://crd-reporting-test.aem-tx.com/api/v1.0/auth?code=abc123&code_verifier=123abc
        const url = `${api_url}auth?code=${authorizationCode}&code_verifier=${codeVerifier}&redirect_uri=${this.oAuthConfig.redirectUri}&issuer=${authorizationIssuer}`;  // issuer: icam or google

        // withCredentials: this allows for passing cookies as well. By default, cookies cannot be read coming from the backend without this set to true
        return this.http.get<AuthorizationCodeResponse>(url, { withCredentials: true});

    }

    /**
     * This will rest a users Acceptable Use Policy and Rules of Behavior (AUP/ROB) terms session for a year
     * @param email
     * @param name is the PKCE_verifier
     */
    acceptAUPROBTerms(email: string, name: string){
        this.logger.debug('Inside acceptAUPROBTerms');

        const {api_url} = environment;

        const queryParams = encodeURI(`email=${email}&name=${name}`);

        //https://crd-reporting-test.aem-tx.com/api/v1.0/auth?code=abc123&code_verifier=123abc
        const url = `${api_url}acceptAUPROBTerms?${queryParams}`;  // issuer: icam or google

        // withCredentials: this allows for passing cookies as well. By default, cookies cannot be read coming from the backend without this set to true
        this.http.get<any>(url, { withCredentials: true}).subscribe((response: any) => {

            this.logger.debug('response: ', response);

        }, error => {
            this.logger.error('Error trying to accept terms, error: ', error);
            //this.toastService.error('Unable to logout');
        }, () => {
            this.logger.debug('Done accepting terms');
        });

    }

    logoutImplicitFlow(){
        this.logger.debug('Inside logoutImplicitFlow');

        //If you want to revoke the existing access token and the existing refresh token before logging out, use the following method:
        this.oAuthService.revokeTokenAndLogout();

        //The logOut method clears the used token store (by default sessionStorage) and forwards the user to the auth servers
        // logout endpoint if one was configured (manually or via the discovery document).
        this.oAuthService.logOut();
    }

    revokeAuthenticateToken(logoutEvent:string){
        this.logger.debug('Inside revokeAuthenticateToken');

        if (environment.useAuthorizationCodeFlow){

            const {api_url} = environment;

            //https://crd-reporting-test.aem-tx.com/api/v1.0/auth?code=abc123&code_verifier=123abc
            const url = `${api_url}logout?redirect_url=${this.oAuthConfig.logoutUrl}&logoutEvent=${logoutEvent}`;

            // withCredentials: so we can send cookies
            return this.http.get<any>(url, {withCredentials: true});

        }
        else {

            this.logoutImplicitFlow();

        }

    }

    icamLogout(isLoginDotGovUser: boolean){
        this.logger.debug('Inside icamLogout, isLoginDotGovUser: ', isLoginDotGovUser);

        const postUrl = this.oAuthConfig.logoutUrl;   // https://crd-reporting-test.aem-tx.com this.getHomeUrl();

        // this is for ed.gov user accounts in B2C and B2B
        let logoutUrl = `${environment.icamLogoutUrl}?client_id=${this.oAuthConfig.clientId}`;

        // this is to logout using a login.gov user account in the B2C ICAM setup - only for dev and test
        if (isLoginDotGovUser){
            logoutUrl = environment.icamLoginDotGovLogoutUrl;
        }

        logoutUrl += `&post_logout_redirect_uri=${postUrl}`;    //${this.oAuthConfig.logoutUrl} this.getHomeUrl();
        this.logger.debug('logoutUrl: ', logoutUrl);

        window.location.href = logoutUrl;

    }


}
