import { Component, Input, OnInit } from '@angular/core';
import { EntityType, Measure } from '../../models/profile-model';
import { EnrollmentDataQuery } from '../../queries/enrollment-data-query';
import { MiscFieldDataQuery } from '../../queries/misc-field-data-query';
import { StateNationalEtimationQuery } from '../../queries/state-national-estimation-query';
import { EnrollmentDataService } from '../../services/data/enrollment-data.service';
import { MiscService } from '../../services/data/misc.service';
import { StateNationalEstimationService } from '../../services/data/state-national-estimation.service';

@Component({
  selector: 'app-quick-stats',
  templateUrl: './quick-stats.component.html',
  styleUrls: ['./quick-stats.component.scss']
})
export class QuickStatsComponent implements OnInit {

  @Input() entityId: number;
  @Input() surveyYear: number;
  @Input() entityType: EntityType;

  totEnrollment: number;
  tooltipPopup: string;
  enrollmentMeasures: Measure[];
  nationalEnrollmentMeasures: Measure[];
  loadedMeasures: number;
  formattedMeasures: Measure[];

  constructor(
    private readonly enrollmentDataService: EnrollmentDataService,
    private readonly stateNationalEstimationService: StateNationalEstimationService,
    private readonly miscDataService: MiscService
  ) {
    this.tooltipPopup = 'tooltip-popup';
    this.enrollmentMeasures = [
      { measure: 129, title: 'Students Reported as Harassed/Bullied on Sex Basis', toolTipVerb: 'Number of K-12 students reported as harassed or bullied on the basis of sex.', dMeasure_Format: null },
      { measure: 141, title: 'Students with Disabilities Physically Restrained', toolTipVerb: 'Number of K-12 students with disabilities served under IDEA and subjected to physical restraint.', dMeasure_Format: null },
      { measure: 320, title: 'Students Repeating a Grade', toolTipVerb: 'Percentage of K-12 students who were retained or not promoted to the next grade level prior to the beginning of the following school year.', dMeasure_Format: null },
      { measure: 318, title: "Students Suspended", toolTipVerb: 'Percentage of K-12 students who received one or more out-of-school suspensions.', dMeasure_Format: null },
      { measure: 'TOT_FTE', title: 'Student to Teacher Ratio', toolTipVerb: '', dMeasure_Format: null },
      { measure: 'COUNSEL', title: 'Student to Counselor Ratio', toolTipVerb: '', dMeasure_Format: null }
    ];
    this.nationalEnrollmentMeasures = [
      { measure: 129, title: 'Students Reported as Harassed/Bullied on Sex Basis', toolTipVerb: 'Number of K-12 students reported as harassed or bullied on the basis of sex.', dMeasure_Format: null },
      { measure: 141, title: 'Students with Disabilities Physically Restrained', toolTipVerb: 'Number of K-12 students with disabilities served under IDEA and subjected to physical restraint.', dMeasure_Format: null },
      { measure: 320, title: 'Students Repeating a Grade', toolTipVerb: 'Percentage of K-12 students who were retained or not promoted to the next grade level prior to the beginning of the following school year.', dMeasure_Format: null },
      { measure: 318, title: "Students Suspended", toolTipVerb: 'Percentage of preschool to grade 12 students who received one or more out-of-school suspensions.', dMeasure_Format: null },
      { measure: 'nSCH_FTE_CERT_P', title: 'Percent of Schools with all Teachers Certified', toolTipVerb: 'Percentage of public schools with 100% of their teachers having met all applicable state teacher certification requirements for a standard certificate.', dMeasure_Format: null },
      { measure: 'nSCH_COUNSEL_P', title: 'Percent of Schools with Counselors', toolTipVerb: 'Percentage of public schools with at least one school counselor.', dMeasure_Format: null }
    ];
    this.loadedMeasures = 0;
    this.formattedMeasures = [];
   }

  ngOnInit(): void {
    switch (this.entityType) {
      case EntityType.SCHOOL:
        this.totEnrollment = JSON.parse(sessionStorage.getItem('schoolProfile')).totalEnrollment;
        break;
      case EntityType.DISTRICT:
        this.totEnrollment = JSON.parse(sessionStorage.getItem('districtProfile')).bTotEnrol;
        break;
      case EntityType.STATE:
        this.totEnrollment = JSON.parse(sessionStorage.getItem('stateProfile')).b_Count;
        break;
      case EntityType.NATIONAL:
        this.totEnrollment = JSON.parse(sessionStorage.getItem('nationalProfile')).b_Count;
        break;
    }

    this.getMeasures();
  }

  getMeasures() {
    if (this.entityType === EntityType.NATIONAL) {
      this.nationalEnrollmentMeasures.forEach((measure, i) => {
        this.getStateNationalMeasure(measure, i);
      });
    } else {
      this.enrollmentMeasures.forEach((measure, i) => {
        if (this.entityType === EntityType.SCHOOL || this.entityType === EntityType.DISTRICT) {
          if (typeof measure.measure === 'number') {
            this.getSchoolDistrictMeasure(measure, i);
          } else {
            this.getSchoolDistrictMiscData(measure, i);
          }
        } else {
          this.getStateNationalMeasure(measure, i);
        }
      });
    }
  }

  setSchoolDistrictData(measure: Measure, data: any, index: number) {
    switch (measure.title) {
      case 'Students Reported as Harassed/Bullied on Sex Basis':
      case 'Students with Disabilities Physically Restrained':
        if (this.entityId === 29005 || this.entityId === 57717) {
          let doubleDaggerMeasure: Measure = { measure: 'ǂ', title: measure.title, toolTipVerb: measure.toolTipVerb, dMeasure_Format: null }
          this.formattedMeasures[index] = doubleDaggerMeasure;
        } else if (data.b_TOT >= 0 && data.b_TOT != null) {
          let studentCount: string = data.b_TOT.toLocaleString('en-US');
          let formattedMeasure: Measure = { measure: studentCount, title: measure.title, toolTipVerb: measure.toolTipVerb, dMeasure_Format: null };
          this.formattedMeasures[index] = formattedMeasure;
        } else if (data.b_TOT == -11) {
          let doubleDaggerMeasure: Measure = { measure: 'ǂ', title: measure.title, toolTipVerb: measure.toolTipVerb, dMeasure_Format: null }
          this.formattedMeasures[index] = doubleDaggerMeasure;
        } else {
          let emDashMeasure: Measure = { measure: '—', title: measure.title, toolTipVerb: measure.toolTipVerb, dMeasure_Format: null }
          this.formattedMeasures[index] = emDashMeasure;
        }
        break;
      case 'Students Suspended':
      case 'Students Repeating a Grade':
        if (data.b_TOT >= 0 && this.totEnrollment > 0 && data.b_TOT != null) {
          let studentPercentage: number = (data.b_TOT / this.totEnrollment) * 100;
          let studentPercentageString: string = studentPercentage > 0 && studentPercentage < 1 ? '<1%' : `${Math.round(studentPercentage)}%`;
          let formattedMeasure: Measure = { measure: studentPercentageString, title: measure.title, toolTipVerb: measure.toolTipVerb, dMeasure_Format: null };
          this.formattedMeasures[index] = formattedMeasure;
        } else if (data.b_TOT == -11) {
          let doubleDaggerMeasure: Measure = { measure: 'ǂ', title: measure.title, toolTipVerb: measure.toolTipVerb, dMeasure_Format: null }
          this.formattedMeasures[index] = doubleDaggerMeasure;
        } else {
          let emDashMeasure: Measure = { measure: '—', title: measure.title, toolTipVerb: measure.toolTipVerb, dMeasure_Format: null }
          this.formattedMeasures[index] = emDashMeasure;
        }
        break;
      case 'Student to Teacher Ratio':
      case 'Student to Counselor Ratio':
        let denominator: any = data.value;
        let miscMeasure: Measure = { measure: null, title: measure.title, toolTipVerb: measure.toolTipVerb, dMeasure_Format: null };

        if (denominator == null) {
          miscMeasure.measure = '—';
          this.formattedMeasures[index] = miscMeasure;
        } else if (isNaN(denominator)) {
          miscMeasure.measure = denominator;
          this.formattedMeasures[index] = miscMeasure;
        } else {
          let denominatorValue: number = Number(denominator);

          if (denominatorValue > 0 && this.totEnrollment > 0) {
            miscMeasure.measure = `${Math.round(this.totEnrollment / denominatorValue)}:1`;
            this.formattedMeasures[index] = miscMeasure;
          } else if (denominatorValue == -11) {
            miscMeasure.measure = 'ǂ';
            this.formattedMeasures[index] = miscMeasure;
          } else {
            miscMeasure.measure = '—';
            this.formattedMeasures[index] = miscMeasure;
          }
        }
        break;
    }
  }

  setStateNationalData(measure: Measure, data: any, index?: number) {
    switch (measure.title) {
      case 'Students Reported as Harassed/Bullied on Sex Basis':
      case 'Students with Disabilities Physically Restrained':
        if (this.entityId === 12 || this.entityId === 52) {
          let doubleDaggerMeasure: Measure = { measure: 'ǂ', title: measure.title, toolTipVerb: measure.toolTipVerb, dMeasure_Format: null }
          this.formattedMeasures[index] = doubleDaggerMeasure;
        } else if (data.b_Count >= 0 && this.totEnrollment > 0 && data.b_Count != null) {
          let studentCount: string = data.b_Count.toLocaleString('en-US');
          let formattedMeasure: Measure = { measure: studentCount, title: measure.title, toolTipVerb: measure.toolTipVerb, dMeasure_Format: null };
          this.formattedMeasures[index] = formattedMeasure;
        } else if (data.b_Count == -11) {
          let doubleDaggerMeasure: Measure = { measure: 'ǂ', title: measure.title, toolTipVerb: measure.toolTipVerb, dMeasure_Format: null }
          this.formattedMeasures[index] = doubleDaggerMeasure;
        } else {
          let emDashMeasure: Measure = { measure: '—', title: measure.title, toolTipVerb: measure.toolTipVerb, dMeasure_Format: null }
          this.formattedMeasures[index] = emDashMeasure;
        }
        break;
      case 'Students Suspended':
      case 'Students Repeating a Grade':
        if (data.b_Count >= 0 && this.totEnrollment > 0 && data.b_Count != null) {
          let studentPercentage: number = (data.b_Count / this.totEnrollment) * 100;
          let studentPercentageString: string = studentPercentage > 0 && studentPercentage < 1 ? '<1%' : `${Math.round(studentPercentage)}%`;
          let formattedMeasure: Measure = { measure: studentPercentageString, title: measure.title, toolTipVerb: measure.toolTipVerb, dMeasure_Format: null };
          this.formattedMeasures[index] = formattedMeasure;
        } else if (data.b_Count == -11) {
          let doubleDaggerMeasure: Measure = { measure: 'ǂ', title: measure.title, toolTipVerb: measure.toolTipVerb, dMeasure_Format: null }
          this.formattedMeasures[index] = doubleDaggerMeasure;
        } else {
          let emDashMeasure: Measure = { measure: '—', title: measure.title, toolTipVerb: measure.toolTipVerb, dMeasure_Format: null }
          this.formattedMeasures[index] = emDashMeasure;
        }
        break;
      case 'Student to Teacher Ratio':
        let teacher: any = this.entityType === EntityType.STATE ? data['stTOT_FTE'] : data['nTOT_FTE'];
        let teacherMiscMeasure: Measure = { measure: null, title: measure.title, toolTipVerb: measure.toolTipVerb, dMeasure_Format: null };

        if (teacher == null) {
          teacherMiscMeasure.measure = '—';
          this.formattedMeasures[index] = teacherMiscMeasure;
        } else if (isNaN(teacher)) {
          teacherMiscMeasure.measure = teacher;
          this.formattedMeasures[index] = teacherMiscMeasure;
        } else {
          let teacherValue: number = Number(teacher);

          if (teacherValue > 0 && this.totEnrollment > 0) {
            teacherMiscMeasure.measure = `${Math.round(this.totEnrollment / teacherValue)}:1`;
            this.formattedMeasures[index] = teacherMiscMeasure;
          } else if (teacherValue == -11) {
            teacherMiscMeasure.measure = 'ǂ';
            this.formattedMeasures[index] = teacherMiscMeasure;
          } else {
            teacherMiscMeasure.measure = '—';
            this.formattedMeasures[index] = teacherMiscMeasure;
          }
        }
        break;
      case 'Student to Counselor Ratio':
        let counsel: any = this.entityType === EntityType.STATE ? data['stCOUNSEL'] : data['nCOUNSEL'];
        let counselMiscMeasure: Measure = { measure: null, title: measure.title, toolTipVerb: measure.toolTipVerb, dMeasure_Format: null };

        if (counsel == null) {
          counselMiscMeasure.measure = '—';
          this.formattedMeasures[index] = counselMiscMeasure;
        } else if (isNaN(counsel)) {
          counselMiscMeasure.measure = counsel;
          this.formattedMeasures[index] = counselMiscMeasure;
        } else {
          let counselValue: number = Number(counsel);

          if (counselValue > 0 && this.totEnrollment > 0) {
            counselMiscMeasure.measure = `${Math.round(this.totEnrollment / counselValue)}:1`;
            this.formattedMeasures[index] = counselMiscMeasure;
          } else if (counselValue == -11) {
            counselMiscMeasure.measure = 'ǂ';
            this.formattedMeasures[index] = counselMiscMeasure;
          } else {
            counselMiscMeasure.measure = '—';
            this.formattedMeasures[index] = counselMiscMeasure;
          }
        }
        break;
      case 'Percent of Schools with all Teachers Certified':
            let fteCert: any = data['nSCH_FTE_CERT_P'];
        let fteCertMeasure: Measure = { measure: null, title: measure.title, toolTipVerb: measure.toolTipVerb, dMeasure_Format: null };

        if (fteCert == null) {
          fteCertMeasure.measure = '—';
          this.formattedMeasures[index] = fteCertMeasure;
        } else if (isNaN(fteCert)) {
          fteCertMeasure.measure = fteCert;
          this.formattedMeasures[index] = fteCertMeasure;
        } else {
          let fteCertValue: number = Number(fteCert);

          if (fteCertValue >= 0) {
            fteCertMeasure.measure = fteCertValue > 0 && fteCertValue < 1 ? '<1%' : `${Math.round(fteCertValue)}%`;
            this.formattedMeasures[index] = fteCertMeasure;
          } else if (fteCertValue == -11) {
            fteCertMeasure.measure = 'ǂ';
            this.formattedMeasures[index] = fteCertMeasure;
          } else {
            fteCertMeasure.measure = '—';
            this.formattedMeasures[index] = fteCertMeasure;
          }
        }
        break;
      case 'Percent of Schools with Counselors':
            let counselSchools: any = data['nSCH_COUNSEL_P'];
        let counselSchoolsMeasure: Measure = { measure: null, title: measure.title, toolTipVerb: measure.toolTipVerb, dMeasure_Format: null };

        if (counselSchools == null) {
          counselSchoolsMeasure.measure = '—';
          this.formattedMeasures[index] = counselSchoolsMeasure;
        } else if (isNaN(counselSchools)) {
          counselSchoolsMeasure.measure = counselSchools;
          this.formattedMeasures[index] = counselSchoolsMeasure;
        } else {
          let counselSchoolsValue: number = Number(counselSchools);

          if (counselSchoolsValue >= 0) {
            counselSchoolsMeasure.measure = counselSchoolsValue > 0 && counselSchoolsValue < 1 ? '<1%' : `${Math.round(counselSchoolsValue)}%`;
            this.formattedMeasures[index] = counselSchoolsMeasure;
          } else if (counselSchoolsValue == -11) {
            counselSchoolsMeasure.measure = 'ǂ';
            this.formattedMeasures[index] = counselSchoolsMeasure;
          } else {
            counselSchoolsMeasure.measure = '—';
            this.formattedMeasures[index] = counselSchoolsMeasure;
          }
        }
        break;
    }
  }

  getSchoolDistrictMeasure(measure: Measure, index: number): Promise<void> {
    let resolveRef;
    let rejectRef;

    let dataPromise: Promise<void> = new Promise((resolve, reject) => {
      resolveRef = resolve;
      rejectRef = reject;
    });

    let query: EnrollmentDataQuery = new EnrollmentDataQuery();

    query.entityId = this.entityId;
    query.surveyYearKey = this.surveyYear;
    query.measureId = measure.measure;

    this.enrollmentDataService.getEnrollmentData(query).subscribe(data => {
      if (data) {
        this.setSchoolDistrictData(measure, data, index);
      } else {
        let emDashMeasure: Measure = { measure: '—', title: measure.title, toolTipVerb: measure.toolTipVerb, dMeasure_Format: null }
        this.formattedMeasures[index] = emDashMeasure;
      }

      this.loadedMeasures++;
      resolveRef(null);
    });

    return dataPromise;
  }

  getSchoolDistrictMiscData(measure: Measure, index: number): Promise<void> {
    let resolveRef;
    let rejectRef;

    let dataPromise: Promise<void> = new Promise((resolve, reject) => {
      resolveRef = resolve;
      rejectRef = reject;
    });

    let query: MiscFieldDataQuery = new MiscFieldDataQuery();

    query.entityId = this.entityId;
    query.surveyYearKey = this.surveyYear;
    query.field = measure.measure;
    query.isSchoolOrDistrict = this.entityType === EntityType.SCHOOL ? 's' : 'd';

    this.miscDataService.getMiscField(query).subscribe(data => {
      if (data) {
        this.setSchoolDistrictData(measure, data, index);
      } else {
        let emDashMeasure: Measure = { measure: '—', title: measure.title, toolTipVerb: measure.toolTipVerb, dMeasure_Format: null }
        this.formattedMeasures[index] = emDashMeasure;
      }

      this.loadedMeasures++;
      resolveRef(null);
    });

    return dataPromise;
  }

  getStateNationalMeasure(measure: Measure, index?: number): Promise<void> {
    let resolveRef;
    let rejectRef;

    let dataPromise: Promise<void> = new Promise((resolve, reject) => {
      resolveRef = resolve;
      rejectRef = reject;
    });

    let query: StateNationalEtimationQuery = new StateNationalEtimationQuery();

    if (typeof measure.measure === 'number') {
      query.measureId = measure.measure;
    } else {
      query.measureId = 1;
    }

    query.surveyYearKey = this.surveyYear;

    if (this.entityId) {
      query.stateId = this.entityId;

      this.stateNationalEstimationService.getStateEstimation(query).subscribe(data => {
        if (data) {
          this.setStateNationalData(measure, data, index);
        } else {
          let emDashMeasure: Measure = { measure: '—', title: measure.title, toolTipVerb: measure.toolTipVerb, dMeasure_Format: null }
          this.formattedMeasures[index] = emDashMeasure;
        }

        this.loadedMeasures++;
        resolveRef(null);
      })
    } else {
      this.stateNationalEstimationService.getNationalEstimation(query).subscribe(data => {
        if (data) {
          this.setStateNationalData(measure, data, index);
        } else {
          let emDashMeasure: Measure = { measure: '—', title: measure.title, toolTipVerb: measure.toolTipVerb, dMeasure_Format: null }
          this.formattedMeasures[index] = emDashMeasure;
        }

        this.loadedMeasures++;
        resolveRef(null);
      })
    }

    return dataPromise;
  }

  replaceSpaces(text: string) {
    return text.replace(/ /g, '_');
  }
}
