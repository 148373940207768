import { Component, Input, OnInit } from '@angular/core';
import { EntityType, SurveyYearModel } from '../../models/profile-model';
import { ProfileService } from '../../services/helpers/profile.service';

@Component({
  selector: 'app-navigation-tree',
  templateUrl: './navigation-tree.component.html',
  styleUrls: ['./navigation-tree.component.scss']
})
export class NavigationTreeComponent implements OnInit {

  @Input() surveyYearKey: number;
  @Input() schName: string;
  @Input() leaName: string;
  @Input() leaId: string;
  @Input() state: string;
  @Input() stateCode: string;
  @Input() stateId: string;

  constructor(
    private profileService: ProfileService) {
  }

  ngOnInit(): void { }

  routeEntity(entityType: string): string {
    let surveyYear: SurveyYearModel = this.profileService.getSurveyYearKeys(EntityType.GENERAL).find(surveyYear => surveyYear.yearKey === this.surveyYearKey);

    switch (entityType) {
      case 'nation':
        return `/profile/us?surveyYear=${surveyYear.surveyYear}`;
      case 'state':
        return `/profile/us/${this.stateCode}?surveyYear=${surveyYear.surveyYear}`;
      case 'district':
        let districtName = this.profileService.encodeCrdUri(this.leaName.replace(/\s+/g, '_').toLowerCase());
        return `/profile/us/${this.stateCode}/${districtName}?surveyYear=${surveyYear.surveyYear}&nces=${this.leaId}`;
    }
  }

}
