<div class="data-tool-status-container is-flex is-flex-direction-column">
    <div class="data-tool-status-container-progress" aria-label="progress">
        <ol class="data-tool-status-container-progress-segments is-flex">
            <li *ngFor="let status of statuses; let i = index"
                [ngClass]="{'data-tool-status-container-progress-segments-segment': i !== (step - 1), 'data-tool-status-container-progress-segments-segment-is-active': i === (step - 1)}">
                <span [ngClass]="{'body' : true, 'data-tool-status-container-progress-segments-segment-label': i !== (step - 1), 'data-tool-status-container-progress-segments-segment-is-active-label': i === (step - 1)}">{{ status }} <span *ngIf="i > (step - 1)"
                        class="is-sr-only">completed</span></span>
            </li>
        </ol>
    </div>
    <div class="data-tool-status-container-steps is-flex is-align-items-center">
        <p class="large-body has-text-primary">Step</p>
        <div class="data-tool-status-container-steps-number-circle">
            <div>{{step}}</div>
        </div>
        <p class="large-body has-text-primary">of 4:</p>
        <h4 class="has-text-primary" style="font-size:2rem">{{ titles[step - 1] }}</h4>
    </div>

    <p class="large-body has-text-primary">{{ descriptions[step - 1] }}</p>
</div>
