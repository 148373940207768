import { AfterViewInit, HostListener, ElementRef, Component } from '@angular/core';
import { SCREEN_SIZE } from '../../enums/screen-size.enum';
import { ResizeService } from '../../services/core/resize.service';


@Component({
    selector: 'app-size-detector',
    templateUrl: './size-detector.component.html',
    styleUrls: ['./size-detector.component.scss']
})
export class SizeDetectorComponent implements AfterViewInit {
    prefix = 'screen-size-';
    sizes = [
        {
            id: SCREEN_SIZE.mobile, name: 'mobile', css: `is-visible-only-mobile`
        },
        {
            id: SCREEN_SIZE.tablet, name: 'tablet', css: `is-visible-only-tablet`
        },
        {
            id: SCREEN_SIZE.desktop, name: 'desktop', css: `is-visible-only-desktop`
        },
        {
            id: SCREEN_SIZE.widescreen, name: 'widescreen', css: `is-visible-only-widescreen`
        },
        {
            id: SCREEN_SIZE.fullhd, name: 'fullhd', css: `is-visible-only-fullhd`
        }
    ];

    constructor(private elementRef: ElementRef, private resizeSvc: ResizeService) { }

    @HostListener("window:resize", [])
    private onResize() {
        this.detectScreenSize();
    }

    ngAfterViewInit() {
        this.detectScreenSize();
    }

    private detectScreenSize() {
        let size;
        for (var i = 0; i < this.sizes.length; i++) {
            size = this.sizes[i];

            const el = this.elementRef.nativeElement.querySelector(`.${this.prefix}${size.id}`);

            const isVisible = window.getComputedStyle(el).display != 'none';
        }

        this.resizeSvc.onResize(size.id);
    }

}
