import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { EntityType } from '../../models/profile-model';
import { EntitySearchResult } from '../../query-models/entity-search-model';
import { FormControl, FormGroup } from '@angular/forms';
import { SchoolProfileQueryModel } from '../../query-models/school-profile-query-model';
import { DistrictProfileQueryModel } from '../../query-models/district-profile-query-model';
import { ProfileService } from '../../services/helpers/profile.service';
import { NGXLogger } from 'ngx-logger';

/**
 * Represents the component for Step One of the data tool.
 */
@Component({
  selector: 'app-data-tool-step-one',
  templateUrl: './data-tool-step-one.component.html',
  styleUrls: ['./data-tool-step-one.component.scss']
})
export class DataToolStepOneComponent implements OnInit, AfterViewInit, OnChanges {

  /**
   * Used for comparing entity type in html
   */
  EntityType = EntityType

  /**
   * Represents the form group input.
   */
  @Input() formGroup: FormGroup;

  /**
   * Represents the entity profile input.
   */
  @Input() entityProfile: SchoolProfileQueryModel | DistrictProfileQueryModel;

  /**
   * Represents the form response output.
   */
  @Output() formResponse: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  @Output() showLoadingEmitter: EventEmitter<{ show: boolean, message?: string }> = new EventEmitter<{ show: boolean, message: string }>();

  /**
   * Represents the school radio button, which is the first input element on the page. Is used
   * to set the focus to the first element on the page when the user presses the back button.
   */
  @ViewChild(`entityTypeSelection`) entityTypeSelection: ElementRef;

  /**
   * Represents the entity name.
   */
  public entityName: string;

  /**
   * Represents the student count.
   */
  public studentCount: string;

  /**
   * The list of entity types to be displayed for selection in the radio buttons.
   */
  public entityTypes: EntityType[];

  constructor(
    private readonly profileService: ProfileService,
    private readonly logger: NGXLogger,
  ) { }

  /**
   * Initializes the component subscribing to changes on the form group and entityType.
   */
  ngOnInit() {
    this.logger.debug('Inside ngOnInit for DataToolStepOneComponent');
    this.formGroup.valueChanges.subscribe(this.onFormChanges.bind(this));
    this.entityType.valueChanges.subscribe(this.onEntityTypeChange.bind(this));
    this.entityTypes = [EntityType.SCHOOL, EntityType.DISTRICT];
  }

  ngAfterViewInit(): void {
    this.logger.debug('Inside ngAfterViewInit for DataToolStepOneComponent');
    const entitySelectionChildren: HTMLCollection = this.entityTypeSelection.nativeElement.children;
    const schoolRadioButton: HTMLInputElement = entitySelectionChildren[0].children[0] as HTMLInputElement;
    const leaRadioButton: HTMLInputElement = entitySelectionChildren[1].children[0] as HTMLInputElement;

    if (schoolRadioButton.checked) {
      schoolRadioButton.focus();
    } else {
      leaRadioButton.focus();
    }
  }

  /**
   * Updates entityName and studentCount if entityProfile changes.
   */
  ngOnChanges() {
    this.logger.debug('Entity Profile: ', this.entityProfile);
    if (this.entityProfile) {
      this.setEntityName();
      this.setStudentCount();
      this.showLoadingEmitter.emit({ show: false });
    }
  }

  /**
   * Outputs any formGroup changes to the parent component.
   */
  onFormChanges() {
    this.formResponse.emit(this.formGroup);
  }

  /**
   * Resets selectedEntity to null if entityType changes.
   */
  onEntityTypeChange() {
    this.selectedEntity.setValue(null);
  }

  /**
   * Sets the selected entity on when the user makes a selection to change the entity.
   * @param $event The entity search result.
   */
  onEntityChange($event: EntitySearchResult) {
    this.selectedEntity.setValue($event);
    this.showLoadingEmitter.emit({ show: true, message: 'Loading Entity Profile ...' });
  }

  /**
   * Sets the entity name based on the entity type.
   */
  setEntityName(): void {
    if (this.entityType.value === EntityType.SCHOOL) {
      this.entityName = (this.entityProfile as SchoolProfileQueryModel).schName;
    } else if (this.entityType.value === EntityType.DISTRICT) {
      this.entityName = (this.entityProfile as DistrictProfileQueryModel).leaName;
    }
  }

  /**
   * Sets the student count based on the entity type.
   */
  setStudentCount(): void {
    if (this.entityType.value === EntityType.SCHOOL) {
      this.studentCount = this.profileService.getFormattedCount((this.entityProfile as SchoolProfileQueryModel).totalEnrollment).toLocaleString('en-US');
    } else if (this.entityType.value === EntityType.DISTRICT) {
      this.studentCount = this.profileService.getFormattedCount((this.entityProfile as DistrictProfileQueryModel).totalStudentCount).toLocaleString('en-US');
    }
  }

  /**
   * Gets the entity type form control.
   */
  get entityType() {
    return this.formGroup.get('entityType') as FormControl<EntityType>;
  }

  /**
   * Gets the selected entity form control.
   */
  get selectedEntity() {
    return this.formGroup.get('selectedEntity') as FormControl<EntitySearchResult>;
  }

  showLoading(event: { show: boolean, message?: string }) {
    this.logger.debug('Inside showLoading, event: ', event);
    this.showLoadingEmitter.emit(event);
  }
}
