<div class="container content">
  <page-title title="2013-14 State and National Tables"></page-title>
  <p>
    The 2013-14 tables are based on data collected from all of the nation’s school districts and schools—approximately 16,500 school districts and 96,500 schools. Documentation for the 2013-14 tables is available on the Data Notes page available
    here:
  </p>
  <p class="has-text-centered">
    <a href="assets/downloads/2013-2014/Data-Notes-CRDC-2013-14.pdf" rel="noopener noreferrer" target="_blank">Data-Notes-CRDC-2013-14.pdf</a>
  </p>
  <mat-accordion>

    <span class="has-text-weight-bold">Student Enrollment</span>
    <mat-expansion-panel>
      <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
        <mat-panel-title>
          All Enrollment
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="accordion-panel-body">
        <p class="has-text-centered is-size-6 has-text-weight-bold">Tables for Enrollment</p>
        <p>
            This set of Excel files contains data for all states on overall enrollment, enrollment of students served under IDEA, enrollment of students served under Section 504, and enrollment of students who are english learners (EL). For each category, there are three spreadsheets: total students, male students, and female students.
        </p>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2013-2014/SCH-0005-Overall-Enrollment.xlsx" rel="noopener noreferrer" target="_blank">Enrollment</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2013-2014/SCH-0101-Enrollment-of-Students-with-Disabilities-Served-under-IDEA.xlsx" rel="noopener noreferrer" target="_blank">IDEA enrollment</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2013-2014/SCH-0102-Enrollment-of-Students-with-Disabilities-Served-under-Section-504-only.xlsx" rel="noopener noreferrer" target="_blank">Section 504 enrollment</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
              <a href="assets/downloads/2013-2014/SCH-0009-Limited-English-Proficient-Students-by-state.xlsx" rel="noopener noreferrer" target="_blank">EL enrollment</a>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
    <br />

    <span class="has-text-weight-bold">Pathways to College and Career Readiness</span>
    <mat-expansion-panel>
      <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
        <mat-panel-title>
          Early Childhood and Prekindergarten Enrollment
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="accordion-panel-body">
        <p class="has-text-centered is-size-6 has-text-weight-bold">Early Childhood and Prekindergarten Enrollment</p>
        <p>
          This Excel file contains early childhood and prekindergarten student enrollment data for all states. The file contains three spreadsheets: total children, male children, and female children.
        </p>
        <div class="columns is-gapless is-multiline">
          <div class="column is-full has-text-centered">
            <a href="assets/downloads/2013-2014/Total-Enrollment-in-Early-Childhood-and-Prekindergarten.xlsx" rel="noopener noreferrer" target="_blank">Early childhood and prekindergarten enrollment</a>
          </div>
        </div>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
        <mat-panel-title>
          English Language Instruction Educational Programs
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="accordion-panel-body">
        <p class="has-text-centered is-size-6 has-text-weight-bold">English Language Instruction Program Enrollment</p>
        <p>
          This Excel file contains data for English language learners enrolled in English language instruction educational programs for all states. The file contains three spreadsheets: total children, male children, and female children.
        </p>
        <div class="columns is-gapless is-multiline">
          <div class="column is-full has-text-centered">
            <a href="assets/downloads/2013-2014/English-Language-Instruction-Programs-all-states.xlsx" rel="noopener noreferrer" target="_blank">English language instruction educational program enrollment</a>
          </div>
        </div>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
        <mat-panel-title>
          Gifted and Talented
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="accordion-panel-body">
        <p class="has-text-centered is-size-6 has-text-weight-bold">Gifted and Talented Enrollment</p>
        <p>
          This Excel file contains data on students enrolled in gifted and talented programs for all states. The file contains three spreadsheets: total students, male students, and female students.
        </p>
        <div class="columns is-gapless is-multiline">
          <div class="column is-full has-text-centered">
            <a href="assets/downloads/2013-2014/Gifted-Talented-Programs.xlsx" rel="noopener noreferrer" target="_blank">Gifted and talented enrollment</a>
          </div>
        </div>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
        <mat-panel-title>
          Retention
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="accordion-panel-body">
        <p class="has-text-centered is-size-6 has-text-weight-bold">Retention by Grade</p>
        <p>
          This set of Excel files contains student retention data for all states, presented by grade. For each grade, there are three spreadsheets: total students, male students, and female students.
        </p>
        <div class="columns is-multiline is-gapless">
          <div class="column is-one-third has-text-centered">
            <a href="assets/downloads/2013-2014/Retention-in-Kindergarten.xlsx" rel="noopener noreferrer" target="_blank">Kindergarten</a>
          </div>
          <div class="column is-one-third has-text-centered">
            <a href="assets/downloads/2013-2014/Retention-in-Grade-1.xlsx" rel="noopener noreferrer" target="_blank">Grade 1</a>
          </div>
          <div class="column is-one-third has-text-centered">
            <a href="assets/downloads/2013-2014/Retention-in-Grade-2.xlsx" rel="noopener noreferrer" target="_blank">Grade 2</a>
          </div>
        </div>
        <div class="columns is-multiline is-gapless">
          <div class="column is-one-third has-text-centered">
            <a href="assets/downloads/2013-2014/Retention-in-Grade-3.xlsx" rel="noopener noreferrer" target="_blank">Grade 3</a>
          </div>
          <div class="column is-one-third has-text-centered">
            <a href="assets/downloads/2013-2014/Retention-in-Grade-4.xlsx" rel="noopener noreferrer" target="_blank">Grade 4</a>
          </div>
          <div class="column is-one-third has-text-centered">
            <a href="assets/downloads/2013-2014/Retention-in-Grade-5.xlsx" rel="noopener noreferrer" target="_blank">Grade 5</a>
          </div>
        </div>
        <div class="columns is-multiline is-gapless">
          <div class="column is-one-third has-text-centered">
            <a href="assets/downloads/2013-2014/Retention-in-Grade-6.xlsx" rel="noopener noreferrer" target="_blank">Grade 6</a>
          </div>
          <div class="column is-one-third has-text-centered">
            <a href="assets/downloads/2013-2014/Retention-in-Grade-7.xlsx" rel="noopener noreferrer" target="_blank">Grade 7</a>
          </div>
          <div class="column is-one-third has-text-centered">
            <a href="assets/downloads/2013-2014/Retention-in-Grade-8.xlsx" rel="noopener noreferrer" target="_blank">Grade 8</a>
          </div>
        </div>
        <div class="columns is-multiline is-gapless">
          <div class="column is-one-third has-text-centered">
            <a href="assets/downloads/2013-2014/Retention-in-Grade-9.xlsx" rel="noopener noreferrer" target="_blank">Grade 9</a>
          </div>
          <div class="column is-one-third has-text-centered">
            <a href="assets/downloads/2013-2014/Retention-in-Grade-10.xlsx" rel="noopener noreferrer" target="_blank">Grade 10</a>
          </div>
          <div class="column is-one-third has-text-centered">
            <a href="assets/downloads/2013-2014/Retention-in-Grade-11.xlsx" rel="noopener noreferrer" target="_blank">Grade 11</a>
          </div>
        </div>
        <div class="columns is-multiline is-gapless">
          <div class="column is-one-third has-text-centered">
            <a href="assets/downloads/2013-2014/Retention-in-Grade-12.xlsx" rel="noopener noreferrer" target="_blank">Grade 12</a>
          </div>
        </div>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
        <mat-panel-title>
          Student Absenteeism
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="accordion-panel-body">
        <p class="has-text-centered is-size-6 has-text-weight-bold">Student Absenteeism</p>
        <p>
          This Excel file contains data on chronic student absenteeism - students absent 15 or more days during the school year - for all states. The file contains three spreadsheets: total students, male students, and female students.
        </p>
        <div class="columns is-gapless is-multiline">
          <div class="column is-full has-text-centered">
            <a href="assets/downloads/2013-2014/Chronic-Absenteeism.xlsx" rel="noopener noreferrer" target="_blank">Student absenteeism</a>
          </div>
        </div>

      </div>
    </mat-expansion-panel>

    <br />

    <span class="has-text-weight-bold">College and Career Readiness</span>
    <mat-expansion-panel>
      <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
        <mat-panel-title>
          Mathematics and Science
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="accordion-panel-body">
        <p class="has-text-centered is-size-6 has-text-weight-bold">Schools Offering Mathematics and Science Classes</p>
        <p>
          This Excel file contains data for schools offering classes in mathematics and science for all states. The file contains one spreadsheet for total schools.
        </p>
        <div class="has-text-centered">
          <a href="assets/downloads/2013-2014/Classes-in-Mathematics-and-Science.xlsx" rel="noopener noreferrer" target="_blank">Schools offering mathematics and science classes</a>
        </div>

        <br /><br />
        <p class="has-text-centered is-size-6 has-text-weight-bold">Mathematics Tables by Course</p>
        <p>
          This set of Excel files contains student enrollment data for all states, presented by mathematics course. For all mathematics courses, there are three spreadsheets: total students, male students, and female students.
        </p>
        <div class="columns is-multiline is-gapless">
          <div class="column is-one-third has-text-centered">
            <a href="assets/downloads/2013-2014/Enrollment-in-Algebra-I-7-8.xlsx" rel="noopener noreferrer" target="_blank">Algebra I 7-8</a>
          </div>
          <div class="column is-one-third has-text-centered">
            <a href="assets/downloads/2013-2014/Enrollment-in-Algebra-I-9-10.xlsx" rel="noopener noreferrer" target="_blank">Algebra I 9-10</a>
          </div>
          <div class="column is-one-third has-text-centered">
            <a href="assets/downloads/2013-2014/Enrollment-in-Algebra-I-11-12.xlsx" rel="noopener noreferrer" target="_blank">Algebra I 11-12</a>
          </div>
        </div>
        <div class="columns is-multiline is-gapless">
          <div class="column is-one-third has-text-centered">
            <a href="assets/downloads/2013-2014/Enrollment-in-Geometry.xlsx" rel="noopener noreferrer" target="_blank">Geometry</a>
          </div>
          <div class="column is-one-third has-text-centered">
            <a href="assets/downloads/2013-2014/Enrollment-in-Algebra-II.xlsx" rel="noopener noreferrer" target="_blank">Algebra II</a>
          </div>
          <div class="column is-one-third has-text-centered">
            <a href="assets/downloads/2013-2014/Enrollment-in-Calculus.xlsx" rel="noopener noreferrer" target="_blank">Calculus</a>
          </div>
        </div>
        <div class="columns is-multiline is-gapless">
          <div class="column is-one-third has-text-centered">
            <a href="assets/downloads/2013-2014/Enrollment-in-Advanced-Mathematics.xlsx" rel="noopener noreferrer" target="_blank">Advanced mathematics</a>
          </div>
        </div>

        <br /><br />
        <p class="has-text-centered is-size-6 has-text-weight-bold">Science Tables by Course</p>
        <p>
          This set of Excel files contains student enrollment data for all states, presented by science course. For each science course, there are three spreadsheets: total students, male students, and female students.
        </p>
        <div class="columns is-multiline is-gapless">
          <div class="column is-one-third has-text-centered">
            <a href="assets/downloads/2013-2014/Enrollment-in-Biology.xlsx" rel="noopener noreferrer" target="_blank">Biology</a>
          </div>
          <div class="column is-one-third has-text-centered">
            <a href="assets/downloads/2013-2014/Enrollment-in-Chemistry.xlsx" rel="noopener noreferrer" target="_blank">Chemistry</a>
          </div>
          <div class="column is-one-third has-text-centered">
            <a href="assets/downloads/2013-2014/Enrollment-in-Physics.xlsx" rel="noopener noreferrer" target="_blank">Physics</a>
          </div>
        </div>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
        <mat-panel-title>
          Advanced Placement
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="accordion-panel-body">
        <p class="has-text-centered is-size-6 has-text-weight-bold">Advanced Placement Total Enrollment</p>
        <p>
          This Excel file contains student enrollment in Advanced Placement for all states. There are three spreadsheets: total students, male students, and female students.
        </p>
        <div class="has-text-centered">
          <a href="assets/downloads/2013-2014/Advanced-Placement-Enrollment.xlsx" rel="noopener noreferrer" target="_blank">Advanced placement</a>
        </div>

        <br /><br />
        <p class="has-text-centered is-size-6 has-text-weight-bold">Advanced Placement Enrollment, by Subject</p>
        <p>
          This set of Excel files contains student enrollment data for all states, presented by Advanced Placement (AP) subject. For each AP subject, there are three spreadsheets: total students, male students, and female students.
        </p>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-third has-text-centered">
            <a href="assets/downloads/2013-2014/Advanced-Placement-Mathematics-Enrollment.xlsx" rel="noopener noreferrer" target="_blank">AP mathematics</a>
          </div>
          <div class="column is-one-third has-text-centered">
            <a href="assets/downloads/2013-2014/Advanced-Placement-Science-Enrollment.xlsx" rel="noopener noreferrer" target="_blank">AP science</a>
          </div>
          <div class="column is-one-third has-text-centered">
            <a href="assets/downloads/2013-2014/Advanced-Placement-Other-Subjects-Enrollment.xlsx" rel="noopener noreferrer" target="_blank">AP other subjects</a>
          </div>
        </div>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
        <mat-panel-title>
          SAT/ACT
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="accordion-panel-body">
        <p class="has-text-centered is-size-6 has-text-weight-bold">SAT/ACT Participation</p>
        <p>
          This Excel file contains SAT/ACT student participation data for all states. The file contains three spreadsheets: total students, male students, and female students.
        </p>
        <div class="columns is-gapless">
          <div class="column is-full has-text-centered">
            <a href="assets/downloads/2013-2014/SAT-or-ACT-Participation-by-state.xlsx" rel="noopener noreferrer" target="_blank">SAT/ACT participation</a>
          </div>
        </div>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
        <mat-panel-title>
          Advanced Placement Exams
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="accordion-panel-body">
        <p class="has-text-centered is-size-6 has-text-weight-bold">2013-14 Advanced Placement Test Taking</p>
        <p>
          This set of Excel files contains data on Advanced Placement test taking for all states. For each set, there are three three spreadsheets: total students, male students, and female students.
        </p>
        <div class="columns is-gapless is-multiline">
          <div class="column is-half has-text-centered">
            <a href="assets/downloads/2013-2014/Advanced-Placement-Participation-by-state-Did-Not-Take-Exam.xlsx" rel="noopener noreferrer" target="_blank">Did not take AP exam</a>
          </div>
          <div class="column is-half has-text-centered">
            <a href="assets/downloads/2013-2014/Advanced-Placement-Participation-by-state-Took-Exam.xlsx" rel="noopener noreferrer" target="_blank">Took AP exam</a>
          </div>
        </div>
      </div>
    </mat-expansion-panel>

    <br />

    <span class="has-text-weight-bold">School Staff</span>
    <mat-expansion-panel>
      <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
        <mat-panel-title>
          Classroom Teachers
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="accordion-panel-body">
        <p class="has-text-centered is-size-6 has-text-weight-bold">Classroom Teachers</p>
        <p>
          This Excel file contains data on classroom teachers, including full-time equivalency counts, certification, and years of experience for all states. The file contains one spreadsheet for total classroom teachers.
        </p>
        <div class="columns is-gapless is-multiline">
          <div class="column is-full has-text-centered">
            <a href="assets/downloads/2013-2014/Teacher-Certification-and-Years-of-Experience.xlsx" rel="noopener noreferrer" target="_blank">Classroom teachers</a>
          </div>
        </div>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
        <mat-panel-title>
          School Counselors
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="mat-expansion-panel-content-padding accordion-panel-body">
        <p class="has-text-centered is-size-6 has-text-weight-bold">School Counselors</p>
        <p>
          This Excel file contains data on high school counselors for all states. The file contains one spreadsheet for total high school counselors.
        </p>
        <div class="columns is-gapless is-multiline">
          <div class="column is-full has-text-centered">
            <a href="assets/downloads/2013-2014/School-Counselors.xlsx" rel="noopener noreferrer" target="_blank">School counselors</a>
          </div>
        </div>
      </div>
    </mat-expansion-panel>

    <br />

    <span class="has-text-weight-bold">Discipline, Harassment or Bullying, and Restraint and Seclusion</span>
    <mat-expansion-panel>
      <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
        <mat-panel-title>
          Discipline
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="accordion-panel-body">
        <p class="has-text-centered is-size-6 has-text-weight-bold">Discipline by Discipline Type</p>
        <p>
          This set of Excel files contains data for all states, presented by discipline type. For each discipline type, there are nine spreadsheets: male students with disabilities, female students with disabilities, total students with disabilities, male students without disabilities, female students without disabilities, total students without disabilities, male students with and without disabilities, female students with and without disabilities, and total students with and without disabilities
        </p>
        <div class="columns is-gapless is-multiline">
          <div class="column is-half has-text-centered">
            <a href="assets/downloads/2013-2014/Corporal-Punishment.xlsx" rel="noopener noreferrer" target="_blank">Corporal punishment</a>
          </div>
          <div class="column is-half has-text-centered">
            <a href="assets/downloads/2013-2014/In-School-Suspensions.xlsx" rel="noopener noreferrer" target="_blank">In-school suspension</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-half has-text-centered">
            <a href="assets/downloads/2013-2014/One-out-of-school-suspension.xlsx" rel="noopener noreferrer" target="_blank">One out-of-school suspension</a>
          </div>
          <div class="column is-half has-text-centered">
            <a href="assets/downloads/2013-2014/More-than-one-out-of-school-suspension.xlsx" rel="noopener noreferrer" target="_blank">More than one out-of-school suspension</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-half has-text-centered">
            <a href="assets/downloads/2013-2014/One-or-more-out-of-school-suspensions.xlsx" rel="noopener noreferrer" target="_blank">One or more out-of-school suspensions</a>
          </div>
          <div class="column is-half has-text-centered">
            <a href="assets/downloads/2013-2014/Expulsions-with-educational-services.xlsx" rel="noopener noreferrer" target="_blank">Expulsions with educational services</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-half has-text-centered">
            <a href="assets/downloads/2013-2014/Expulsions-without-educational-services.xlsx" rel="noopener noreferrer" target="_blank">Expulsions without educational services</a>
          </div>
          <div class="column is-half has-text-centered">
            <a href="assets/downloads/2013-2014/Expulsions-with-and-without-educational-services.xlsx" rel="noopener noreferrer" target="_blank">Expulsions with and without educational services</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-half has-text-centered">
            <a href="assets/downloads/2013-2014/Expulsions-under-zero-tolerance-policies.xlsx" rel="noopener noreferrer" target="_blank">Expulsions under zero tolerance policies</a>
          </div>
          <div class="column is-half has-text-centered">
            <a href="assets/downloads/2013-2014/Referral-to-law-enforcement.xlsx" rel="noopener noreferrer" target="_blank">Referrals to law enforcement</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-half has-text-centered">
            <a href="assets/downloads/2013-2014/School-related-arrests.xlsx" rel="noopener noreferrer" target="_blank">School-related arrests</a>
          </div>
        </div>

        <br /><br />
        <p class="has-text-centered is-size-6 has-text-weight-bold">Preschool Discipline by Discipline Type</p>
        <p>
          This set of Excel files contains data for all states, presented by discipline type. For each discipline type, there are three spreadsheets: total preschool children, male preschool children, and female preschool children.
        </p>
        <div class="columns is-gapless is-multiline">
          <div class="column is-half has-text-centered">
            <a href="assets/downloads/2013-2014/Preschool-One-Out-of-School-Suspension.xlsx" rel="noopener noreferrer" target="_blank">One out-of-school suspension</a>
          </div>
          <div class="column is-half has-text-centered">
            <a href="assets/downloads/2013-2014/Preschool-More-than-One-Out-of-School-Suspension.xlsx" rel="noopener noreferrer" target="_blank">More than one out-of-school suspension</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-half has-text-centered">
            <a href="assets/downloads/2013-2014/Preschool-One-or-More-Out-of-School-Suspensions.xlsx" rel="noopener noreferrer" target="_blank">One or more out-of-school suspensions</a>
          </div>
          <div class="column is-half has-text-centered">
            <a href="assets/downloads/2013-2014/Preschool-Expulsions.xlsx" rel="noopener noreferrer" target="_blank">Expulsions</a>
          </div>
        </div>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
        <mat-panel-title>
          Harassment or Bullying
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="accordion-panel-body">
        <p class="has-text-centered is-size-6 has-text-weight-bold">Harassment or Bullying by Basis Category</p>
        <p>
          This set of Excel files contains data for all states, presented by harassment or bullying basis category and by whether students were reported as harassed or bullied or disciplined for harassment and bullying. For each set, there are three spreadsheets: total students, male students, and female students.
        </p>

        <div class="columns is-gapless is-multiline">
          <div class="column is-one-third has-text-centered">
            <a href="assets/downloads/2013-2014/Harassment-Bullying-on-basis-of-sex-reported.xlsx" rel="noopener noreferrer" target="_blank">On basis of sex - reported</a>
          </div>
          <div class="column is-one-third has-text-centered">
            <a href="assets/downloads/2013-2014/Harassment-Bullying-on-basis-of-race-reported.xlsx" rel="noopener noreferrer" target="_blank">On basis of race - reported</a>
          </div>
          <div class="column is-one-third has-text-centered">
            <a href="assets/downloads/2013-2014/Harassment-Bullying-on-basis-of-disability-reported.xlsx" rel="noopener noreferrer" target="_blank">On basis of disability - reported</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-third has-text-centered">
            <a href="assets/downloads/2013-2014/Harassment-Bullying-on-basis-of-sex-disciplined.xlsx" rel="noopener noreferrer" target="_blank">On basis of sex - disciplined</a>
          </div>
          <div class="column is-one-third has-text-centered">
            <a href="assets/downloads/2013-2014/Harassment-Bullying-on-basis-of-race-disciplined.xlsx" rel="noopener noreferrer" target="_blank">On basis of race - disciplined</a>
          </div>
          <div class="column is-one-third has-text-centered">
            <a href="assets/downloads/2013-2014/Harassment-Bullying-on-basis-of-disability-disciplined.xlsx" rel="noopener noreferrer" target="_blank">On basis of disability - disciplined</a>
          </div>
        </div>

        <br /><br />
        <p class="has-text-centered is-size-6 has-text-weight-bold">2013-14 Allegations of Harassment or Bullying</p>
        <p>
          This Excel file contains data for all states on the number of students that were alleged to be harassed or bullied. The spreadsheet contains data on the total number of allegations of harassment or bullying, and on allegations of harassment or bullying on the basis of sex, race, and disability.
        </p>
        <div class="columns is-gapless is-multiline">
          <div class="column is-full has-text-centered">
            <a href="assets/downloads/2013-2014/Allegations-of-Harassment-or-Bullying.xlsx" rel="noopener noreferrer" target="_blank">Allegations of harassment or bullying</a>
          </div>
        </div>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
        <mat-panel-title>
          Restraint and Seclusion
        </mat-panel-title>
      </mat-expansion-panel-header>

     <div class="accordion-panel-body">
        <p class="has-text-centered is-size-6 has-text-weight-bold">Restraint and Seclusion by Restraint or Seclusion Category</p>
        <p>
          This set of Excel files contains data for all states, presented by restraint or seclusion category. For each category, there are nine spreadsheets: students with and without disabilities, male students with and without disabilities, female students with and without disabilities, students served under IDEA, male students served under IDEA, female students served under IDEA, students not served under IDEA, male students not served under IDEA, and female students not served under IDEA.
        </p>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-third has-text-centered">
            <a href="assets/downloads/2013-2014/Mechanical-Restraint.xlsx" rel="noopener noreferrer" target="_blank">Mechanical restraint</a>
          </div>
          <div class="column is-one-third has-text-centered">
            <a href="assets/downloads/2013-2014/Physical-Restraint.xlsx" rel="noopener noreferrer" target="_blank">Physical restraint</a>
          </div>
          <div class="column is-one-third has-text-centered">
            <a href="assets/downloads/2013-2014/Seclusion.xlsx" rel="noopener noreferrer" target="_blank">Seclusion</a>
          </div>
        </div>

        <br /><br />
        <p class="has-text-centered is-size-6 has-text-weight-bold">2013-14 Instances of Restraint and Seclusion</p>
        <p>
          This set of Excel files contains data for all states, on the number of instances of restraint or seclusion. There are three spreadsheets: for students served under IDEA, for non-IDEA students, and for total.
        </p>
        <div class="columns is-gapless is-multiline">
          <div class="column is-full has-text-centered">
            <a href="assets/downloads/2013-2014/Instances-of-Restraint-or-Seclusion-by-IDEA-Status.xlsx" rel="noopener noreferrer" target="_blank">Instances of restraint or seclusion</a>
          </div>
        </div>
      </div>
    </mat-expansion-panel>

  </mat-accordion>
  <document-viewer docType="PDF"></document-viewer>
</div>
