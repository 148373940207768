import { Component } from '@angular/core';
import { ToastService } from '@shared/components/toast/toast.service';


@Component({
  selector: 'app-toasts',
  templateUrl: './toasts-container.component.html',
  styleUrls: ['./toasts-container.component.scss'],
  host: {'[class.ngb-toasts]': 'true'}
})
export class ToastsContainer {

  autoHideToast: boolean = true;

  constructor(public toastService: ToastService) { }

}
