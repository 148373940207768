import {NgModule} from '@angular/core';
import {LoggerModule, NgxLoggerLevel} from 'ngx-logger';
import {environment} from '../../environments/environment';
import {HttpClientModule} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';




@NgModule({
    imports: [
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        HttpClientModule,
        // LoggerModule.forRoot({serverLoggingUrl: '/api/logs', level: NgxLoggerLevel.DEBUG, serverLogLevel: NgxLoggerLevel.ERROR})
        LoggerModule.forRoot({
            level: environment.logLevel,
            // THIS IS REQUIRED, to make "line-numbers" work in SourceMap Object definition (without evalSourceMap)
            enableSourceMaps: (environment.logLevel === NgxLoggerLevel.OFF ? false : true)
        }),
        // module that are a core part of your app
    ],
})
export class CoreModule {}
