<div class="share-report-container">
    <div class="share-report-content is-flex is-flex-direction-row is-justify-content-space-between">
        <div class="is-flex is-align-self-center is-flex-direction-column is-align-items-start" style="gap:.5rem">
            <h5 class="has-text-primary">
                CRDC Report
            </h5>
            <p class="large-body has-text-primary">
                Generated {{ reportDate }}
            </p>
        </div>
        <div class="is-flex is-flex-direction-row" style="gap:2rem">
            <div class="is-flex is-flex-direction-column is-align-items-center" style="gap:1.5rem; position: relative;" (click)="copyURL()" ((mouseleave))="displayTooltip = false">
                <img src="assets/images/copy-report.svg" style="cursor:pointer" alt="Copy Report">
                <p class="bold-body has-text-primary" style="cursor:pointer">Copy Report URL</p>
                <span class="notification tooltip-container" *ngIf="displayTooltip">Link copied</span>
            </div>
            <!-- <div class="is-flex is-flex-direction-column is-align-items-center" style="gap:1.5rem" (click)="printReport()">
                <a><img src="assets/images/print-report.svg" alt="Print Report"></a>
                <a><p class="bold-body has-text-primary">Print Report</p></a>
            </div> -->
        </div>
    </div>
</div>
