<fieldset class="data-tool-entity-selection-container">
    <div class="is-flex is-justify-content-space-between">
        <legend style="margin-bottom: .5rem" class="subheader-2 has-text-primary">{{ entityTypeString }} Selection <button
            class="data-tool-entity-selection-container-view-all-button body has-text-primary" type="button"
            (click)="toggleSideNav()">View All Selections</button></legend>

        <p *ngIf="entityTotal > 200" class="body has-text-primary">Showing 200 of {{ entityTotal | number }}</p>
    </div>

    <table mat-table [dataSource]="dataSource" class="compact-table">
        <!-- Checkbox column -->
        <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox aria-label="select all entities" (change)="$event ? masterToggle() : null"
                    [checked]="selectedEntities.length > 0 && isAllSelected()"
                    [indeterminate]="selectedEntities.length > 0 && !isAllSelected()" [disabled]="disabled">
                </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
                <mat-checkbox [aria-label]="row.label" (change)="$event ? toggleCheck(row) : null"
                    [checked]="row.checked" [disabled]="(!isUnderMaxLimit() && !row.checked) || disabled">
                </mat-checkbox>
            </td>
        </ng-container>

        <!-- Name column -->
        <ng-container matColumnDef="name">
            <th class="large-body has-text-white" mat-header-cell *matHeaderCellDef>{{ entityTypeString }} Name</th>
            <td mat-cell *matCellDef="let element" [id]="element.entity_Name">{{ element.entity_Name }}</td>
        </ng-container>

        <!-- State column -->
        <ng-container matColumnDef="state">
            <th class="large-body has-text-white" mat-header-cell *matHeaderCellDef>State</th>
            <td mat-cell *matCellDef="let element">{{ element.entity_State }}</td>
        </ng-container>

        <!-- Grades Offered column -->
        <ng-container matColumnDef="gradesOffered">
            <th class="large-body has-text-white" mat-header-cell *matHeaderCellDef>Grades</th>
            <td mat-cell *matCellDef="let element">{{ element.gradesOffered }}</td>
        </ng-container>

        <!-- Student Count column -->
        <ng-container matColumnDef="studentCount">
            <th class="large-body has-text-white" mat-header-cell *matHeaderCellDef>Students</th>
            <td mat-cell *matCellDef="let element">{{ element.studentCount }}</td>
        </ng-container>

        <!-- Table rows -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <p *ngIf="selectedEntities" class="num-entities-selected large-body has-text-primary">
        {{ selectedEntities.length }} {{ entityTypeString }}(s) selected
    </p>

    <!-- Pagination -->
    <mat-paginator #MatPaginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons
        aria-label="Select page of entity selection">
    </mat-paginator>
</fieldset>
