import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

import { MessageService } from '../core/message.service';

import { BaseService } from '../core/base.service';
import { DataService } from '../core/data.service';

import { EntityMeasuresQuery } from '../../queries/entity-measures-query';
import { Observable } from 'rxjs';
import * as internal from 'stream';
import {SurveyYearsModels} from '../../models/profile-model';
import {NGXLogger} from 'ngx-logger';
import {ToastService} from '@shared/components/toast/toast.service';


@Injectable()
export class SurveyYearService extends BaseService {

  private apiUrl = environment.api_url; // URL to web api

  constructor(
    private http: HttpClient,
    logger: NGXLogger,
    toastService: ToastService,
    messageService: MessageService,
    dataService: DataService
  ) {
    super(logger, toastService, messageService, dataService);
  }

    // example to use survey year API call.
    // parameter - number of survey years to pull in desc order.
    //             use 1 to pull the latest survey year
    //             use 10 or more to pull all available survey years in desc order
    getSurveyYearsList(cutoff: number): Observable<SurveyYearsModels> {
        this.setHeaders();
        const url = this.apiUrl + 'SurveyYearsList';

        var params = new HttpParams();
        params = params.append('cutoff', cutoff.toString());

        return this.http.get<SurveyYearsModels>(
            url,
            {
                params: params,
                observe: 'response',
                headers: this.headers
            })
            .pipe(
                map(resp => {
                    if (resp != undefined || resp != null) {
                        return resp.body;
                    }

                    return null;
                }),
                tap(resp => {
                    this.log(`fetched`);
                }),
                catchError(this.handleError('get', null))
            );
    }


}
