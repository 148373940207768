import { Component, HostListener, OnInit, ViewChild, OnDestroy } from '@angular/core';
import {MatDrawerMode, MatSidenav} from '@angular/material/sidenav';
import { filter, map, mergeMap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import { SEOService } from './shared/services/helpers/seo-service';
import { ProfileService } from './shared/services/helpers/profile.service';
import { NGXLogger } from 'ngx-logger';
import { environment } from '@environments/environment';
import { Location } from '@angular/common';
import { AuthorizationService } from '@core/auth/services/authorization.service';
import { ComparisonService } from '@shared/services/helpers/comparison.service';
import { EntitySearchResult, EntitySearchStep2Result } from '@shared/query-models/entity-search-model';
import {HttpParams} from '@angular/common/http';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    @ViewChild('sidenav') sidenav: MatSidenav;
    title = 'app';
    sideNavOpened: boolean = false;
    isMobile: boolean;
    //modeType: MatDrawerMode;
    skipLinkPath: string;

    isLoggedIn: boolean = false;
    userFullName: string = 'unknown';
    userEmail: string = 'unknown';


    private routerSubscription: Subscription;

    constructor(private logger: NGXLogger,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private _seoService: SEOService,
        private profileService: ProfileService,
        private authorizationService: AuthorizationService,
        private comparisonService: ComparisonService,
        private routeLocation: Location,
    ) {
        // Global site tag(gtag.js) - Google Analytics
        // register google tag manager
        const gTagManagerScript = document.createElement('script');
        gTagManagerScript.async = true;
        gTagManagerScript.src = `https://www.googletagmanager.com/gtag/js?id=G-Q2QF222K7L`;
        document.head.appendChild(gTagManagerScript);

        // register google analytics
        const gaScript = document.createElement('script');
        gaScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag() { dataLayer.push(arguments); }
        gtag('js', new Date());
        gtag('config', 'G-Q2QF222K7L', { 'send_page_view': false });
        `;
        document.head.appendChild(gaScript);
        // MS Clarity
        const MSClarityScript = document.createElement('script');
        MSClarityScript.innerHTML = `
            (function (c, l, a, r, i, t, y)
                {
                    c[a] = c[a] || function ()
                    {
                        (c[a].q = c[a].q || []).push(arguments)
                    };
                    t = l.createElement(r);
                    t.async = 1;
                    t.src = "https://www.clarity.ms/tag/+i;y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y)";
                })(window, document, "clarity", "script", "8vxq3wbcel");
            `;
        document.head.appendChild(MSClarityScript);

        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {

                (<any>window).gtag('event', 'page_view', {
                    page_path: event.urlAfterRedirects
                })
            }
        });

        // Load the survey year keys and states
        this.profileService.loadSurveyYearKeys();
        this.profileService.loadStateList();
    }

    ngOnDestroy(): void {
        this.routerSubscription.unsubscribe();
    }

    ngOnInit(): void {

        this.logger.debug('Inside application ngOnInit', this.router.url);

        this.authorizationService.getAuthenticationListener().subscribe(
            (isLoggedIn) => {

                this.logger.debug('App: AuthenticationListener isLoggedIn: ', isLoggedIn);

                this.isLoggedIn = isLoggedIn;

                this.userFullName = this.authorizationService.getUserFullName();
                this.userEmail = this.authorizationService.getUserEmail();
            }
        );

        this.routerSubscription = this.router.events.subscribe(event => {

            this.logger.debug('router.event: ', event.type);

            if (event instanceof NavigationEnd) {

                this.logger.debug('event type: NavigationEnd');

                const [path, query] = this.routeLocation.path().split('?');

                this.logger.debug('path: ', path);
                this.logger.debug('query: ', query);

                let params = new HttpParams({ fromString: query });

                // remove authentication query parameters
                if (params.get('code') || params.get('id_token_hint')){

                    //const theValueIWant = params.get('theParamIWant');
                    params = params.delete('code').delete('state').delete('id_token_hint'); //access_token
                    this.logger.debug('remove query parameters, params: ', params);
                    //this.routelocation.replaceState(path, params.toString());
                    this.routeLocation.replaceState(path);
                }
                // if this is the data tool, then no need to refresh the auth guard will do that
                else if (path.indexOf('data-tool') === -1) {
                    this.logger.debug('refresh from app, path: ', path);
                    this.authorizationService.refreshAuthenticationState();
                }

                // only enable this if the redirect is to the home page
                //this.performAuthentication();
                let url: string = this.router.url;

                this.logger.debug('url: ', url);    //, event.url);

                if (url.includes('#content')) {
                    url = url.replace('#content', '');
                }

                this.skipLinkPath = `${url}#content`;
            }
        });

        this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            map(() => this.activatedRoute),
            map((route) => {
                while (route.firstChild) route = route.firstChild;
                return route;
            }),
            filter((route) => route.outlet === 'primary'),
            mergeMap((route) => route.data)
        )
            .subscribe((event) => {
                this._seoService.updateOgTitle(event['title']);
                this._seoService.updateOgDescription(event['description']);
                this._seoService.updateOgImage(event['ogImage']);
            });

        this.setSize();

        // Listener for the comparison toggle event
        this.comparisonService.toggleSideNavEvent.subscribe(() => {
            this.toggleSideNav();
        });
    }

    performAuthentication(){

        this.logger.debug('Inside App performAuthentication');

        this.authorizationService.performAuthentication(this.activatedRoute.snapshot).subscribe(
            (isLoggedIn) => {

                this.logger.debug('App: performAuthentication isLoggedIn: ', isLoggedIn);

                this.isLoggedIn = isLoggedIn;
                if (isLoggedIn) {

                    this.userFullName = this.authorizationService.getUserFullName();
                    this.userEmail = this.authorizationService.getUserEmail();
                }
                else {
                    //this.router.navigate(['/data-tool-login-landing']);
                }
            }
        );
    }

    toggleSideNav() {
        let targetItem: EntitySearchResult = this.comparisonService.getTargetItem();
        let comparisonItems: EntitySearchStep2Result[] = this.comparisonService.getComparisonItems();

        if (comparisonItems.length > 0 || targetItem) {
            this.sideNavOpened = !this.sideNavOpened;
        } else {
            this.sideNavOpened = false;
            this.router.navigate(['/data-tool']);
        }

    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.setSize();
    }

    setSize() {
        if (environment.production) {
            this.isMobile = window.innerWidth <= 1023;
        } else {
            this.isMobile = window.innerWidth <= 1193;
        }

        // if (this.isMobile) {
        //     this.modeType = "top";   // push or side
        // } else {
        //     this.modeType = "over";
        // }

        this.logger.debug('Inside setSize, isMobile: ', this.isMobile);
    }

    close(event) {
        this.sidenav.close();
        this.sideNavOpened = false;
    }

    scrollToTop() {
        let sideNavContent: Element = document.getElementById('content');
        sideNavContent.scroll(0, 0);
    }

    loggingOut($event: any){
        this.logger.debug('Inside app loggingOut, event: ', $event);

        this.isLoggedIn = !$event;
    }
}
