import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

// Services
import { ComparisonService } from '../../services/helpers/comparison.service';
import { EntitySearchResult, EntitySearchStep2Result } from '../../query-models/entity-search-model';
import { SEARCH_STEP } from '@shared/query-models/step-search.model';
import { NGXLogger } from 'ngx-logger';
import { Router } from '@angular/router';

@Component({
    selector: 'side-nav',
    templateUrl: './side-nav.component.html',
    styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit, OnDestroy {

    @Output() closePressed = new EventEmitter();

    isEditable: boolean;
    targetItem: EntitySearchResult;
    itemsArray: EntitySearchStep2Result[];
    showResetReportConfigurationModal: boolean = false;

    isEditableSubscription: Subscription;
    targetItemSubscription: Subscription;
    comparisonSubscription: Subscription;

    constructor(
        private comparisonService: ComparisonService,
        private logger: NGXLogger,
        private router: Router,
    ) { }

    ngOnInit(): void {
        this.itemsArray = [];
        this.getEditable();
        this.getTargetItem();
        this.getCompareItems();
    }

    ngOnDestroy(): void {
        this.targetItemSubscription.unsubscribe();
        this.comparisonSubscription.unsubscribe();
    }

    getEditable(): void {
        this.isEditableSubscription = this.comparisonService.editableUpdate.subscribe((isEditable: boolean) => {
            this.isEditable = isEditable;
        });
    }

    getTargetItem(): void {
        this.targetItemSubscription = this.comparisonService.targetItemUpdate.subscribe((item: EntitySearchResult) => {
            this.targetItem = item;
        })
    }

    getCompareItems(): void {
        this.comparisonSubscription = this.comparisonService.comparisonUpdate.subscribe((items: EntitySearchStep2Result[]) => {
            this.itemsArray = items;
        });
    }

    closeSideNav() {
        this.closePressed.emit(true);
        this.closeResetReportConfigurationModal();
    }

    removeCompareItem(item: EntitySearchStep2Result) {
        this.logger.debug('Removing item: ', item);
        this.comparisonService.removeCompareItem(item);
    }

    openResetReportConfigurationModal(): void {
        this.logger.debug('Inside openResetReportConfigurationModal');
        this.showResetReportConfigurationModal = true;
    }

    closeResetReportConfigurationModal(): void {
        this.logger.debug('Inside closeResetReportConfigurationModal');
        this.showResetReportConfigurationModal = false;
    }

    confirmResetReportConfiguration(): void {
        this.logger.debug('Inside confirmResetReportConfiguration');
        for (let i = SEARCH_STEP.STEP_ONE; i <= SEARCH_STEP.STEP_FOUR; i++) {
            this.comparisonService.clearSessionItem(i);
        }

        // Close side nav
        this.closePressed.emit(true);
        // Close modal
        this.closeResetReportConfigurationModal();
        this.logger.debug('Going to data tool');
        this.router.navigate(['/data-tool']);
        this.comparisonService.triggerResetReportConfiguration();
    }

}
