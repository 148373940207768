import { AfterViewInit, Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';

// Models
import { EntitySearchQuery } from '../../queries/entity-search-query';

// Data Services
import { DistrictProfileService } from '../../../shared/services/data/district-profile.service';
import { Router } from '@angular/router';
import { EntitySearchService } from '../../../shared/services/data/entity-search.service';
import { DataToolService } from '../../../shared/services/data/datatool.service';
import {EntitySearchResult} from '../../query-models/entity-search-model';

import {concat, debounceTime, Observable, of, Subject, switchMap, throwError} from 'rxjs';
import { ProfileService } from '../../services/helpers/profile.service';
import { DOCUMENT } from '@angular/common';
import { EntityType, SurveyYearModel } from '../../models/profile-model';
import { NgSelectComponent } from '@ng-select/ng-select';
import { NGXLogger } from 'ngx-logger';
import {ToastService} from '@shared/components/toast/toast.service';
import {distinctUntilChanged, map, tap} from 'rxjs/operators';

@Component({
    selector: 'app-entity-search',
    templateUrl: './entity-search.component.html',
    styleUrls: ['./entity-search.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class EntitySearchComponent implements OnInit, AfterViewInit, OnChanges {

    @Input() entityType: EntityType;

    @Output() isSearched: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() entity: EventEmitter<EntitySearchResult> = new EventEmitter<EntitySearchResult>();
    @Output() showLoadingEmitter: EventEmitter<{ show: boolean, message?: string }> = new EventEmitter<{ show: boolean, message: string }>();

    @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;

    surveyYearKey: SurveyYearModel;
    //currentSearch: string = '';

    selectedEntity: number;
    pageKey: string;
    pageTitle: string;
    routeLoaded: boolean = false;
    runSearch: boolean = true;
    placeholder: string;
    initialized: boolean = false;

    entities: EntitySearchResult[] = [];
    entities$: Observable<EntitySearchResult[]>; // Observable<EntitySearchModel>;
    entitiesLoading = false;
    entitiesInput$ = new Subject<string>();

    constructor(
        private logger: NGXLogger,
        private toastService: ToastService,
        private router: Router,
        private readonly districtProfileService: DistrictProfileService,
        private readonly dataToolService: DataToolService,
        private readonly entitySearchService: EntitySearchService,
        private readonly profileService: ProfileService,
        @Inject(DOCUMENT) private document: Document
    ) {

    }

    ngOnInit(): void {
        this.placeholder = this.entityType ? 'Search by school or LEA' : "Search by school, district, or state";
        this.initialized = true;
        this.loadEntities();
    }

    ngAfterViewInit(): void {
        try {
            let container = this.ngSelectComponent.element.children[0];
            let valueContainer = container.children[0];
            let placeholderContainer = valueContainer.children[0];
            let input = this.ngSelectComponent.element.children[0].children[0].children[1].children[0];

            input['title'] = this.placeholder;
            input['autocomplete'] = "on";

            if (this.entityType) {
                container.setAttribute('style', 'border: 1px solid #133559; color: #133559;');
                input.setAttribute('style', 'color: #133559;');
                placeholderContainer.setAttribute('style', 'color: #133559;');
            }
        } catch (e) {
            //Silently fail just in case children break
        }

    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.entityType?.currentValue && this.ngSelectComponent) {
            this.entities = [];
            // For some reason have to do this instead of this.ngSelectComponent.handleClearClick(). That method
            // triggers an error where it says ExpressionChangedAfterItHasBeenCheckedError
            const input = this.ngSelectComponent.element.children[0].children[0].children[1].children[0] as HTMLInputElement;
            input.value = '';
        }
    }

    // onChange(input: any) {
    //
    //     this.logger.debug('Inside keyup onChange, input: ', input);
    //
    //     if (input.key === 'Backspace') {
    //         this.currentSearch = input.target.value;
    //     } else if (input.key.length === 1) {
    //         this.currentSearch = input.target.value;
    //     } else if (input.key == 'Meta') {
    //         this.currentSearch = input.target.value
    //     }
    //
    //     if (this.currentSearch.length >= 3 && this.runSearch) {
    //         this.runSearch = false;
    //         this.getEntitySearchData();
    //     } else if (this.currentSearch.length < 3) {
    //         this.runSearch = true;
    //         this.entities = [];
    //     }
    // }



    // once the user selects a value in the dropdown
    loadEntityDetails() {
        this.logger.debug('Inside loadEntityDetails, selectedEntity: ', this.selectedEntity);

        if (this.selectedEntity) {

            const selectedEntity =  this.entities.find(entity => entity.entity_ID === this.selectedEntity);

            if (this.entityType) {

                if (selectedEntity){
                    this.entity.emit(selectedEntity);
                }
                else {
                    this.logger.error('selectedEntity not found');
                }

            }
            else {
                //let entity = this.entities.find(entity => entity.entity_ID === this.selectedEntity);
                let entityType = selectedEntity.entity_Type;
                let surveyYears = JSON.parse(selectedEntity.syk).SYK;
                let yearKeys: number[] = surveyYears.map(year => year.Survey_Year_Key);
                let latestYearKey: number = Math.max(...yearKeys);

                this.surveyYearKey = this.profileService.getSurveyYearKeys(EntityType.GENERAL).find(year => year.yearKey === latestYearKey);
                sessionStorage.setItem("surveyYearKeys", selectedEntity.syk);

                switch (entityType) {
                    case 'Schools':
                        this.routeSchoolProfile(selectedEntity);
                        break;
                    case 'Districts':
                        this.routeDistrictProfile(selectedEntity);
                        break;
                    case 'States':
                        this.routeStateEstimation();
                        break;
                    default:
                        throwError("Unrecognized entity type.");
                }
            }

            this.entities = [];
            this.selectedEntity = undefined;
            //this.currentSearch = '';
            this.ngSelectComponent.handleClearClick();
            this.ngSelectComponent.blur();
        }
    }

    routeSchoolProfile(entity: any) {
        let state: string = entity.state_Name.replace(/\s+/g, '_').toLowerCase();
        let district: string = this.profileService.encodeCrdUri(entity.leA_Name.replace(/\s+/g, '_').toLowerCase());
        let school: string = this.profileService.encodeCrdUri(entity.school_Name.replace(/\s+/g, '_').toLowerCase());
        let ncesId: string = entity.lonG_NCES_ID;

        this.router.navigateByUrl(`profile/us/${state}/${district}/${school}?surveyYear=${this.surveyYearKey.surveyYear}&nces=${ncesId}`);
        this.isSearched.emit(true);
    }

    routeDistrictProfile(entity: any) {
        let state: string = entity.state_Name.replace(/\s+/g, '_').toLowerCase();
        let district: string = this.profileService.encodeCrdUri(entity.leA_Name.replace(/\s+/g, '_').toLowerCase());
        let ncesId: string = entity.lonG_NCES_ID;

        this.router.navigateByUrl(`profile/us/${state}/${district}?surveyYear=${this.surveyYearKey.surveyYear}&nces=${ncesId}`);
        this.isSearched.emit(true);
    }

    routeStateEstimation() {
        let stateEntity: EntitySearchResult = this.entities.find(entity => entity.entity_ID === this.selectedEntity);
        let stateName: string = stateEntity.entity_Name;
        let stateCode: any = this.profileService.getStateIds().find(state => state.stateName === stateName).state_Code;

        this.router.navigate([`profile/us/${stateCode}`], { queryParams: { surveyYear: this.surveyYearKey.surveyYear } });
        this.isSearched.emit(true);
    }

    private loadEntities() {
        this.logger.debug('Inside loadEntities');

        this.entities$ = concat(
            of([]), // default items
            this.entitiesInput$.pipe(
                debounceTime(350),
                distinctUntilChanged(), // only search if the input is changing
                tap(() => (this.entitiesLoading = true)),
                switchMap(searchTerm =>

                    // searchTerm.length < 3 ? [] : this.searchForEntityData(searchTerm).pipe(
                        this.searchForEntityData(searchTerm).pipe(
                        // map(entitySearchResults => {
                        //     return entitySearchResults;
                        // }),
                        //catchError(() => of([])), // empty list on error
                        tap(() => (this.entitiesLoading = false)),
                    ),

                    // this.dataService.getPeople(term).pipe(
                    //     catchError(() => of([])), // empty list on error
                    //     tap(() => (this.peopleLoading = false)),
                    // ),
                ),
            ),
        );
    }

    searchForEntityData(searchTerm: string) {

        this.logger.debug('Inside searchForEntityData, searchTerm: ', searchTerm);

        let query: EntitySearchQuery = new EntitySearchQuery();
        query.entityName = searchTerm;
        query.SYK_CutOff = 8;

        if (this.entityType) {

            query.entityType = this.entityType === EntityType.SCHOOL ? 'a' : 'b';

            //this.showLoading(true);

            // use new service call to secured API
            return this.dataToolService.getEntitySearchStep1(query).pipe(
                map(entitySearchResults => {

                    //this.showLoading(false);

                    if (!entitySearchResults) {

                        this.logger.error('getEntitySearchStep1 NO data');
                        this.toastService.info('No data found for the given search: ' + searchTerm);
                        return [];
                    }
                    else {

                        this.entities = this.formatSearchResults(entitySearchResults);
                        return this.entities;
                    }

                })
            );

            //     .subscribe(data => {
            //
            //     this.logger.debug('getEntitySearchStep1 response data: ', data);
            //     this.showLoading(false);
            //
            //     if (data) {
            //         this.entities = this.formatSearchResults(data.entitySearchResults);
            //     }
            //     else {
            //         this.logger.error('getEntitySearchStep1 NO data');
            //         this.toastService.info('No data found for the given search: ' + this.currentSearch);
            //     }
            // });
        }
        else {

            return this.entitySearchService.getEntitySearchResult(query).pipe(
                map(entitySearchResults => {

                    this.logger.debug('found entitySearchResults: ', entitySearchResults);

                    //this.showLoading(false);

                    if (!entitySearchResults) {

                        this.logger.error('getEntitySearchResult NO data');
                        this.toastService.info('No data found for the given search: ' + searchTerm);
                        return [];
                    }
                    else {

                        const formattedResults = this.formatSearchResults(entitySearchResults);

                        let states = formattedResults.filter(entity => entity.entity_Type === 'States');
                        let districts = formattedResults.filter(entity => entity.entity_Type === 'Districts');
                        let schools = formattedResults.filter(entity => entity.entity_Type === 'Schools');

                        this.entities = [...states, ...districts, ...schools];

                        return this.entities;
                    }

                })
            );



                // .subscribe(data => {
                //     this.logger.debug('getEntitySearchResult response data: ', data);
                //
                //     if (data) {
                //
                //         const formattedResults = this.formatSearchResults(data.entitySearchResults);
                //
                //         let states = formattedResults.filter(entity => entity.entity_Type === 'States');
                //         let districts = formattedResults.filter(entity => entity.entity_Type === 'Districts');
                //         let schools = formattedResults.filter(entity => entity.entity_Type === 'Schools');
                //
                //         this.entities = [...states, ...districts, ...schools];
                //     }
                // });

        }


    }

    // getEntitySearchData() {
    //
    //     this.logger.debug('Inside getEntitySearchData, currentSearch: ', this.currentSearch);
    //
    //     let query: EntitySearchQuery = new EntitySearchQuery();
    //     query.entityName = this.currentSearch;
    //     query.SYK_CutOff = 8;
    //
    //     if (this.entityType) {
    //         query.entityType = this.entityType === EntityType.SCHOOL ? 'a' : 'b';
    //
    //         this.showLoading(true);
    //
    //         // use new service call to secured API
    //         this.dataToolService.getEntitySearchStep1(query).subscribe(entitySearchResults => {
    //
    //             this.logger.debug('getEntitySearchStep1 response entitySearchResults: ', entitySearchResults);
    //             this.showLoading(false);
    //
    //             if (entitySearchResults) {
    //                 this.entities = this.formatSearchResults(entitySearchResults);
    //             }
    //             else {
    //                 this.logger.error('getEntitySearchStep1 NO data');
    //                 this.toastService.info('No data found for the given search: ' + this.currentSearch);
    //             }
    //         });
    //     } else {
    //         this.entitySearchService.getEntitySearchResult(query)
    //             .subscribe(entitySearchResults => {
    //                 this.logger.debug('getEntitySearchResult response entitySearchResults: ', entitySearchResults);
    //
    //                 if (entitySearchResults) {
    //
    //                     const formattedResults = this.formatSearchResults(entitySearchResults);
    //
    //                     let states = formattedResults.filter(entity => entity.entity_Type === 'States');
    //                     let districts = formattedResults.filter(entity => entity.entity_Type === 'Districts');
    //                     let schools = formattedResults.filter(entity => entity.entity_Type === 'Schools');
    //
    //                     this.entities = [...states, ...districts, ...schools];
    //                 }
    //             });
    //     }
    // }

    private formatSearchResults(searchResults: EntitySearchResult[]): EntitySearchResult[] {
        this.logger.debug('Inside formatSearchResults');    //, searchResults: ', searchResults);

        return searchResults.map(result => {
            let entityType: string = result.entity_Type;

            switch (entityType) {
                case 'a':
                    result.entity_Type = 'Schools';
                    break;
                case 'b':
                    result.entity_Type = 'Districts';
                    break;
                case 'c':
                    result.entity_Type = 'States';
                    break;
                default:
                    throwError(() => new Error("Search result with unknown entity type."));
            }

            return result;
        });
    }

    private showLoading(show: boolean, message: string = null) {

        this.logger.debug('Inside showLoading, show: ', show);

        if (show) {
            this.showLoadingEmitter.emit({ show: true, message: message ? message : 'Loading Data ...' })
        } else {
            this.showLoadingEmitter.emit({ show: false })
        }
    }
}
