import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { EntityType, SurveyYearModel } from '../../models/profile-model';
import { DistrictSearchQuery } from '../../queries/district-search-query';
import { SchoolSearchQuery } from '../../queries/school-search-query';
import { DistrictProfileQueryModel } from '../../query-models/district-profile-query-model';
import { SchoolProfileQueryModel } from '../../query-models/school-profile-query-model';
import { DistrictSearchService } from '../../services/data/district-search.service';
import { SchoolSearchService } from '../../services/data/school-search.service';
import { ProfileService } from '../../services/helpers/profile.service';

export interface School {
  ncesId: string;
  school: string;
  district: string;
  state: string;
  enrollment: number;
  entityId: number;
}

export interface District {
  ncesId: string;
  district: string;
  state: string;
  numSchools: number;
  enrollment: number;
  gradesOffered: string;
  entityId: number;
}

@Component({
  selector: 'app-find-nearby',
  templateUrl: './find-nearby.component.html',
  styleUrls: ['./find-nearby.component.scss']
})
export class FindNearbyComponent implements OnInit {

  @Input() entityType: EntityType;
  @Input() surveyYearKey: number;

  headerText: string;
  subHeaderText: string;
  dataLoaded: boolean = false;
  formattedData: any[];
  sortedData: any[];
  schoolProfile: SchoolProfileQueryModel;
  districtProfile: DistrictProfileQueryModel;
  stateProfile: any;

  constructor(
    private readonly schoolSearchService: SchoolSearchService,
    private readonly districtSearchService: DistrictSearchService,
    private profileService: ProfileService,
    private router: Router
  ) { }

  @ViewChild(MatSort) sort: MatSort;

  ngOnInit(): void {
    this.generateFindNearby();
  }

  generateFindNearby() {
    let schoolQuery: SchoolSearchQuery = new SchoolSearchQuery();
    let districtQuery: DistrictSearchQuery = new DistrictSearchQuery();

    schoolQuery.survey_Year_Key = this.surveyYearKey;
    districtQuery.survey_Year_Key = this.surveyYearKey;

    let stateCode: string;
    let stateName: string;

    switch (this.entityType) {
      case EntityType.SCHOOL:
        this.schoolProfile = JSON.parse(sessionStorage.getItem('schoolProfile'));

        schoolQuery.leaId = this.schoolProfile.leaId;

        this.search(schoolQuery, EntityType.SCHOOL);
        this.headerText = `Schools in District: ${this.schoolProfile.leaName}`;
        this.subHeaderText = 'If a school is not listed, then the school can be found using the search bar.';
        break;
      case EntityType.DISTRICT:
        this.districtProfile = JSON.parse(sessionStorage.getItem('districtProfile'));

        schoolQuery.leaId = this.districtProfile.leaId;

        this.search(schoolQuery, EntityType.SCHOOL);
        this.headerText = `Schools in District: ${this.districtProfile.leaName}`;
        this.subHeaderText = 'If a school is not listed, then the school can be found using the search bar.';
        break;
      case EntityType.STATE:
        this.stateProfile = JSON.parse(sessionStorage.getItem('stateProfile'));

        stateCode = this.stateProfile.state_Code;
        stateName = this.profileService.getStateIds().find(state => state.state_Code === stateCode).stateName;
        districtQuery.stateKeys = stateCode;

        this.search(districtQuery, EntityType.DISTRICT);
        this.headerText = `Districts in ${stateName}`;
        this.subHeaderText = 'If a school district is not listed, then the school district can be found using the search bar.';
        break;
      case EntityType.NATIONAL:
        this.headerText = 'Schools in the United States';
        break;
      default:
        throw new Error("Unknown entity type");
    }
  }

  search(query: any, entityType: EntityType) {
    switch (entityType) {
      case EntityType.SCHOOL:
        this.schoolSearchService.get(query).subscribe(data => {
          if (data) {
            this.formatData(data, entityType);
          } else {
            throw new Error("Could not get school data.");
          }
        });
        break;
      case EntityType.DISTRICT:
        this.districtSearchService.get(query).subscribe(data => {
          if (data) {
            this.formatData(data, entityType);
          } else {
            throw new Error("Could not get school data.");
          }
        });
        break;
    }
  }

  formatData(data: any[], entityType: EntityType) {
    let formattedData: any[];

    switch (entityType) {
      case EntityType.SCHOOL:
        formattedData = data.map(school => {
          return {
            ncesId: school.long_Nces_Id,
            school: school.sch_Name,
            district: school.lea_Name,
            state: school.sch_State,
            enrollment: school.b_Tot_Enrol,
            entityId: school.school_Id
          }
        });
        break;
      case EntityType.DISTRICT:
        formattedData = data.map(district => {
          let gradesOffered: string = district.gradesOffered;
          let grades: string[];
          let formattedGrades: string;

          if (gradesOffered) {
            grades = gradesOffered?.split(',');
            formattedGrades = `${grades[0]} -${grades[grades.length - 1]}`;
          }

          return {
            ncesId: district.leaId,
            district: district.lea_Name,
            state: district.lea_State,
            numSchools: district.toT_SCH,
            enrollment: district.b_Tot_Enrol,
            gradesOffered: formattedGrades,
            entityId: district.lea_Id
          }
        });
        break;
    }

    this.formattedData = formattedData;
    this.sortedData = this.formattedData.slice();
    this.dataLoaded = true;
  }

  sortData(sort: Sort) {
    const data = this.formattedData.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'ncesId':
          return this.compare(a.ncesId, b.ncesId, isAsc);
        case 'school':
          return this.compare(a.school, b.school, isAsc);
        case 'district':
          return this.compare(a.district, b.district, isAsc);
        case 'enrollment':
          return this.compare(a.enrollment, b.enrollment, isAsc);
        case 'numSchools':
          return this.compare(a.numSchools, b.numSchools, isAsc);
        case 'gradesOffered':
          return this.compare(a.gradesOffered, b.gradesOffered, isAsc);
        default:
          return 0;
      }
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  routeSchool(schoolElement: School) {
    let state: string = schoolElement.state.replace(/\s+/g, '_').toLowerCase();
    let district: string = this.profileService.encodeCrdUri(schoolElement.district.replace(/\s+/g, '_').toLowerCase());
    let school: string = this.profileService.encodeCrdUri(schoolElement.school.replace(/\s+/g, '_').toLowerCase());
    let schoolRoute: string = `profile/us/${state}/${district}/${school}`;

    let surveyYears: SurveyYearModel[] = this.profileService.getSurveyYearKeys(EntityType.GENERAL);
    let surveyYear: SurveyYearModel = surveyYears.find(year => year.yearKey === this.surveyYearKey);

    this.router.navigate([schoolRoute], { queryParams: { surveyYear: surveyYear.surveyYear, nces: schoolElement.ncesId } });
  }

  routeDistrict(districtElement: District) {
    let state: string = districtElement.state.replace(/\s+/g, '_').toLowerCase();
    let district: string = this.profileService.encodeCrdUri(districtElement.district.replace(/\s+/g, '_').toLowerCase());
    let districtRoute: string = `profile/us/${state}/${district}`;

    let surveyYears: SurveyYearModel[] = this.profileService.getSurveyYearKeys(EntityType.GENERAL);
    let surveyYear: SurveyYearModel = surveyYears.find(year => year.yearKey === this.surveyYearKey);

    this.router.navigate([districtRoute], { queryParams: { surveyYear: surveyYear.surveyYear, nces: districtElement.ncesId } });
  }
}
