import {Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import * as XLSX from 'xlsx-js-style'; //'xlsx';
import * as FileSaver from 'file-saver';
import {
    EntitySearchStep4PreViewModel,
    GENDER_TYPE,
    MEASURE_TYPE,
    RACE_TYPE,
    Step4Entity,
    Step4Measure,
    Step4SYKData
} from '../../shared/models/entity-search-step4-pre-view.model';

@Injectable({
    providedIn: 'root'
})
export class ExportDataService {

    private EXCEL_TYPE: string = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    private EXCEL_EXTENSION: string = '.xlsx';
    private EXCEL_SHEET_COLUMNS: string[] = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'AA', 'AB', 'AC', 'AD', 'AE', 'AF', 'AG', 'AH', 'AI', 'AJ', 'AK', 'AL', 'AM', 'AN', 'AO', 'AP'];

    constructor(
        private logger: NGXLogger,
    ) {}


    /**
     * This method will take step 4 data and export it to an excel spreadsheet
     * The spreadsheet will have a tab for each module and show multiple measures for each module within each module spreadsheet tab
     * Each measure can have one or more survey years and within each survey year you can have a combination of
     * many different race and gender groups
     * @param reportData
     */
    public exportResultsToExcel(reportData: EntitySearchStep4PreViewModel, addRiskRatioTpExportedData: boolean): number {

        this.logger.debug('Inside exportResultsToExcel, reportData: ', reportData, ', addRiskRatioTpExportedData: ', addRiskRatioTpExportedData);

        let modulesAddedCount = 0;

        if (reportData?.result) {

            // get the list of modules
            const reportModules = reportData.result;

            const moduleNames: string[] = reportModules.map(m => m.module_Title);

            this.logger.debug('moduleNames: ', moduleNames);

            // get the list of schools
            const schoolNames: string[] = reportModules[0].data[0]?.data.map(entity => entity.entity_Name);

            this.logger.debug('schoolNames: ', schoolNames);

            // create workbook
            const workbook = XLSX.utils.book_new();

            this.logger.debug('reportModules: ', reportModules);

            if (reportModules?.length > 0) {

                this.logger.debug('have reportModules');
                reportModules.forEach((module, moduleIndex: number) => {

                    let measureType;
                    let measureSheetDataRows: any[] = [];
                    let haveRaceData = false;
                    let schoolColumnsCount = 2; // defaults to count and percentage - ENR

                    //  - for each sheet/module add the list of measures
                    //      - for each measure add a header
                    //      - for each measure add the data

                    this.logger.debug('module.data?.measure: ', module.module_Title, module.data[0]?.data);

                    // loop through the list of measures for the given module
                    module.data?.forEach((measureInstance, measureIndex) => {

                        measureType = measureInstance.location;
                        this.logger.debug('measureInstance: ', measureInstance);

                        if (measureType === MEASURE_TYPE.ENR){
                            haveRaceData = true;

                            // we use 3 columns for the school name so that we have a column for: count, percentage and risk ratio,
                            // but if the user did not select to see risk ratio we need to hide the column
                            if (addRiskRatioTpExportedData){
                                this.logger.debug('add risk ratio column');
                                schoolColumnsCount = 3;
                            }
                            else {
                                schoolColumnsCount = 2;
                            }
                        }
                        else if (measureInstance.location === MEASURE_TYPE.MISC){
                            schoolColumnsCount = 1;  // only a single count value, no percentage or risk ratio
                        }
                        else if (measureInstance.location === MEASURE_TYPE.DETAILS){
                            //this.logger.debug('Skipping measureInstance: ', measureInstance.measure_Title);
                            schoolColumnsCount = 1; // question:answer -> needs 2 columns
                        }


                        // if the first measure add the module header
                        if (measureIndex === 0){
                            this.logger.debug('Add module header: ', moduleNames[moduleIndex]);

                            const moduleHeader = {v: 'Module - ' +  moduleNames[moduleIndex], t: 's', s: { font: { name: "Arial", sz: 14, bold: true } } }
                            // now ass a blank space between measures
                            measureSheetDataRows.push({ A: ' ', B: moduleHeader});
                            modulesAddedCount++;
                        }

                        const measureSheetRowData = this.createMeasureData(measureInstance, schoolNames, schoolColumnsCount);

                        measureSheetRowData.forEach(row => {

                            measureSheetDataRows.push(row);
                        });

                    });


                    if (measureSheetDataRows.length > 0){

                        // now add the risk ratio note
                        if (addRiskRatioTpExportedData && haveRaceData){
                            this.addWorkSheetFooter(module.module_Title, measureSheetDataRows);
                        }

                        this.logger.debug('measureSheetDataRows: ', measureSheetDataRows);
                        // create a sheet for each module and add the sheets to a workbook
                        const ws = this.createWorkSheet(workbook, moduleNames[moduleIndex], measureType, measureSheetDataRows);
                    }
                    else {
                        this.logger.debug('Skipping module: ', module.module_Title);
                    }

                });
            }

            if (modulesAddedCount > 0){

                //const fileName = 'data_file_request_' + new Date().toDateString();
                //this.logger.error('fileName: ', fileName);
                // save the workbook to disc
                this.saveWorkBook("report_export_data", workbook);
            }


        } else {
            this.logger.error('Invalid Report Data');
        }

        return modulesAddedCount;

    }

    /**
     * This created the survey year table header columns. It includes adding two columns for each selected schools
     * representing the student count and percentage for each race/gender group
     * @param schoolNames
     * @private
     */
    private createSurveyYearHeaderWithAllSchools(measureType: MEASURE_TYPE, schoolNames: string[], measureSheetRowData: any[], schoolColumnsCount: number): void {

        this.logger.debug('Inside createSurveyYearHeader, schoolColumnsCount: ', schoolColumnsCount);

        const finalMeasureHeader = {};
        const schoolNamesHeader = {};
        let measureHeader = ['Description', 'Year'];
        const schoolColumnOffset = 2; // we start after the description and year columns

        let sheetColumnIndex = 0;

        let nextSchoolColumnOffset = 0;

        // Add the school Names above the count and percentage columns
        schoolNames.forEach((schoolName,schoolIndex) => {

            if (schoolIndex === 0){
                nextSchoolColumnOffset = schoolColumnOffset;    // (0 + 1)*2 = 2, (1+1)*2 = 4
            }
            else {
                nextSchoolColumnOffset +=  schoolColumnsCount;
            }

            //this.logger.debug('nextSchoolColumnOffset: ', nextSchoolColumnOffset);

            //{v: schoolName, t: 's', s: { font: { name: "Arial", sz: 12, bold: true }, fill : {fgColor: { rgb: 'fde8d9'}} } };
            const schoolColumn = {v: schoolName, t: 's', s: { font: { name: "Arial", sz: 12, bold: true } } };

            schoolNamesHeader[this.EXCEL_SHEET_COLUMNS[nextSchoolColumnOffset]] = schoolColumn;

        });

        this.logger.debug('schoolNamesHeader: ', schoolNamesHeader);

        measureSheetRowData.push(schoolNamesHeader);

        schoolNames.forEach(s => {

            if (measureType === MEASURE_TYPE.DETAILS){

                measureHeader.push('Answer');
            }
            // ENR or MISC
            else {

                measureHeader.push('Count');

                // for schoolColumnsCount = 2
                if (schoolColumnsCount > 1){
                    measureHeader.push('Percentage');
                }

                // we use 3 columns for the school name so that we have a column for: count, percentage and risk ratio,
                // but if the user did not select to see risk ratio we need to hide the column
                //measureType === MEASURE_TYPE.ENR
                if (schoolColumnsCount === 3){
                    measureHeader.push('Relative Risk Ratio');
                }
            }

        });


        for (const key in measureHeader) {

            const headerColumn = {v: measureHeader[key], t: 's', s: { font: { name: "Arial", sz: 12, bold: true }, border: {bottom: {style: 'thin', color: 'black'}}, fill : {fgColor: { rgb: 'fde8d9'}} } };

            if (measureHeader[key]) {
                finalMeasureHeader[this.EXCEL_SHEET_COLUMNS[sheetColumnIndex]] = headerColumn;
                sheetColumnIndex++; // go to the next column
            }
        }

        this.logger.debug('finalMeasureHeader: ', finalMeasureHeader);

        measureSheetRowData.push(finalMeasureHeader);

    }

    /**
     * This will create a table for each survey year within a measure for each school inside the measure
     * The table will include a list of gender/race groups with the total students for each school as well as the
     * percentage of students for each race/gender group within the school
     * @param measureTile
     * @param measureInputData: is all the shool data for a given measure
     * @param schoolNames
     * @private
     */
    private createMeasureData(measureInstance: Step4Measure, schoolNames: string[], schoolColumnsCount: number): any[] {

        const measureTile = measureInstance.measure_Title;
        const measureSchoolDataList: Step4Entity[] = measureInstance.data;
        const measureType: MEASURE_TYPE = measureInstance.location;

        this.logger.debug('Inside createMeasureData, measureSchoolDataList: ', measureSchoolDataList, ', measureTile: ', measureTile, ', schoolColumnsCount: ', schoolColumnsCount);

        const measureSheetRowData = [];
        // const measureSheetData = [
        //     { A: "S", B: "h", C: "e", D: "e", E: "t", F: "J", G: "S" }
        // ];

        // XLSX.utils.sheet_add_json(ws, [
        //   { A: 1, B: 2 }, { A: 2, B: 3 }, { A: 3, B: 4 }
        // ], {skipHeader: true, origin: "A2"});

        // add measure title header
        if (measureTile) {
            const measureHeader = {v: 'Question - ' + measureTile, t: 's', s: { font: { name: "Arial", sz: 12, bold: true } } };

            measureSheetRowData.push({ A: ' ', B: ' '});    // add an empty row for spacing
            measureSheetRowData.push({ A: ' ', B: measureHeader});
            measureSheetRowData.push({ A: ' ', B: ' '});    // add an empty row for spacing
        }

        this.createSurveyYearHeaderWithAllSchools(measureType, schoolNames, measureSheetRowData, schoolColumnsCount);


        // if we have data for at least one school with at least one year
        if (measureSchoolDataList?.length > 0 && measureSchoolDataList[0]?.data?.length > 0) {

            const attributeDescriptions = Object.keys(measureSchoolDataList[0]?.data[0].sykData);

            let isLastAttribute = false;

            // we start with an attribute, then get all the school data and year data for each attribute
            attributeDescriptions.forEach((sykDataAttr, sykDataAttrIndex) => {

                this.logger.debug('Printing attr description: ', sykDataAttr);

                if (sykDataAttr === 'studentEnrollment'){
                    //this.logger.debug('skip sykDataAttr: ', sykDataAttr);
                } else {

                    let sykDataAttributeSchoolYearRows;

                    if (measureType === MEASURE_TYPE.DETAILS){
                        sykDataAttributeSchoolYearRows = this.createSYKDataQuestionAnswerAttributeSchoolAndYearRows(measureType, measureSchoolDataList, measureTile);
                    }
                    else {
                        sykDataAttributeSchoolYearRows = this.createSYKDataAttributeSchoolAndYearRows(measureType, sykDataAttr, measureSchoolDataList, measureTile, schoolColumnsCount);
                    }

                    // if the last attribute add the footer
                    if ((attributeDescriptions.length - 1) === sykDataAttrIndex){
                        this.logger.debug('add footer: ');
                        isLastAttribute = true;
                        this.addMeasureFooter(sykDataAttributeSchoolYearRows);
                    }


                    sykDataAttributeSchoolYearRows.forEach(row => {
                        measureSheetRowData.push(row);
                    });


                }

            });

        }

        // now ass a blank space between measures
        measureSheetRowData.push({ A: ' ', B: ' ' });

        this.logger.debug('measureSheetRowData: ', measureSheetRowData);
        return measureSheetRowData;

    }

    /**
     *
     * @param sykDataAttribute
     * @param isLastAttribute
     * @param measureSchoolDataList: list of schools fot this measure
     * @private
     */
    private createSYKDataAttributeSchoolAndYearRows(measureType: MEASURE_TYPE, sykDataAttribute: string, measureSchoolDataList: Step4Entity[], measureTile: string, schoolColumnsCount: number): any[] {

        this.logger.debug('Inside createSYKDataAttributeSchoolAndYearRows, sykDataAttribute: ', sykDataAttribute, ', measureSchoolDataList: ', measureSchoolDataList);

        try {

            const schoolColumnOffset = 2; // we start after the description and year columns

            const sykDataAttributeRows: any[] = [];
            let raceGenderDescription: string;

            // we use 3 columns for the school name so that we have a column for: count, percentage and risk ratio
            // but if the user dis not select to see risk ratio we need to hide the column
            // Also we only translate the descriptions for race and gender if measure type is ENR
            if (measureType === MEASURE_TYPE.ENR) {

                raceGenderDescription = this.getRaceGenderDescription(sykDataAttribute);
            }
                // this is where the measure location = MISC
            // if it is a miscellaneous measure there will be no gender available and we use the measure title for the description
            else if (measureType === MEASURE_TYPE.MISC) {
                raceGenderDescription = measureTile;
            }
            else {
                raceGenderDescription = 'Unknown';
            }
            // list of all school years (SYK list)
            const schoolYears = measureSchoolDataList[0].data.map(schoolYear => schoolYear.syk);

            this.logger.debug('schoolYears: ', schoolYears);

            const genderRaceColumn = {v: raceGenderDescription, t: 's', s: {font: {name: "Arial", sz: 11}}};


            // walk through all school years and get all the school data for a given year to be processed
            schoolYears.forEach((schoolYear, yearIndex) => {

                this.logger.debug('schoolYear: ', schoolYear, yearIndex);
                let sykDataAttributeRaceGenderRowData: any = null;
                const allSchoolsForSpecificYear: Step4SYKData[] = [];
                const yearColumnStyle: any = {font: {name: "Arial", sz: 11}, alignment: {horizontal: 'left'}};

                // now get all the syk data for a given year (Entity_Data list)
                measureSchoolDataList.forEach(school => {

                    const yData = school.data.find(schoolYearData => schoolYearData.syk === schoolYear);

                    if (yData) {
                        allSchoolsForSpecificYear.push(yData);
                    }
                });

                this.logger.debug('allSchoolsForSpecificYear: ', allSchoolsForSpecificYear, 'sykDataAttribute: ', sykDataAttribute);

                // find the school year period
                const schoolYearPeriod: string = allSchoolsForSpecificYear[0].surveyPeriod;
                //now find all school data for this year for the given syk attribute (list of numbers for every school for a given attribute)
                const attributeSchoolYearData = allSchoolsForSpecificYear.map(schoolYearData => schoolYearData.sykData[sykDataAttribute]);

                //now find all the StudentCounts for this year (list of numbers for every school for attribute: 'studentEnrollment')
                let schoolStudentCountYearData;
                if (measureType === MEASURE_TYPE.ENR){
                    // this is the total count for both male and female
                    schoolStudentCountYearData = allSchoolsForSpecificYear.map(schoolYearData => schoolYearData.sykData['B_TOT'] ? schoolYearData.sykData['B_TOT']['count'] : schoolYearData.sykData['studentEnrollment']['count']); //allSchoolsForSpecificYear.map(schoolYearData => schoolYearData.sykData['studentEnrollment']['count']);
                }
                else if (measureType === MEASURE_TYPE.MISC){
                    // this is the total student count in the school
                    schoolStudentCountYearData = allSchoolsForSpecificYear.map(schoolYearData => schoolYearData.sykData['studentEnrollment']['count']);
                }

                // this is the total student count in the school
                //const schoolStudentCountYearData = allSchoolsForSpecificYear.map(schoolYearData => schoolYearData.sykData['studentEnrollment']['count']);
                // this is the total count for both male and female
                //const schoolStudentCountYearData = allSchoolsForSpecificYear.map(schoolYearData => schoolYearData.sykData['B_TOT']['count']);


                this.logger.debug('attributeSchoolYearData: ', attributeSchoolYearData, ', schoolStudentCountYearData: ', schoolStudentCountYearData, 'reportYearPeriod: ', schoolYearPeriod);

                const reportYearPeriod = schoolYearPeriod;  //this.profileService.getSurveyYearPeriod(schoolYear); // year

                //this.logger.debug('reportYearPeriod: ', reportYearPeriod);

                // column A & B
                if (yearIndex === 0) {
                    sykDataAttributeRaceGenderRowData = {A: genderRaceColumn, B: {v: reportYearPeriod, t: 's', s: yearColumnStyle}};
                } else {
                    sykDataAttributeRaceGenderRowData = {A: ' ', B: {v: reportYearPeriod, t: 's', s: yearColumnStyle}};
                }


                let nextSchoolColumnOffset = 0;
                // now go through the list of schools for this year and add it to the spreadsheet
                attributeSchoolYearData.forEach((sykAttributeYearSchoolData, schoolIndex) => {

                    if (schoolIndex === 0) {
                        nextSchoolColumnOffset = schoolColumnOffset;    // (0 + 1)*2 = 2, (1+1)*2 = 4
                    } else {
                        nextSchoolColumnOffset += schoolColumnsCount;
                    }

                    //const nextSchoolColumnOffset = (schoolIndex + 1)*schoolColumnOffset;
                    const schoolYearAttributeColumnStyle: any = {font: {name: "Arial", sz: 11}, alignment: {horizontal: 'center'}};
                    this.logger.debug('nextSchoolColumnOffset: ', nextSchoolColumnOffset, 'sykAttributeYearSchoolData: ', sykAttributeYearSchoolData);

                    // column C - School Count
                    //sykDataAttributeRaceGenderRowData[this.EXCEL_SHEET_COLUMNS[nextSchoolColumnOffset]] = {v: schoolStudentCountYearData[schoolIndex], t: 's', s: schoolYearAttributeColumnStyle}; // school student count

                    let attrPercentage: string = '0.00';
                    let attrCount: string = '0';
                    let attrRatio: string = 'N/A';

                    // for misc and details
                    if (schoolColumnsCount === 1) {

                        if (sykAttributeYearSchoolData.count < 0) {
                            attrCount = 'N/A';
                        }
                        else {
                            attrCount = sykAttributeYearSchoolData.count.toFixed(2);
                        }

                    }
                    // only ENR
                    else if (schoolColumnsCount > 1){

                        if (sykAttributeYearSchoolData.count >= 0) {

                            if (measureType === MEASURE_TYPE.ENR) {
                                attrPercentage = ((sykAttributeYearSchoolData.count / schoolStudentCountYearData[schoolIndex]) * 100).toFixed(2)  + '%';  //Math.ceil() Math.round(): round to the nearest integer , Math.round(num * 100) / 100 to the nearest 2 digits
                                //this.logger.debug('attrPercentage: ', attrPercentage, ((sykAttributeYearSchoolData / schoolStudentCountYearData[schoolIndex]) * 100) );

                                attrCount = sykAttributeYearSchoolData.count;
                            }
                            else {
                                this.logger.error('Invalid measureType: ', measureType);
                            }

                        }
                        // set everything to NA
                        else {
                            attrPercentage = 'N/A'; // cannot deal with negative numbers
                            attrCount = attrPercentage;
                        }

                        // if we need to add the risk ratio column
                        if (schoolColumnsCount === 3) {

                            if (sykAttributeYearSchoolData?.ratio > 0) {
                                attrRatio = sykAttributeYearSchoolData?.ratio.toFixed(2);
                            }
                            // need to handle -1, -9 and null
                            else if (!sykAttributeYearSchoolData.ratio || sykAttributeYearSchoolData?.ratio < 0) {
                                attrRatio = 'N/A';
                            }
                        }

                    }

                    // column C - school count attribute value
                    sykDataAttributeRaceGenderRowData[this.EXCEL_SHEET_COLUMNS[nextSchoolColumnOffset]] = {
                        v: attrCount,
                        t: 's',
                        s: schoolYearAttributeColumnStyle
                    }; // school student count

                    //this.logger.debug('attrPercentage: ', attrPercentage);

                    if (schoolColumnsCount > 1){
                        // column D - using count to calculate School Percentage - we only calculate the percentage if the value is a positive number
                        sykDataAttributeRaceGenderRowData[this.EXCEL_SHEET_COLUMNS[1 + nextSchoolColumnOffset]] = {
                            v: attrPercentage,
                            t: 's',
                            s: schoolYearAttributeColumnStyle
                        }; // race/gender percentage of school student count
                    }


                    // column E: only enrollments support risk ratio
                    if (schoolColumnsCount === 3) {
                        // column E - ratio
                        sykDataAttributeRaceGenderRowData[this.EXCEL_SHEET_COLUMNS[2 + nextSchoolColumnOffset]] = {
                            v: attrRatio,
                            t: 's',
                            s: schoolYearAttributeColumnStyle
                        }; // race/gender percentage of school student count
                    }

                    this.logger.debug('add sykDataAttributeRaceGenderRowData: ', sykDataAttributeRaceGenderRowData);

                });

                sykDataAttributeRows.push(sykDataAttributeRaceGenderRowData);

            });

            // // if the last attribute then add a border to the bottom
            // if (isLastAttribute) {
            //
            //     const lastRow = sykDataAttributeRows[sykDataAttributeRows.length - 1];
            //
            //     this.logger.debug('add border, lastRow: ', lastRow);
            //
            //     for (const key in lastRow) {
            //
            //         if (lastRow[key].s) {
            //             lastRow[key].s['border'] = borderStyle;
            //         }
            //         else {
            //             //this.logger.debug('no style for: ', key);
            //         }
            //
            //     }
            // }
            //
            // this.logger.debug('sykDataAttributeRows: ', sykDataAttributeRows);

            return sykDataAttributeRows;

        }
        catch (e) {
            this.logger.error('Error in createSYKDataAttributeSchoolAndYearRows trying to read sykDataAttribute['+sykDataAttribute+'] for measureTile: ', measureTile);
            throw e;
        }

    }

    private createSYKDataQuestionAnswerAttributeSchoolAndYearRows(measureType: MEASURE_TYPE, measureSchoolDataList: Step4Entity[], measureTile: string): any[] {

        this.logger.debug('Inside createSYKDataQuestionAnswerAttributeSchoolAndYearRows, measureSchoolDataList: ', measureSchoolDataList);

        if (measureType !== MEASURE_TYPE.DETAILS) {
            this.logger.debug('Invalid measure type: ', measureType, ' for measureTile: ', measureTile);
            return [];
        }

        try {

            const schoolColumnOffset = 2; // we start after the description and year columns

            const sykDataAttributeRows: any[] = [];

            // get a list of unique question based on the first year data
            const questionListForAllSchools = measureSchoolDataList[0].data[0].sykData.questionAnswers.map(questionAnswerData => questionAnswerData.question);

            this.logger.debug('questionListForAllSchools: ', questionListForAllSchools);

            // list of all school years (SYK list)
            const schoolYears = measureSchoolDataList[0].data.map(schoolYear => schoolYear.syk);

            const schoolYearRowOffset = schoolYears.length;

            this.logger.debug('schoolYears: ', schoolYears, ', schoolYearRowOffset: ', schoolYearRowOffset);

            // now go question for question and get the year and answer for each school
            questionListForAllSchools.forEach(question => {

                // walk through all school years and get all the school data for a given year to be processed
                schoolYears.forEach((schoolYear, yearIndex) => {

                    this.logger.debug('schoolYear: ', schoolYear, yearIndex);
                    let sykDataAttributeQuestionAnswerRowData: any = null;
                    const allSchoolsForSpecificYear: Step4SYKData[] = [];

                    // now get all the syk data for a given year (Entity_Data list)
                    measureSchoolDataList.forEach(school => {

                        const yData = school.data.find(schoolYearData => schoolYearData.syk === schoolYear);

                        if (yData) {
                            allSchoolsForSpecificYear.push(yData);
                        }
                    });

                    this.logger.debug('allSchoolsForSpecificYear: ', allSchoolsForSpecificYear);

                    // find the school year period
                    const schoolYearPeriod: string = allSchoolsForSpecificYear[0].surveyPeriod;

                    // now get all the answers for all schools for a given year
                    const answerListForAllSchoolsThisYearThisQuestion = allSchoolsForSpecificYear.map(schoolData => schoolData.sykData.questionAnswers.find(q => q.question === question).answer);

                    this.logger.debug('answerListForAllSchoolsThisYearThisQuestion[' + question + ']: ', answerListForAllSchoolsThisYearThisQuestion);

                    const yearColumnStyle: any = {font: {name: "Arial", sz: 11}, alignment: {horizontal: 'left'}};

                    // column A - Question text and B - year
                    if (yearIndex === 0) {
                        const questionColumn = {v: question, t: 's', s: {font: {name: "Arial", sz: 11} , alignment: {horizontal: 'left', wrapText: true}}};
                        sykDataAttributeQuestionAnswerRowData = {A: questionColumn, B: {v: schoolYearPeriod, t: 's', s: yearColumnStyle}};
                    }
                    else {
                        sykDataAttributeQuestionAnswerRowData = {A: ' ', B: {v: schoolYearPeriod, t: 's', s: yearColumnStyle}};
                    }

                    let nextSchoolColumnOffset = 0;

                    answerListForAllSchoolsThisYearThisQuestion.forEach((schoolAnswer, schoolIndex) => {

                        if (schoolIndex === 0) {
                            nextSchoolColumnOffset = schoolColumnOffset;
                        } else {
                            nextSchoolColumnOffset += 1;    // we only have one column (answer) for each school
                        }

                        const schoolYearAttributeColumnStyle: any = {font: {name: "Arial", sz: 11}, alignment: {horizontal: 'center'}};

                        // column C - answer
                        sykDataAttributeQuestionAnswerRowData[this.EXCEL_SHEET_COLUMNS[nextSchoolColumnOffset]] = {
                            v: schoolAnswer,
                            t: 's',
                            s: schoolYearAttributeColumnStyle
                        }; // school student count


                    });

                    this.logger.debug('add sykDataAttributeQuestionAnswerRowData: ', sykDataAttributeQuestionAnswerRowData);

                    sykDataAttributeRows.push(sykDataAttributeQuestionAnswerRowData);

                }); // end year

            }); // end question

            this.logger.debug('sykDataAttributeRows: ', sykDataAttributeRows);

            return sykDataAttributeRows;

        }
        catch (e) {
            this.logger.error('Error in createSYKDataAttributeSchoolAndYearRows trying to read questionAnswers for measureTile: ', measureTile);
            throw e;
        }

    }

    // if the last attribute then add a border to the bottom
    private addMeasureFooter(sykDataAttributeRows: any[]): void {
        this.logger.debug('Inside addMeasureFooter');

        const lastRow = sykDataAttributeRows[sykDataAttributeRows.length - 1];
        const borderStyle = {bottom: {style: 'thin', color: 'black'}};

        this.logger.debug('add border, lastRow: ', lastRow);

        for (const key in lastRow) {

            if (lastRow[key].s) {
               lastRow[key].s['border'] = borderStyle;
            }
            else {
                //this.logger.debug('no style for: ', key);
            }

        }

        this.logger.debug('sykDataAttributeRows: ', sykDataAttributeRows);
    }

    /**
     * This method creates a new spreadsheet tab for each module in the search results
     * @param workbook
     * @param sheetName
     * @param rows
     * @private
     */
    private createWorkSheet(workbook: XLSX.WorkBook, sheetName: string, measureType: MEASURE_TYPE, rows: any[]): XLSX.WorkSheet {

        this.logger.debug('Inside createWorkSheet, sheetName: ', sheetName);

        // sheet names are not allowed to have any of the following characters: /
        if (!sheetName) {
            this.logger.error('invalid sheetName: ', sheetName);
            sheetName = 'unknown';
        }
        else if (sheetName.includes('/')){
            sheetName = sheetName.replace(/\//g, '-');
            this.logger.debug('modified sheetName: ', sheetName);
        }


        // const ws = XLSX.utils.json_to_sheet([
        //     sheetName,
        // ], {skipHeader: false});

        const sheetOptions: XLSX.JSON2SheetOpts = {header: this.EXCEL_SHEET_COLUMNS, skipHeader: true};

        // It will skip generating a header row and one row per object in the rows array
        const ws = XLSX.utils.json_to_sheet(rows, sheetOptions);

        // set column widths
        const columnWidths = [];

        this.EXCEL_SHEET_COLUMNS.forEach(columnName => {

            if (columnName === 'A'){

                if (measureType === MEASURE_TYPE.DETAILS){
                    columnWidths.push({ wch: 100 });
                }
                else {
                    columnWidths.push({ wch: 50 });
                }

            }
            else if (columnName === 'B'){
                columnWidths.push({ wch: 10 });
            }
            else {
                columnWidths.push({ wch: 20 });
            }

        });

        ws["!cols"] = columnWidths; //[ { wch: 50 }, { wch: 10 }, { wch: 25 }, { wch: 25 } ]; // set column A width to 40 characters

        // the sheet name cannot be more than 31 characters
        sheetName = sheetName.substring(0, 30);
        XLSX.utils.book_append_sheet(workbook, ws, sheetName);

        // const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(chartJson);
        // const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };


        // ws.A2.c.hidden = true;
        //const ws = workbook.Sheets[sheetName];

        return ws;

    }

    /**
     * This prepares and saves the excel workbook to disk
     * @param excelFileName
     * @param workbook
     * @private
     */
    private saveWorkBook(excelFileName: string, workbook: XLSX.WorkBook){

        this.logger.debug('Inside saveWorkBook, excelFileName: ', excelFileName);

        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
        this.saveAsExcelFile(excelBuffer, excelFileName);
    }

    private addWorkSheetFooter (moduleTitle: string, measureSheetDataRows: any[]){

        this.logger.debug('Inside addWorkSheetFooter for moduleTitle: ', moduleTitle);

        //if (haveRaceData){

            const ratioNote = '*Relative Risk Ratio: Values greater than 1 ' +
                'indicate a higher likelihood compared to other ' +
                'groups. Values less than 1 indicate a lower ' +
                'likelihood compared to other groups.';

            const moduleFooter = {v: ratioNote, t: 's', s: { font: { name: "Arial", sz: 12, italic: true } } }
            // now ass a blank space between measures
            measureSheetDataRows.push({ A: ' '});
            // add module footer
            measureSheetDataRows.push({ A: moduleFooter});

        //}

    }

    /**
     * This creates a data buffer that will then be used to create the file on the local machine for download
     * @param buffer
     * @param fileName
     * @private
     */
    private saveAsExcelFile(buffer: any, fileName: string): void {

        const data: Blob = new Blob([buffer], {
            type: this.EXCEL_TYPE
        });

        fileName = fileName + this.EXCEL_EXTENSION;
        FileSaver.saveAs(data, fileName);
    }

    /**
     * Translate SYK into a string
     * @param schoolYear
     * @private
     */
    private getRaceGenderDescription(SYK_Data: string){
        this.logger.debug('Inside getRaceGenderDescription, SYK_Data: ', SYK_Data);

        const genderKey = SYK_Data.substring(0, 2);
        this.logger.debug('genderKey: ', genderKey);

        let description;

        if (genderKey === 'B_'){
            description = GENDER_TYPE.BOTH;
        }
        else if (genderKey === 'F_'){
            description = GENDER_TYPE.FEMALE;
        }
        else if (genderKey === 'M_'){
            description = GENDER_TYPE.MALE;
        }
        else {
            description = '';
        }

        // if we have a gender description
        if (description){

            const raceKey = SYK_Data.substring(2);
            const raceDesc = RACE_TYPE[raceKey];

            this.logger.debug('raceDesc: ', raceDesc);

            if (raceDesc){

                if (description === GENDER_TYPE.BOTH){

                    description = 'Total ' + raceDesc + ' Students';
                }
                else if (raceKey === 'TOT'){

                    description = 'Total ' + description + ' Students';
                }
                else {

                    description += ' ' + raceDesc;

                }
            }
            else {
                description += ' ' + raceKey;
            }

        }
        else {
            this.logger.error('Unable to calculate race/sex description for: ', SYK_Data);
        }

        this.logger.debug('description: ', description);
        return description;
    }
}
