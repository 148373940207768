import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { EntitySearchStep2Result } from '../../query-models/entity-search-model';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatPaginator } from '@angular/material/paginator';
import { NGXLogger } from 'ngx-logger';
import { EntityType } from '@shared/models/profile-model';
import { ProfileService } from '@shared/services/helpers/profile.service';
import { ComparisonService } from '@shared/services/helpers/comparison.service';

/**
 * Component for selecting entities in the data tool.
 */
@Component({
  selector: 'app-data-tool-entity-selection',
  templateUrl: './data-tool-entity-selection.component.html',
  styleUrls: ['./data-tool-entity-selection.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DataToolEntitySelectionComponent
    }
  ]
})
export class DataToolEntitySelectionComponent implements ControlValueAccessor, OnInit, OnChanges {

  /**
   * The selected entities.
   */
  public selectedEntities: EntitySearchStep2Result[];

  /**
   * The maximum number of entities that can be selected.
   */
  public maxEntities: number;

  /**
   * The columns to be displayed in the table.
   */
  public displayedColumns: string[];

  /**
   *  Data supporting table derived from entities
   */
  public dataSource: MatTableDataSource<EntitySearchStep2Result>;

  /**
   * Entity type to display for the header.
   */
  public entityTypeString: string;

  /**
   * The list of all entities that will be used as input for displaying one or more pages in the table.
   */
  @Input() entities: EntitySearchStep2Result[];

  /**
   * The total number of entities that the search could return. Entities is limited to 200, so this is
   * used to show what the total number of entities would be if there were no limit.
   */
  @Input() entityTotal: number;

  /**
   * The type of entity to be selected.
   */
  @Input() entityType: EntityType;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private _snackBar: MatSnackBar,
    private logger: NGXLogger,
    private profileService: ProfileService,
    private comparisonService: ComparisonService,
  ) { }

  ngOnInit(): void {
    this.maxEntities = environment.maxEntities;
    this.displayedColumns = ['select', 'name', 'state', 'gradesOffered', 'studentCount'];
    this.entities?.forEach((entity: EntitySearchStep2Result) => {
      entity.studentCount = this.profileService.getFormattedCount(entity.studentCount as number).toLocaleString('en-US');
      entity.label = `${entity.entity_Name}, (${entity.entity_State}, grades ${entity.gradesOffered}, ${entity.studentCount} students)`;
    });
    this.dataSource = new MatTableDataSource<EntitySearchStep2Result>(this.entities);
    this.entityTypeString = this.entityType === EntityType.SCHOOL ? 'School' : 'LEA';
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.logger.debug('Inside data tool entity selection on changes: ', changes);
    if (changes.entities?.currentValue) {
      this.entities.forEach((entity: EntitySearchStep2Result) => {
        entity.studentCount = this.profileService.getFormattedCount(entity.studentCount as number).toLocaleString('en-US');
        entity.label = `${entity.entity_Name}, (${entity.entity_State}, grades ${entity.gradesOffered}, ${entity.studentCount} students)`;
        entity.checked = false;
      });
      this.dataSource = new MatTableDataSource<EntitySearchStep2Result>(this.entities);
      this.dataSource.paginator = this.paginator;
      this.logger.debug('Inside data tool entity selection on changes selected entities and data: ', this.selectedEntities ,this.dataSource.data);
      this.updateSelectedEntities();
    }
  }

  /**
   * Opens a snack bar with the given message.
   * @param message The message to display in the snack bar.
   */
  openSnackBar(message: string) {
    this._snackBar.open(message, 'Dismiss', {
      duration: 3000,
      panelClass: ['mat-toolbar', 'mat-primary']
    });
  }

  /**
   * Checks if all entities are selected.
   * @returns True if all entities are selected, false otherwise.
   */
  isAllSelected(): boolean {
    const numSelected = this.selectedEntities.length;
    const numRows = this.maxEntities > this.dataSource.data?.length ? this.dataSource.data.length : this.maxEntities;
    return numSelected === numRows;
  }

  /**
   * Checks if the number of entities is lower than the number of max entities
   * @returns True if there are less entties selected than max
   */
  isUnderMaxLimit(): boolean {
    return this.selectedEntities.length < this.maxEntities;
  }

  /**
   * Toggles the selection of all entities.
   */
  masterToggle() {
    this.markAsTouched();

    if (this.isAllSelected() && !this.disabled) {
      this.dataSource.data.forEach(row => {
        if (row.checked) this.toggleCheck(row);
      });
    } else if (!this.disabled) {
      this.dataSource.data.forEach(row => {
        if (this.isUnderMaxLimit() && !row.checked) this.toggleCheck(row);
      });
    }
  }

  updateSelectedEntities(): void {
    this.logger.debug('Inside update selected entities: ', this.selectedEntities, this.dataSource.data);

    // Set the checked property of each entity based on whether it is in the selectedEntities array
    this.dataSource.data.forEach((entity: EntitySearchStep2Result) => {
      this.selectedEntities?.findIndex((e: EntitySearchStep2Result) => e.entity_ID === entity.entity_ID) !== -1 ? entity.checked = true : entity.checked = false;
    });
  }

  onChange = (selectedEntities: EntitySearchStep2Result[]) => { };

  onTouched = () => { };

  touched = false;

  disabled = false;

  /**
   * Toggles the selection of the given entity.
   * @param row The entity to toggle the selection for.
   */
  toggleCheck(row: EntitySearchStep2Result) {
    this.logger.debug('')
    this.markAsTouched();

    if (!this.disabled) {

      row.checked = !row.checked;

      if (row.checked) {
        this.logger.debug('Inside toggle check: row checked', row);
        this.selectedEntities.push(row);
      } else {
        const index = this.selectedEntities.findIndex((e: EntitySearchStep2Result) => e.entity_ID === row.entity_ID);
        this.logger.debug('Inside toggle check: row not checked', row);
        this.logger.debug('splicing at index: ', index);
        this.logger.debug('splicing entity: ', this.selectedEntities[index]);
        this.selectedEntities.splice(index, 1);
      }

      if (!this.isUnderMaxLimit()) this.openSnackBar('Max number of items reached');
      this.onChange(this.selectedEntities);
    }
  }

  writeValue(selectedEntities: EntitySearchStep2Result[]): void {
    this.logger.debug('Inside write value: ', selectedEntities);
    this.selectedEntities = selectedEntities;
    this.updateSelectedEntities();
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  toggleSideNav(): void {
    this.logger.debug('Inside toggle side nav');
    this.comparisonService.triggerToggleSideNav();
  }

}
