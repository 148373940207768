import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {SEARCH_STEP} from '@shared/query-models/step-search.model';
import { AuthorizationService } from '@core/auth/services/authorization.service';
import {ToastService} from '@shared/components/toast/toast.service';

@Component({
  selector: 'app-data-tool-login-landing',
  templateUrl: './data-tool-login-landing.component.html',
  styleUrls: ['./data-tool-login-landing.component.scss']
})
export class DataToolLoginLandingComponent implements OnInit {

    protected readonly SEARCH_STEP = SEARCH_STEP;

    selectToAgree:boolean = false;
    constructor(
        private logger: NGXLogger,
        private toastService: ToastService,
        private authorizationService: AuthorizationService
    ) { }

    ngOnInit(){

        this.logger.debug('Inside ngOnInit');

        // check to see if we are already logged in
        if (this.authorizationService.hasValidAuthSessionIdCookie()) {
            //this.logout();
            this.toastService.success('You are already logged in as: ', this.authorizationService.getUserFullName());
        }
    }

    selectedIAgree(){
        this.logger.debug('Inside selectedIAgree', this.selectToAgree);
    }

    requestAuthorization(){
        this.logger.debug('Inside requestAuthorization');
        this.authorizationService.requestAuthorization();
    }
}
