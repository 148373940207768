import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { MessageService } from '../core/message.service';
import { BaseService } from '../core/base.service';
import { DataService } from '../core/data.service';
import { EntitySearchQuery } from '../../queries/entity-search-query';
import {EntitySearchModel, EntitySearchResult} from '../../query-models/entity-search-model';
import {NGXLogger} from 'ngx-logger';
import {ToastService} from '@shared/components/toast/toast.service';
import { Observable } from 'rxjs';

@Injectable()
export class EntitySearchService extends BaseService {

  private apiUrl = environment.api_url; // URL to web api

  constructor(
    private http: HttpClient,
    logger: NGXLogger,
    toastService: ToastService,
    messageService: MessageService,
    dataService: DataService
  ) {
    super(logger, toastService, messageService, dataService);
  }

  /** GET **/
    getEntitySearchResult(query: EntitySearchQuery): Observable<EntitySearchResult[]> {

      this.logger.debug('Inside getEntitySearchResult');

        this.setHeaders();
      const url = this.apiUrl + 'EntitySearch';

      var params = new HttpParams();
      params = params.append('EntityName', query.entityName);
      params = params.append('EntityType', query.entityType);

      return this.http.get<EntitySearchModel>(
      url,
      {
        params: params,
        observe: 'response',
        headers: this.headers
      })
      .pipe(
        map(resp => {
          if (resp?.body?.entitySearchResults) {
            return resp.body.entitySearchResults;
          }

          return null;
        }),
        tap(resp => {
          this.log(`fetched`);
        }),
        catchError(this.handleError('get', null))
      );
    }

}
