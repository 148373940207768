import { Component } from '@angular/core';
import { StateNationalEstimationService } from '../../../shared/services/data/state-national-estimation.service';
import { StateNationalReportQuery } from '../../../shared/queries/state-national-report-query';
import { saveAs } from "file-saver";

@Component({
    selector: 'estimations-2020-2021',
    templateUrl: './estimations-2020-2021.component.html',
    styleUrls: [ './estimations-2020-2021.component.scss' ]
})

export class Estimations20202021Component {

    private snr: StateNationalReportQuery = new StateNationalReportQuery();

    constructor(private readonly stateNationalEstimationService: StateNationalEstimationService) { }

    downloadStateNationalEstimationExcelFile(survey_year_key: number, reportId: number, filename: string) {

        this.snr.surveyYearKey = survey_year_key;
        this.snr.reportId = reportId;

        this.stateNationalEstimationService.downloadStateNationalEstimationExcelFile(this.snr)
            .subscribe(data => saveAs(data, filename));
    }

}
