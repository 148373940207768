import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, HostListener, ViewChild, ChangeDetectorRef, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { ComparisonService } from '../../services/helpers/comparison.service';
import { EntitySearchComponent } from '@shared/components/entity-search/entity-search.component';
import { environment } from 'src/environments/environment';
import { EntitySearchStep2Result } from '@shared/query-models/entity-search-model';
import { NGXLogger } from 'ngx-logger';
import { AuthorizationService } from '@core/auth/services/authorization.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})

/**
 * This class allows us to update the navbar on certain events.
 */
export class NavbarComponent implements OnInit, OnDestroy {

    @Input() isMobile: boolean;
    @Input() isLoggedIn: boolean;
    @Input() userFullName: string;
    @Input() userEmail: string;

    //@Output() logoutEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('about') about: ElementRef;
  @ViewChild('aboutSubMenu') aboutSubMenu: ElementRef;
  @ViewChild('exploreData') exploreData: ElementRef;
  @ViewChild('exploreDataSubMenu') exploreDataSubMenu: ElementRef;

  private navbarSearch: EntitySearchComponent;

    showLogoutConfirmModal: boolean = false;

  @ViewChild('navbarSearch', { static: false }) set content(content: EntitySearchComponent) {
    if (content) {
      this.navbarSearch = content;
    }
  }

    showSubMenu: boolean = false;
    elementExpanded: string;

  isMobileMenuVisible: boolean = false;

  numSchools: number;
  comparisonSubscription: Subscription;
  production: boolean = environment.production;



  constructor(
    private comparisonService: ComparisonService,
    private cdRef: ChangeDetectorRef,
    private logger: NGXLogger,
    private authorizationService: AuthorizationService
  ) { }

  ngOnInit(): void {

      this.logger.debug('inside navbar ngOnInit');
      this.numSchools = 0;

    this.comparisonSubscription = this.comparisonService.comparisonUpdate.subscribe((items: EntitySearchStep2Result[]) => {
      this.numSchools = items.length;
    });

  }

  ngOnDestroy(): void {
      this.logger.debug('inside navbar ngOnDestroy');
    this.comparisonSubscription.unsubscribe();
  }

  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (this.isMobileMenuVisible && this.isMobile) {
      this.toggleMobileNavbar();
    }

    if (this.showSubMenu) {
      switch (this.elementExpanded) {
        case 'about':
          this.about.nativeElement.focus();
          break;
        case 'exploreData':
          this.exploreData.nativeElement.focus();
          break;
      }
    }
  }

  onKeyDown(event: KeyboardEvent, element: string) {
    switch (event.key) {
      case "ArrowUp":
        event.stopPropagation();
        document.activeElement.parentElement.previousElementSibling?.querySelector('a').focus();
        break;

      case "ArrowDown":
        event.stopPropagation();

        if (element === 'about') {
          this.aboutSubMenu.nativeElement.querySelector('a').focus();
        } else if (element === 'exploreData') {
          this.exploreDataSubMenu.nativeElement.querySelector('a').focus();
        } else if (element === 'aboutSubMenu' || element === 'exploreDataSubMenu') {
          document.activeElement.parentElement.nextElementSibling?.querySelector('a').focus();
        }
        break;
    }
  }

  /**
   * This method toggles the mobile navbar such that in mobile configurations it is visible,
   * and in desktop, the normal navbar is visible.
   */
  toggleMobileNavbar(close?: boolean) {
    if (this.isMobile) {
      if (close) {
        this.isMobileMenuVisible = false;
      } else {
        this.isMobileMenuVisible = !this.isMobileMenuVisible;
      }
    }

    this.cdRef.detectChanges();

    if (this.isMobileMenuVisible) {
      this.navbarSearch.ngSelectComponent.searchInput.nativeElement.focus();
    }
  }

  showSideNav() {
    this.comparisonService.triggerToggleSideNav();
  }

  expand(elementId: string, expand: string) {
    document.getElementById(elementId).ariaExpanded = expand;
    this.elementExpanded = elementId;
    this.showSubMenu = Boolean(expand);
  }

  logUserOut() {
    this.logger.debug('Inside logUserOut');

      this.showLogoutConfirmModal = true;
  }

    gotToLoginPage(){
        this.logger.debug('Inside gotToLoginPage');

        this.authorizationService.gotToLoginPage();
    }

    /**
     * Closes the update step 2 modal.
     */
    closeLogoutConfirmModalModal() {
        this.showLogoutConfirmModal = false;

    }

    confirmLogout(){
        this.logger.debug('Inside confirmLogout');

        this.closeLogoutConfirmModalModal();

        this.authorizationService.logout(true, 'user');

        //this.logoutEmitter.emit(true);

        this.logger.debug('this.isLoggedIn: ', this.isLoggedIn);

    }

    declineLogout(){
        this.logger.debug('Inside declineLogout');

        this.closeLogoutConfirmModalModal();
    }

}
