import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

// Services
import { BaseService } from '../core/base.service';
import { DataService } from '../core/data.service';
import { MessageService } from '../core/message.service';

// Models
import { MiscQueryModel } from '../../query-models/misc-query-model';
import { DataSpotlightQuery } from '../../queries/data-spotlight-query';
import {NGXLogger} from 'ngx-logger';
import {ToastService} from '@shared/components/toast/toast.service';




@Injectable()
export class DataSpotlightService extends BaseService {

    private apiUrl = environment.api_url; // URL to web api

    constructor(
        private http: HttpClient,
        logger: NGXLogger,
        toastService: ToastService,
        messageService: MessageService,
        dataService: DataService
    ) {
        super(logger, toastService, messageService, dataService);
    }

    /** GET **/
    get(query: DataSpotlightQuery) {
        this.setHeaders();
        const url = this.apiUrl + 'DataSpotlight';

        var params = new HttpParams();
        params = params.append('surveyYearKey', query.surveyYearKey.toString());
        params = params.append('stateId', query.stateId.toString());

        return this.http.get(
            url,
            {
                params: params,
                observe: 'response',
                headers: this.headers
            })
            .pipe(
                map(resp => {
                    if (resp != undefined || resp != null) {
                        return resp.body;
                    }

                    return null;
                }),
                tap(resp => {
                    this.log(`fetched`);
                }),
                catchError(this.handleError('get', null))
            );
    }
}
