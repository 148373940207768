export class DistrictSearchQuery {    
    lea_Address: string; 
    long_Nces_Id: string; 
    lea_Name: string; // District Name
    leaId: string; // District Id
    lea_Id: string;
    lea_Zip: string;
    lea_City: string; 
    districtDistance: number;
    survey_Year_Key: number;
    stateKeys: string; // Comma delimited
    state: string;
    lowerSchoolCount: string;
    higherSchoolCount: string;
    leaTitle1: string;
    leaMagnetSchool: string;
    leaAltSchool: string;
    leaJuvenileJusticeSchool: string;
    leaCharterSchool: string;
    leaStudentToTeacherLtGt: string;
    leaStudentToTeacher: string;
    leaFirstYearTeachersLtGt: string;
    leaFirstYearTeachers: string;
    leaCertifiedTeachersLtGt: string;
    leaCertifiedTeachers: string;
    leaGTProgram: string;
    leaALG1Program: string;
    leaBIOProgram: string;
    leaGEOProgram: string;
    leaCHEProgram: string;
    leaALG2Program: string;
    leaPHIProgram: string;
    leaAMSProgram: string;
    leaAPProgram: string;
    leaCalcProgram: string;
    leaCriteria: string;
    leaLessMoreThan: string;
    leaQuantity: string;
    leaCriteriaAdvanced: string;
    leaLessMoreThanAdvanced: string;
    leaQuantityAdvanced: string;
}
