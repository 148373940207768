import { Component } from '@angular/core';

@Component({
    selector: 'estimations-2011-2012',
    templateUrl: './estimations-2011-2012.component.html',
    styleUrls: [ './estimations-2011-2012.component.scss' ]
})

export class Estimations20112012Component {


}
