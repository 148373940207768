<form [formGroup]="formGroup">
    <div class="data-tool-step-three-container is-flex is-flex-direction-column" style="gap: 1.5rem">
        <app-data-tool-module-options-selection #modules [modules]="moduleOptions" [label]="'Modules'" [expanded]="true"
            [scrollIntoView]="scrollItem === 'Modules'" formControlName="selectedModules">
        </app-data-tool-module-options-selection>
        <!-- <app-data-tool-module-options-selection [modules]="analysisOptions" [label]="'Analyses Types'"
            [scrollIntoView]="scrollAnalysesIntoView" [expanded]="true" formControlName="selectedComparisons">
        </app-data-tool-module-options-selection> -->

        <div class="card data-tool-card">
            <header class="card-header is-justify-content-space-between" (click)="toggleDropdown()" onkeypress="">
                <p class="subheader-2 has-text-primary" id="Analyses Types">Analyses Types</p>
                <p class="selected body has-text-primary">{{ numSelectedItems }}</p>
                <button type="button" class="card-header-icon search-button" aria-label="toggle show Analyses Types"
                    [attr.aria-expanded]="expanded" mat-icon-button>
                    <i *ngIf="!expanded" aria-hidden="true" class="material-icons">keyboard_arrow_down</i>
                    <!-- arrow_drop_down arrow_drop_up -->
                    <i *ngIf="expanded" aria-hidden="true" class="material-icons">keyboard_arrow_up</i>
                </button>
            </header>

            <div *ngIf="expanded" class="card-content is-flex is-flex-direction-column" style="gap: 1rem;">
                <fieldset class="is-flex is-flex-direction-column" style="gap: .5rem;">
                    <legend id="demographicDistributions" class="subheader-2 has-text-primary">Demographic Distributions</legend>
                    <ul class="card-content-list is-flex is-flex-direction-column" aria-labelledby="demographicDistributions">
                        <li *ngFor="let datum of demographicLabelValuePairs" class="card-content-list-item is-flex">
                            <input #radioInput [id]="datum.label" type="radio" name="demographicSelection"
                                [value]="datum.value" formControlName="demographicSelection">
                            <label class="large-body has-text-primary is-flex is-align-items-center" style="gap:.5rem;"
                                [for]="datum.label">{{ datum.label }}</label>
                        </li>
                    </ul>
                </fieldset>

                <fieldset class="is-flex is-flex-direction-column" style="gap: .5rem;">
                    <legend id="otherDistributionsLabel" class="subheader-2 has-text-primary">
                        {{ otherDistributions.title }}</legend>
                    <ul class="card-content-list is-flex is-flex-direction-column"
                        aria-labelledby="otherDistributionsLabel">
                        <li *ngFor="let datum of otherDistributions.data" class="card-content-list-item is-flex">
                            <input [id]="datum.title" type="checkbox" [name]="datum.dTool_ID"
                                [formControlName]="datum.dTool_ID">
                            <label class="large-body has-text-primary is-flex is-align-items-center" style="gap:.5rem;"
                                [for]="datum.title">{{ datum.title }} <mat-icon *ngIf="datum.tooltip" class="ed-slate"
                                    tabindex="0" aria-labelledby="tooltip-popup" [tooltip]="datum.tooltip"> info_outline
                                </mat-icon></label>
                        </li>
                    </ul>
                </fieldset>
            </div>
        </div>
    </div>
</form>
