<form *ngIf="(selectedModules || readonly) && modules" [formGroup]="selectedModules">
    <div class="card data-tool-card">
        <header class="card-header is-justify-content-space-between" (click)="toggleDropdown()" onkeypress="">
            <div class="is-flex" style="gap: 1rem;">
                <p class="subheader-2 has-text-primary" [id]="label">{{ label }}</p>
                <button *ngIf="readonly" class="link-button body" type="button" (click)="edit()"
                    [attr.aria-label]="'edit ' + label">Edit</button>
            </div>
            <p class="selected body has-text-primary">{{ numSelectedModules }}</p>
            <button type="button" class="card-header-icon search-button" [attr.aria-label]="'toggle show ' + label"
                [attr.aria-expanded]="expanded" mat-icon-button>
                <i *ngIf="!expanded" aria-hidden="true" class="material-icons">keyboard_arrow_down</i>
                <!-- arrow_drop_down arrow_drop_up -->
                <i *ngIf="expanded" aria-hidden="true" class="material-icons">keyboard_arrow_up</i>
            </button>
        </header>

        <div *ngIf="expanded" class="card-content is-flex-direction-column" style="gap: 1rem;">
            <fieldset *ngFor="let module of modules" class="is-flex is-flex-direction-column" style="gap: .5rem;">
                <legend class="subheader-2 has-text-primary">{{ module.title }}</legend>
                <ul *ngIf="!readonly" class="card-content-list is-flex is-flex-direction-column">
                    <li *ngFor="let datum of module.data" class="card-content-list-item is-flex">
                        <input #checkboxInput type="checkbox" [name]="datum.dTool_ID" [id]="datum.title"
                            [formControlName]="datum.dTool_ID">
                        <p class="large-body has-text-primary is-flex is-align-items-center" style="gap:.5rem;">
                            {{ datum.title }} <mat-icon *ngIf="datum.tooltip" class="ed-slate" tabindex="0"
                                aria-labelledby="tooltip-popup" [tooltip]="datum.tooltip"> info_outline
                            </mat-icon>
                        </p>
                        <label class="is-sr-only" style="gap:.5rem;" [for]="datum.title">{{ datum.title }}, {{ module.title }}</label>
                    </li>
                </ul>
                <p *ngIf="readonly" class="large-body has-text-primary">
                    {{ module.dataString }}
                </p>
            </fieldset>
        </div>
    </div>
</form>
