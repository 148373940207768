import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { MessageService } from '../core/message.service';
import { BaseService } from '../core/base.service';
import { DataService } from '../core/data.service';
import { Observable } from 'rxjs';
import {NGXLogger} from 'ngx-logger';
import {ToastService} from '@shared/components/toast/toast.service';

@Injectable()
export class StatesService extends BaseService {

  private apiUrl = environment.api_url; // URL to web api

  constructor(
    private http: HttpClient,
    logger: NGXLogger,
    toastService: ToastService,
    messageService: MessageService,
    dataService: DataService
  ) {
    super(logger, toastService, messageService, dataService);
  }

    // return list of states
    getStatesList(): Observable<any> {
        this.setHeaders();
        const url = this.apiUrl + 'StatesList';


        return this.http.get<any>(
            url,
            {
                observe: 'response',
                headers: this.headers,
                withCredentials: true,  // so we can send cookies
            })
            .pipe(
                map(resp => {
                    if (resp != undefined || resp != null) {
                        return resp.body;
                    }

                    return null;
                }),
                tap(resp => {
                    this.log(`fetched`);
                }),
                catchError(this.handleError('get', null))
            );
    }


}
