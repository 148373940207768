import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { EntityType, SurveyYearModel } from '../../models/profile-model';
import { ProfileService } from '../../services/helpers/profile.service';
import { SurveyYearService } from '../../services/data/survey-year.service';
import { throwError } from 'rxjs';
//import { StatesService } from '../../services/data/states.service';
@Component({
  selector: 'app-survey-year-dropdown',
  templateUrl: './survey-year-dropdown.component.html',
  styleUrls: ['./survey-year-dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SurveyYearDropdownComponent implements OnInit {

  @Input() surveyYear: number;
  @Input() entityType: EntityType;

  @Output() surveyYearUpdate: EventEmitter<number> = new EventEmitter<number>();

  public surveyYears: SurveyYearModel[];

  public dataLoaded: boolean = false;

  constructor(
    private profileService: ProfileService,
    private surveyYearService: SurveyYearService
  ) {

  }

  ngOnInit(): void {
    this.surveyYearService.getSurveyYearsList(5).subscribe(data => {
      if (data) {
        this.profileService.setSurveyYearKeys(data.surveyYearsModels);
        this.surveyYears = this.profileService.getSurveyYearKeys(this.entityType);

        if (!this.surveyYear) {
          this.surveyYear = this.surveyYears[0].yearKey;
        }

        this.dataLoaded = true;
      } else {
        throwError(() => new Error('Error getting survey years'));
      }
    });
  }

  onYearChange(): void {
    this.surveyYearUpdate.emit(this.surveyYear);
  }

}
