<app-hero heroHeader="Ensuring Equal Access" heroSubHeader="CRDC Overview"
    heroDescription="OCR's mission is to ensure equal access to education and to promote educational excellence through vigorous enforcement of civil rights in our nation's schools."
    heroImageSrc="assets/images/about-ocr-image.jpg"
    heroImageAlt="students line up behind a student with Down's Syndrome">
</app-hero>
<div class="primary-content-container">
    <div class="primary-content is-flex is-justify-content-center">
        <div class="is-flex is-flex-direction-column">
            <h4 class="primary-header has-text-primary">About the Office for Civil Rights</h4>
            <div class="tile is-ancestor">
                <div class="tile is-parent is-6">
                    <article class="tile is-child">
                        <p class="large-body has-text-primary">
                            The U.S. Department of Education's <a
                                href="https://www2.ed.gov/about/offices/list/ocr/index.html" target="_blank"
                                rel="noreferrer noopener">Office for Civil Rights (OCR)</a> is responsible for
                            enforcing federal civil rights laws that guarantee access to educational opportunities
                            for all students free from discrimination based on race, color, national origin, sex,
                            disability, and age. OCR's mission is to ensure equal access to education and to promote
                            educational excellence in the nation's schools through vigorous enforcement of civil
                            rights.
                        </p>
                    </article>
                </div>
                <div class="tile is-parent is-6">
                    <article class="tile is-child">
                        <p class="large-body has-text-primary">
                            OCR uses several strategies to carry out its mission, including the investigation of
                            discrimination complaints, the development of regulations and policy guidance relating
                            to the civil rights laws OCR enforces, provision of technical assistance to schools
                            regarding their legal obligations, and the collection of civil rights data about
                            students' experiences in our nation's schools through the Civil Rights Data Collection.
                        </p>
                    </article>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="secondary-content-container">
    <div class="secondary-content is-flex is-justify-content-center">
        <div class="tile is-ancestor">
            <div class="tile is-parent is-5">
                <article class="tile is-child">
                    <h1 class="has-text-primary">Enforcing Federal Civil Rights Laws</h1>
                </article>
            </div>
            <div class="tile is-parent is-7">
                <article class="tile is-child">
                    <p class="large-body has-text-primary">
                        OCR enforces several laws that prohibit discrimination in programs or activities that
                        receive Federal financial assistance from the U.S. Department of Education, including all
                        state educational agencies, local educational agencies, elementary and secondary schools,
                        colleges and universities, vocational schools, proprietary schools, state vocational
                        rehabilitation agencies, libraries, and museums.<br><br>Civil rights laws enforced by OCR
                        include: <a href="https://www2.ed.gov/about/offices/list/ocr/raceoverview.html" target="_blank"
                            rel="noreferrer noopener">Title VI</a> of the Civil Rights Act of 1964,
                        which prohibits discrimination on the basis of race, color, or national origin; <a
                            href="https://www2.ed.gov/about/offices/list/ocr/sexoverview.html" target="_blank"
                            rel="noreferrer noopener">Title IX</a> of the Education Amendments of
                        1972, which prohibits sex discrimination; <a
                            href="https://www2.ed.gov/about/offices/list/ocr/disabilityoverview.html" target="_blank"
                            rel="noreferrer noopener">Section 504</a> of the Rehabilitation Act of
                        1973, which prohibits discrimination on the basis of disability; and <a
                            href="https://www2.ed.gov/about/offices/list/ocr/agediscrimination.html" target="_blank"
                            rel="noreferrer noopener">Age Discrimination Act</a> of 1975, which prohibits age
                        discrimination. OCR also has responsibilities under Title II of the <a
                            href="https://www2.ed.gov/about/offices/list/ocr/disabilityoverview.html" target="_blank"
                            rel="noreferrer noopener">Americans with Disabilities Act</a> of 1990
                        (prohibiting disability discrimination by public entities, whether or not they receive
                        federal financial assistance). To view OCR's guidance please visit the <a
                            href="https://www2.ed.gov/about/offices/list/ocr/frontpage/faq/rr/policyguidance/index.html"
                            target="_blank" rel="noreferrer noopener">Policy Guidance Portal</a>.
                    </p>
                </article>
            </div>
        </div>
    </div>
</div>
