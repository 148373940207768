<div class="is-flex is-flex-direction-row">
    <!-- <button (click)="prev()" class="btn btn-default is-align-self-center">Prev</button> -->
    <button mat-icon-button (click)="prev()" class="btn btn-default is-align-self-center">
        <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    <section class="carousel-wrapper" [ngStyle]="carouselWrapperStyle">
        <ul class="carousel-inner" #carousel>

            <li *ngFor="let item of items;" class="carousel-item">
                <ng-container [ngTemplateOutlet]="item.tpl"></ng-container>
            </li>

        </ul>
    </section>
    <button mat-icon-button (click)="next()" class="btn btn-default is-align-self-center">
        <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
</div>
