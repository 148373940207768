import { Component } from '@angular/core';

@Component({
    selector: 'estimations-2015-2016',
    templateUrl: './estimations-2015-2016.component.html',
    styleUrls: [ './estimations-2015-2016.component.scss' ]
})

export class Estimations20152016Component {


}
