import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { EntityType, LabelValuePair, State, SurveyYearModel } from 'src/app/shared/models/profile-model';
import { DistrictProfileQueryModel } from 'src/app/shared/query-models/district-profile-query-model';
import { EntitySearchResult, EntitySearchStep2Result } from 'src/app/shared/query-models/entity-search-model';
import { SchoolProfileQueryModel } from 'src/app/shared/query-models/school-profile-query-model';
import { ProfileService } from 'src/app/shared/services/helpers/profile.service';
import { environment } from 'src/environments/environment';
import { NGXLogger } from 'ngx-logger';
import { ExportDataService } from './export.data.service';
import { Bounds, CriteriaDTO, CRITERIA_LABELS } from 'src/app/shared/models/entity-search-step2-model';
import { ToastService } from '@shared/components/toast/toast.service';
import { SEARCH_STEP } from '@shared/query-models/step-search.model';
import { EntitySearchStep4PreViewModel, ModuleDef, ModuleL1, ModuleViewModel } from '@shared/models/entity-search-step4-pre-view.model';
import { EntitySearchStep4Query } from '@shared/queries/entity-search-query';
import { DataToolService } from '@shared/services/data/datatool.service';
import { firstValueFrom, Observable, Subscription } from 'rxjs';
import { ComparisonType, DemographicType } from '@shared/models/entity-search-step3-model';
import { ComparisonService } from '@shared/services/helpers/comparison.service';
import { AuthorizationService } from '@core/auth/services/authorization.service';

@Component({
  selector: 'app-enforcement-data-tool',
  templateUrl: './enforcement-data-tool.component.html',
  styleUrls: ['./enforcement-data-tool.component.scss']
})
export class EnforcementDataToolComponent implements OnInit, OnDestroy {

  routeEventSubscription$: Subscription;

  protected readonly SEARCH_STEP = SEARCH_STEP; // to support the html

  showLoadingSpinner: boolean = false;
  spinnerMessage: string;
  spinnerActionActivated: boolean = false;    // is used together with showLoadingSpinner and showLoadingEmitter

  private maxSteps: number;

  public currentStep: SEARCH_STEP;
  public formGroups: FormGroup[];
  public entitySurveyYears: SurveyYearModel[];
  public entityProfile: SchoolProfileQueryModel | DistrictProfileQueryModel;
  public moduleOptions: ModuleViewModel;
  public scrollItem: string;

  analysisOptions: ModuleL1[] = [
    {
      dTool_ID: 1, title: 'Demographic Distributions', data: [
        {
          dTool_ID: ComparisonType.SEX,
          title: 'Count and percentage distribution by sex'
        },
        {
          dTool_ID: ComparisonType.RACE_ETHNICITY,
          title: 'Count and percentage distribution by race/ethnicity'
        },
      ]
    },
    {
      dTool_ID: 2, title: 'Other Distributions', data: [
        // { dTool_ID: ComparisonType.IDEA_504, title: 'Count and percentage distribution by disability (IDEA and Section 504)' },
        {
          dTool_ID: ComparisonType.EL_STATUS,
          title: 'Count and percentage distribution by English learner status'
        },
        {
          dTool_ID: ComparisonType.IDEA,
          title: 'Count and percentage distribution by disability (IDEA)'
        },
        {
          dTool_ID: ComparisonType.SECTION504,
          title: 'Count and percentage distribution by disability (Section 504 only)'
        },
      ]
    },
    {
      dTool_ID: 2, title: 'Relative Risk Ratio', data: [
        {
          dTool_ID: ComparisonType.RELATIVE_RISK,
          title: 'Relative risk ratio',
          tooltip: 'Relative Risk Ratio can be selected only when “Count and percentage distribution by race/ethnicity” is selected.'
        },
      ]
    }
  ];
  private readonly otherDistributions: ModuleL1 = {
    dTool_ID: 2, title: 'Other Distributions', data: [
      // { dTool_ID: ComparisonType.IDEA_504, title: 'Count and percentage distribution by disability (IDEA and Section 504)' },
      {
        dTool_ID: ComparisonType.EL_STATUS,
        title: 'Count and percentage distribution by English learner status'
      },
      {
        dTool_ID: ComparisonType.IDEA,
        title: 'Count and percentage distribution by disability (IDEA)'
      },
      {
        dTool_ID: ComparisonType.SECTION504,
        title: 'Count and percentage distribution by disability (Section 504 only)'
      },
    ]
  };


  public initialized: boolean = false;

  /**
   * Boolean to show the update step 2 modal that will be used to either reset the form
   * to before the change, or to go to step 2 with the new entity.
   */
  public showUpdateStep2Modal: boolean = false;

  //################# Accept terms #####################

  @ViewChild('aupROBTerms') private aupROBTermsContainer: ElementRef;

  //Acceptable Use Policy and Rules of Behavior (AUP/ROB) once a year after I login to get access to the data tool.
  showAUPROBModal: boolean = false;
  showDeclineConfirmationAUPROBModal: boolean = false;
  enableAcceptButton: boolean = false;

  acceptableUsePolicyList: string[] = [
    'The data analysis tool includes security measures (authentication, access, and audit controls) to protect the Department of Education (ED) interests, users should not expect to have any right to, or expectation of, privacy while using this ED data analysis tool. The system is reviewed, audited, and monitored.',
    'Users are required to understand and take responsibility for the security of information that is stored, processed, and transmitted as an essential part of their job.',
    'Users of the data analysis tool shall only use the account(s) assigned to them, and not share account information with others, nor attempt to impersonate identities of others.',
    'Unauthorized attempts to access, upload, download, change, or delete information, or make unapproved changes to the data analysis tool are prohibited.',
    'Users shall agree to abide by the Department of Education (ED) policies, procedures, and laws. Acceptable use must be ethical, legal, and show responsible use for the data analysis tool. Users shall use their best judgment to guide their actions and seek guidance when appropriate from their supervisor.',
    'Unauthorized attempts or acts may result in criminal, civil, and/or administrative penalties.'
  ];

  rulesOfBehavior: string[] = [
    'I shall only access systems, software, and data which I am authorized to use, and will comply with any applicable copyright and licensing restrictions.',
    'I shall use my access only for authorized and official duties, and I shall only access data that is needed in the fulfillment of my duties.',
    'I shall properly dispose of the information, either in hardcopy, softcopy, or electronic format, in accordance with ED policies and procedures.',
    'I shall not access a protected computer without authorization, or beyond my authorization level.',
    'I shall not use my account access to engage in any activity that is illegal or otherwise expressly prohibited (e.g. political activity, lobbying activity prohibited by law or running a personal business).',
    'I shall complete all required training (security or privacy) required before receiving access to the system.',
    'I shall protect passwords/PIV and any access codes from unauthorized use and disclosure.',
    'I shall not store any passwords/access codes in any type of script file or cache on the data analysis tool.',
    'I shall report suspected or identified information security incidents to the EDSOC and ISSO.',
    'I shall not post organization information on public websites, to include social media or networking sites.',
    'I am prohibited from sharing my account information with anyone, and must take the appropriate action to ensure my account information is stored in a secure manner.',
    'If my User-ID or password is compromised, I will report it immediately to my supervisor and ISSO/ISSM.'
  ];

  additionalRulesOfBehavior: string[] = [
    'I shall abide by the general user AUP and ROB as well as the additional ROB for privileged users.',
    'I shall use my privileged account only for system administration duties and not to conduct day-to-day business or personal business.',
    'I shall not attempt to probe computer systems (unauthorized Footprinting) in obtaining information on either available ports or running services for malicious intent.',
    'I shall not disable or degrade tools used by the data analysis tool.',
    'I shall notify system owners immediately when privileged access is no longer required.',
    'I shall report all suspected and identified information security incidents (e.g., violation of security policies) and privacy breaches (e.g., loss, compromise or unauthorized access and use of PII) to the EDSOC without unreasonable delay and no later than within one (1) hour of occurrence/discovery.',
    'I shall not remove or destroy system audit logs or any other security, event log information unless authorized by appropriate official(s) in writing.',
    'I shall not tamper with audit logs of any kind (logs can be considered evidence and tampering may be a criminal offense punishable by fines and possible imprisonment).',
    'I shall not knowingly write, code, compile, store, transmit, or transfer malicious software code, to include viruses, logic bombs, worms, and macro viruses.',
    'I shall not elevate the privileges of any user without appropriate approvals.',
    'I shall complete any specialized role-based security or privacy training as required before receiving privileged system access.',
  ];

  penaltiesOfNonCompliance: string[] = [
    'Mandatory security training.',
    'Suspension of access privileges.',
    'Suspension from work.',
    'Revocation of access to systems and facilities.',
    'Reprimand.',
    'Demotion.',
    'Termination of employment.',
    'Removal or disbarment from work on federal contracts or projects.',
    'Criminal and civil penalties.'
  ];

  termsAcknowledgement: string[] = [
    'I acknowledge that I have received as well as understand my responsibilities and will comply with the Rules of Behavior for access to Department IT systems, networks and information.',
    'I acknowledge my responsibility to conform to the above terms and conditions set forth by the Information Assurance Program on behalf of the Department of Education.',
    'I understand that my failure to sign this Rules of Behavior will result in the denial of access to Department systems, networks and information.',
  ];


  //##################################################

  constructor(private logger: NGXLogger,
    private toastService: ToastService,
    private authorizationService: AuthorizationService,
    private profileService: ProfileService,
    private exportDataService: ExportDataService,
    private comparisonService: ComparisonService,
    private readonly dataToolService: DataToolService,
  ) { }

  ngOnInit(): void {

    this.maxSteps = environment.dataToolMaxSteps;
    this.currentStep = SEARCH_STEP.STEP_ONE;
    this.formGroups = [];
    this.entitySurveyYears = [];
    this.resetFormData();

    // Listen for when the user clicks on the resume or new report and confirm button, the form data is reset
    this.comparisonService.resetReportConfigurationEvent.subscribe(() => {
      this.logger.debug('resetReportConfigurationEvent found');
      this.resetFormData();
    });

    // we need to see if we have to prompt the user to accept the AUP_ROB terms if he successfully logged in and have not accepted the terms yet
    if (this.authorizationService.getIsLoggedIn() && !this.authorizationService.getAcceptedAUPROBTerms()) {

      this.showAUPROBModal = true;
    }
    this.showAUPROBModal = true;  // for testing only
  }

  ngOnDestroy() {
    this.logger.debug('Inside ngOnDestroy');
    //When the user leaves the data tool page, the current step form data is saved so that the user can resume where they left off.
    this.saveStepFormData(this.currentStep);
    //We then remove any future steps as they would be invalid
    this.clearOutDatedFutureStepFormData(this.currentStep);
    this.comparisonService.setIsEditable(false);

    if (this.routeEventSubscription$) {
      this.routeEventSubscription$.unsubscribe();
    }
  }

  /**
   * If user reloads page, the form data is reset to the last saved state.
   */
  resetFormData(): void {
    this.logger.debug('Inside resetFormData');

    for (let i = SEARCH_STEP.STEP_ONE; i <= this.maxSteps; i++) {
      let patched: boolean = false;
      this.logger.debug('process step: ', i);
      // This form data is saved in the sessionStorage when the user navigates to the next step.
      if (i === SEARCH_STEP.STEP_THREE || i === SEARCH_STEP.STEP_FOUR) {
        patched = this.patchModuleStepData(i);
      } else {
        patched = this.patchStepData(i);
      }

      if (patched) {
        this.currentStep = i;
      } else {
        //Nothing else to patch, so we break out of the loop
        this.logger.debug('No form data to patch for step: ', i);
        break;
      }

      this.logger.debug('currentStep set to: ', this.currentStep);
    }

    // Once all form controls and groups are initialized, we can set the current step and paint the screen
    this.initialized = true;
    this.logger.info('setting current step to: ', this.currentStep);
    // Set is editable property for side nav
    this.comparisonService.setIsEditable(this.currentStep === SEARCH_STEP.STEP_TWO);
  }

  /**
   * Used to patch the data for form groups step 3 and 4.
   * @param step Form group step number
   */
  patchModuleStepData(step: SEARCH_STEP): boolean {
    const lastSavedStepFormDataModules = sessionStorage.getItem(`enforcementDataToolStep${step}Modules`);
    const lastSavedStepFormDataComparisons = sessionStorage.getItem(`enforcementDataToolStep${step}Comparisons`);
    const lastSavedStepFormData = sessionStorage.getItem(`enforcementDataToolStep${step}`);
    this.logger.debug('lastSavedStepFormDataModules, step: ', lastSavedStepFormDataModules, step);
    this.logger.debug('lastSavedStepFormDataComparisons: ', lastSavedStepFormDataComparisons, step);
    this.logger.debug('lastSavedStepFormData: ', lastSavedStepFormData, step);

    // Form data for step 3 consists of the selected modules and form data, while step 4 consists of the selected modules, comparisons, and form data
    if (lastSavedStepFormDataModules && (lastSavedStepFormDataComparisons || step === SEARCH_STEP.STEP_THREE) && lastSavedStepFormData) {
      this.initializeStepForm(step);

      if (step === SEARCH_STEP.STEP_THREE) {
        this.selectedModules.value.patchValue(JSON.parse(lastSavedStepFormDataModules));
        // this.selectedComparisons.value.patchValue(JSON.parse(lastSavedStepFormDataComparisons));
        this.formGroups[step - 1].patchValue(JSON.parse(lastSavedStepFormData));
      } else if (step === SEARCH_STEP.STEP_FOUR) {
        this.selectedModuleOptions.value.patchValue(JSON.parse(lastSavedStepFormDataModules));
        this.selectedComparisonOptions.value.patchValue(JSON.parse(lastSavedStepFormDataComparisons));
        this.formGroups[step - 1].patchValue(JSON.parse(lastSavedStepFormData));
      }

      return true;
    }

    this.logger.debug('No saved data to process for step: ', step);
    return false;
  }

  /**
   * Used to patch the data for form groups step 1 and 2.
   * @param step Form group step number
   */
  patchStepData(step: SEARCH_STEP): boolean {
    const lastSavedStepFormData = sessionStorage.getItem(`enforcementDataToolStep${step}`);

    if (lastSavedStepFormData) {
      this.initializeStepForm(step);
      this.logger.debug('currentStep set to: ', this.currentStep);
      this.logger.debug('setting form data for step: ', step);
      this.formGroups[step - 1].patchValue(JSON.parse(lastSavedStepFormData));
      return true;
    } else if (step === SEARCH_STEP.STEP_ONE) {
      // Step one should always be initialized whether we have saved data or not.
      this.initializeStepForm(step);
      return true;
    }

    this.logger.debug('No saved data to process for step: ', step);
    return false;
  }


  /**
   * Initializes the form based on the given step.
   *
   * @param step - The step number to determine the form initialization.
   * @returns void
   */
  initializeStepForm(step: SEARCH_STEP): void {
    this.logger.debug('Inside initializeStepForm, step: ', step);
    switch (step) {
      case SEARCH_STEP.STEP_ONE: {
        this.initializeStep1Form();
        break;
      }
      case SEARCH_STEP.STEP_TWO: {
        this.initializeStep2Form();
        break;
      }
      case SEARCH_STEP.STEP_THREE: {
        this.initializeStep3Form();
        break;
      }
      case SEARCH_STEP.STEP_FOUR: {
        this.initializeStep4Form();
        break;
      }
    }

    // Tracking the form changes to determine if we need to reinitialize the next step form
    this.formGroups[step - 1].valueChanges.subscribe(() => {
      this.formGroups[step - 1].markAsDirty();
      this.logger.debug('current formGroup: ', this.formGroups[step - 1]);
    });
  }

  initializeStep1Form(): void {
    this.formGroups[0] = new FormGroup({
      entityType: new FormControl<EntityType>(EntityType.SCHOOL, [Validators.required]),
      selectedEntity: new FormControl<EntitySearchResult | null>(null, [Validators.required]),
    });
    this.logger.debug('Created new formGroup step 1: ', this.formGroups[0]);
    this.updateProfile();
    this.selectedEntity.valueChanges.subscribe((selectedEntity: EntitySearchResult) => {
      this.comparisonService.setTargetItem(selectedEntity);
    });
  }

  initializeStep2Form(): void {
    this.logger.debug('Inside initializeStep2Form');
    const defaultLocality: string = this.entityType?.value === EntityType.SCHOOL ? 'd' : 'st';
    this.logger.debug('entityProfile: ', this.entityProfile);
    const stateCode: string = this.entityType?.value === EntityType.SCHOOL ? (this.entityProfile as SchoolProfileQueryModel).schState : (this.entityProfile as DistrictProfileQueryModel).leaState;
    this.logger.debug('stateName: ', stateCode);
    this.logger.debug('stateIds: ', this.profileService.getStateIds());
    const defaultState: State = this.profileService.getStateIds().find(state => state.state_Code === stateCode);
    this.logger.debug('defaultState: ', defaultState);
    const gradesList: LabelValuePair[] = this.profileService.getGradeLevels();
    const defaultGrades: string[] = this.entityProfile?.gradesOffered ? this.entityProfile.gradesOffered.split(',').map(grade => {
      const gradeValue = gradesList.find(g => g.label === grade);
      return gradeValue?.value;
    }) : [];
    const allEntitySurveyYears = this.profileService.getFormattedSurveyYearKeys(this.selectedEntity?.value?.syk);
    const criteriaValues: string[] = [
      'M_TOT_ENROL',
      'F_TOT_ENROL',
      'B_BLA_ENROL',
      'B_AME_ENROL',
      'B_ASI_ENROL',
      'B_HIS_ENROL',
      'B_HI_PAC_ENROL',
      'B_WHI_ENROL',
      'B_MORE_ENROL',
      'B_DIS_ENROL',
      'B_DIS5_ENROL',
      'B_LEP_ENROL'
    ];
    let criteria: CriteriaDTO = {};
    criteriaValues.forEach(value => {
      criteria = {
        ...criteria,
        [value]: {
          lowerBound: Bounds.LOWER,
          upperBound: Bounds.UPPER,
        }
      };
    });

    // Only show survey years greater than 7, so anything after 2013-14
    this.entitySurveyYears = allEntitySurveyYears.filter(syk => syk.yearKey > 7);
    this.formGroups[1] = new FormGroup({
      surveyYears: new FormControl<number[]>([], [this.minLengthArray(1)]),
      locality: new FormControl<string>(defaultLocality, [Validators.required]),
      selectedState: new FormControl<State>(defaultState),
      schoolTypes: new FormControl<string[]>([], [this.minLengthArray(1)]),
      grades: new FormControl<string[]>(defaultGrades),
      criteria: new FormControl<CriteriaDTO>(criteria, [this.rangeValidator(Bounds.LOWER, Bounds.UPPER)]),
      selectedEntities: new FormControl<EntitySearchStep2Result[]>([], [this.minLengthArray(1)]),
    });
    this.logger.debug('Created new formGroup step 2: ', this.formGroups[1]);

    if (this.entityType?.value === EntityType.DISTRICT) {
      this.schoolTypes.disable();
      this.grades.disable();
    }
    this.logger.debug('formGroup step 2: ', this.formGroups[1]);

    // Modules not needed in step 2, but will be needed for step 3.
    // Load it now so we don't have to wait for it in step 3
    this.loadModuleOptions(this.entityType.value);
    this.logger.debug('watching selectedEntities value changes');
    this.comparisonService.setCompareItems(this.selectedEntities.value);
    this.selectedEntities.valueChanges.subscribe((selectedEntities: EntitySearchStep2Result[]) => {
      this.logger.info('selectedEntities changed: ', selectedEntities);
      this.comparisonService.setCompareItems(selectedEntities);
    });
  }

  initializeStep3Form(): void {
    this.logger.debug('moduleOptions and analysis options step 3: ', this.moduleOptions, this.analysisOptions);

    this.formGroups[2] = new FormGroup({
      selectedModules: new FormControl<FormGroup>(new FormGroup({}), [this.atLeastOneSelected()]),
      // selectedComparisons: new FormControl<FormGroup>(new FormGroup({}), [this.atLeastOneSelected()]),
      demographicSelection: new FormControl<DemographicType>(DemographicType.SEX, [Validators.required]),
    });

    const options: FormGroup = this.selectedModules.value;
    // const comparisons: FormGroup = this.selectedComparisons.value;

    if (this.moduleOptions?.result) {
      this.moduleOptions.result.forEach((module: ModuleL1) => {
        module.data.forEach((measure: ModuleDef) => {
          options.addControl(measure.dTool_ID.toString(), new FormControl<boolean>(false));
        });
      });
    }
    else {
      this.logger.error('Invalid moduleOptions for step 3');
    }

    this.otherDistributions.data.forEach((datum: ModuleDef) => {
      this.formGroups[2].addControl(datum.dTool_ID.toString(), new FormControl<boolean>(false));
    });

    this.logger.debug('Created new formGroup step 3: ', this.formGroups[2]);
    this.logger.debug('selectedModules: ', this.selectedModules.value);
  }

  initializeStep4Form(): void {
    this.logger.debug('Inside initializeStep4Form');
    const entityType: string = this.entityType?.value === EntityType.SCHOOL ? 'a' : 'b';
    const filteredCriteria: string[] = Object.keys(this.criteria.value).filter((key: string) => {
      return this.criteria.value[key].lowerBound > 0 && this.criteria.value[key].lowerBound < 100 || this.criteria.value[key].upperBound < 100;
    });
    const criteriaAsStrings = filteredCriteria?.map((key: string) => {
      return `${CRITERIA_LABELS[key]} between ${this.criteria.value[key].lowerBound} and ${this.criteria.value[key].upperBound}%`;
    });
    const criteriaString = criteriaAsStrings?.join(', ');

    let selectedComparisonFormGroup: FormGroup = new FormGroup({});
    const demographic: DemographicType = this.demographicSelection.value;
    const sexValue: boolean = demographic === DemographicType.SEX || demographic === DemographicType.SEX_RACE_ETHNICITY;
    const raceValue: boolean = demographic === DemographicType.RACE_ETHNICITY || demographic === DemographicType.RACE_ETHNICITY_RELATIVE_RISK || demographic === DemographicType.SEX_RACE_ETHNICITY;
    const riskRatioValue: boolean = demographic === DemographicType.RACE_ETHNICITY_RELATIVE_RISK;
    selectedComparisonFormGroup.addControl(ComparisonType.SEX.toString(), new FormControl<boolean>(sexValue));
    selectedComparisonFormGroup.addControl(ComparisonType.RACE_ETHNICITY.toString(), new FormControl<boolean>(raceValue));
    selectedComparisonFormGroup.addControl(ComparisonType.EL_STATUS.toString(), new FormControl<boolean>(this.elp.value));
    selectedComparisonFormGroup.addControl(ComparisonType.IDEA.toString(), new FormControl<boolean>(this.disabilityIDEA.value));
    selectedComparisonFormGroup.addControl(ComparisonType.SECTION504.toString(), new FormControl<boolean>(this.disability504.value));
    selectedComparisonFormGroup.addControl(ComparisonType.RELATIVE_RISK.toString(), new FormControl<boolean>(riskRatioValue));

    this.formGroups[3] = new FormGroup({
      selectedSurveyYears: new FormControl<number[]>(this.surveyYears.value),   // survey years
      selectedEntityType: new FormControl<string>(entityType),
      selectedEnrollment: new FormControl<string>(criteriaString),   // enrollment
      selectedTargetEntityStep4: new FormControl<EntitySearchResult>(this.selectedEntity.value),  // target
      selectedEntitiesStep4: new FormControl<EntitySearchStep2Result[]>(this.selectedEntities.value),    // comparisons
      selectedModuleOptions: new FormControl<FormGroup>(this.selectedModules.value),   // modules
      selectedComparisonOptions: new FormControl<FormGroup>(selectedComparisonFormGroup),
    });
    this.logger.debug('Created new formGroup step 4: ', this.formGroups[3]);
  }

  /**
   * The profile is updated when the selected entity changes in step 1.
   */
  private updateProfile(): void {
    this.entityProfile = null;
    this.logger.debug('Inside updateProfile');

    this.selectedEntity.valueChanges.subscribe(async () => {
      this.entityProfile = null;
      this.logger.debug('selectedEntity changed: ', this.selectedEntity.value);
      const entity: EntitySearchResult = this.selectedEntity.value;

      if (entity) {
        const entityId: number = entity.entity_ID;
        const survyeYearKeys: SurveyYearModel[] = this.profileService.getFormattedSurveyYearKeys(entity.syk);
        const surveyYear: SurveyYearModel = survyeYearKeys[survyeYearKeys.length - 1];
        const sessionProfile = JSON.parse(sessionStorage.getItem('enforcementDataToolEntityProfile'));
        const sessionProfileId: number = this.entityType.value === EntityType.SCHOOL ? (sessionProfile as SchoolProfileQueryModel)?.schoolId : (sessionProfile as DistrictProfileQueryModel)?.lea_Id;

        if (sessionProfileId === entityId) {
          this.entityProfile = sessionProfile;
        } else {
          this.entityProfile = await this.profileService.getDataToolSchoolOrDistictProfile(entityId, this.entityType.value, surveyYear?.yearKey);
          sessionStorage.setItem('enforcementDataToolEntityProfile', JSON.stringify(this.entityProfile));
        }
      }
    });
  }

  /**
   * Sets the form group for the current step to the newly updated form group.
   * @param $event Updated form group
   */
  onFormResponse($event: FormGroup): void {
    this.formGroups[this.currentStep - 1] = $event;
  }

  routeToStep(component?: string): void {
    this.logger.debug('Inside routeToStep, step: ', component);

    // Set current step based on the component. If no component, then should route to step 2.
    // If there is a component, then we need to set the current step to step 3 and scrolled to the
    // correct component. Otherwise, should route to the top of the page.
    component ? this.currentStep = SEARCH_STEP.STEP_THREE : this.currentStep = SEARCH_STEP.STEP_TWO;
    this.formGroups[this.currentStep - 1].markAsPristine();
    this.comparisonService.setIsEditable(this.currentStep === SEARCH_STEP.STEP_TWO);

    if (component) {
      this.scrollItem = component;
    } else {
      this.scrollToTop();
    }
  }

  previousStep(): void {
    this.currentStep--;
    this.scrollItem = null;
    this.formGroups[this.currentStep - 1].markAsPristine();
    this.comparisonService.setIsEditable(this.currentStep === SEARCH_STEP.STEP_TWO);
    this.scrollToTop();
    this.logger.debug('Inside previousStep, currentStep: ', this.currentStep);
    this.logger.debug('current formGroup: ', this.formGroups[this.currentStep - 1]);
  }

  nextStep(confirmClick?: boolean): void {
    // If the user is on step 1 and the form has been changed, then we need to confirm if they want to make a change
    // that would remove all of their currently selected entities. If they confirm, then we can proceed to the next step.
    const comparisonItems = this.comparisonService.getComparisonItems();
    const someComparisonItems = comparisonItems.length > 0;
    if (this.currentStep === SEARCH_STEP.STEP_ONE && this.formGroups[0].dirty && someComparisonItems && !confirmClick) {
      this.logger.debug('Inside nextStep open modal');
      this.logger.debug('dirty formGroup: ', this.formGroups[0]);
      this.openUpdateStep2Modal();
      return;
    } else if (this.currentStep === SEARCH_STEP.STEP_ONE && confirmClick) {
      this.closeUpdateStep2Modal(confirmClick);
    }

    const nextStep = this.currentStep + 1;
    this.logger.debug('Inside nextStep[' + nextStep + '], currentStep: ', this.currentStep);

    this.initialized = false;

    // Saving form data to sessionStorage to be used in resetFormData
    this.saveStepFormData(this.currentStep);

    // Clearing any form data from steps that are ahead of the current step if current step changed
    // Only initializing the next step form if the form data has changed (we skip step 4 as it is the last step)
    if (this.formGroups[this.currentStep - 1].dirty) {
      this.clearOutDatedFutureStepFormData(this.currentStep);
      this.initializeStepForm(nextStep);
    } else if (!this.formGroups[this.currentStep]) {
      this.initializeStepForm(nextStep);
    }

    // Once all form controls and groups are initialized, we can set the current step and paint the screen
    this.initialized = true;
    this.currentStep++;
    this.formGroups[this.currentStep - 1].markAsPristine();
    // Set is editable property for side nav
    this.comparisonService.setIsEditable(this.currentStep === SEARCH_STEP.STEP_TWO);
    this.scrollToTop();
  }

  private saveStepFormData(step: SEARCH_STEP) {
    this.logger.debug('Inside saveStepFormData, step: ', step);

    if (step === SEARCH_STEP.STEP_THREE || step === SEARCH_STEP.STEP_FOUR) {
      const selectedModules = step === SEARCH_STEP.STEP_THREE ? this.selectedModules.value : this.selectedModuleOptions.value;
      this.logger.debug(`Saving step ${step} form data to sessionStorage: `, selectedModules);
      sessionStorage.setItem(`enforcementDataToolStep${step}Modules`, JSON.stringify(selectedModules.value));

      if (step === SEARCH_STEP.STEP_FOUR) {
        const otherStep4Values = {
          selectedSurveyYears: this.selectedSurveyYears.value,
          selectedEntityType: this.selectedEntityType.value,
          selectedTargetEntityStep4: this.selectedEntity.value,
          selectedEntitiesStep4: this.selectedEntitiesStep4.value,
        };
        sessionStorage.setItem(`enforcementDataToolStep${step}`, JSON.stringify(otherStep4Values));
        sessionStorage.setItem(`enforcementDataToolStep${step}Comparisons`, JSON.stringify(this.selectedComparisonOptions.value.value));
      } else {
        const otherStep3Values = {
          demographicSelection: this.demographicSelection.value,
          3: this.elp.value,
          4: this.disabilityIDEA.value,
          5: this.disability504.value,
        }
        sessionStorage.setItem(`enforcementDataToolStep${step}`, JSON.stringify(otherStep3Values));
      }
    } else {
      this.logger.debug('Saving form data to sessionStorage: ', this.formGroups[step - 1].value);
      sessionStorage.setItem(`enforcementDataToolStep${step}`, JSON.stringify(this.formGroups[step - 1].value));
    }
  }

  private clearOutDatedFutureStepFormData(step: SEARCH_STEP) {

    this.logger.debug('Inside clearOutDatedFutureStepFormData from step: ', step);

    // Clearing any form data from steps that are ahead of the current step if current step changed
    // Only initializing the next step form if the form data has changed (we skip step 4 as it is the last step)
    if (this.formGroups[step - 1].dirty) {
      for (let nextStep = (step + 1); nextStep <= this.maxSteps; nextStep++) {
        this.comparisonService.clearSessionItem(nextStep);
      }
    }
  }

  /**
   * Opens the update step 2 modal to display the confirm and cancel buttons that
   * allows the user to either reset the form to before the change, or to go to step 2
   */
  openUpdateStep2Modal() {
    this.showUpdateStep2Modal = true;
  }

  /**
   * Closes the update step 2 modal.
   */
  closeUpdateStep2Modal(confirmClick?: boolean) {
    this.showUpdateStep2Modal = false;

    // If not confirming, then reset step 1 form to before the change
    if (!confirmClick) {
      this.patchStepData(SEARCH_STEP.STEP_ONE);
      this.formGroups[0].markAsPristine();
    }
  }

  async loadModuleOptions(entityType: EntityType): Promise<void> {
    this.logger.debug('Inside loadModuleOptions, entityType: ', entityType);

    // If the entitytype is different from the current moduleEntityType, then we need to load the module options
    const savedModuleOptions = sessionStorage.getItem('moduleOptions_' + entityType);

    if (savedModuleOptions) {
      this.logger.debug('Use modules from storage');
      // If we have the module options in session storage, then we know the user has reloaded the page and we can use the module options from session storage
      this.moduleOptions = JSON.parse(savedModuleOptions);
    } else {
      const entityTypeString = entityType === EntityType.SCHOOL ? 'a' : 'b';

      this.moduleOptions = await firstValueFrom(this.dataToolService.getStep3Modules(entityTypeString))
        .catch((error) => {
          const msg = 'Unable to retrieve modules, error: ';
          this.logger.error(msg, error);
          this.toastService.error('Error loading module options');
          return null;
        });

      // Save the latest module options to session storage
      sessionStorage.setItem('moduleOptions_' + entityType, JSON.stringify(this.moduleOptions));
    }

    this.logger.debug('found moduleOptions: ', this.moduleOptions);
  }

  private getSelectedModules(): number[] {
    this.logger.debug('Inside getSelectedModules');

    let selectedModules: number[] = [];

    this.logger.debug('selectedModules.value.value: ', this.selectedModules?.value?.value);

    if (this.selectedModules?.value?.value) {

      for (const moduleId in this.selectedModules.value.value) {

        //this.logger.debug('moduleId: ', moduleId, this.selectedModules.value.value[moduleId]);

        if (this.selectedModules.value.value[moduleId] === true) {
          selectedModules.push(+moduleId);  // convert to a number
        }

      }

      this.logger.debug('selectedModules: ', selectedModules);
    }
    else {
      this.logger.debug('No modules selected');
    }

    return selectedModules;
  }

  private getSelectedAnalysisTypes(): number[] {
    this.logger.debug('Inside getSelectedAnalysisTypes');

    let analysisTypes: number[] = [];

    this.logger.debug('selectedComparisonOptions.value.value: ', this.selectedComparisonOptions.value.value);

    //value : {1: true, 2: true, 3: false, 4: false, 5: false, 6: false}
    if (this.selectedComparisonOptions?.value?.value) {
      for (const analysisId in this.selectedComparisonOptions.value.value) {

        //this.logger.debug('analysisId: ', analysisId, this.selectedComparisonOptions.value.value[analysisId]);

        if (this.selectedComparisonOptions.value.value[analysisId] === true) {
          analysisTypes.push(+analysisId);  // convert to a number
        }

      }

      this.logger.debug('analysisTypes: ', analysisTypes);
    }
    else {
      this.logger.debug('No analysis types selected');
    }

    return analysisTypes;
  }

  loadStep4ModuleExportData(selectedModules: number[], analysisTypes: number[]): Observable<EntitySearchStep4PreViewModel> {
    this.logger.debug('Inside loadStep4ModuleExportData');

    let searchQuery: EntitySearchStep4Query = new EntitySearchStep4Query();

    searchQuery.surveyYearKeys = this.selectedSurveyYears.value.join(',');
    searchQuery.entityType = this.selectedEntityType.value;
    searchQuery.entityIDs = this.selectedEntitiesStep4.value.map((e: EntitySearchStep2Result) => e.entity_ID).join(',');

    // include target school
    searchQuery.entityIDs = this.getTargetSchoolId() + ',' + searchQuery.entityIDs;

    searchQuery.modules = selectedModules.join(',');
    searchQuery.analysisTypes = analysisTypes.join(',');

    return this.dataToolService.loadEntitySearchStep4Data(searchQuery);
  }

  showLoading(event: { show: boolean, message?: string }) {

    this.logger.debug('Inside showLoading, event: ', event);
    //this.logger.debug('Inside showLoading['+show+'], spinnerLoadingMessage: ', spinnerLoadingMessage);

    const { show, message } = event;

    // this is to indicate that a spinner action was requested, but we will wait for .5 seconds before we show it.
    this.spinnerActionActivated = show;

    if (this.spinnerActionActivated) {

      if (message) {
        this.spinnerMessage = message;
      }
      else {
        this.spinnerMessage = null;
      }

      this.logger.debug('spinnerMessage: ', this.spinnerMessage);
      //this.cdRef.detectChanges();

      // we only want to display the spinner if the action is taking more than .5 seconds
      // this avoids the flashing of the screen if certain actions takes less than .5 seconds to complete
      setTimeout(() => {

        if (this.spinnerActionActivated) {
          this.logger.debug('go ahead and show spinner: ', show);
          this.showLoadingSpinner = true;
        }

      }, 500);

      //this.showLoadingSpinner = true;
    }
    else {
      this.logger.debug('stop showing spinner: ', this.showLoadingSpinner);

      // if we are showing the spinner already wait half a second before removing it so that we avoid a flash on the screen
      if (this.showLoadingSpinner) {

        setTimeout(() => {

          // we do not want to hide the spinner if another process already activated it again
          if (!this.spinnerActionActivated) {
            this.logger.debug('go ahead and hide spinner after delay: ', show);
            this.showLoadingSpinner = false;
          }

        }, 100);
      }
      else {
        this.showLoadingSpinner = false;
      }

    }

  }

  /**
   * Exports the results to an excel file to the user's local machine
   */
  public exportResults(): void {
    this.logger.debug('Inside exportResults');

    //this.toastService.info('Inside performStep4Search');
    this.showLoading({ show: true, message: 'Loading data for export ...' });

    let selectedModules: number[] = this.getSelectedModules();
    let analysisTypes: number[] = this.getSelectedAnalysisTypes();

    this.loadStep4ModuleExportData(selectedModules, analysisTypes).subscribe(moduleData => {

      this.logger.debug('Found moduleData: ', moduleData);

      let addRiskRatioTpExportedData = false;
      if (moduleData) {

        // we need to check if risk ratio was a selected option
        if (analysisTypes) {

          const haveRiskRation = analysisTypes.find(at => at === ComparisonType.RELATIVE_RISK);

          // we need to know if we have to add the relative risk ratio column to the spreadsheet for each school
          if (haveRiskRation) {
            addRiskRatioTpExportedData = true;
          }

          // for (const analysisId in this.selectedComparisonOptions.value.value) {
          //
          //     const isSelected = this.selectedComparisonOptions.value.value[analysisId];
          //
          //   this.logger.debug('analysisId: ', analysisId, isSelected);
          //
          //   // we need to know if we have to add the relative risk ratio column to the spreadsheet for each school
          //   if (+analysisId === ComparisonType.RELATIVE_RISK && isSelected) {
          //     addRiskRatioTpExportedData = true;
          //   }
          //
          // }

        }

        try {

          const modulesExportedCount = this.exportDataService.exportResultsToExcel(moduleData, addRiskRatioTpExportedData);

          this.logger.debug('modulesExportedCount: ', modulesExportedCount);

          if (modulesExportedCount === 0) {
            this.toastService.warning('No Data to export. Please adjust your search criteria');
          }
        }
        catch (e) {
          this.logger.error('Error in exportResultsToExcel, error: ', e);
          this.toastService.warning('Unable to export the data. Please contact your system administrator');
          //this.showLoading({ show: false });
          //throw e;
        }


      } else {

        this.toastService.info('No Data to export');
      }

      this.showLoading({ show: false });
    });


  }

  scrollToTop() {
    let sideNavContent: Element = document.getElementById('content');
    sideNavContent.scroll(0, 0);
  }

  // Validators

  /**
   * Validates an array to ensure it has a minimum length.
   * @param min minumum length of the array
   * @returns Error or null for the form control
   */
  minLengthArray(min: number): ValidatorFn {
    return (control: FormControl<any[]>) => {
      if (control.value.length < min) {
        return { requiredLength: min, actualLength: control.value.length };
      }

      return null;
    }
  }

  /**
   * Validates that at least one checkbox is selected.
   * @returns Error or null for the form control
   */
  atLeastOneSelected(): ValidatorFn {
    return (control: FormControl<FormGroup>) => {
      const controlKeys: string[] = Object.keys(control.value.controls);
      const someSelected: boolean = controlKeys.some(x => control.value.controls[x].value === true);

      if (!someSelected) {
        return { requiredSomeSelected: true, actualSomeSelected: false };
      }

      return someSelected ? null : { notValid: true }
    }
  }

  /**
   * Validates that a number is within a range and doesn't have decimals.
   * @param min minimum range value
   * @param max maximum range value
   * @returns Error or null for the form control
   */
  rangeValidator(min: number, max: number): ValidatorFn {
    return (control: FormControl<CriteriaDTO>) => {

      for (const key in control.value) {
        const lowerBound = control.value[key].lowerBound;
        const upperBound = control.value[key].upperBound;
        const invalidLowerBound = lowerBound < min || lowerBound > max;
        const invalidUpperBound = upperBound < min || upperBound > max;
        const invalidDecimal = lowerBound % 1 !== 0 || upperBound % 1 !== 0;
        if (invalidLowerBound || invalidUpperBound || invalidDecimal) {
          return { requiredRange: [min, max], actualRange: [control.value[key].lowerBound, control.value[key].upperBound], requiredDecimal: false, actualDecimal: invalidDecimal };
        }
      }

      return null;
    }
  }

  // Step 1 Getters

  get entityType() {
    return this.formGroups[0].get('entityType') as FormControl<EntityType>;
  }

  get selectedEntity() {
    return this.formGroups[0].get('selectedEntity') as FormControl<EntitySearchResult>;
  }

  // Step 2 Getters

  get surveyYears() {
    return this.formGroups[1].get('surveyYears') as FormControl<number[]>;
  }

  get locality() {
    return this.formGroups[1].get('locality') as FormControl<string>;
  }

  get selectedState() {
    return this.formGroups[1].get('selectedState') as FormControl<State>;
  }

  get schoolTypes() {
    return this.formGroups[1].get('schoolTypes') as FormControl<string[]>;
  }

  get grades() {
    return this.formGroups[1].get('grades') as FormControl<string[]>;
  }

  get criteria() {
    return this.formGroups[1].get('criteria') as FormControl<CriteriaDTO>;
  }

  get selectedEntities() {
    return this.formGroups[1].get('selectedEntities') as FormControl<EntitySearchStep2Result[]>;
  }

  // Step 3 Getters

  get selectedModules() {

    if (this.formGroups[2]?.get('selectedModules')) {
      return this.formGroups[2].get('selectedModules') as FormControl<FormGroup>;
    }

    this.logger.error('Invalid selectedModules');
    return null;
  }

  // get selectedComparisons() {
  //   return this.formGroups[2].get('selectedComparisons') as FormControl<FormGroup>;
  // }

  get demographicSelection() {
    return this.formGroups[2].get('demographicSelection') as FormControl<DemographicType>;
  }

  get elp() {
    return this.formGroups[2].get(ComparisonType.EL_STATUS.toString()) as FormControl<boolean>;
  }

  get disabilityIDEA() {
    return this.formGroups[2].get(ComparisonType.IDEA.toString()) as FormControl<boolean>;
  }

  get disability504() {
    return this.formGroups[2].get(ComparisonType.SECTION504.toString()) as FormControl<boolean>;
  }


  // Step 4 Getters

  get selectedSurveyYears() {
    return this.formGroups[3].get('selectedSurveyYears') as FormControl<number[]>;
  }

  get selectedEntityType() {
    return this.formGroups[3].get('selectedEntityType') as FormControl<string>;
  }

  get selectedEnrollment() {
    return this.formGroups[3].get('selectedEnrollment') as FormControl<string>;
  }

  get selectedEntitiesStep4() {
    return this.formGroups[3].get('selectedEntitiesStep4') as FormControl<EntitySearchStep2Result[]>;
  }

  getTargetSchoolId(): number {

    const step4Form = this.formGroups[3].get('selectedTargetEntityStep4') as FormControl<EntitySearchResult>;
    return step4Form.value.entity_ID;
  }

  get selectedModuleOptions() {
    return this.formGroups[3].get('selectedModuleOptions') as FormControl<FormGroup>;
  }

  get selectedComparisonOptions() {
    return this.formGroups[3].get('selectedComparisonOptions') as FormControl<FormGroup>;
  }


  //######################### ACCEPT TERMS ##############################################

  closeAUPROBModal() {
    this.logger.debug('Inside closeAUPROBModal');

    this.showAUPROBModal = false;
  }

  acceptTerms() {
    this.logger.debug('Inside acceptTerms');

    this.closeAUPROBModal();
    this.authorizationService.recordUserAcceptedAUPROBTerms();
  }

  declineTerms() {
    this.logger.debug('Inside declineTerms');

    // if the user decline we need to show him a confirmation popup
    this.showDeclineConfirmationAUPROBModal = true;
  }

  onScrollTerms() {
    //this.logger.debug('Inside onScrollTerms');

    const element = this.aupROBTermsContainer.nativeElement;


    if (element) {

      const scrollBuffer = 50;
      const scrollBottom = element.scrollHeight - (element.scrollTop + scrollBuffer);
      // x === 500
      const atBottom = scrollBottom < element.clientHeight;

      this.logger.debug('atBottom: ', atBottom, ', scrollBottom: ', scrollBottom, 'element.scrollHeight: ', element.scrollHeight, ', element.scrollTop: ', element.scrollTop, ', element.clientHeight: ', element.clientHeight);

      if (!this.enableAcceptButton && atBottom) {
        this.enableAcceptButton = true;
      }
      // else {
      //     this.enableAcceptButton = false;
      // }
    }

  }

  confirmDeclineTerms() {
    this.logger.debug('Inside confirmDeclineTerms');

    this.showLoading({ show: true, message: 'Logging out ...' });

    //this.showDeclineConfirmationAUPROBModal = false;
    //this.closeAUPROBModal();

    // tell the backend to log this user out
    this.authorizationService.logout(true, 'decline-terms');

  }

  cancelConfirmationOfDeclineTerms() {
    this.logger.debug('Inside cancelConfirmationOfDeclineTerms');

    this.showDeclineConfirmationAUPROBModal = false;
  }

}
