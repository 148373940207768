import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MaterialModule } from '../../shared/modules/material.module';

import { BubbleChartComponent } from './bubble-chart/bubble-chart.component';
import { AreaChartComponent } from './area-chart/area-chart.component';
import { StackedBubbleChartComponent } from './stacked-bubble-chart/stacked-bubble-chart.component';
import { BarChartComponent } from './bar-chart/bar-chart.component';
import { StackedBarChartComponent } from './stacked-bar-chart/stacked-bar-chart.component';
import { NationalChartComponent } from './national-chart/national-chart.component';
import { ChartsComponent } from './charts.component';

@NgModule({
    declarations: [
        ChartsComponent,
        AreaChartComponent,
        BubbleChartComponent,
        StackedBubbleChartComponent,
        BarChartComponent,
        StackedBarChartComponent,
        NationalChartComponent,
    ],
    imports: [
        CommonModule,
        MaterialModule
    ],
    exports: [
        ChartsComponent
    ],
    providers: [
    ]
})
export class ChartsModule { }
