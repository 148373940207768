<div class="hero-container"
    [style.paddingTop]="paddingTop ? paddingTop : '9rem'"
    [style.paddingBottom]="paddingBottom ? paddingBottom : '9rem'">
    <div class="hero-primary is-flex is-flex-direction-row">
        <div class="hero-header-container is-flex is-align-self-center is-flex-direction-column">
            <p class="hero-subheader has-text-left large-body has-text-white">{{ heroSubHeader }}</p>
            <h1 class="hero-header has-text-left">{{ heroHeader }}</h1>
            <div class="hero-content-container">
                <hr class="hero-line">
                <div class="hero-text large-body"> {{ heroDescription }} </div>
            </div>
        </div>
        <div class="hero-img is-hidden-touch">
            <img [src]="heroImageSrc" class="rounded-corners" [alt]="heroImageAlt" />
        </div>
    </div>
</div>
