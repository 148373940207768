import { Component, Input, OnInit } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-area-chart',
  templateUrl: './area-chart.component.html',
  styleUrls: ['./area-chart.component.scss']
})

/**
 * This class renders an area chart
 */
export class AreaChartComponent implements OnInit {

  @Input() data;

  private svg;
  private legendSvg;
  private mobileLegendSvg;
  private margin = { top: 10, right: 0, bottom: 75, left: 0 };
  private width = 1440;
  private height = 600;
  private legendWidth = 704;
  private legendHeight = 105;
  private mobileLegendWidth = 361;
  private mobileLegendHeight = 142;
  private colorRange = ["rgba(255, 255, 255, 0.2)", "rgba(255, 255, 255, 0.25)", "rgba(255, 255, 255, 0.3)", "rgba(255, 255, 255, 0.4)",
    "rgba(255, 255, 255, 0.45)", "rgba(255, 255, 255, 0.46)"];

  constructor() { }

  /**
   * This is the entry point function that runs all of the rendering functions.
   */
  ngOnInit(): void {
    if (this.data) {
      this.createSvg();
      this.createLegendSvg();
      this.createMobileLegendSvg();
      this.createBackground();
      this.createXAxisBackground();
      this.drawAreaChart();
      this.drawLegend();
      this.drawMobileLegend();
    }
  }

  /**
   * This function creates the svg for the chart to be placed in.
   */
  private createSvg(): void {
    this.svg = d3.select("#area-chart").append("svg").attr("viewBox", `0 0 ${this.width} ${this.height}`)
      .append("g").attr("transform", "translate(" + this.margin.left + "," + this.margin.top + ")");
  }

  private createLegendSvg(): void {
    this.legendSvg = d3.select("#legend").append("svg").attr("viewBox", `0 0 ${this.legendWidth} ${this.legendHeight}`).attr("width", this.legendWidth).attr("height", this.legendHeight)
      .append("g");
  }

  private createMobileLegendSvg(): void {
    this.mobileLegendSvg = d3.select("#mobile-legend").append("svg").attr("viewBox", `0 0 ${this.mobileLegendWidth} ${this.mobileLegendHeight}`).attr("width", this.mobileLegendWidth).attr("height", this.mobileLegendHeight)
      .append("g");
  }

  /**
   * This function creates a blue rectangle as the background
   */
  private createBackground(): void {
    let rectHeight = this.height - this.margin.bottom;

    this.svg.append("rect").attr("width", this.width).attr("height", rectHeight).attr("fill", "#133559")
      .attr("transform", "translate(" + this.margin.left + "," + -this.margin.top + ")").append("g");
  }

  private createXAxisBackground(): void {
    let topPadding = this.height - this.margin.bottom - this.margin.top;

    this.svg.append("rect").attr("width", this.width).attr("height", this.margin.bottom).attr("fill", "rgba(255, 255, 255, 0.6)")
      .attr("transform", "translate(" + this.margin.left + "," + topPadding + ")").append("g");
  }

  /**
   * This function draws the overlapping area chart.
   */
  private drawAreaChart(): void {
    let chartWidth = this.width - this.margin.right - this.margin.left;
    let chartHeight = this.height - this.margin.top - this.margin.bottom;

    // Sets up the colors
    let variables = this.data.columns.slice(1);
    this.colorRange = this.colorRange.splice(0, (variables.length));
    let color = d3.scaleOrdinal().domain(variables).range(this.colorRange);

    // Sets up the x and y scales
    let x = d3.scaleLinear().range([0, chartWidth]).domain(d3.extent(this.getXDomain()));
    let y = d3.scaleLinear().range([chartHeight, 0]).domain(this.getYDomain());

    // this.getTooltipsPos(x, y);

    // Sets up the area to be rendered
    let area = d3.area().curve(d3.curveMonotoneX).x(function (d: any) { return x(d.date); }).y0(y(0)).y1(function (d: any) { return y(d.value); })

    let sources = this.getSources();

    // Sets up the x and y axis
    let xAxisText = d3.scaleLinear().range([50, (chartWidth - 50)]).domain(d3.extent(this.getXDomain()));
    let xAxis = d3.axisBottom(xAxisText).tickValues(this.getXDomain()).tickFormat(d3.format("d")).tickSize(0);
    let rightYAxis = d3.axisRight(y).tickValues(this.getYTickValues(sources)).tickSize(chartWidth - 306);
    let leftYAxis = d3.axisLeft(y).tickValues(this.getYTickValues(sources)).tickSize(0);


    // Renders the x and y axis
    this.svg.append("g").attr("class", "axis axis--x").attr("transform", "translate(0," + chartHeight + ")").call(customXAxis);
    this.svg.append("g").attr("class", "axis rightAxis--y").call(customRightYAxis);
    this.svg.append("g").attr("class", "axis leftAxis--y").call(customLeftYAxis);

    // Adds class labels to all area
    let source = this.svg.selectAll(".area")
      .data(sources).enter().append("g")
      .attr("class", function (d) { return `area-${d.label.replaceAll(' ', '-')}`; });

    // Renders the area
    source.append("path")
      .attr("d", function (d) { return area(d.values); })
      .style("fill", function (d) { return color(d.label); })
      .on("mouseover", function (d) {
        d3.select("figure#area-chart")
          .select("svg")
          .selectAll(`.area-${d.path[1].__data__.label.replaceAll(' ', '-')}`)
          .transition().duration(100)
          .style("stroke", "white");

        d3.select("figure#area-chart")
          .select("svg")
          .selectAll(`.legend-${d.path[1].__data__.label.replaceAll(' ', '-')}`)
          .transition().duration(100)
          .style("stroke", "white");

        d3.select("figure#legend")
          .select("svg")
          .selectAll(`.legendText-${d.path[1].__data__.label.replaceAll(' ', '-')}`)
          .transition().duration(100)
          .style("font-weight", "bold");

        d3.select("figure#area-chart")
          .select("svg")
          .selectAll(`.tooltip-${d.path[1].__data__.label.replaceAll(' ', '-')}`)
          .transition().duration(100)
          .style("visibility", "visibile");
      }).on("mouseout", function (d) {
        d3.select("figure#area-chart")
          .select("svg")
          .selectAll(`.area-${d.path[1].__data__.label.replaceAll(' ', '-')}`)
          .transition().duration(100)
          .style("stroke", "none");

        d3.select("figure#area-chart")
          .select("svg")
          .selectAll(`.legend-${d.path[1].__data__.label.replaceAll(' ', '-')}`)
          .transition().duration(100)
          .style("stroke", "none");

        d3.select("figure#legend")
          .select("svg")
          .selectAll(`.legendText-${d.path[1].__data__.label.replaceAll(' ', '-')}`)
          .transition().duration(100)
          .style("font-weight", "normal");

        d3.select("figure#area-chart")
          .select("svg")
          .selectAll(`.tooltip-${d.path[1].__data__.label.replaceAll(' ', '-')}`)
          .transition().duration(100)
          .style("visibility", "hidden");
      });

    /**
     * This function customizes the x axis
     * @param g the associated svg
     */
    function customXAxis(g) {
      g.call(xAxis);
      g.select(".domain").remove();
      g.selectAll(".tick text").each(function(d, i) {
        let year: number = parseInt(d3.select(this).text());
        let newYear: number = year++;
        let period: string = `${newYear}-${year}`;
        d3.select(this).text(period);
      })
      g.selectAll(".tick text").attr("dy", 40).attr("fill", "#133559").style("font-size", "14px");
    }

    /**
     * This function customizes the right y axis
     * @param g the assoicated svg
     */
    function customRightYAxis(g) {
      let rightPadding = 153;

      g.call(rightYAxis);
      g.select(".domain").remove();
      g.selectAll(".tick line").attr("stroke", "#B0B8D1").attr("stroke-dasharray", "2,2").attr("transform", "translate(" + rightPadding + ",0)");
      g.selectAll(".tick text").attr("fill", "#FFFFFF").style("font-size", "14px").attr("transform", "translate(" + (rightPadding + 60) + ",0)");
    }

    /**
     * This function customizes the left y axis
     * @param g the associated svg
     */
    function customLeftYAxis(g) {
      let leftPadding = 153;

      g.call(leftYAxis);
      g.select(".domain").remove();
      g.selectAll(".tick line").remove();
      g.selectAll(".tick text").attr("fill", "#FFFFFF").style("font-size", "14px").attr("transform", "translate(" + (leftPadding - 60) + ",0)");
    }

  }

  private drawLegend(): void {
    let variables = this.data.columns.slice(1);
    let color = d3.scaleOrdinal().domain(variables).range(this.colorRange);

    this.getSources().forEach((column, i) => {
      let padding = 210
      let rightSidePadding = padding + Math.floor(i / 2) * padding;

      if ((i % 2) === 0) {
        this.legendSvg.append("circle").attr("class", `legend-${column.label.replaceAll(' ', '-')}`).attr("cx", this.legendWidth - rightSidePadding).attr("cy", 15).attr("r", 15).style("fill", color(column.label))
          .on("mouseover", function () {
            d3.select("figure#area-chart")
              .select("svg")
              .selectAll(`.area-${column.label.replaceAll(' ', '-')}`)
              .transition().duration(100)
              .style("stroke", "white");

            d3.select("figure#area-chart")
              .select("svg")
              .selectAll(`.legend-${column.label.replaceAll(' ', '-')}`)
              .transition().duration(100)
              .style("stroke", "white");

            d3.select("figure#legend")
              .select("svg")
              .selectAll(`.legendText-${column.label.replaceAll(' ', '-')}`)
              .transition().duration(100)
              .style("font-weight", "bold");
          })
          .on("mouseout", function () {
            d3.select("figure#area-chart")
              .select("svg")
              .selectAll(`.area-${column.label.replaceAll(' ', '-')}`)
              .transition().duration(100)
              .style("stroke", "none");

            d3.select("figure#area-chart")
              .select("svg")
              .selectAll(`.legend-${column.label.replaceAll(' ', '-')}`)
              .transition().duration(100)
              .style("stroke", "none");

            d3.select("figure#legend")
              .select("svg")
              .selectAll(`.legendText-${column.label.replaceAll(' ', '-')}`)
              .transition().duration(100)
              .style("font-weight", "normal");
          });

        this.legendSvg.append("text").attr("id", "legendText").attr("class", `legendText-${column.label.replaceAll(' ', '-')}`).attr("x", this.legendWidth - rightSidePadding + 20).attr("y", 15)
          .text(column.label).style("font-size", "14px").attr("alignment-baseline", "middle").attr("fill", "#B0B8D1");

      } else {
        this.legendSvg.append("circle").attr("class", `legend-${column.label.replaceAll(' ', '-')}`).attr("cx", this.legendWidth - rightSidePadding).attr("cy", 80).attr("r", 15).style("fill", color(column.label))
          .on("mouseover", function () {
            d3.select("figure#area-chart")
              .select("svg")
              .selectAll(`.area-${column.label.replaceAll(' ', '-')}`)
              .transition().duration(100)
              .style("stroke", "white");

            d3.select("figure#area-chart")
              .select("svg")
              .selectAll(`.legend-${column.label.replaceAll(' ', '-')}`)
              .transition().duration(100)
              .style("stroke", "white");

            d3.select("figure#legend")
              .select("svg")
              .selectAll(`.legendText-${column.label.replaceAll(' ', '-')}`)
              .transition().duration(100)
              .style("font-weight", "bold");
          })
          .on("mouseout", function () {
            d3.select("figure#area-chart")
              .select("svg")
              .selectAll(`.area-${column.label.replaceAll(' ', '-')}`)
              .transition().duration(100)
              .style("stroke", "none");

            d3.select("figure#area-chart")
              .select("svg")
              .selectAll(`.legend-${column.label.replaceAll(' ', '-')}`)
              .transition().duration(100)
              .style("stroke", "none");

            d3.select("figure#legend")
              .select("svg")
              .selectAll(`.legendText-${column.label.replaceAll(' ', '-')}`)
              .transition().duration(100)
              .style("font-weight", "normal");
          });

        this.legendSvg.append("text").attr("id", "legendText").attr("class", `legendText-${column.label.replaceAll(' ', '-')}`).attr("x", this.legendWidth - rightSidePadding + 20).attr("y", 80)
          .text(column.label).style("font-size", "14px").attr("alignment-baseline", "middle").attr("fill", "#B0B8D1");
      }
    });

    this.legendSvg.selectAll("#legendText").call(wrap, 140);

    function wrap(text, width) {
      text.each(function () {
        var text = d3.select(this),
          words = text.text().split(" ").reverse(),
          word,
          line = [],
          lineNumber = 0,
          lineHeight = 17,
          x = text.attr("x"),
          y = text.attr("y"),
          tspan = text.text(null).append("tspan").attr("x", x).attr("y", y);
        while (word = words.pop()) {
          line.push(word);
          tspan.text(line.join(" "));
          if (tspan.node().getComputedTextLength() > width) {
            line.pop();
            tspan.text(line.join(" "));
            line = [word];
            tspan = text.append("tspan").attr("x", x).attr("y", y).attr("dy", ++lineNumber * lineHeight + "px").text(word);
          }
        }
      });
    }
  }

  private drawMobileLegend(): void {
    let variables = this.data.columns.slice(1);
    let color = d3.scaleOrdinal().domain(variables).range(this.colorRange);

    this.getSources().forEach((column, i) => {
      let padding = 54
      let bottomPadding = 24 + Math.floor(i / 2) * padding;

      if ((i % 2) === 0) {
        this.mobileLegendSvg.append("circle").attr("class", `mobileLegend-${column.label.replaceAll(' ', '-')}`).attr("cx", 4).attr("cy", this.mobileLegendHeight - bottomPadding).attr("r", 4).style("fill", color(column.label))
          .on("mouseover", function () {
            d3.select("figure#area-chart")
              .select("svg")
              .selectAll(`.area-${column.label.replaceAll(' ', '-')}`)
              .transition().duration(100)
              .style("stroke", "white");

            d3.select("figure#mobile-legend")
              .select("svg")
              .selectAll(`.mobileLegend-${column.label.replaceAll(' ', '-')}`)
              .transition().duration(100)
              .style("stroke", "white");

            d3.select("figure#mobile-legend")
              .select("svg")
              .selectAll(`.mobileLegendText-${column.label.replaceAll(' ', '-')}`)
              .transition().duration(100)
              .style("font-weight", "bold");
          })
          .on("mouseout", function () {
            d3.select("figure#area-chart")
              .select("svg")
              .selectAll(`.area-${column.label.replaceAll(' ', '-')}`)
              .transition().duration(100)
              .style("stroke", "none");

            d3.select("figure#mobile-legend")
              .select("svg")
              .selectAll(`.mobileLegend-${column.label.replaceAll(' ', '-')}`)
              .transition().duration(100)
              .style("stroke", "none");

            d3.select("figure#mobile-legend")
              .select("svg")
              .selectAll(`.mobileLegendText-${column.label.replaceAll(' ', '-')}`)
              .transition().duration(100)
              .style("font-weight", "normal");
          });

        this.mobileLegendSvg.append("text").attr("id", "mobileLegendText").attr("class", `mobileLegendText-${column.label.replaceAll(' ', '-')}`).attr("x", 24).attr("y", this.mobileLegendHeight - bottomPadding)
          .text(column.label).style("font-size", "14px").attr("alignment-baseline", "middle").attr("fill", "#B0B8D1");;

      } else {
        this.mobileLegendSvg.append("circle").attr("class", `mobileLegend-${column.label.replaceAll(' ', '-')}`).attr("cx", 186).attr("cy", this.mobileLegendHeight - bottomPadding).attr("r", 4).style("fill", color(column.label))
          .on("mouseover", function () {
            d3.select("figure#area-chart")
              .select("svg")
              .selectAll(`.area-${column.label.replaceAll(' ', '-')}`)
              .transition().duration(100)
              .style("stroke", "white");

            d3.select("figure#mobile-legend")
              .select("svg")
              .selectAll(`.mobileLegend-${column.label.replaceAll(' ', '-')}`)
              .transition().duration(100)
              .style("stroke", "white");

            d3.select("figure#mobile-legend")
              .select("svg")
              .selectAll(`.mobileLegendText-${column.label.replaceAll(' ', '-')}`)
              .transition().duration(100)
              .style("font-weight", "bold");
          })
          .on("mouseout", function () {
            d3.select("figure#area-chart")
              .select("svg")
              .selectAll(`.area-${column.label.replaceAll(' ', '-')}`)
              .transition().duration(100)
              .style("stroke", "none");

            d3.select("figure#mobile-legend")
              .select("svg")
              .selectAll(`.mobileLegend-${column.label.replaceAll(' ', '-')}`)
              .transition().duration(100)
              .style("stroke", "none");

            d3.select("figure#mobile-legend")
              .select("svg")
              .selectAll(`.mobileLegendText-${column.label.replaceAll(' ', '-')}`)
              .transition().duration(100)
              .style("font-weight", "normal");
          });

        this.mobileLegendSvg.append("text").attr("id", "mobileLegendText").attr("class", `mobileLegendText-${column.label.replaceAll(' ', '-')}`).attr("x", 210).attr("y", this.mobileLegendHeight - bottomPadding)
          .text(column.label).style("font-size", "14px").attr("alignment-baseline", "middle").attr("fill", "#B0B8D1");
      }
    });

    this.mobileLegendSvg.selectAll("#mobileLegendText").call(wrap, 150);

    function wrap(text, width) {
      text.each(function () {
        var text = d3.select(this),
          words = text.text().split(" ").reverse(),
          word,
          line = [],
          lineNumber = 0,
          lineHeight = 17,
          x = text.attr("x"),
          y = text.attr("y"),
          tspan = text.text(null).append("tspan").attr("x", x).attr("y", y);
        while (word = words.pop()) {
          line.push(word);
          tspan.text(line.join(" "));
          if (tspan.node().getComputedTextLength() > width) {
            line.pop();
            tspan.text(line.join(" "));
            line = [word];
            tspan = text.append("tspan").attr("x", x).attr("y", y).attr("dy", ++lineNumber * lineHeight + "px").text(word);
          }
        }
      });
    }
  }

  /**
   * This function gets the columns that encompass all the data
   * @returns an array of data with the sources as the columns
   */
  private getSources() {
    return this.data.columns.slice(1).map((value) => {
      return {
        label: value,
        values: this.data.map((d) => {
          return { date: Number(d.category), value: d[value] };
        })
      }
    });
  }

  /**
   * Gets the x domain
   * @returns an array of all the categories
   */
  private getXDomain() {
    let domain = [];

    this.data.forEach(element => {
      domain.push(Number(element.category));
    });

    return domain;
  }

  /**
   * Gets the y domain
   * @returns the y domain given the columns
   */
  private getYDomain(): number[] {
    let max: number = 0;
    let values: string[] = this.data.columns.slice(1);

    this.data.forEach((element) => {
      values.forEach((value) => {
        let size = element[value];

        if (max === 0) {
          max = size;
        } else if (size > max) {
          max = size
        }
      });
    });

    return [0, max];
  }

  /**
   * This function gets the y tick values for the left and right y axis
   * @param sources the sources for all the data
   * @returns 
   */
  private getYTickValues(sources: Array<any>): Array<Number> {
    let max = parseInt(d3.max(sources, function (c) { return d3.max(c.values, function (d: any) { return d.value; }); }));
    let roundedMax = Math.round(max / 100000) * 100000;
    let tickDelta = Math.round(roundedMax / 30000) * 10000;
    let tickValues = [];

    for (let i = 1; i < 4; i++) {
      tickValues.push(tickDelta * i);
    }

    return tickValues;
  }

  private getTooltipsPos(xScale: any, yScale: any): void {
    let toolTipPos: Array<any>;
    this.getSources().forEach((element, i) => {
      let date: number;
      let max = d3.max(element.values, function (d: any) { return d.value });

      element.values.forEach((e, j) => {
        if (j == 0) {
          date = e.value;
        } else if (e.value > date) {
          date = e.value;
        }
      });

      toolTipPos[i] = [date, max, element.label];
    });

    toolTipPos.forEach(toolTip => {
      this.svg.append("div").attr("class", `.tooltip-${toolTip[2].replaceAll(' ', '-')}`)
        .style("position", "absolute")
        // .style("visibility", "hidden")
        .style("background-color", "white")
        .style("box-shadow", "0px 4px 4px rgba(0, 0, 0, 0.25)")
        .style("padding", "10px")
        .style("top", yScale(toolTip[1]))
        .style("left", xScale(toolTip[0]))
        .html(`<p class='has-text-primary small-body'>${toolTip[2]}</p>`)
    })
  }
}
