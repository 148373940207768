import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Subscription, map, combineLatest } from 'rxjs';
import { EntityType, SurveyYearModel } from 'src/app/shared/models/profile-model';
import { DataSpotlightQuery } from 'src/app/shared/queries/data-spotlight-query';
import { DistrictSearchQuery } from 'src/app/shared/queries/district-search-query';
import { EntityDataQuery } from 'src/app/shared/queries/entity-data-query';
import { EntitySearchQuery } from 'src/app/shared/queries/entity-search-query';
import { SchoolSearchQuery } from 'src/app/shared/queries/school-search-query';
import { StateNationalEtimationQuery } from 'src/app/shared/queries/state-national-estimation-query';
import { DistrictProfileQueryModel } from 'src/app/shared/query-models/district-profile-query-model';
import { DistrictSearchQueryModel } from 'src/app/shared/query-models/district-search-query-model';
import { EntitySearchResult } from 'src/app/shared/query-models/entity-search-model';
import { SchoolProfileQueryModel } from 'src/app/shared/query-models/school-profile-query-model';
import { SchoolSearchQueryModel } from 'src/app/shared/query-models/school-search-query-model';
import { DataSpotlightService } from 'src/app/shared/services/data/data-spotlight.service';
import { DistrictProfileService } from 'src/app/shared/services/data/district-profile.service';
import { DistrictSearchService } from 'src/app/shared/services/data/district-search.service';
import { EntitySearchService } from 'src/app/shared/services/data/entity-search.service';
import { SchoolProfileService } from 'src/app/shared/services/data/school-profile.service';
import { SchoolSearchService } from 'src/app/shared/services/data/school-search.service';
import { StateNationalEstimationService } from 'src/app/shared/services/data/state-national-estimation.service';
import { ProfileService } from 'src/app/shared/services/helpers/profile.service';
import { SEOService } from 'src/app/shared/services/helpers/seo-service';

@Component({
  selector: 'app-profile-report',
  templateUrl: './profile-report.component.html',
  styleUrls: ['./profile-report.component.scss']
})
export class ProfileReportComponent implements OnInit, OnDestroy {

  EntityType = EntityType;

  public entityId: number;
  public surveyYearKey: number;
  public entityType: EntityType;

  public showReport: boolean = false;
  public isLoading: boolean = false;
  public districtLoaded: boolean = false;

  private routerSubscription: Subscription;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private readonly schoolSearchService: SchoolSearchService,
    private readonly schoolProfileService: SchoolProfileService,
    private readonly districtSearchService: DistrictSearchService,
    private readonly districtProfileService: DistrictProfileService,
    private readonly entitySearchService: EntitySearchService,
    private readonly stateNationalEstimationService: StateNationalEstimationService,
    private readonly dataSpotlightService: DataSpotlightService,
    private profileService: ProfileService,
    private _seoService: SEOService
  ) {
    this.showReport = false;
    this.isLoading = true;
  }

  async ngOnInit(): Promise<void> {
    await this.profileService.loadStateList();
    await this.profileService.loadSurveyYearKeys();

    this.routerSubscription = combineLatest([this.activatedRoute.paramMap, this.activatedRoute.queryParamMap]).pipe(
      map(([paramMap, queryParamMap]) => ({
        paramMap,
        queryParamMap
      }))
    ).subscribe(async ({ paramMap, queryParamMap }) => {
      this.scrollToTop();
      this.showReport = false;
      this.isLoading = true;

      if (paramMap.get('school')) {
        this.entityType = EntityType.SCHOOL;
      } else if (paramMap.get('district')) {
        this.entityType = EntityType.DISTRICT;
      } else if (paramMap.get('state')) {
        this.entityType = EntityType.STATE;
      } else {
        this.entityType = EntityType.NATIONAL;
      }

      const currentUrl: string = this.router.url.replace('#content', '');
      const urlAndQuery: string[] = currentUrl.split('?');
      const queryParams: string = urlAndQuery[1];
      const surveyYears: SurveyYearModel[] = this.profileService.getSurveyYearKeys(EntityType.GENERAL);

      if (this.queryCheck(queryParams) && surveyYears.length > 0) {
        const queries: string[] = queryParams.split('&');
        const surveyYearParam: string = queries[0];
        const ncesIdParam: string = queries[1];
        const surveyYear: string = surveyYearParam.split('=')[1];
        const currentSurveyYear: SurveyYearModel = surveyYears.find(year => year.surveyYear === surveyYear);

        this.surveyYearKey = currentSurveyYear.yearKey;

        switch (this.entityType) {
          case EntityType.SCHOOL:
            let schoolNcesId: string = ncesIdParam.split('=')[1];
            this.getSchool(schoolNcesId);
            break;
          case EntityType.DISTRICT:
            let districtNcesId: string = ncesIdParam.split('=')[1];
            this.getDistrict(districtNcesId);
            break;
          case EntityType.STATE:
            this.getState(paramMap);
            break;
          default:
            this.entityId = 0;
            this.getSpotlight();
            break;
        }
      } else {
        this.routeTo404();
      }
    });
  }

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
  }

  private scrollToTop(): void {
    let sideNavContent: Element = document.getElementById('content');
    sideNavContent.scroll(0, 0);
  }

  private queryCheck(queryParams: string): boolean {
    if (queryParams) {
      let queries: string[] = queryParams.split('&');
      let surveyYearParam: string = queries[0];
      let ncesIdParam: string = queries[1];
      let surveyYearCheck: RegExp = new RegExp(/^surveyYear=20\d{2}$/);
      let schoolNcesIdCheck: RegExp = new RegExp(/^nces=.{12}$/);
      let districtNcesIdCheck: RegExp = new RegExp(/^nces=.{7}$/);

      switch (this.entityType) {
        case EntityType.SCHOOL:
          return surveyYearCheck.test(surveyYearParam) && schoolNcesIdCheck.test(ncesIdParam);
        case EntityType.DISTRICT:
          return surveyYearCheck.test(surveyYearParam) && districtNcesIdCheck.test(ncesIdParam);
        default:
          return surveyYearCheck.test(surveyYearParam);
      }
    } else {
      return false;
    }
  }


  private getSchool(ncesId: string): void {
    let profile: SchoolProfileQueryModel = JSON.parse(sessionStorage.getItem('schoolProfile'));
    let profileSurveyYearKey: number = profile ? profile.surveyYearKey : 0;
    let profileNcesId: string = profile ? profile.longNcesId : '';

    if (ncesId === profileNcesId && this.surveyYearKey === profileSurveyYearKey) {
      let name: string = profile.schName;

      this._seoService.updateTitle(`${name} | Civil Rights Data`);
      this._seoService.updateOgTitle(`${name} | Civil Rights Data`);
      this._seoService.updateOgDescription(`Assess the state of civil rights at ${name}.`);

      this.isLoading = false;
      this.entityId = profile.schoolId;
      this.showReport = true;
    } else {
      this.search(ncesId);
    }
  }

  private getDistrict(ncesId: string) {
    let profile: DistrictProfileQueryModel = JSON.parse(sessionStorage.getItem('districtProfile'));
    let profileSurveyYear: string = profile ? profile.surveyYear :  null;
    let surveyYears: SurveyYearModel[] = this.profileService.getSurveyYearKeys(EntityType.GENERAL);
    let profileSurveyYearKey: number = profileSurveyYear ? surveyYears.find(year => year.surveyYear === profileSurveyYear).yearKey : 0;
    let profileNcesId = profile ? profile.lea_Id : '';

    if (ncesId === profileNcesId && this.surveyYearKey === profileSurveyYearKey) {
      let name: string = profile.leaName;

      this._seoService.updateTitle(`${name} | Civil Rights Data`);
      this._seoService.updateOgTitle(`${name} | Civil Rights Data`);
      this._seoService.updateOgDescription(`Assess the state of civil rights within ${name}.`);

      this.isLoading = false;
      this.districtLoaded = true;
      this.entityId = profile.lea_Id;
      this.showReport = true;
    } else {
      this.search(ncesId);
    }
  }

  private async getState(paramMap: ParamMap) {
    let states = this.profileService.getStateIds();

    if (states === undefined) {
      this.routeTo404();
    }

    let stateCode: string = paramMap.get('state').toUpperCase();
    let query: EntitySearchQuery = new EntitySearchQuery();
    let stateName: string = states.find(state => state.state_Code === stateCode).stateName;

    query.entityName = stateName;

    this._seoService.updateTitle(`${stateName} | Civil Rights Data`);
    this._seoService.updateOgTitle(`${stateName} | Civil Rights Data`);
    this._seoService.updateOgDescription(`Assess the state of civil rights in ${stateName} education.`);

    this.entitySearchService.getEntitySearchResult(query).subscribe(entitySearchResults => {
      if (entitySearchResults) {
        let entities: EntitySearchResult[] = entitySearchResults;
        let state: EntitySearchResult = entities.find(entity => entity.entity_Type === 'c' && entity.entity_Name === stateName);

        this.entityId = state.entity_ID;
        this.getSpotlight();
      } else {
        this.routeTo404();
      }
    });
  }

  search(ncesId: string): void {
    switch (this.entityType) {
      case EntityType.SCHOOL:
        let schoolQuery: SchoolSearchQuery = new SchoolSearchQuery();

        schoolQuery.long_Nces_Id = ncesId;
        schoolQuery.survey_Year_Key = this.surveyYearKey;

        this.schoolSearchService.get(schoolQuery).subscribe(data => {
          if (data) {
            let entity: SchoolSearchQueryModel = data[0];

            sessionStorage.setItem('entitySurveyYearKeys', entity.entityYears);

            this.entityId = entity.school_Id;
            this.getSchoolProfile();
          } else {
            this.routeTo404();
          }
        });
        break;
      case EntityType.DISTRICT:
        let districtQuery: DistrictSearchQuery = new DistrictSearchQuery();

        districtQuery.lea_Id = ncesId;
        districtQuery.survey_Year_Key = this.surveyYearKey;

        this.districtSearchService.get(districtQuery).subscribe(data => {
          if (data) {
            let entity: DistrictSearchQueryModel = data[0];

            sessionStorage.setItem('entitySurveyYearKeys', entity.entityYears);

            this.entityId = entity.lea_Id;
            this.getDistrictProfile();
          } else {
            this.routeTo404();
          }
        });
        break;
    }
  }

  private getSchoolProfile(): void {
    let query: EntityDataQuery = new EntityDataQuery();

    query.surveyYearKey = this.surveyYearKey;
    query.entityId = this.entityId;

    this.schoolProfileService.get(query).subscribe(data => {
      if (data) {
        let schoolProfile: SchoolProfileQueryModel = data;
        let name: string = schoolProfile.schName;

        this._seoService.updateTitle(`${name} | Civil Rights Data`);
        this._seoService.updateOgTitle(`${name} | Civil Rights Data`);
        this._seoService.updateOgTitle(`${name} | Civil Rights Data`);
        this._seoService.updateOgDescription(`Assess the state of civil rights at ${name}.`);

        this.isLoading = false;
        sessionStorage.setItem('schoolProfile', JSON.stringify(data));
        this.showReport = true;
      } else {
        this.routeTo404();
      }
    });
  }

  private getDistrictProfile(): void {
    var query: EntityDataQuery = new EntityDataQuery();

    query.surveyYearKey = this.surveyYearKey;
    query.entityId = this.entityId;

    this.districtProfileService.get(query)
      .subscribe(data => {
        if (data) {
          let leaName: string = data.leaName;

          this._seoService.updateTitle(`${leaName} | Civil Rights Data`);
          this._seoService.updateOgTitle(`${leaName} | Civil Rights Data`);
          this._seoService.updateOgTitle(`${leaName} | Civil Rights Data`);
          this._seoService.updateOgDescription(`Assess the state of civil rights within ${leaName}.`);

          this.isLoading = false;
          this.districtLoaded = true;
          sessionStorage.setItem('districtProfile', JSON.stringify(data));
          this.showReport = true;
        } else {
          this.routeTo404();
        }
      });
  }

  private getSpotlight(): void {
    let query: DataSpotlightQuery = new DataSpotlightQuery();

    query.surveyYearKey = this.surveyYearKey;
    query.stateId = this.entityId;

    this.dataSpotlightService.get(query).subscribe(data => {
      if (data) {
        if (this.entityType === EntityType.STATE) {
          sessionStorage.setItem('stateSpotlight', JSON.stringify(data));
        } else {
          sessionStorage.setItem('dataSpotlight', JSON.stringify(data));
        }

        this.getStateNationalEstimation();
      } else {
        this.routeTo404();
      }
    });
  }

  private getStateNationalEstimation(): void {
    let query: StateNationalEtimationQuery = new StateNationalEtimationQuery();

    query.measureId = 1;
    query.surveyYearKey = this.surveyYearKey;

    if (this.entityType === EntityType.STATE) {
      query.stateId = this.entityId;

      this.stateNationalEstimationService.getStateEstimation(query)
        .subscribe(data => {
          if (data) {
            sessionStorage.setItem('stateProfile', JSON.stringify(data));

            this.isLoading = false;
            this.showReport = true;
          } else {
            this.routeTo404();
          }
        });
    } else {
      this.stateNationalEstimationService.getNationalEstimation(query)
        .subscribe(data => {
          if (data) {
            sessionStorage.setItem('nationalProfile', JSON.stringify(data));

            this.isLoading = false;
            this.showReport = true;
          } else {
            this.routeTo404();
          }
        });
    }
  }

  private routeTo404(): void {
    this.isLoading = false;
    this.profileService.setNotFoundLink(window.location.href);
    this.router.navigateByUrl('/404');
  }

  public updateSurveyYear(year: number): void {
    this.surveyYearKey = year;
    this.showReport = false;
    this.isLoading = true;

    switch (this.entityType) {
      case EntityType.SCHOOL:
        this.getSchoolProfile();
        break;
      case EntityType.DISTRICT:
        this.getDistrictProfile();
        break;
      case EntityType.STATE:
        this.getSpotlight();
        break;
      default:
        this.getSpotlight();
        break;
    }
  }
}
