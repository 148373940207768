export interface CriteriaDTO {
    M_TOT_ENROL?: CriterionDTO;
    F_TOT_ENROL?: CriterionDTO;
    B_BLA_ENROL?: CriterionDTO;
    B_AME_ENROL?: CriterionDTO;
    B_ASI_ENROL?: CriterionDTO;
    B_HIS_ENROL?: CriterionDTO;
    B_HI_PAC_ENROL?: CriterionDTO;
    B_WHI_ENROL?: CriterionDTO;
    B_MORE_ENROL?: CriterionDTO;
    B_DIS_ENROL?: CriterionDTO;
    B_DIS5_ENROL?: CriterionDTO;
    B_LEP_ENROL?: CriterionDTO;
}

export interface CriterionDTO {
    lowerBound: number;
    upperBound: number;
}

export enum Bounds {
    LOWER = 0,
    UPPER = 100
}

export class CRITERIA_LABELS {
    static readonly M_TOT_ENROL: string = 'Male students';
    static readonly F_TOT_ENROL: string = 'Female students';
    static readonly B_BLA_ENROL: string = 'Black or African American';
    static readonly B_AME_ENROL: string = 'American Indian or Alaska Native';
    static readonly B_ASI_ENROL: string = 'Asian';
    static readonly B_HIS_ENROL: string = 'Hispanic or Latino or any race';
    static readonly B_HI_PAC_ENROL: string = 'Native Hawaiian or Other Pacific Islander';
    static readonly B_WHI_ENROL: string = 'White';
    static readonly B_MORE_ENROL: string = 'Two or more races';
    static readonly B_DIS_ENROL: string = 'IDEA';
    static readonly B_DIS5_ENROL: string = 'Section 504 only'
    static readonly B_LEP_ENROL: string = 'English learners';
}