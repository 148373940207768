<div class="container content">
  <page-title title="2011-12 State and National Tables"></page-title>
  <p>
    The 2011-12 tables are based on data collected from all of the nation’s school districts and schools—approximately 16,500 school districts and 96,500 schools. Documentation for the 2011-12 tables is available on the Data Notes page available here:
  </p>
  <p class="has-text-centered">
    <a href="assets/downloads/Data-Notes-CRDC-2011-12-2.9.pdf" rel="noopener noreferrer" target="_blank">Data Notes CRDC 2011-12 2.9.pdf</a>
  </p>


  <mat-accordion>

    <span class="has-text-weight-bold">Student Enrollment</span>
    <mat-expansion-panel>
      <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
        <mat-panel-title>
          All Enrollment
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="accordion-panel-body">
        <p class="has-text-centered is-size-6 has-text-weight-bold">Tables for Enrollment</p>
        <p>
          This set of Excel files contains data for all states on overall enrollment, enrollment of students served under IDEA, enrollment of students served under Section 504, and enrollment of students who are english learners (EL). For each category, there are three spreadsheets: total students, male students, and female students.
        </p>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/enrollment/SCH-0005-Overall-Enrollment.xlsx" rel="noopener noreferrer" target="_blank">Enrollment</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/enrollment/SCH-0101-Enrollment-of-Students-with-Disabilities-Served-under-IDEA.xlsx" rel="noopener noreferrer" target="_blank">IDEA enrollment</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/enrollment/SCH-0102-Enrollment-of-Students-with-Disabilities-Served-under-Section-504-only.xlsx" rel="noopener noreferrer" target="_blank">Section 504 enrollment</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
              <a href="assets/downloads/2011-2012/enrollment/SCH-0009-Limited-English-Proficient-Students-by-state.xlsx" rel="noopener noreferrer" target="_blank">EL enrollment</a>
          </div>
        </div>
      </div>
    </mat-expansion-panel>

    <br />
    <span class="has-text-weight-bold">Pathways to College and Career Readiness</span>
    <mat-expansion-panel>
      <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
        <mat-panel-title>
          Early Childhood and Prekindergarten Enrollment
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="accordion-panel-body">
        <p class="has-text-centered is-size-6 has-text-weight-bold">Early Childhood and Prekindergarten Enrollment</p>
        <p>
          This Excel file contains early childhood and prekindergarten student enrollment data for all states. The file contains three spreadsheets: total children, male children, and female children.
        </p>
        <div class="columns is-gapless is-multiline">
          <div class="column is-full has-text-centered">
            <a href="assets/downloads/2011-2012/Early Childhood and PreK Enrollment/Total-Enrollment-in-Early-Childhood-and-Prekindergarten.xlsx" rel="noopener noreferrer" target="_blank">Early childhood and prekindergarten enrollment</a>
          </div>
        </div>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
        <mat-panel-title>
          English Language Instruction Educational Programs
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="accordion-panel-body">
        <p class="has-text-centered is-size-6 has-text-weight-bold">English Language Instruction Program Enrollment for Nation and by State</p>
        <p>
          This Excel file contains data for English language learners enrolled in English language instruction educational programs for all states. The file contains three spreadsheets: total children, male children, and female children.
        </p>
        <div class="has-text-centered">
          <a href="assets/downloads/2011-2012/English Language Instuction Program/by state/US.xls" rel="noopener noreferrer" target="_blank">National total</a>
        </div>
        <br />
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/English Language Instuction Program/by state/AL.xls" rel="noopener noreferrer" target="_blank">Alabama</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/English Language Instuction Program/by state/AK.xls" rel="noopener noreferrer" target="_blank">Alaska</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/English Language Instuction Program/by state/AZ.xls" rel="noopener noreferrer" target="_blank">Arizona</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/English Language Instuction Program/by state/AR.xls" rel="noopener noreferrer" target="_blank">Arkansas</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/English Language Instuction Program/by state/CA.xls" rel="noopener noreferrer" target="_blank">California</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/English Language Instuction Program/by state/CO.xls" rel="noopener noreferrer" target="_blank">Colorado</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/English Language Instuction Program/by state/CT.xls" rel="noopener noreferrer" target="_blank">Connecticut</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/English Language Instuction Program/by state/DE.xls" rel="noopener noreferrer" target="_blank">Delaware</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/English Language Instuction Program/by state/DC.xls" rel="noopener noreferrer" target="_blank">District of Columbia</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/English Language Instuction Program/by state/FL.xls" rel="noopener noreferrer" target="_blank">Florida</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/English Language Instuction Program/by state/GA.xls" rel="noopener noreferrer" target="_blank">Georgia</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/English Language Instuction Program/by state/HI.xls" rel="noopener noreferrer" target="_blank">Hawaii</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/English Language Instuction Program/by state/ID.xls" rel="noopener noreferrer" target="_blank">Idaho</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/English Language Instuction Program/by state/IL.xls" rel="noopener noreferrer" target="_blank">Illinois</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/English Language Instuction Program/by state/IN.xls" rel="noopener noreferrer" target="_blank">Indiana</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/English Language Instuction Program/by state/IA.xls" rel="noopener noreferrer" target="_blank">Iowa</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/English Language Instuction Program/by state/KS.xls" rel="noopener noreferrer" target="_blank">Kansas</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/English Language Instuction Program/by state/KY.xls" rel="noopener noreferrer" target="_blank">Kentucky</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/English Language Instuction Program/by state/LA.xls" rel="noopener noreferrer" target="_blank">Louisiana</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/English Language Instuction Program/by state/ME.xls" rel="noopener noreferrer" target="_blank">Maine</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/English Language Instuction Program/by state/MD.xls" rel="noopener noreferrer" target="_blank">Maryland</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/English Language Instuction Program/by state/MA.xls" rel="noopener noreferrer" target="_blank">Massachusetts</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/English Language Instuction Program/by state/MI.xls" rel="noopener noreferrer" target="_blank">Michigan</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/English Language Instuction Program/by state/MN.xls" rel="noopener noreferrer" target="_blank">Minnesota</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/English Language Instuction Program/by state/MS.xls" rel="noopener noreferrer" target="_blank">Mississippi</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/English Language Instuction Program/by state/MO.xls" rel="noopener noreferrer" target="_blank">Missouri</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/English Language Instuction Program/by state/MT.xls" rel="noopener noreferrer" target="_blank">Montana</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/English Language Instuction Program/by state/NE.xls" rel="noopener noreferrer" target="_blank">Nebraska</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/English Language Instuction Program/by state/NV.xls" rel="noopener noreferrer" target="_blank">Nevada</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/English Language Instuction Program/by state/NH.xls" rel="noopener noreferrer" target="_blank">New Hampshire</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/English Language Instuction Program/by state/NJ.xls" rel="noopener noreferrer" target="_blank">New Jersey</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/English Language Instuction Program/by state/NM.xls" rel="noopener noreferrer" target="_blank">New Mexico</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/English Language Instuction Program/by state/NY.xls" rel="noopener noreferrer" target="_blank">New York</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/English Language Instuction Program/by state/NC.xls" rel="noopener noreferrer" target="_blank">North Carolina</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/English Language Instuction Program/by state/ND.xls" rel="noopener noreferrer" target="_blank">North Dakota</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/English Language Instuction Program/by state/OH.xls" rel="noopener noreferrer" target="_blank">Ohio</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/English Language Instuction Program/by state/OK.xls" rel="noopener noreferrer" target="_blank">Oklahoma</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/English Language Instuction Program/by state/OR.xls" rel="noopener noreferrer" target="_blank">Oregon</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/English Language Instuction Program/by state/PA.xls" rel="noopener noreferrer" target="_blank">Pennsylvania</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/English Language Instuction Program/by state/RI.xls" rel="noopener noreferrer" target="_blank">Rhode Island</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/English Language Instuction Program/by state/SC.xls" rel="noopener noreferrer" target="_blank">South Carolina</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/English Language Instuction Program/by state/SD.xls" rel="noopener noreferrer" target="_blank">South Dakota</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/English Language Instuction Program/by state/TN.xls" rel="noopener noreferrer" target="_blank">Tennessee</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/English Language Instuction Program/by state/TX.xls" rel="noopener noreferrer" target="_blank">Texas</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/English Language Instuction Program/by state/UT.xls" rel="noopener noreferrer" target="_blank">Utah</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/English Language Instuction Program/by state/VT.xls" rel="noopener noreferrer" target="_blank">Vermont</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/English Language Instuction Program/by state/VA.xls" rel="noopener noreferrer" target="_blank">Virginia</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/English Language Instuction Program/by state/WA.xls" rel="noopener noreferrer" target="_blank">Washington</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/English Language Instuction Program/by state/WV.xls" rel="noopener noreferrer" target="_blank">West Virginia</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/English Language Instuction Program/by state/WI.xls" rel="noopener noreferrer" target="_blank">Wisconsin</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/English Language Instuction Program/by state/WY.xls" rel="noopener noreferrer" target="_blank">Wyoming</a>
          </div>
        </div>

        <br /><br />
        <p class="has-text-centered is-size-6 has-text-weight-bold">English Language Instruction Enrollment</p>
        <p>
          This Excel file contains data for English language learners enrolled in English language instruction educational programs for all states. The file contains three spreadsheets: total students, male students, and female students.
        </p>
        <div class="columns is-gapless is-multiline">
          <div class="column is-full has-text-centered">
            <a href="assets/downloads/2011-2012/English Language Instuction Program/All states/English-Language-Instruction-Programs-all-states.xlsx" rel="noopener noreferrer" target="_blank">English language instruction educational program enrollment</a>
          </div>
        </div>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
        <mat-panel-title>
          Gifted and Talented
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="accordion-panel-body">
        <p class="has-text-centered is-size-6 has-text-weight-bold">Gifted and Talented Enrollment</p>
        <p>
          This Excel file contains data on students enrolled in gifted and talented programs for all states. The file contains three spreadsheets: total students, male students, and female students.
        </p>
        <div class="columns is-gapless is-multiline">
          <div class="column is-full has-text-centered">
            <a href="assets/downloads/2011-2012/Gifted and Talented/Gifted-Talented-Programs.xlsx" rel="noopener noreferrer" target="_blank">Gifted and talented enrollment</a>
          </div>
        </div>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
        <mat-panel-title>
          Retention
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="accordion-panel-body">
        <p class="has-text-centered is-size-6 has-text-weight-bold">Retention for Nation and by State</p>
        <p>
          This set of Excel files contains student retention data for all grade levels, presented for the nation and by state. For the nation and each state, there is one spreadsheet for total students.
        </p>
        <div class="has-text-centered">
          <a href="assets/downloads/2011-2012/Retention/by state/US.xlsx" rel="noopener noreferrer" target="_blank">National total</a>
        </div>
        <br />
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Retention/by state/AL.xls" rel="noopener noreferrer" target="_blank">Alabama</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Retention/by state/AK.xls" rel="noopener noreferrer" target="_blank">Alaska</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Retention/by state/AZ.xls" rel="noopener noreferrer" target="_blank">Arizona</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Retention/by state/AR.xls" rel="noopener noreferrer" target="_blank">Arkansas</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Retention/by state/CA.xls" rel="noopener noreferrer" target="_blank">California</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Retention/by state/CO.xls" rel="noopener noreferrer" target="_blank">Colorado</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Retention/by state/CT.xls" rel="noopener noreferrer" target="_blank">Connecticut</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Retention/by state/DE.xls" rel="noopener noreferrer" target="_blank">Delaware</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Retention/by state/DC.xls" rel="noopener noreferrer" target="_blank">District of Columbia</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Retention/by state/FL.xls" rel="noopener noreferrer" target="_blank">Florida</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Retention/by state/GA.xls" rel="noopener noreferrer" target="_blank">Georgia</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Retention/by state/HI.xls" rel="noopener noreferrer" target="_blank">Hawaii</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Retention/by state/ID.xls" rel="noopener noreferrer" target="_blank">Idaho</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Retention/by state/IL.xls" rel="noopener noreferrer" target="_blank">Illinois</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Retention/by state/IN.xls" rel="noopener noreferrer" target="_blank">Indiana</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Retention/by state/IA.xls" rel="noopener noreferrer" target="_blank">Iowa</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Retention/by state/KS.xls" rel="noopener noreferrer" target="_blank">Kansas</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Retention/by state/KY.xls" rel="noopener noreferrer" target="_blank">Kentucky</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Retention/by state/LA.xls" rel="noopener noreferrer" target="_blank">Louisiana</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Retention/by state/ME.xls" rel="noopener noreferrer" target="_blank">Maine</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Retention/by state/MD.xls" rel="noopener noreferrer" target="_blank">Maryland</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Retention/by state/MA.xls" rel="noopener noreferrer" target="_blank">Massachusetts</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Retention/by state/MI.xls" rel="noopener noreferrer" target="_blank">Michigan</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Retention/by state/MN.xls" rel="noopener noreferrer" target="_blank">Minnesota</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Retention/by state/MS.xls" rel="noopener noreferrer" target="_blank">Mississippi</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Retention/by state/MO.xls" rel="noopener noreferrer" target="_blank">Missouri</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Retention/by state/MT.xls" rel="noopener noreferrer" target="_blank">Montana</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Retention/by state/NE.xls" rel="noopener noreferrer" target="_blank">Nebraska</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Retention/by state/NV.xls" rel="noopener noreferrer" target="_blank">Nevada</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Retention/by state/NH.xls" rel="noopener noreferrer" target="_blank">New Hampshire</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Retention/by state/NJ.xls" rel="noopener noreferrer" target="_blank">New Jersey</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Retention/by state/NM.xls" rel="noopener noreferrer" target="_blank">New Mexico</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Retention/by state/NY.xls" rel="noopener noreferrer" target="_blank">New York</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Retention/by state/NC.xls" rel="noopener noreferrer" target="_blank">North Carolina</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Retention/by state/ND.xls" rel="noopener noreferrer" target="_blank">North Dakota</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Retention/by state/OH.xls" rel="noopener noreferrer" target="_blank">Ohio</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Retention/by state/OK.xls" rel="noopener noreferrer" target="_blank">Oklahoma</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Retention/by state/OR.xls" rel="noopener noreferrer" target="_blank">Oregon</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Retention/by state/PA.xls" rel="noopener noreferrer" target="_blank">Pennsylvania</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Retention/by state/RI.xls" rel="noopener noreferrer" target="_blank">Rhode Island</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Retention/by state/SC.xls" rel="noopener noreferrer" target="_blank">South Carolina</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Retention/by state/SD.xls" rel="noopener noreferrer" target="_blank">South Dakota</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Retention/by state/TN.xls" rel="noopener noreferrer" target="_blank">Tennessee</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Retention/by state/TX.xls" rel="noopener noreferrer" target="_blank">Texas</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Retention/by state/UT.xls" rel="noopener noreferrer" target="_blank">Utah</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Retention/by state/VT.xls" rel="noopener noreferrer" target="_blank">Vermont</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Retention/by state/VA.xls" rel="noopener noreferrer" target="_blank">Virginia</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Retention/by state/WA.xls" rel="noopener noreferrer" target="_blank">Washington</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Retention/by state/WV.xls" rel="noopener noreferrer" target="_blank">West Virginia</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Retention/by state/WI.xls" rel="noopener noreferrer" target="_blank">Wisconsin</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Retention/by state/WY.xls" rel="noopener noreferrer" target="_blank">Wyoming</a>
          </div>
        </div>

        <br /><br />
        <p class="has-text-centered is-size-6 has-text-weight-bold">Retention by Grade</p>
        <p>
          This set of Excel files contains student retention data for all states, presented by grade. For each grade, there are three spreadsheets: total students, male students, and female students.
        </p>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Retention/by grade/Retention-in-Kindergarten.xlsx" rel="noopener noreferrer" target="_blank">Kindergarten</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Retention/by grade/Retention-in-Grade-1.xlsx" rel="noopener noreferrer" target="_blank">Grade 1</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Retention/by grade/Retention-in-Grade-2.xlsx" rel="noopener noreferrer" target="_blank">Grade 2</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Retention/by grade/Retention-in-Grade-3.xlsx" rel="noopener noreferrer" target="_blank">Grade 3</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Retention/by grade/Retention-in-Grade-4.xlsx" rel="noopener noreferrer" target="_blank">Grade 4</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Retention/by grade/Retention-in-Grade-5.xlsx" rel="noopener noreferrer" target="_blank">Grade 5</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Retention/by grade/Retention-in-Grade-6.xlsx" rel="noopener noreferrer" target="_blank">Grade 6</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Retention/by grade/Retention-in-Grade-7.xlsx" rel="noopener noreferrer" target="_blank">Grade 7</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Retention/by grade/Retention-in-Grade-8.xlsx" rel="noopener noreferrer" target="_blank">Grade 8</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Retention/by grade/Retention-in-Grade-9.xlsx" rel="noopener noreferrer" target="_blank">Grade 9</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Retention/by grade/Retention-in-Grade-10.xlsx" rel="noopener noreferrer" target="_blank">Grade 10</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Retention/by grade/Retention-in-Grade-11.xlsx" rel="noopener noreferrer" target="_blank">Grade 11</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Retention/by grade/Retention-in-Grade-12.xlsx" rel="noopener noreferrer" target="_blank">Grade 12</a>
          </div>
        </div>
      </div>
    </mat-expansion-panel>

    <br />
    <span class="has-text-weight-bold">College and Career Readiness</span>
    <mat-expansion-panel>
      <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
        <mat-panel-title>
          Mathematics and Science
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="accordion-panel-body">
        <p class="has-text-centered is-size-6 has-text-weight-bold">Mathematics and Science Enrollment for Nation and by State</p>
        <p>
          This set of Excel files contains student enrollment data for all mathematics and science courses, presented for the nation and by state. For the nation and each state, there is one spreadsheet for total students.
        </p>
        <div class="columns is-gapless is-multiline">
          <div class="column is-full has-text-centered">
            <a href="assets/downloads/2011-2012/Mathematics and Science/Math and Science Enrollment by State/US.xls" rel="noopener noreferrer" target="_blank">National total</a>
          </div>
        </div>
        <br />
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Mathematics and Science/Math and Science Enrollment by State/AL.xls" rel="noopener noreferrer" target="_blank">Alabama</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Mathematics and Science/Math and Science Enrollment by State/AK.xls" rel="noopener noreferrer" target="_blank">Alaska</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Mathematics and Science/Math and Science Enrollment by State/AZ.xls" rel="noopener noreferrer" target="_blank">Arizona</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Mathematics and Science/Math and Science Enrollment by State/AR.xls" rel="noopener noreferrer" target="_blank">Arkansas</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Mathematics and Science/Math and Science Enrollment by State/CA.xls" rel="noopener noreferrer" target="_blank">California</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Mathematics and Science/Math and Science Enrollment by State/CO.xls" rel="noopener noreferrer" target="_blank">Colorado</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Mathematics and Science/Math and Science Enrollment by State/CT.xls" rel="noopener noreferrer" target="_blank">Connecticut</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Mathematics and Science/Math and Science Enrollment by State/DE.xls" rel="noopener noreferrer" target="_blank">Delaware</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Mathematics and Science/Math and Science Enrollment by State/DC.xls" rel="noopener noreferrer" target="_blank">District of Columbia</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Mathematics and Science/Math and Science Enrollment by State/FL.xls" rel="noopener noreferrer" target="_blank">Florida</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Mathematics and Science/Math and Science Enrollment by State/GA.xls" rel="noopener noreferrer" target="_blank">Georgia</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Mathematics and Science/Math and Science Enrollment by State/HI.xls" rel="noopener noreferrer" target="_blank">Hawaii</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Mathematics and Science/Math and Science Enrollment by State/ID.xls" rel="noopener noreferrer" target="_blank">Idaho</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Mathematics and Science/Math and Science Enrollment by State/IL.xls" rel="noopener noreferrer" target="_blank">Illinois</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Mathematics and Science/Math and Science Enrollment by State/IN.xls" rel="noopener noreferrer" target="_blank">Indiana</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Mathematics and Science/Math and Science Enrollment by State/IA.xls" rel="noopener noreferrer" target="_blank">Iowa</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Mathematics and Science/Math and Science Enrollment by State/KS.xls" rel="noopener noreferrer" target="_blank">Kansas</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Mathematics and Science/Math and Science Enrollment by State/KY.xls" rel="noopener noreferrer" target="_blank">Kentucky</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Mathematics and Science/Math and Science Enrollment by State/LA.xls" rel="noopener noreferrer" target="_blank">Louisiana</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Mathematics and Science/Math and Science Enrollment by State/ME.xls" rel="noopener noreferrer" target="_blank">Maine</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Mathematics and Science/Math and Science Enrollment by State/MD.xls" rel="noopener noreferrer" target="_blank">Maryland</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Mathematics and Science/Math and Science Enrollment by State/MA.xls" rel="noopener noreferrer" target="_blank">Massachusetts</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Mathematics and Science/Math and Science Enrollment by State/MI.xls" rel="noopener noreferrer" target="_blank">Michigan</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Mathematics and Science/Math and Science Enrollment by State/MN.xls" rel="noopener noreferrer" target="_blank">Minnesota</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Mathematics and Science/Math and Science Enrollment by State/MS.xls" rel="noopener noreferrer" target="_blank">Mississippi</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Mathematics and Science/Math and Science Enrollment by State/MO.xls" rel="noopener noreferrer" target="_blank">Missouri</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Mathematics and Science/Math and Science Enrollment by State/MT.xls" rel="noopener noreferrer" target="_blank">Montana</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Mathematics and Science/Math and Science Enrollment by State/NE.xls" rel="noopener noreferrer" target="_blank">Nebraska</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Mathematics and Science/Math and Science Enrollment by State/NV.xls" rel="noopener noreferrer" target="_blank">Nevada</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Mathematics and Science/Math and Science Enrollment by State/NH.xls" rel="noopener noreferrer" target="_blank">New Hampshire</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Mathematics and Science/Math and Science Enrollment by State/NJ.xls" rel="noopener noreferrer" target="_blank">New Jersey</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Mathematics and Science/Math and Science Enrollment by State/NM.xls" rel="noopener noreferrer" target="_blank">New Mexico</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Mathematics and Science/Math and Science Enrollment by State/NY.xls" rel="noopener noreferrer" target="_blank">New York</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Mathematics and Science/Math and Science Enrollment by State/NC.xls" rel="noopener noreferrer" target="_blank">North Carolina</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Mathematics and Science/Math and Science Enrollment by State/ND.xls" rel="noopener noreferrer" target="_blank">North Dakota</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Mathematics and Science/Math and Science Enrollment by State/OH.xls" rel="noopener noreferrer" target="_blank">Ohio</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Mathematics and Science/Math and Science Enrollment by State/OK.xls" rel="noopener noreferrer" target="_blank">Oklahoma</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Mathematics and Science/Math and Science Enrollment by State/OR.xls" rel="noopener noreferrer" target="_blank">Oregon</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Mathematics and Science/Math and Science Enrollment by State/PA.xls" rel="noopener noreferrer" target="_blank">Pennsylvania</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Mathematics and Science/Math and Science Enrollment by State/RI.xls" rel="noopener noreferrer" target="_blank">Rhode Island</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Mathematics and Science/Math and Science Enrollment by State/SC.xls" rel="noopener noreferrer" target="_blank">South Carolina</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Mathematics and Science/Math and Science Enrollment by State/SD.xls" rel="noopener noreferrer" target="_blank">South Dakota</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Mathematics and Science/Math and Science Enrollment by State/TN.xls" rel="noopener noreferrer" target="_blank">Tennessee</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Mathematics and Science/Math and Science Enrollment by State/TX.xls" rel="noopener noreferrer" target="_blank">Texas</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Mathematics and Science/Math and Science Enrollment by State/UT.xls" rel="noopener noreferrer" target="_blank">Utah</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Mathematics and Science/Math and Science Enrollment by State/VT.xls" rel="noopener noreferrer" target="_blank">Vermont</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Mathematics and Science/Math and Science Enrollment by State/VA.xls" rel="noopener noreferrer" target="_blank">Virginia</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Mathematics and Science/Math and Science Enrollment by State/WA.xls" rel="noopener noreferrer" target="_blank">Washington</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Mathematics and Science/Math and Science Enrollment by State/WV.xls" rel="noopener noreferrer" target="_blank">West Virginia</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Mathematics and Science/Math and Science Enrollment by State/WI.xls" rel="noopener noreferrer" target="_blank">Wisconsin</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Mathematics and Science/Math and Science Enrollment by State/WY.xls" rel="noopener noreferrer" target="_blank">Wyoming</a>
          </div>
        </div>

        <br /><br />
        <p class="has-text-centered is-size-6 has-text-weight-bold">Schools Offering Mathematics and Science Classes</p>
        <p>
          This Excel file contains data for schools offering classes in mathematics and science for all states. The file contains one spreadsheet for total schools.
        </p>
        <div class="has-text-centered">
          <a href="assets/downloads/2011-2012/Mathematics and Science/Math and Science Classes/Classes-in-Mathematics-and-Science.xlsx" rel="noopener noreferrer" target="_blank">Schools offering mathematics and science classes</a>
        </div>

        <br /><br />
        <p class="has-text-centered is-size-6 has-text-weight-bold">Mathematics Tables by Course</p>
        <p>
          This set of Excel files contains student enrollment data for all states, presented by mathematics course. For the Algebra I course, there are nine spreadsheets: students in grade 7 or 8, male students in grade 7 or 8, female students in grade 7 or 8, students in grade 9 or 10, male students in grade 9 or 10, female students in grade 9 or 10, students in grade 11 or 12, male students in grade 11 or 12, and female students in grade 11 or 12. For each of the other mathematics courses, there are three spreadsheets: total students, male students, and female students.
        </p>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Mathematics and Science/Math by Course/Enrollment-in-Algebra-I.xlsx" rel="noopener noreferrer" target="_blank">Algebra I (grades 7-8, 9-10, 11-12)</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Mathematics and Science/Math by Course/Enrollment-in-Geometry.xlsx" rel="noopener noreferrer" target="_blank">Geometry</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Mathematics and Science/Math by Course/Enrollment-in-Algebra-II.xlsx" rel="noopener noreferrer" target="_blank">Algebra II</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Mathematics and Science/Math by Course/Enrollment-in-Advanced-Mathematics.xlsx" rel="noopener noreferrer" target="_blank">Advanced mathematics</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Mathematics and Science/Math by Course/Enrollment-in-Calculus.xlsx" rel="noopener noreferrer" target="_blank">Calculus</a>
          </div>
        </div>

        <br /><br />
        <p class="has-text-centered is-size-6 has-text-weight-bold">Science Tables by Course</p>
        <p>
          This set of Excel files contains student enrollment data for all states, presented by science course. For each science course, there are three spreadsheets: total students, male students, and female students.
        </p>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-third has-text-centered">
            <a href="assets/downloads/2011-2012/Mathematics and Science/Science by Course/Enrollment-in-Biology.xlsx" rel="noopener noreferrer" target="_blank">Biology</a>
          </div>
          <div class="column is-one-third has-text-centered">
            <a href="assets/downloads/2011-2012/Mathematics and Science/Science by Course/Enrollment-in-Chemistry.xlsx" rel="noopener noreferrer" target="_blank">Chemistry</a>
          </div>
          <div class="column is-one-third has-text-centered">
            <a href="assets/downloads/2011-2012/Mathematics and Science/Science by Course/Enrollment-in-Physics.xlsx" rel="noopener noreferrer" target="_blank">Physics</a>
          </div>
        </div>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
        <mat-panel-title>
          Advanced Placement and International Baccalaureate Diploma Programme
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="accordion-panel-body">
        <p class="has-text-centered is-size-6 has-text-weight-bold">Advanced Placement and International Baccalaureate for Nation and by State</p>
        <p>
          This set of Excel files contains student enrollment data for Advanced Placement (AP) courses and the International Baccalaureate (IB) Diploma Programme, presented for the nation and by state. For the nation and each state, there are two spreadsheets: total students in AP courses and total students in the IB Diploma Programme.
        </p>
        <div class="columns is-gapless is-multiline">
          <div class="column is-full has-text-centered">
            <a href="assets/downloads/2011-2012/Advanced Placement and International Baccalaureate/by state/US-AP-and-IB.xlsx" rel="noopener noreferrer" target="_blank">National total</a>
          </div>
        </div>
        <br />
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Advanced Placement and International Baccalaureate/by state/AL-AP-and-IB.xlsx" rel="noopener noreferrer" target="_blank">Alabama</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Advanced Placement and International Baccalaureate/by state/AK-AP-and-IB.xlsx" rel="noopener noreferrer" target="_blank">Alaska</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Advanced Placement and International Baccalaureate/by state/AZ-AP-and-IB.xlsx" rel="noopener noreferrer" target="_blank">Arizona</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Advanced Placement and International Baccalaureate/by state/AR-AP-and-IB.xlsx" rel="noopener noreferrer" target="_blank">Arkansas</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Advanced Placement and International Baccalaureate/by state/CA-AP-and-IB.xlsx" rel="noopener noreferrer" target="_blank">California</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Advanced Placement and International Baccalaureate/by state/CO-AP-and-IB.xlsx" rel="noopener noreferrer" target="_blank">Colorado</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Advanced Placement and International Baccalaureate/by state/CT-AP-and-IB.xlsx" rel="noopener noreferrer" target="_blank">Connecticut</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Advanced Placement and International Baccalaureate/by state/DE-AP-and-IB.xlsx" rel="noopener noreferrer" target="_blank">Delaware</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Advanced Placement and International Baccalaureate/by state/DC-AP-and-IB.xlsx" rel="noopener noreferrer" target="_blank">District of Columbia</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Advanced Placement and International Baccalaureate/by state/FL-AP-and-IB.xlsx" rel="noopener noreferrer" target="_blank">Florida</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Advanced Placement and International Baccalaureate/by state/GA-AP-and-IB.xlsx" rel="noopener noreferrer" target="_blank">Georgia</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Advanced Placement and International Baccalaureate/by state/HI-AP-and-IB.xlsx" rel="noopener noreferrer" target="_blank">Hawaii</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Advanced Placement and International Baccalaureate/by state/ID-AP-and-IB.xlsx" rel="noopener noreferrer" target="_blank">Idaho</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Advanced Placement and International Baccalaureate/by state/IL-AP-and-IB.xlsx" rel="noopener noreferrer" target="_blank">Illinois</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Advanced Placement and International Baccalaureate/by state/IN-AP-and-IB.xlsx" rel="noopener noreferrer" target="_blank">Indiana</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Advanced Placement and International Baccalaureate/by state/IA-AP-and-IB.xlsx" rel="noopener noreferrer" target="_blank">Iowa</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Advanced Placement and International Baccalaureate/by state/KS-AP-and-IB.xlsx" rel="noopener noreferrer" target="_blank">Kansas</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Advanced Placement and International Baccalaureate/by state/KY-AP-and-IB.xlsx" rel="noopener noreferrer" target="_blank">Kentucky</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Advanced Placement and International Baccalaureate/by state/LA-AP-and-IB.xlsx" rel="noopener noreferrer" target="_blank">Louisiana</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Advanced Placement and International Baccalaureate/by state/ME-AP-and-IB.xlsx" rel="noopener noreferrer" target="_blank">Maine</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Advanced Placement and International Baccalaureate/by state/MD-AP-and-IB.xlsx" rel="noopener noreferrer" target="_blank">Maryland</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Advanced Placement and International Baccalaureate/by state/MA-AP-and-IB.xlsx" rel="noopener noreferrer" target="_blank">Massachusetts</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Advanced Placement and International Baccalaureate/by state/MI-AP-and-IB.xlsx" rel="noopener noreferrer" target="_blank">Michigan</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Advanced Placement and International Baccalaureate/by state/MN-AP-and-IB.xlsx" rel="noopener noreferrer" target="_blank">Minnesota</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Advanced Placement and International Baccalaureate/by state/MS-AP-and-IB.xlsx" rel="noopener noreferrer" target="_blank">Mississippi</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Advanced Placement and International Baccalaureate/by state/MO-AP-and-IB.xlsx" rel="noopener noreferrer" target="_blank">Missouri</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Advanced Placement and International Baccalaureate/by state/MT-AP-and-IB.xlsx" rel="noopener noreferrer" target="_blank">Montana</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Advanced Placement and International Baccalaureate/by state/NE-AP-and-IB.xlsx" rel="noopener noreferrer" target="_blank">Nebraska</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Advanced Placement and International Baccalaureate/by state/NV-AP-and-IB.xlsx" rel="noopener noreferrer" target="_blank">Nevada</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Advanced Placement and International Baccalaureate/by state/NH-AP-and-IB.xlsx" rel="noopener noreferrer" target="_blank">New Hampshire</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Advanced Placement and International Baccalaureate/by state/NJ-AP-and-IB.xlsx" rel="noopener noreferrer" target="_blank">New Jersey</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Advanced Placement and International Baccalaureate/by state/NM-AP-and-IB.xlsx" rel="noopener noreferrer" target="_blank">New Mexico</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Advanced Placement and International Baccalaureate/by state/NY-AP-and-IB.xlsx" rel="noopener noreferrer" target="_blank">New York</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Advanced Placement and International Baccalaureate/by state/NC-AP-and-IB.xlsx" rel="noopener noreferrer" target="_blank">North Carolina</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Advanced Placement and International Baccalaureate/by state/ND-AP-and-IB.xlsx" rel="noopener noreferrer" target="_blank">North Dakota</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Advanced Placement and International Baccalaureate/by state/OH-AP-and-IB.xlsx" rel="noopener noreferrer" target="_blank">Ohio</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Advanced Placement and International Baccalaureate/by state/OK-AP-and-IB.xlsx" rel="noopener noreferrer" target="_blank">Oklahoma</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Advanced Placement and International Baccalaureate/by state/OR-AP-and-IB.xlsx" rel="noopener noreferrer" target="_blank">Oregon</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Advanced Placement and International Baccalaureate/by state/PA-AP-and-IB.xlsx" rel="noopener noreferrer" target="_blank">Pennsylvania</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Advanced Placement and International Baccalaureate/by state/RI-AP-and-IB.xlsx" rel="noopener noreferrer" target="_blank">Rhode Island</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Advanced Placement and International Baccalaureate/by state/SC-AP-and-IB.xlsx" rel="noopener noreferrer" target="_blank">South Carolina</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Advanced Placement and International Baccalaureate/by state/SD-AP-and-IB.xlsx" rel="noopener noreferrer" target="_blank">South Dakota</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Advanced Placement and International Baccalaureate/by state/TN-AP-and-IB.xlsx" rel="noopener noreferrer" target="_blank">Tennessee</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Advanced Placement and International Baccalaureate/by state/TX-AP-and-IB.xlsx" rel="noopener noreferrer" target="_blank">Texas</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Advanced Placement and International Baccalaureate/by state/UT-AP-and-IB.xlsx" rel="noopener noreferrer" target="_blank">Utah</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Advanced Placement and International Baccalaureate/by state/VT-AP-and-IB.xlsx" rel="noopener noreferrer" target="_blank">Vermont</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Advanced Placement and International Baccalaureate/by state/VA-AP-and-IB.xlsx" rel="noopener noreferrer" target="_blank">Virginia</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Advanced Placement and International Baccalaureate/by state/WA-AP-and-IB.xlsx" rel="noopener noreferrer" target="_blank">Washington</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Advanced Placement and International Baccalaureate/by state/WV-AP-and-IB.xlsx" rel="noopener noreferrer" target="_blank">West Virginia</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Advanced Placement and International Baccalaureate/by state/WI-AP-and-IB.xlsx" rel="noopener noreferrer" target="_blank">Wisconsin</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Advanced Placement and International Baccalaureate/by state/WY-AP-and-IB.xlsx" rel="noopener noreferrer" target="_blank">Wyoming</a>
          </div>
        </div>

        <br /><br />
        <p class="has-text-centered is-size-6 has-text-weight-bold">Advanced Placement and International Baccalaureate Enrollment by Program Type</p>
        <p>
          This set of Excel files contains student enrollment data for all states, presented by program type. For each program type, there are three spreadsheets: total students, male students, and female students.
        </p>
        <div class="columns is-gapless is-multiline">
          <div class="column is-half has-text-centered">
            <a href="assets/downloads/2011-2012/Advanced Placement and International Baccalaureate/by program type/Advanced-Placement-Enrollment.xlsx" rel="noopener noreferrer" target="_blank">Advanced Placement</a>
          </div>
          <div class="column is-half has-text-centered">
            <a href="assets/downloads/2011-2012/Advanced Placement and International Baccalaureate/by program type/Enrollment-in-the-IB-Program.xlsx" rel="noopener noreferrer" target="_blank">International Baccalaureate</a>
          </div>
        </div>

        <br /><br />
        <p class="has-text-centered is-size-6 has-text-weight-bold">Advanced Placement Enrollment, by Subject</p>
        <p>
          This set of Excel files contains student enrollment data for all states, presented by Advanced Placement (AP) subject. For each AP subject, there are three spreadsheets: total students, male students, and female students.
        </p>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Advanced Placement and International Baccalaureate/by subject/Advanced-Placement-Mathematics-Enrollment.xlsx" rel="noopener noreferrer" target="_blank">AP mathematics</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Advanced Placement and International Baccalaureate/by subject/Advanced-Placement-Science-Enrollment.xlsx" rel="noopener noreferrer" target="_blank">AP science</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Advanced Placement and International Baccalaureate/by subject/Advanced-Placement-Foreign-Language-Enrollment.xlsx" rel="noopener noreferrer" target="_blank">AP foreign language</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Advanced Placement and International Baccalaureate/by subject/Advanced-Placement-Other-Subjects-Enrollment.xlsx" rel="noopener noreferrer" target="_blank">AP other subjects</a>
          </div>
        </div>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
        <mat-panel-title>
          SAT/ACT
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="accordion-panel-body">
        <p class="has-text-centered is-size-6 has-text-weight-bold">SAT/ACT Participation</p>
        <p>
          This Excel file contains SAT/ACT student participation data for all states. The file contains three spreadsheets: total students, male students, and female students.
        </p>
        <div class="columns is-gapless is-multiline">
          <div class="column is-full has-text-centered">
            <a href="assets/downloads/2011-2012/SAT or ACT/SAT-or-ACT-Participation-by-state.xlsx" rel="noopener noreferrer" target="_blank">SAT/ACT participation</a>
          </div>
        </div>
      </div>
    </mat-expansion-panel>

    <br />
    <span class="has-text-weight-bold">School Staff</span>
    <mat-expansion-panel>
      <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
        <mat-panel-title>
          Classroom Teachers
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="accordion-panel-body">
        <p class="has-text-centered is-size-6 has-text-weight-bold">Classroom Teachers</p>
        <p>
          This Excel file contains data on classroom teachers, including full-time equivalency counts, certification, and years of experience for all states. The file contains one spreadsheet for total classroom teachers.
        </p>
        <div class="columns is-gapless is-multiline">
          <div class="column is-full has-text-centered">
            <a href="assets/downloads/2011-2012/School Staff/Teacher-Certification-and-Years-of-Experience.xlsx" rel="noopener noreferrer" target="_blank">Classroom teachers</a>
          </div>
        </div>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
        <mat-panel-title>
          School Counselors
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="accordion-panel-body">
        <p class="has-text-centered is-size-6 has-text-weight-bold">School Counselors</p>
        <p>
          This Excel file contains data on high school counselors for all states. The file contains one spreadsheet for total high school counselors.
        </p>
        <div class="columns is-gapless is-multiline">
          <div class="column is-full has-text-centered">
            <a href="assets/downloads/2011-2012/School Staff/School-Counselors.xlsx" rel="noopener noreferrer" target="_blank">School counselors</a>
          </div>
        </div>
      </div>
    </mat-expansion-panel>

    <br />
    <span class="has-text-weight-bold">Discipline, Harassment or Bullying, and Restraint and Seclusion</span>
    <mat-expansion-panel>
      <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
        <mat-panel-title>
          Discipline
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="accordion-panel-body">
        <p class="has-text-centered is-size-6 has-text-weight-bold">Discipline for Nation and by State</p>
        <p>
          This set of Excel files contains data for all disciplinary actions, presented for the nation and by state. For the nation and each state, there are three spreadsheets: students with and without disabilities, students with disabilities, and students without disabilities.
        </p>
        <div class="columns is-gapless is-multiline">
          <div class="column is-full has-text-centered">
            <a href="assets/downloads/2011-2012/states/National-Totals.xls" rel="noopener noreferrer" target="_blank">National total</a>
          </div>
        </div>
        <br />
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/states/Alabama.xlsx" rel="noopener noreferrer" target="_blank">Alabama</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/states/Alaska.xlsx" rel="noopener noreferrer" target="_blank">Alaska</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/states/Arizona.xlsx" rel="noopener noreferrer" target="_blank">Arizona</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/states/Arkansas.xlsx" rel="noopener noreferrer" target="_blank">Arkansas</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/states/California.xlsx" rel="noopener noreferrer" target="_blank">California</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/states/Colorado.xlsx" rel="noopener noreferrer" target="_blank">Colorado</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/states/Connecticut.xlsx" rel="noopener noreferrer" target="_blank">Connecticut</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/states/Delaware.xlsx" rel="noopener noreferrer" target="_blank">Delaware</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/states/District of Columbia.xlsx" rel="noopener noreferrer" target="_blank">District of Columbia</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/states/Florida.xlsx" rel="noopener noreferrer" target="_blank">Florida</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/states/Georgia.xlsx" rel="noopener noreferrer" target="_blank">Georgia</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/states/Hawaii.xlsx" rel="noopener noreferrer" target="_blank">Hawaii</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/states/Idaho.xlsx" rel="noopener noreferrer" target="_blank">Idaho</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/states/Illinois.xlsx" rel="noopener noreferrer" target="_blank">Illinois</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/states/Indiana.xlsx" rel="noopener noreferrer" target="_blank">Indiana</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/states/Iowa.xlsx" rel="noopener noreferrer" target="_blank">Iowa</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/states/Kansas.xlsx" rel="noopener noreferrer" target="_blank">Kansas</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/states/Kentucky.xlsx" rel="noopener noreferrer" target="_blank">Kentucky</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/states/Louisiana.xlsx" rel="noopener noreferrer" target="_blank">Louisiana</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/states/Maine.xlsx" rel="noopener noreferrer" target="_blank">Maine</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/states/Maryland.xlsx" rel="noopener noreferrer" target="_blank">Maryland</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/states/Massachusetts.xlsx" rel="noopener noreferrer" target="_blank">Massachusetts</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/states/Michigan.xlsx" rel="noopener noreferrer" target="_blank">Michigan</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/states/Minnesota.xlsx" rel="noopener noreferrer" target="_blank">Minnesota</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/states/Mississippi.xlsx" rel="noopener noreferrer" target="_blank">Mississippi</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/states/Missouri.xlsx" rel="noopener noreferrer" target="_blank">Missouri</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/states/Montana.xlsx" rel="noopener noreferrer" target="_blank">Montana</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/states/Nebraska.xlsx" rel="noopener noreferrer" target="_blank">Nebraska</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/states/Nevada.xlsx" rel="noopener noreferrer" target="_blank">Nevada</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/states/New Hampshire.xlsx" rel="noopener noreferrer" target="_blank">New Hampshire</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/states/New Jersey.xlsx" rel="noopener noreferrer" target="_blank">New Jersey</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/states/New Mexico.xlsx" rel="noopener noreferrer" target="_blank">New Mexico</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/states/New York.xlsx" rel="noopener noreferrer" target="_blank">New York</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/states/North Carolina.xlsx" rel="noopener noreferrer" target="_blank">North Carolina</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/states/North Dakota.xlsx" rel="noopener noreferrer" target="_blank">North Dakota</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/states/Ohio.xlsx" rel="noopener noreferrer" target="_blank">Ohio</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/states/Oklahoma.xlsx" rel="noopener noreferrer" target="_blank">Oklahoma</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/states/Oregon.xlsx" rel="noopener noreferrer" target="_blank">Oregon</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/states/Pennsylvania.xlsx" rel="noopener noreferrer" target="_blank">Pennsylvania</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/states/Rhode Island.xlsx" rel="noopener noreferrer" target="_blank">Rhode Island</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/states/South Carolina.xlsx" rel="noopener noreferrer" target="_blank">South Carolina</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/states/South Dakota.xlsx" rel="noopener noreferrer" target="_blank">South Dakota</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/states/Tennessee.xlsx" rel="noopener noreferrer" target="_blank">Tennessee</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/states/Texas.xlsx" rel="noopener noreferrer" target="_blank">Texas</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/states/Utah.xlsx" rel="noopener noreferrer" target="_blank">Utah</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/states/Vermont.xlsx" rel="noopener noreferrer" target="_blank">Vermont</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/states/Virginia.xlsx" rel="noopener noreferrer" target="_blank">Virginia</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/states/Washington.xlsx" rel="noopener noreferrer" target="_blank">Washington</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/states/West Virginia.xlsx" rel="noopener noreferrer" target="_blank">West Virginia</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/states/Wisconsin.xlsx" rel="noopener noreferrer" target="_blank">Wisconsin</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/states/Wyoming.xlsx" rel="noopener noreferrer" target="_blank">Wyoming</a>
          </div>
        </div>

        <br /><br />
        <p class="has-text-centered is-size-6 has-text-weight-bold">Discipline by Discipline Type</p>
        <p>
          This set of Excel files contains data for all states, presented by discipline type. For each discipline type, there are nine spreadsheets: male students with disabilities, female students with disabilities, total students with disabilities, male students without disabilities, female students without disabilities, total students without disabilities, male students with and without disabilities, female students with and without disabilities, and total students with and without disabilities.
        </p>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/discipline/Corporal-Punishment.xlsx" rel="noopener noreferrer" target="_blank">Corporal punishment</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/discipline/One-or-more-in-school-suspensions.xlsx" rel="noopener noreferrer" target="_blank">In-school suspension</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/discipline/Only-one-out-of-school-suspension.xlsx" rel="noopener noreferrer" target="_blank">One out-of-school suspension</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/discipline/More-than-one-out-of-school-suspension.xlsx" rel="noopener noreferrer" target="_blank">More than one out-of-school suspension</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/discipline/One-or-more-out-of-school-suspensions.xlsx" rel="noopener noreferrer" target="_blank">One or more out-of-school suspensions</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/discipline/Expulsions-with-educational-services.xlsx" rel="noopener noreferrer" target="_blank">Expulsions with educational services</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/discipline/Expulsions-without-educational-services.xlsx" rel="noopener noreferrer" target="_blank">Expulsions without educational services</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/discipline/Expulsions-with-and-without-educational-services.xlsx" rel="noopener noreferrer" target="_blank">Expulsions with and without educational services</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/discipline/Expulsions-under-zero-tolerance-policies.xlsx" rel="noopener noreferrer" target="_blank">Expulsions under zero tolerance policies</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/discipline/Referral-to-law-enforcement.xlsx" rel="noopener noreferrer" target="_blank">Referrals to law enforcement</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/discipline/School-related-arrests.xlsx" rel="noopener noreferrer" target="_blank">School-related arrests</a>
          </div>
        </div>

        <br /><br />
        <p class="has-text-centered is-size-6 has-text-weight-bold">Discipline by Disability Status</p>
        <p>
          This set of Excel files contains data for all states, presented by disability status. Each Excel file contains 11 spreadsheets, organized by discipline type.
        </p>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-third has-text-centered">
            <a href="assets/downloads/2011-2012/disability/Students-with-Disabilities.xlsx" rel="noopener noreferrer" target="_blank">Students with disabilities</a>
          </div>
          <div class="column is-one-third has-text-centered">
            <a href="assets/downloads/2011-2012/disability/Students-without-Disabilities.xlsx" rel="noopener noreferrer" target="_blank">Students without disabilities</a>
          </div>
          <div class="column is-one-third has-text-centered">
            <a href="assets/downloads/2011-2012/disability/Students-with-and-without-Disabilities.xlsx" rel="noopener noreferrer" target="_blank">Students with and without disabilities</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-third has-text-centered">
            <a href="assets/downloads/2011-2012/disability/Male-Students-with-Disabilities.xlsx" rel="noopener noreferrer" target="_blank">Male students with disabilities</a>
          </div>
          <div class="column is-one-third has-text-centered">
            <a href="assets/downloads/2011-2012/disability/Male-Students-without-Disabilities.xlsx" rel="noopener noreferrer" target="_blank">Male students without disabilities</a>
          </div>
          <div class="column is-one-third has-text-centered">
            <a href="assets/downloads/2011-2012/disability/Male-Students-with-and-without-Disabilities.xlsx" rel="noopener noreferrer" target="_blank">Male students with and without disabilities</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-third has-text-centered">
            <a href="assets/downloads/2011-2012/disability/Female-Students-with-Disabilities.xlsx" rel="noopener noreferrer" target="_blank">Female students with disabilities</a>
          </div>
          <div class="column is-one-third has-text-centered">
            <a href="assets/downloads/2011-2012/disability/Female-Students-without-Disabilities.xlsx" rel="noopener noreferrer" target="_blank">Female students without disabilities</a>
          </div>
          <div class="column is-one-third has-text-centered">
            <a href="assets/downloads/2011-2012/disability/Female-Students-with-and-without-Disabilities.xlsx" rel="noopener noreferrer" target="_blank">Female students with and without disabilities</a>
          </div>
        </div>

        <br /><br />
        <p class="has-text-centered is-size-6 has-text-weight-bold">Preschool Discipline for Nation and by State</p>
        <p>
          This set of Excel files contains data for all preschool disciplinary actions, presented for the nation and by state. For the nation and each state, there is one spreadsheet for total preschool children.
        </p>
        <div class="columns is-gapless is-multiline">
          <div class="column is-full has-text-centered">
            <a href="assets/downloads/2011-2012/Preschool Discipline/by state/US.xlsx" rel="noopener noreferrer" target="_blank">National total</a>
          </div>
        </div>
        <br />
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Preschool Discipline/by state/AL.xls" rel="noopener noreferrer" target="_blank">Alabama</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Preschool Discipline/by state/AK.xls" rel="noopener noreferrer" target="_blank">Alaska</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Preschool Discipline/by state/AZ.xls" rel="noopener noreferrer" target="_blank">Arizona</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Preschool Discipline/by state/AR.xls" rel="noopener noreferrer" target="_blank">Arkansas</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Preschool Discipline/by state/CA.xls" rel="noopener noreferrer" target="_blank">California</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Preschool Discipline/by state/CO.xls" rel="noopener noreferrer" target="_blank">Colorado</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Preschool Discipline/by state/CT.xls" rel="noopener noreferrer" target="_blank">Connecticut</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Preschool Discipline/by state/DE.xls" rel="noopener noreferrer" target="_blank">Delaware</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Preschool Discipline/by state/DC.xls" rel="noopener noreferrer" target="_blank">District of Columbia</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Preschool Discipline/by state/FL.xls" rel="noopener noreferrer" target="_blank">Florida</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Preschool Discipline/by state/GA.xls" rel="noopener noreferrer" target="_blank">Georgia</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Preschool Discipline/by state/HI.xls" rel="noopener noreferrer" target="_blank">Hawaii</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Preschool Discipline/by state/ID.xls" rel="noopener noreferrer" target="_blank">Idaho</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Preschool Discipline/by state/IL.xls" rel="noopener noreferrer" target="_blank">Illinois</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Preschool Discipline/by state/IN.xls" rel="noopener noreferrer" target="_blank">Indiana</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Preschool Discipline/by state/IA.xls" rel="noopener noreferrer" target="_blank">Iowa</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Preschool Discipline/by state/KS.xls" rel="noopener noreferrer" target="_blank">Kansas</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Preschool Discipline/by state/KY.xls" rel="noopener noreferrer" target="_blank">Kentucky</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Preschool Discipline/by state/LA.xls" rel="noopener noreferrer" target="_blank">Louisiana</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Preschool Discipline/by state/ME.xls" rel="noopener noreferrer" target="_blank">Maine</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Preschool Discipline/by state/MD.xls" rel="noopener noreferrer" target="_blank">Maryland</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Preschool Discipline/by state/MA.xls" rel="noopener noreferrer" target="_blank">Massachusetts</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Preschool Discipline/by state/MI.xls" rel="noopener noreferrer" target="_blank">Michigan</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Preschool Discipline/by state/MN.xls" rel="noopener noreferrer" target="_blank">Minnesota</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Preschool Discipline/by state/MS.xls" rel="noopener noreferrer" target="_blank">Mississippi</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Preschool Discipline/by state/MO.xls" rel="noopener noreferrer" target="_blank">Missouri</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Preschool Discipline/by state/MT.xls" rel="noopener noreferrer" target="_blank">Montana</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Preschool Discipline/by state/DE.xls" rel="noopener noreferrer" target="_blank">Nebraska</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Preschool Discipline/by state/NV.xls" rel="noopener noreferrer" target="_blank">Nevada</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Preschool Discipline/by state/NH.xls" rel="noopener noreferrer" target="_blank">New Hampshire</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Preschool Discipline/by state/NJ.xls" rel="noopener noreferrer" target="_blank">New Jersey</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Preschool Discipline/by state/NM.xls" rel="noopener noreferrer" target="_blank">New Mexico</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Preschool Discipline/by state/NY.xls" rel="noopener noreferrer" target="_blank">New York</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Preschool Discipline/by state/NC.xls" rel="noopener noreferrer" target="_blank">North Carolina</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Preschool Discipline/by state/ND.xls" rel="noopener noreferrer" target="_blank">North Dakota</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Preschool Discipline/by state/OH.xls" rel="noopener noreferrer" target="_blank">Ohio</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Preschool Discipline/by state/OK.xls" rel="noopener noreferrer" target="_blank">Oklahoma</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Preschool Discipline/by state/OR.xls" rel="noopener noreferrer" target="_blank">Oregon</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Preschool Discipline/by state/PA.xls" rel="noopener noreferrer" target="_blank">Pennsylvania</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Preschool Discipline/by state/RI.xls" rel="noopener noreferrer" target="_blank">Rhode Island</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Preschool Discipline/by state/SC.xls" rel="noopener noreferrer" target="_blank">South Carolina</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Preschool Discipline/by state/SD.xls" rel="noopener noreferrer" target="_blank">South Dakota</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Preschool Discipline/by state/TN.xls" rel="noopener noreferrer" target="_blank">Tennessee</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Preschool Discipline/by state/TX.xls" rel="noopener noreferrer" target="_blank">Texas</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Preschool Discipline/by state/UT.xls" rel="noopener noreferrer" target="_blank">Utah</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Preschool Discipline/by state/VT.xls" rel="noopener noreferrer" target="_blank">Vermont</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Preschool Discipline/by state/VA.xls" rel="noopener noreferrer" target="_blank">Virginia</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Preschool Discipline/by state/WA.xls" rel="noopener noreferrer" target="_blank">Washington</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Preschool Discipline/by state/WV.xls" rel="noopener noreferrer" target="_blank">West Virginia</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Preschool Discipline/by state/WI.xls" rel="noopener noreferrer" target="_blank">Wisconsin</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Preschool Discipline/by state/WY.xls" rel="noopener noreferrer" target="_blank">Wyoming</a>
          </div>
        </div>

        <br /><br />
        <p class="has-text-centered is-size-6 has-text-weight-bold">Preschool Discipline by Discipline Type</p>
        <p>
          This set of Excel files contains data for all states, presented by discipline type. For each discipline type, there are three spreadsheets: total preschool children, male preschool children, and female preschool children.
        </p>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Preschool Discipline/by discipline type/Preschool-One-Out-of-School-Suspension.xlsx" rel="noopener noreferrer" target="_blank">One out-of-school suspension</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Preschool Discipline/by discipline type/Preschool-More-than-One-Out-of-School-Suspension.xlsx" rel="noopener noreferrer" target="_blank">More than one out-of-school suspension</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Preschool Discipline/by discipline type/Preschool-One-or-More-Out-of-School-Suspensions.xlsx" rel="noopener noreferrer" target="_blank">One or more out-of-school suspensions</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Preschool Discipline/by discipline type/Preschool-Expulsions.xlsx" rel="noopener noreferrer" target="_blank">Expulsions</a>
          </div>
        </div>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
        <mat-panel-title>
          Harassment or Bullying
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="accordion-panel-body">
        <p class="has-text-centered is-size-6 has-text-weight-bold">Harassment or Bullying for Nation and by State</p>
        <p>
          This set of Excel files contains data for all harassment or bullying categories, presented for the nation and by state. For the nation and each state, there are two spreadsheets: students reported as harassed or bullied, and students disciplined for harassment or bullying.
        </p>
        <div class="columns is-gapless is-multiline">
          <div class="column is-full has-text-centered">
            <a href="assets/downloads/2011-2012/Harrassment or Bullying/by State/US-Bullying-or-Harassment.xlsx" rel="noopener noreferrer" target="_blank">National total</a>
          </div>
        </div>
        <br />
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Harrassment or Bullying/by State/AL-Bullying-or-Harassment.xlsx" rel="noopener noreferrer" target="_blank">Alabama</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Harrassment or Bullying/by State/AK-Bullying-or-Harassment.xlsx" rel="noopener noreferrer" target="_blank">Alaska</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Harrassment or Bullying/by State/AZ-Bullying-or-Harassment.xlsx" rel="noopener noreferrer" target="_blank">Arizona</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Harrassment or Bullying/by State/AR-Bullying-or-Harassment.xlsx" rel="noopener noreferrer" target="_blank">Arkansas</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Harrassment or Bullying/by State/CA-Bullying-or-Harassment.xlsx" rel="noopener noreferrer" target="_blank">California</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Harrassment or Bullying/by State/CO-Bullying-or-Harassment.xlsx" rel="noopener noreferrer" target="_blank">Colorado</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Harrassment or Bullying/by State/CT-Bullying-or-Harassment.xlsx" rel="noopener noreferrer" target="_blank">Connecticut</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Harrassment or Bullying/by State/DE-Bullying-or-Harassment.xlsx" rel="noopener noreferrer" target="_blank">Delaware</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Harrassment or Bullying/by State/DC-Bullying-or-Harassment.xlsx" rel="noopener noreferrer" target="_blank">District of Columbia</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Harrassment or Bullying/by State/FL-Bullying-or-Harassment.xlsx" rel="noopener noreferrer" target="_blank">Florida</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Harrassment or Bullying/by State/GA-Bullying-or-Harassment.xlsx" rel="noopener noreferrer" target="_blank">Georgia</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Harrassment or Bullying/by State/HI-Bullying-or-Harassment.xlsx" rel="noopener noreferrer" target="_blank">Hawaii</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Harrassment or Bullying/by State/ID-Bullying-or-Harassment.xlsx" rel="noopener noreferrer" target="_blank">Idaho</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Harrassment or Bullying/by State/IL-Bullying-or-Harassment.xlsx" rel="noopener noreferrer" target="_blank">Illinois</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Harrassment or Bullying/by State/IN-Bullying-or-Harassment.xlsx" rel="noopener noreferrer" target="_blank">Indiana</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Harrassment or Bullying/by State/IA-Bullying-or-Harassment.xlsx" rel="noopener noreferrer" target="_blank">Iowa</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Harrassment or Bullying/by State/KS-Bullying-or-Harassment.xlsx" rel="noopener noreferrer" target="_blank">Kansas</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Harrassment or Bullying/by State/KY-Bullying-or-Harassment.xlsx" rel="noopener noreferrer" target="_blank">Kentucky</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Harrassment or Bullying/by State/LA-Bullying-or-Harassment.xlsx" rel="noopener noreferrer" target="_blank">Louisiana</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Harrassment or Bullying/by State/ME-Bullying-or-Harassment.xlsx" rel="noopener noreferrer" target="_blank">Maine</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Harrassment or Bullying/by State/MD-Bullying-or-Harassment.xlsx" rel="noopener noreferrer" target="_blank">Maryland</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Harrassment or Bullying/by State/MA-Bullying-or-Harassment.xlsx" rel="noopener noreferrer" target="_blank">Massachusetts</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Harrassment or Bullying/by State/MI-Bullying-or-Harassment.xlsx" rel="noopener noreferrer" target="_blank">Michigan</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Harrassment or Bullying/by State/MN-Bullying-or-Harassment.xlsx" rel="noopener noreferrer" target="_blank">Minnesota</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Harrassment or Bullying/by State/MS-Bullying-or-Harassment.xlsx" rel="noopener noreferrer" target="_blank">Mississippi</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Harrassment or Bullying/by State/MO-Bullying-or-Harassment.xlsx" rel="noopener noreferrer" target="_blank">Missouri</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Harrassment or Bullying/by State/MT-Bullying-or-Harassment.xlsx" rel="noopener noreferrer" target="_blank">Montana</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Harrassment or Bullying/by State/NE-Bullying-or-Harassment.xlsx" rel="noopener noreferrer" target="_blank">Nebraska</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Harrassment or Bullying/by State/NV-Bullying-or-Harassment.xlsx" rel="noopener noreferrer" target="_blank">Nevada</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Harrassment or Bullying/by State/NH-Bullying-or-Harassment.xlsx" rel="noopener noreferrer" target="_blank">New Hampshire</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Harrassment or Bullying/by State/NJ-Bullying-or-Harassment.xlsx" rel="noopener noreferrer" target="_blank">New Jersey</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Harrassment or Bullying/by State/NM-Bullying-or-Harassment.xlsx" rel="noopener noreferrer" target="_blank">New Mexico</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Harrassment or Bullying/by State/NY-Bullying-or-Harassment.xlsx" rel="noopener noreferrer" target="_blank">New York</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Harrassment or Bullying/by State/NC-Bullying-or-Harassment.xlsx" rel="noopener noreferrer" target="_blank">North Carolina</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Harrassment or Bullying/by State/ND-Bullying-or-Harassment.xlsx" rel="noopener noreferrer" target="_blank">North Dakota</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Harrassment or Bullying/by State/OH-Bullying-or-Harassment.xlsx" rel="noopener noreferrer" target="_blank">Ohio</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Harrassment or Bullying/by State/OK-Bullying-or-Harassment.xlsx" rel="noopener noreferrer" target="_blank">Oklahoma</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Harrassment or Bullying/by State/OR-Bullying-or-Harassment.xlsx" rel="noopener noreferrer" target="_blank">Oregon</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Harrassment or Bullying/by State/PA-Bullying-or-Harassment.xlsx" rel="noopener noreferrer" target="_blank">Pennsylvania</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Harrassment or Bullying/by State/RI-Bullying-or-Harassment.xlsx" rel="noopener noreferrer" target="_blank">Rhode Island</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Harrassment or Bullying/by State/SC-Bullying-or-Harassment.xlsx" rel="noopener noreferrer" target="_blank">South Carolina</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Harrassment or Bullying/by State/SD-Bullying-or-Harassment.xlsx" rel="noopener noreferrer" target="_blank">South Dakota</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Harrassment or Bullying/by State/TN-Bullying-or-Harassment.xlsx" rel="noopener noreferrer" target="_blank">Tennessee</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Harrassment or Bullying/by State/TX-Bullying-or-Harassment.xlsx" rel="noopener noreferrer" target="_blank">Texas</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Harrassment or Bullying/by State/UT-Bullying-or-Harassment.xlsx" rel="noopener noreferrer" target="_blank">Utah</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Harrassment or Bullying/by State/VT-Bullying-or-Harassment.xlsx" rel="noopener noreferrer" target="_blank">Vermont</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Harrassment or Bullying/by State/VA-Bullying-or-Harassment.xlsx" rel="noopener noreferrer" target="_blank">Virginia</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Harrassment or Bullying/by State/WA-Bullying-or-Harassment.xlsx" rel="noopener noreferrer" target="_blank">Washington</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Harrassment or Bullying/by State/WV-Bullying-or-Harassment.xlsx" rel="noopener noreferrer" target="_blank">West Virginia</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Harrassment or Bullying/by State/WI-Bullying-or-Harassment.xlsx" rel="noopener noreferrer" target="_blank">Wisconsin</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Harrassment or Bullying/by State/WY-Bullying-or-Harassment.xlsx" rel="noopener noreferrer" target="_blank">Wyoming</a>
          </div>
        </div>

        <br /><br />
        <p class="has-text-centered is-size-6 has-text-weight-bold">Harassment or Bullying by Basis</p>
        <p>
          This set of Excel files contains data for all states, presented by harassment or bullying basis category. For each basis category, there are six spreadsheets: students reported as harassed or bullied, male students reported as harassed or bullied, female students reported as harassed or bullied, students disciplined for harassment or bullying, male students disciplined for harassment or bullying, and female students disciplined for harassment or bullying.
        </p>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-third has-text-centered">
            <a href="assets/downloads/2011-2012/Harrassment or Bullying/by Basis/Harassment-Bullying-on-basis-of-sex.xlsx" rel="noopener noreferrer" target="_blank">On basis of sex</a>
          </div>
          <div class="column is-one-third has-text-centered">
            <a href="assets/downloads/2011-2012/Harrassment or Bullying/by Basis/Harassment-Bullying-on-basis-of-race.xlsx" rel="noopener noreferrer" target="_blank">On basis of race</a>
          </div>
          <div class="column is-one-third has-text-centered">
            <a href="assets/downloads/2011-2012/Harrassment or Bullying/by Basis/Harassment-Bullying-on-basis-of-disability.xlsx" rel="noopener noreferrer" target="_blank">On basis of disability</a>
          </div>
        </div>

        <br /><br />
        <p class="has-text-centered has-text-weight-bold">Harassment or Bullying by Harassment or Bullying Category</p>
        <p>
          This set of Excel files contains data for all states, presented by harassment or bullying category (students reported as harassed or bullied or disciplined for harassment or bullying). For each category, there are nine spreadsheets: students harassed or bullied on basis of sex, male students on basis of sex, female students on basis of sex, students on basis of race, male students on basis of race, female students on basis of race, students on basis of disability, male students on basis of disability, and female students on basis of disability.
        </p>
        <div class="columns is-gapless is-multiline">
          <div class="column is-half has-text-centered">
            <a href="assets/downloads/2011-2012/Harrassment or Bullying/by Category/Harassment-Bullying-Reported.xlsx" rel="noopener noreferrer" target="_blank">Students reported as harassed or bullied</a>
          </div>
          <div class="column is-half has-text-centered">
            <a href="assets/downloads/2011-2012/Harrassment or Bullying/by Category/Harassment-Bullying-Disciplined.xlsx" rel="noopener noreferrer" target="_blank">Students disciplined for harassment or bullying</a>
          </div>
        </div>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
        <mat-panel-title>
          Restraint and Seclusion
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="accordion-panel-body">
        <p class="has-text-centered is-size-6 has-text-weight-bold">Restraint and Seclusion for Nation and by State</p>
        <p>
          This set of Excel files contains data for all restraint and seclusion categories, presented for the nation and by state. For the nation and each state, there are three spreadsheets: students with and without disabilities, students served under IDEA, and students not served under IDEA.
        </p>
        <div class="columns is-gapless is-multiline">
          <div class="column is-full has-text-centered">
            <a href="assets/downloads/2011-2012/Restraint and Seclusion/by state/US-Restraint-and-Seclusion.xlsx" rel="noopener noreferrer" target="_blank">National total</a>
          </div>
        </div>
        <br />
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Restraint and Seclusion/by state/AL-Restraint-and-Seclusion.xlsx" rel="noopener noreferrer" target="_blank">Alabama</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Restraint and Seclusion/by state/AK-Restraint-and-Seclusion.xlsx" rel="noopener noreferrer" target="_blank">Alaska</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Restraint and Seclusion/by state/AZ-Restraint-and-Seclusion.xlsx" rel="noopener noreferrer" target="_blank">Arizona</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Restraint and Seclusion/by state/AR-Restraint-and-Seclusion.xlsx" rel="noopener noreferrer" target="_blank">Arkansas</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Restraint and Seclusion/by state/CA-Restraint-and-Seclusion.xlsx" rel="noopener noreferrer" target="_blank">California</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Restraint and Seclusion/by state/CO-Restraint-and-Seclusion.xlsx" rel="noopener noreferrer" target="_blank">Colorado</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Restraint and Seclusion/by state/CT-Restraint-and-Seclusion.xlsx" rel="noopener noreferrer" target="_blank">Connecticut</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Restraint and Seclusion/by state/DE-Restraint-and-Seclusion.xlsx" rel="noopener noreferrer" target="_blank">Delaware</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Restraint and Seclusion/by state/DC-Restraint-and-Seclusion.xlsx" rel="noopener noreferrer" target="_blank">District of Columbia</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Restraint and Seclusion/by state/FL-Restraint-and-Seclusion.xlsx" rel="noopener noreferrer" target="_blank">Florida</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Restraint and Seclusion/by state/GA-Restraint-and-Seclusion.xlsx" rel="noopener noreferrer" target="_blank">Georgia</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Restraint and Seclusion/by state/HI-Restraint-and-Seclusion.xlsx" rel="noopener noreferrer" target="_blank">Hawaii</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Restraint and Seclusion/by state/ID-Restraint-and-Seclusion.xlsx" rel="noopener noreferrer" target="_blank">Idaho</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Restraint and Seclusion/by state/IL-Restraint-and-Seclusion.xlsx" rel="noopener noreferrer" target="_blank">Illinois</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Restraint and Seclusion/by state/IN-Restraint-and-Seclusion.xlsx" rel="noopener noreferrer" target="_blank">Indiana</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Restraint and Seclusion/by state/IA-Restraint-and-Seclusion.xlsx" rel="noopener noreferrer" target="_blank">Iowa</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Restraint and Seclusion/by state/KS-Restraint-and-Seclusion.xlsx" rel="noopener noreferrer" target="_blank">Kansas</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Restraint and Seclusion/by state/KY-Restraint-and-Seclusion.xlsx" rel="noopener noreferrer" target="_blank">Kentucky</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Restraint and Seclusion/by state/LA-Restraint-and-Seclusion.xlsx" rel="noopener noreferrer" target="_blank">Louisiana</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Restraint and Seclusion/by state/ME-Restraint-and-Seclusion.xlsx" rel="noopener noreferrer" target="_blank">Maine</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Restraint and Seclusion/by state/MD-Restraint-and-Seclusion.xlsx" rel="noopener noreferrer" target="_blank">Maryland</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Restraint and Seclusion/by state/MA-Restraint-and-Seclusion.xlsx" rel="noopener noreferrer" target="_blank">Massachusetts</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Restraint and Seclusion/by state/MI-Restraint-and-Seclusion.xlsx" rel="noopener noreferrer" target="_blank">Michigan</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Restraint and Seclusion/by state/MN-Restraint-and-Seclusion.xlsx" rel="noopener noreferrer" target="_blank">Minnesota</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Restraint and Seclusion/by state/MS-Restraint-and-Seclusion.xlsx" rel="noopener noreferrer" target="_blank">Mississippi</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Restraint and Seclusion/by state/MO-Restraint-and-Seclusion.xlsx" rel="noopener noreferrer" target="_blank">Missouri</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Restraint and Seclusion/by state/MT-Restraint-and-Seclusion.xlsx" rel="noopener noreferrer" target="_blank">Montana</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Restraint and Seclusion/by state/NE-Restraint-and-Seclusion.xlsx" rel="noopener noreferrer" target="_blank">Nebraska</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Restraint and Seclusion/by state/NV-Restraint-and-Seclusion.xlsx" rel="noopener noreferrer" target="_blank">Nevada</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Restraint and Seclusion/by state/NH-Restraint-and-Seclusion.xlsx" rel="noopener noreferrer" target="_blank">New Hampshire</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Restraint and Seclusion/by state/NJ-Restraint-and-Seclusion.xlsx" rel="noopener noreferrer" target="_blank">New Jersey</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Restraint and Seclusion/by state/NM-Restraint-and-Seclusion.xlsx" rel="noopener noreferrer" target="_blank">New Mexico</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Restraint and Seclusion/by state/NY-Restraint-and-Seclusion.xlsx" rel="noopener noreferrer" target="_blank">New York</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Restraint and Seclusion/by state/NC-Restraint-and-Seclusion.xlsx" rel="noopener noreferrer" target="_blank">North Carolina</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Restraint and Seclusion/by state/ND-Restraint-and-Seclusion.xlsx" rel="noopener noreferrer" target="_blank">North Dakota</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Restraint and Seclusion/by state/OH-Restraint-and-Seclusion.xlsx" rel="noopener noreferrer" target="_blank">Ohio</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Restraint and Seclusion/by state/OK-Restraint-and-Seclusion.xlsx" rel="noopener noreferrer" target="_blank">Oklahoma</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Restraint and Seclusion/by state/OR-Restraint-and-Seclusion.xlsx" rel="noopener noreferrer" target="_blank">Oregon</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Restraint and Seclusion/by state/PA-Restraint-and-Seclusion.xlsx" rel="noopener noreferrer" target="_blank">Pennsylvania</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Restraint and Seclusion/by state/RI-Restraint-and-Seclusion.xlsx" rel="noopener noreferrer" target="_blank">Rhode Island</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Restraint and Seclusion/by state/SC-Restraint-and-Seclusion.xlsx" rel="noopener noreferrer" target="_blank">South Carolina</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Restraint and Seclusion/by state/SD-Restraint-and-Seclusion.xlsx" rel="noopener noreferrer" target="_blank">South Dakota</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Restraint and Seclusion/by state/TN-Restraint-and-Seclusion.xlsx" rel="noopener noreferrer" target="_blank">Tennessee</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Restraint and Seclusion/by state/TX-Restraint-and-Seclusion.xlsx" rel="noopener noreferrer" target="_blank">Texas</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Restraint and Seclusion/by state/UT-Restraint-and-Seclusion.xlsx" rel="noopener noreferrer" target="_blank">Utah</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Restraint and Seclusion/by state/VT-Restraint-and-Seclusion.xlsx" rel="noopener noreferrer" target="_blank">Vermont</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Restraint and Seclusion/by state/VA-Restraint-and-Seclusion.xlsx" rel="noopener noreferrer" target="_blank">Virginia</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Restraint and Seclusion/by state/WA-Restraint-and-Seclusion.xlsx" rel="noopener noreferrer" target="_blank">Washington</a>
          </div>
        </div>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Restraint and Seclusion/by state/WV-Restraint-and-Seclusion.xlsx" rel="noopener noreferrer" target="_blank">West Virginia</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Restraint and Seclusion/by state/WI-Restraint-and-Seclusion.xlsx" rel="noopener noreferrer" target="_blank">Wisconsin</a>
          </div>
          <div class="column is-one-quarter has-text-centered">
            <a href="assets/downloads/2011-2012/Restraint and Seclusion/by state/WY-Restraint-and-Seclusion.xlsx" rel="noopener noreferrer" target="_blank">Wyoming</a>
          </div>
        </div>

        <br /><br />
        <p class="has-text-centered is-size-6 has-text-weight-bold">Restraint and Seclusion by Restraint or Seclusion Category</p>
        <p>
          This set of Excel files contains data for all states, presented by restraint or seclusion category. For each category, there are nine spreadsheets: students with and without disabilities, male students with and without disabilities, female students with and without disabilities, students served under IDEA, male students served under IDEA, female students served under IDEA, students not served under IDEA, male students not served under IDEA, and female students not served under IDEA.
        </p>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-third has-text-centered">
            <a href="assets/downloads/2011-2012/Restraint and Seclusion/by Category/Mechanical-Restraint.xlsx" rel="noopener noreferrer" target="_blank">Mechanical restraint</a>
          </div>
          <div class="column is-one-third has-text-centered">
            <a href="assets/downloads/2011-2012/Restraint and Seclusion/by Category/Physical-Restraint.xlsx" rel="noopener noreferrer" target="_blank">Physical restraint</a>
          </div>
          <div class="column is-one-third has-text-centered">
            <a href="assets/downloads/2011-2012/Restraint and Seclusion/by Category/Seclusion.xlsx" rel="noopener noreferrer" target="_blank">Seclusion</a>
          </div>
        </div>

        <br /><br />
        <p class="has-text-centered is-size-6 has-text-weight-bold">Restraint and Seclusion by Disability Status</p>
        <p>
          This set of Excel files contains restraint and seclusion data for all states, presented by disability status. Each Excel file contains nine spreadsheets organized by restraint or seclusion category: students subjected to mechanical restraint, male students subjected to mechanical restraint, female students subjected to mechanical restraint, students subjected to physical restraint, male students subjected to physical restraint, female students subjected to physical restraint, students subjected to seclusion, male students subjected to seclusion, and female students subjected to seclusion.
        </p>
        <div class="columns is-gapless is-multiline">
          <div class="column is-one-third has-text-centered">
            <a href="assets/downloads/2011-2012/Restraint and Seclusion/by Disability Status/Restraint-or-Seclusion-Students-with-and-without-Disab.xlsx" rel="noopener noreferrer" target="_blank">Students with and without disabilities</a>
          </div>
          <div class="column is-one-third has-text-centered">
            <a href="assets/downloads/2011-2012/Restraint and Seclusion/by Disability Status/Restraint-or-Seclusion-Students-served-under-IDEA.xlsx" rel="noopener noreferrer" target="_blank">Students served under IDEA</a>
          </div>
          <div class="column is-one-third has-text-centered">
            <a href="assets/downloads/2011-2012/Restraint and Seclusion/by Disability Status/Restraint-or-Seclusion-Students-not-served-under-IDEA.xlsx" rel="noopener noreferrer" target="_blank">Students not served under IDEA</a>
          </div>
        </div>
      </div>
    </mat-expansion-panel>

  </mat-accordion>
  <document-viewer docType="PDF"></document-viewer>
</div>
