<!--  (hidden)="toastService.remove(toast)" : not needed, since bootstrap css does it already -->
<ngb-toast [ngClass]="toast.type" *ngFor="let toast of toastService.toasts"
           [autohide]="autoHideToast" [delay]="toast.delay" (mouseenter)="autoHideToast = false"
           (mouseleave)="autoHideToast = true">

    <ng-template ngbToastHeader>
        <div>
            <mat-icon *ngIf="toast.type == 'error'" class="toast-icon">error_outline</mat-icon>
            <mat-icon *ngIf="toast.type == 'warning'" class="toast-icon">error_outline</mat-icon>
            <mat-icon *ngIf="toast.type == 'info'" class="toast-icon">info_outline</mat-icon>
            <mat-icon *ngIf="toast.type == 'success'" class="toast-icon">check_circle_outline</mat-icon>

        </div>
        <strong>{{toast.header}}</strong>
    </ng-template>

    <div fxLayout="row" fxLayoutAlign="start center">

        <div fxFlex="auto" style="white-space: pre-line;">{{ toast.body }}</div>
    </div>
</ngb-toast>
