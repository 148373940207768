import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

import { MessageService } from '../core/message.service';

import { BaseService } from '../core/base.service';
import { DataService } from '../core/data.service';

import { StateNationalEtimationQuery } from '../../queries/state-national-estimation-query';
import { StateNationalReportQuery } from '../../queries/state-national-report-query';
import {NGXLogger} from 'ngx-logger';
import {ToastService} from '@shared/components/toast/toast.service';

@Injectable()
export class StateNationalEstimationService extends BaseService {

  private apiUrl = environment.api_url; // URL to web api

  constructor(
    private http: HttpClient,
    logger: NGXLogger,
    toastService: ToastService,
    messageService: MessageService,
    dataService: DataService) {
        super(logger, toastService, messageService, dataService);
    }

    /** GET **/
    getStateEstimation(query: StateNationalEtimationQuery): Observable<any> {

        this.setHeaders();

        const url = this.apiUrl + 'GetStateEstimation';

        var params = new HttpParams();
        params = params.append('survey_Year_Key', query.surveyYearKey.toString());
        params = params.append('Measure_Id', query.measureId.toString());
        params = params.append('State_Id', query.stateId.toString());

        return this.http.get<any>(
        url,
        {
            params: params,
            observe: 'response',
            headers: this.headers
        })
        .pipe(
            map(resp => {
                if (resp != undefined || resp != null) {
                    return resp.body;
                }

            return null;
            }),
            tap(resp => {
                this.log(`fetched`);
            }),
            catchError(this.handleError('get', null))
      );
    }

    getNationalEstimation(query: StateNationalEtimationQuery): Observable<any> {

        this.setHeaders();

        const url = this.apiUrl + 'GetNationalEstimation';

        let params = new HttpParams();
        params = params.append('survey_Year_Key', query.surveyYearKey.toString());
        params = params.append('Measure_Id', query.measureId.toString());

        return this.http.get<any>(
            url,
            {
                params: params,
                observe: 'response',
                headers: this.headers,
                withCredentials: true,  // so we can send cookies
            })
            .pipe(
                map(resp => {
                    if (resp != undefined || resp != null) {
                        return resp.body;
                    }

                    return null;
                }),
                tap(resp => {
                    this.log(`fetched`);
                }),
                catchError(this.handleError('get', null))
            );
    }

    /**
     *  download generated State/National estimation spreadsheet
     * @returns
     */
    downloadStateNationalEstimationExcelFile(query: StateNationalReportQuery) {
        const url = this.apiUrl + 'GetStateNationalEstimation';

        var params = new HttpParams();
        params = params.append('survey_Year_Key', query.surveyYearKey.toString());    // survey year key
        params = params.append('Report_Id', query.reportId.toString());          // enrollment measure or misc criteria report name
        //params = params.append('Measure_Type', "Enr");      // measure type: 'Enr', 'Misc'

        return this.http.get(url, { params: params, responseType: 'blob' });
    }

}
