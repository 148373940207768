import { Component } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Bounds, CriteriaDTO } from '../../models/entity-search-step2-model';
import { LabelValuePair } from '../../models/profile-model';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-data-tool-criteria-filters',
  templateUrl: './data-tool-criteria-filters.component.html',
  styleUrls: ['./data-tool-criteria-filters.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DataToolCriteriaFiltersComponent
    }
  ]
})
export class DataToolCriteriaFiltersComponent implements ControlValueAccessor {

  Bounds = Bounds

  public criteria: CriteriaDTO;

  public sexCriteria: LabelValuePair[];
  public raceOrEthnicityCriteria: LabelValuePair[];
  public otherCriteria: LabelValuePair[];
  public expanded: boolean;

  constructor(
    private logger: NGXLogger,
  ) {
    this.sexCriteria = [
      {
        label: 'Female students',
        value: 'F_TOT_ENROL'
      },
      {
        label: 'Male students',
        value: 'M_TOT_ENROL'
      }
    ];
    this.raceOrEthnicityCriteria = [
      {
        label: 'American Indian or Alaska Native',
        value: 'B_AME_ENROL'
      },
      {
        label: 'Asian',
        value: 'B_ASI_ENROL'
      },
      {
        label: 'Black or African American',
        value: 'B_BLA_ENROL'
      },
      {
        label: 'Hispanic or Latino or any race',
        value: 'B_HIS_ENROL'
      },
      {
        label: 'Native Hawaiian or Other Pacific Islander',
        value: 'B_HI_PAC_ENROL'
      },
      {
        label: 'Two or more races',
        value: 'B_MORE_ENROL'
      },
      {
        label: 'White',
        value: 'B_WHI_ENROL'
      }
    ];
    this.otherCriteria = [
      {
        label: 'IDEA',
        value: 'B_DIS_ENROL'
      },
      {
        label: 'Section 504 only',
        value: 'B_DIS5_ENROL'
      },
      {
        label: 'English learners',
        value: 'B_LEP_ENROL'
      }
    ];
    this.expanded = false;
  }

  onChange = (criteria: CriteriaDTO) => { };

  onTouched = () => { };

  touched = false;

  disabled = false;

  public updateCriteriaLowerBound(criterion: string, event: any): void {
    const value = event.target.value;
    this.criteria[criterion].lowerBound = value;

    this.onChange(this.criteria);
  }

  public updateCriteriaUpperBound(criterion: string, event: any): void {
    const value = event.target.value;
    this.criteria[criterion].upperBound = value;
    this.onChange(this.criteria);
  }

  public toggleDropdown(event: any): void {
    this.logger.debug('Toggling dropdown');
    this.logger.debug(event);
    this.expanded = !this.expanded;
  }

  writeValue(criteria: CriteriaDTO): void {
    this.criteria = criteria;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

}
