import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ModuleL1 } from '@shared/models/entity-search-step4-pre-view.model';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-data-tool-module-options-selection',
  templateUrl: './data-tool-module-options-selection.component.html',
  styleUrls: ['./data-tool-module-options-selection.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DataToolModuleOptionsSelectionComponent
    }
  ]
})
export class DataToolModuleOptionsSelectionComponent implements ControlValueAccessor, OnInit, AfterViewInit {

  @Input() expanded: boolean = false;
  @Input() modules: ModuleL1[];
  @Input() label: string;
  @Input() readonly: boolean = false;
  @Input() scrollIntoView: boolean = false;

  @Output() onEdit: EventEmitter<void> = new EventEmitter<void>();

  /**
   * Angular will pull the first checkbox input element from the DOM and assign it to this property.
   * Used to set focus on the first checkbox input element when the user presses the next or back button.
   */
  @ViewChild('checkboxInput') checkboxInput: ElementRef;

  public selectedModules: FormGroup;
  public numSelectedModules: string;

  constructor(
    private logger: NGXLogger
  ) { }

  ngOnInit(): void {
    this.logger.debug("Inside ngOnInit, modules: ", this.modules);
    if (this.readonly) {
      this.selectedModules = new FormGroup({});
      this.disabled = true;
      this.modules.forEach((module: ModuleL1) => {
        module.dataString = module.data.map(d => d.title).join(', ');
      });
      this.numSelectedModules = this.getNumSelected();
    }
  }

  ngAfterViewInit(): void {
    if (this.scrollIntoView) {
      this.scrollToTopOfElement(this.label);
      this.focusFirstElement();
    }
  }

  onChange = (selectedOptions: FormGroup) => { };

  onTouched = () => { };

  touched = false;

  disabled = false;

  public onFormChange(): void {
    this.numSelectedModules = this.getNumSelected();
    this.markAsTouched();
    this.logger.debug('onFormChange, selectedModules: ', this.selectedModules);
    this.onChange(this.selectedModules);
  }

  public toggleDropdown(): void {
    this.expanded = !this.expanded;
  }

  /**
   * Gets the number of selected modules out of the total number of modules and returns it as a string.
   * @returns The number of selected modules out of the total number of modules.
   */
  public getNumSelected(): string {
    if (this.readonly) {
      let numItems: number = 0;
      this.modules.forEach((module: ModuleL1) => {
        module.data.forEach(() => {
          numItems++;
        });
      });
      return `${numItems} items`;
    } else {
      const moduleKeys: string[] = Object.keys(this.selectedModules.value);
      const selectedModuleKeys: string[] = moduleKeys.filter((key: string) => this.selectedModules.get(key).value);
      return `${selectedModuleKeys.length}/${moduleKeys.length} selected`;
    }
  }

  /**
   * Focuses on the first checkbox input element when the user presses the next or back button.
   */
  focusFirstElement(): void {
    this.checkboxInput.nativeElement.focus();
  }

  scrollToTopOfElement(element: string): void {
    this.logger.debug('Inside scrollToTopOfElement, element: ', element);
    const scrollElement = document.getElementById(element);
    scrollElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  /**
   * Emits the onEdit event. This will be handled by the parent component to allow the user to edit the
   * selected modules on analyses types.
   */
  public edit(): void {
    this.onEdit.emit();
  }

  writeValue(selectedOptions: FormGroup): void {
    this.selectedModules = selectedOptions;
    this.selectedModules.valueChanges.subscribe(this.onFormChange.bind(this));
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;

    if (this.disabled) {
      this.selectedModules.disable();
    } else {
      this.selectedModules.enable();
    }
  }

}
