import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent {

  @Input() iconString: string;

  getIconString(iconString: string): string {

    if (iconString && iconString.startsWith('mdi ')) {
      iconString = iconString.replace('mdi ', '');
    }

    return iconString;
  }
}
