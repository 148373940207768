import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { ChartDto, ChartTypesDto, StackedBubbleChartDto, StackedBubbleChartChildDto, CategoryDetailDto, GroupValueDto, StackedBubbleChartCategoryDto, Tooltip, TooltipData } from 'src/app/shared/models/charts-model';
import * as d3 from 'd3';
import { EntityType, Genders } from 'src/app/shared/models/profile-model';
import { ProfileService } from 'src/app/shared/services/helpers/profile.service';

@Component({
  selector: 'app-charts',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.scss']
})

/**
 * This class supports the rendering of each chart type with the inputted chart type, data, width, and height.
 */
export class ChartsComponent implements OnInit {
  @Input() chartType: ChartTypesDto;
  @Input() data: ChartDto;
  @Input() sectionName: string;
  @Input() entityType: EntityType;

  transformedData: any;

  constructor(private readonly profileService: ProfileService) { }

  ngOnInit(): void {
  }

  /**
   * This method updates the data for the given chart upon update of chart type.
   * @param changes the chart type that has been updated
   */
  ngOnChanges(changes: SimpleChanges) {
    const chartType = changes['chartType'].currentValue;
    const data = changes['data']?.currentValue;
    if (chartType && this.data) {
      this.cleanData();
      this.transformedData = this.transformDataForChartType(chartType, this.data);
      this.transformedData['tooltips'] = chartType !== ChartTypesDto.AREA_CHART ? this.getTooltipData() : '';
    }
  }

  cleanData() {
    this.data.categories = this.data.categories.map(category => {
      return {
        groupName: category.groupName,
        values: category.values.filter((a) => a)
      }

    })
  }

  /**
   * This method transforms the data for the selected chart type
   * @param chartType chart type selected
   * @param data inputted data to be updated
   * @returns the transformed data
   */
  transformDataForChartType(chartType: ChartTypesDto, data: ChartDto) {
    switch (chartType) {
      case ChartTypesDto.AREA_CHART:
        return this.transformDataForGeneralChart(data, chartType);
      case ChartTypesDto.BAR_CHART:
        let transformedData = this.transformDataForGeneralChart(data, chartType);
        transformedData = this.padData(transformedData);
        return transformedData;
      case ChartTypesDto.BUBBLE_CHART:
        return this.transformDataForBubbleChart(data);
      case ChartTypesDto.NATIONAL_CHART:
        break;
      case ChartTypesDto.STACKED_BUBBLE_CHART:
        return this.transformDataForStackedBubbleChart(data);
      case ChartTypesDto.STACKED_BAR_CHART:
        return this.transformDataForStackedBarChart(data);
      default:
        break;
    }
  }

  /**
   * This method transforms the data into a general format for most charts
   * @param data the inputted data
   * @returns the general data transformation
   */
  transformDataForGeneralChart(data: ChartDto, chartType: ChartTypesDto) {
    let transformedData = data.categories.map((category) => {
      return {
        category: category.groupName,
        ...this.getGroupValuesForGeneralChart(category.values),
      }
    });

    transformedData['columns'] = Object.keys(transformedData[0]);

    if (chartType === ChartTypesDto.BAR_CHART) {
      transformedData = this.scaleData(transformedData);
    }

    return transformedData;
  }

  /**
   * This method gets all of the group values for the general chart format
   * @param categoryValues the list of catagories
   * @returns the list of category values
   */
  getGroupValuesForGeneralChart(categoryValues) {
    let categories = [];

    categoryValues.forEach((category) => {
      categories[category.label] = category.value > 0 ? category.value : 0;
    });

    return categories;
  }

  scaleData(transformedData: any): any {
    let count: number[] = [];
    let columns: string[] = transformedData['columns'];

    transformedData.forEach(category => {
      columns.slice(1).forEach((column, i) => {
        if (count[i] === undefined) {
          count[i] = category[column] > 0 ? category[column] : 0;
        } else {
          count[i] = category[column] > 0 ? count[i] + category[column] : count[i];
        }
      });
    });

    transformedData = transformedData.map(group => {
      let newCategory = {
        category: group.category
      }

      columns.slice(1).forEach((column, i) => {
        newCategory[column] = group[column] > 0 ? Math.round((group[column] / count[i]) * 1000) / 10 : 0;

        if (column === this.entityType) {
          newCategory['sortColumn'] = group[column];
        }
      });

      return newCategory
    });

    transformedData['columns'] = columns;

    return transformedData;
  }

  padData(transformedData: any): any {
    let columns = transformedData['columns'].slice(1);

    if (columns.length < 4) {
      let padding = 4 - columns.length;
      for (let i = 0; i < padding; i++) {
        transformedData.forEach(race => {
          let padName: string = 'pad' + i;
          race[padName] = 0;
          transformedData['columns'].push(padName);
        })
      }
    }

    return transformedData;
  }

  getTooltipHTML(tooltipData: TooltipData[]): any {
    let tooltips: Tooltip[] = [];
    let tooltipVerb: string = this.data.measure.toolTipVerb;
    let total: number;
    let gender: Genders = this.data.selectedGender;

    if (this.data.entityType === EntityType.SCHOOL || this.data.entityType === EntityType.DISTRICT) {
      switch (gender) {
        case Genders.ALL_STUDENTS:
          total = this.data.enrollmentData.b_TOT;
          break;
        case Genders.FEMALE:
          total = this.data.enrollmentData.f_TOT;
          break;
        case Genders.MALE:
          total = this.data.enrollmentData.m_TOT;
          break;
      }
    } else {
      switch (gender) {
        case Genders.ALL_STUDENTS:
          total = this.data.enrollmentData.b_Count;
          break;
        case Genders.FEMALE:
          total = this.data.enrollmentData.f_Count;
          break;
        case Genders.MALE:
          total = this.data.enrollmentData.m_Count;
          break;
      }
    }

    tooltipData.forEach((category: TooltipData, i) => {
      const gender: string = this.data.selectedGender.toLocaleLowerCase();
      let categoryTotal: number = this.data.selectedGender === Genders.ALL_STUDENTS ? category.total : category[gender];
      let subCategoryCounts: string = '';
      let tooltipHtml: string;
      let studentPercent: number = categoryTotal / total;
      let studentPercentString = studentPercent > 0 && studentPercent < .01 ? '<0.1' : category.studentPercent[0].value.toFixed(1);
      let tooltipSubtitle: string = `${categoryTotal.toLocaleString('en-US')} ${tooltipVerb} (${studentPercentString}%)`;
      let sexStats: string = this.data.selectedGender === Genders.ALL_STUDENTS ? `<div class='is-flex is-flex-direction-column is-justify-content-flex-start chart-tooltip-container-content-left'>
        <p class='large-body has-text-primary chart-tooltip-container-content-left-header' style='padding-bottom:.5rem;'>Sex</p>
        <ul class='tooltip-content-left-categories ml-4'>
          <li class='small-body has-text-primary'>Male: ${category.male}</li>
          <li class='small-body has-text-primary'>Female: ${category.female}</li>
        </ul>
      </div>` : '';

      category.studentPercent.slice(1).forEach(entity => {
        subCategoryCounts += `<li class='small-body has-text-primary'>${entity.column}: ${entity.value.toFixed(1)}%</li>`;
      });

      if (subCategoryCounts.length > 0) {
        tooltipHtml = `<div class='is-flex is-flex-direction-column is-align-content-start chart-tooltip-container' style='padding-bottom:1rem; width:15rem; background:white; box-shadow:0px 4px 4px rgba(0, 0, 0, 0.25);'>
          <p class='bold-body has-text-primary chart-tooltip-container-header' style='padding-left:1rem; padding-top:1rem; padding-bottom:1rem;'>${category.label}</p>
          <p class='small-body has-text-primary chart-tooltip-container-subheader' style='font-weight:700; padding-left:1rem;'>${tooltipSubtitle}</p>
          <hr class="chart-tooltip-container-line" style='height:1px; background-color:#133559; margin-top:1rem; margin-bottom:1rem; margin-left:1rem; width:20%;' />
          <div class='is-flex is-flex-direction-row chart-tooltip-container-content' style='gap:15px; margin-left:1rem;'>
            ${sexStats}
            <div class='is-flex is-flex-direction-column is-justify-content-flex-start chart-tooltip-container-content-right'>
              <p class='large-body has-text-primary chart-tooltip-container-content-right-header' style='padding-bottom:.5rem;'>Compare</p>
              <ul class='chart-tooltip-container-content-right-categories ml-4'>
                ${subCategoryCounts}
              </ul>
            </div>
          </div>
        </div>`;
      } else {
        tooltipHtml = `<div class='is-flex is-flex-direction-column is-align-content-start chart-tooltip-container' style='padding-bottom:1rem; width:15rem; background:white; box-shadow:0px 4px 4px rgba(0, 0, 0, 0.25);'>
          <p class='bold-body has-text-primary chart-tooltip-container-header' style='padding-left:1rem; padding-top:1rem; padding-bottom:1rem;'>${category.label}</p>
          <p class='small-body has-text-primary chart-tooltip-container-subheader' style='font-weight:700; padding-left:1rem;'>${tooltipSubtitle}</p>
          <hr class="chart-tooltip-container-line" style='height:1px; background-color:#133559; margin-top:1rem; margin-bottom:1rem; margin-left:1rem; width:20%;' />
          <div class='is-flex is-flex-direction-row chart-tooltip-container-content' style='gap:15px; margin-left:1rem;'>
            ${sexStats}
          </div>
        </div>`;
      }

      let tooltip: Tooltip = {
        label: category.label,
        html: tooltipHtml
      }

      tooltips.push(tooltip);
    });

    return tooltips;
  }

  getTooltipData(): any {
    let races: string[] = ['American Indian or Alaska Native', 'Asian', 'Black or African American',
      'Hispanic or Latino of any race', 'Native Hawaiian or Other Pacific Islander', 'Two or more races', 'White'];
    let femaleKeys: string[] = [];
    let maleKeys: string[] = [];
    let totals: string[] = [];
    let total: number;
    let tooltipData: TooltipData[] = [];
    let enrollmentData = this.data.enrollmentData;
    let localTransformedData: any = this.transformDataForGeneralChart(this.data, ChartTypesDto.BAR_CHART);
    let columns: string[] = localTransformedData['columns'].slice(1);
    let entityType: string = this.data.entityType;

    if (entityType === EntityType.SCHOOL || entityType === EntityType.DISTRICT) {
      femaleKeys = ['f_AME', 'f_ASI', 'f_BLA', 'f_HIS', 'f_HI_PAC', 'f_MORE', 'f_WHI'];
      maleKeys = ['m_AME', 'm_ASI', 'm_BLA', 'm_HIS', 'm_HI_PAC', 'm_MORE', 'm_WHI'];
      totals = ['b_AME', 'b_ASI', 'b_BLA', 'b_HIS', 'b_HI_PAC', 'b_MORE', 'b_WHI']
      total = enrollmentData.b_TOT;
    } else {
      femaleKeys = ['f_AmeCount', 'f_AsiCount', 'f_BlaCount', 'f_HisCount', 'f_HiPacCount', 'f_MoreCount', 'f_WhiCount'];
      maleKeys = ['m_AmeCount', 'm_AsiCount', 'm_BlaCount', 'm_HisCount', 'm_HiPacCount', 'm_MoreCount', 'm_WhiCount'];
      totals = ['b_AmeCount', 'b_AsiCount', 'b_BlaCount', 'b_HisCount', 'b_HiPacCount', 'b_MoreCount', 'b_WhiCount'];
      total = enrollmentData.b_Count;
    }

    if (total > 0) {
      races.forEach((race, i) => {
        let raceData: GroupValueDto = localTransformedData.find(datum => datum.category === race);
        let percentages: any[] = columns.map(column => {
          return { column, value: raceData[column] };
        });
        let tooltipDatum: TooltipData = {
          label: race,
          male: this.profileService.getFormattedCount(enrollmentData[maleKeys[i]]),
          female: this.profileService.getFormattedCount(enrollmentData[femaleKeys[i]]),
          total: this.profileService.getFormattedCount(enrollmentData[totals[i]]),
          studentPercent: percentages
        };

        tooltipData.push(tooltipDatum);
      });
    }

    return this.getTooltipHTML(tooltipData);
  }

  /**
   * This method transforms the data into a form readable by the bubble chart component
   * @param data the inputted data passed to this component
   * @returns transformed data readable by the bubble chart component
   */
  transformDataForBubbleChart(data: ChartDto) {
    let transformedData = data.categories.map((category) => {
      return {
        category: category.groupName,
        ...this.getGroupValuesForBubbleChart(category.values),
      }
    });

    transformedData['columns'] = Object.keys(transformedData[0]);

    return transformedData;
  }

  /**
   * This method gets the values for each category type for the bubble chart
   * @param categoryValues the list of categories
   * @returns a list of category values
   */
  getGroupValuesForBubbleChart(categoryValues) {
    let categories = [];

    categories["value"] = categoryValues[0].value > 0 ? categoryValues[0].value : 0;

    return categories;
  }

  /**
   * This method transforms the data for the stacked bubble chart component
   * @param data the inputted data passed to this component
   * @returns transformed data for the stacked bubble chart
   */
  transformDataForStackedBubbleChart(data: ChartDto): StackedBubbleChartDto {
    let total = this.generateChartTotal(data);
    let children: StackedBubbleChartChildDto[] = data.categories.map(datum => {
      return this.generateStackedBubbleChartChild(datum, total);
    });

    return {
      name: "root",
      children: children
    };
  }

  /**
   * This method generates a stacked bubble chart child from the given category data
   * @param datum an individual category from the inputted data
   * @param chartTotal the total of all values for the chart
   * @returns a stacked bubble chart child
   */
  generateStackedBubbleChartChild(datum: CategoryDetailDto, chartTotal: number): StackedBubbleChartChildDto {
    let name: string = datum.groupName;
    let fill: boolean = true;
    let outline: boolean = true;
    let categories: StackedBubbleChartCategoryDto[] = [];

    let values: GroupValueDto[] = datum.values;
    let maxIteration: number = 0;
    let max: number = values[0].value;
    let categoryTotal: number = 0;

    // Generates the iteration of the max, the max, and the total of all the categories
    for (let i = 1; i < 2; i++) {
      let value: number = values[i].value;

      if (value > max) {
        max = value;
        maxIteration = i;
        categoryTotal += value;
      }
    }

    if (maxIteration != 0) {
      fill = false;
    }

    for (let i = 0; i < 2; i++) {
      categories.push(this.generateStackedBubbleChartCategory(values[i], chartTotal, i, fill))
    }

    return {
      name: name,
      fill: fill,
      outline: outline,
      children: categories
    }

  }

  /**
   * This method generates a stacked bubble chart category
   * @param datum an individual group from the inputted data
   * @param chartTotal the total of all the values in the chart
   * @param iteration the current column we are looking at
   * @param fill an indication of whether to color the item or not
   * @returns a stacked bubble chart category
   */
  generateStackedBubbleChartCategory(datum: GroupValueDto, chartTotal: number, iteration: number, fill: boolean): StackedBubbleChartCategoryDto {
    let name: string = "scale" + (iteration + 1);
    let categoryFill: boolean = false;
    let outline: boolean = false;

    let scale = d3.scaleLinear().domain([0, chartTotal]).range([0, 1000]);
    let size = scale(datum.value);

    if (iteration === 0) {
      outline = true;
    }

    if (iteration === 0 && !fill) {
      categoryFill = true;
    }

    return {
      name: name,
      fill: categoryFill,
      outline: outline,
      size: size
    }
  }

  /**
   * This method generates the total of all the values in the chart data
   * @param datum the chart data
   * @returns a total of all the values in the chart data
   */
  generateChartTotal(datum: ChartDto): number {
    let total: number = 0;

    datum.categories.forEach((category) => {
      for (let i = 0; i < 2; i++) {
        total += category.values[i].value;
      }
    });

    return total;
  }

  /**
   * This method transforms the data for the national chart
   * @param data the inputted data to this component
   * @returns data usable by the national chart
   */
  transformDataForNationalChart(data: ChartDto) {
    return data;
  }

  /**
   * This method transforms the data for the stacked bar chart component
   * @param data the inputted data to this component
   * @returns usable data for the stacked bar chart
   */
  transformDataForStackedBarChart(data: ChartDto) {
    let transformedData = data.categories.map((category) => {
      return {
        category: category.groupName,
        ...this.getGroupValuesForStackedBarChart(category),
      }
    });

    transformedData['columns'] = Object.keys(transformedData[0]);
    return transformedData;
  }

  /**
   * This method gets all the values for each categor in the data
   * @param category an individual category from the data
   * @returns a list of category values
   */
  getGroupValuesForStackedBarChart(category: CategoryDetailDto) {
    let categories = [];

    category.values.forEach((value) => {
      categories[value.label] = value.value / this.getGroupTotalForStackedBarChart(category);
    })

    return categories;
  }

  /**
   * This method gets the total of all values for a category
   * @param category an individual category from the data
   * @returns a total of all values for the category
   */
  getGroupTotalForStackedBarChart(category: CategoryDetailDto): number {
    let total: number = 0;

    category.values.forEach((value: GroupValueDto) => {
      total += value.value
    });

    return total;
  }
}
