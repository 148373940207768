import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Components
import { UrlGuard } from '../../core/auth/guards/url-guard';
import { AboutMainComponent } from './about-main/about-main.component';
import { AboutFaqComponent } from './about-faq/about-faq.component';
import { AboutOcrComponent } from './about-ocr/about-ocr.component';
import { WildcardInterceptorComponent } from 'src/app/shared/components/wildcard-interceptor/wildcard-interceptor.component';

// Feature


const featureRoutes: Routes = [
    {
        path: '',
        pathMatch: "full",
        redirectTo: "crdc"
    },
    {
        path: 'crdc',
        component: AboutMainComponent,
        pathMatch: 'full',
        canActivate: [UrlGuard],
        title: 'About CRDC | Civil Rights Data',
        data: {
            title: 'About CRDC | Civil Rights Data',
            description: 'Learn about the mission and history of the Civil Rights Data Collection.',
            ogImage: 'assets/images/about-ocr-image.png'
        }
    },
    {
        path: 'faqs',
        component: AboutFaqComponent,
        pathMatch: 'full',
        canActivate: [UrlGuard],
        title: 'Frequently Asked Questions | Civil Rights Data',
        data: {
            title: 'Frequently Asked Questions | Civil Rights Data',
            description: 'Answer your questions about the CRDC and the civil rights data featured on this site.',
            ogImage: 'assets/images/about-ocr-image.png'
        }
    },
    {
        path: 'ocr',
        component: AboutOcrComponent,
        pathMatch: 'full',
        canActivate: [UrlGuard],
        title: 'About OCR | Civil Rights Data',
        data: {
            title: 'About OCR | Civil Rights Data',
            description: 'Learn about the key areas of focus for the Department of Education’s Office for Civil Rights.',
            ogImage: 'assets/images/about-ocr-image.png'
        }
    },
    { path: '**',  component: WildcardInterceptorComponent }

];


@NgModule({
    imports: [RouterModule.forChild(featureRoutes)],
    exports: [RouterModule]
})
export class AboutRoutingModule { }