import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-stacked-bubble-chart',
  templateUrl: './stacked-bubble-chart.component.html',
  styleUrls: ['./stacked-bubble-chart.component.scss']
})

/**
 * This class produces a stacked bubble chart with the input data
 */
export class StackedBubbleChartComponent implements OnInit, AfterViewInit {

  @Input() data;
  @Input() sectionName: string;

  private svg;
  private margin = { top: 0, right: 0, bottom: 0, left: 0 };
  private width = 557;
  private height = 498;

  constructor() { }

  /**
   * This function creates the svg and renders the stacked bubble chart
   */
  ngOnInit(): void {
    this.sectionName = this.sectionName.replace(/\s+/g, '-');

  }

  ngAfterViewInit(): void {
    this.createSvg();
    this.drawStackedBubbleChart();
  }

  /**
   * Create svg creates the svg in which the bubble chart will be set
   */
  private createSvg(): void {
    this.svg = d3.select(`figure#${this.sectionName}`).append("svg")
      .attr("width", this.width).attr("height", this.height).attr("class", "bubble")
      .append("g").attr("transform", "translate(" + this.margin.left + "," + this.margin.top + ")");
  }

  /**
   * This function fully renders the stacked bubble chart
   */
  private drawStackedBubbleChart(): void {
    // The available colors
    var circleColorRange = ["rgba(177, 0, 93, 0.3)", "rgba(202, 170, 82, 0.3)", "rgba(19, 53, 89, 0.3)", "rgba(96, 179, 156, 0.3)",
      "rgba(79, 89, 121, 0.3)", "rgba(119, 186, 43, 0.3)", "rgba(0, 100, 168, 0.3)"];
    var circleOutlineRange = ["rgba(177, 0, 93, 1)", "rgba(202, 170, 82, 1)", "rgba(19, 53, 89, 1)", "rgba(96, 179, 156, 1)",
      "rgba(79, 89, 121, 1)", "rgba(119, 186, 43,1)", "rgba(0, 100, 168, 1)"];

    var color = d3.scaleOrdinal().domain(this.getColorDomain()).range(circleColorRange);
    var outlineColor = d3.scaleOrdinal().domain(this.getColorDomain()).range(circleOutlineRange);

    // Creates a packing structure
    var pack = d3.pack()
      .size([this.width, this.height])
      .padding(10);

    // Setting up all of the nodes that will be rendered
    var root = d3.hierarchy(this.data).sum(function (d: any) { return d.size; }).sort(function (a, b) { return b.value - a.value; });
    var nodes = pack(root).descendants();

    // Setting up the circle rendering
    var circle = this.svg.selectAll("circle")
      .data(nodes)
      .enter();

    // Adds the circles to the svg that will then be rendered with it's associated color and outline
    circle.append("circle")
      .attr("class", "circle")
      .attr("r", function (d) { return getRadius(d); })
      .attr("cx", function (d) {
        var width = 557;
        var height = 498;

        return getPos(d, width, height)[0];
      })
      .attr("cy", function (d) {
        var width = 557;
        var height = 498;

        return getPos(d, width, height)[1];
      })
      .style("fill", function (d) { return colorCircle(d)[0]; })
      .attr("stroke", function (d) { return colorCircle(d)[1]; })
      .attr("stroke-width", function (d) {
        var strokeColor = colorCircle(d)[1];

        if (strokeColor == "#133559") {
          return 1;
        } else {
          return 2;
        }
      });

    // Adds text to all the necessary circles at their associated positions
    circle.append("text")
      .attr("x", function (d) {
        var width = 557;
        var height = 498;

        return getPos(d, width, height)[0];
      })
      .attr("y", function (d) {
        var width = 557;
        var height = 498;

        return getPos(d, width, height)[1];
      })
      .attr("text-anchor", "middle")
      .text(function (d) { return getName(d); })
      .style("fill", "#133559")
      .style("font-family", "Helvetica Neue, Helvetica, Arial, san-serif")
      .style("font-size", "12px");


    /**
     * This function gives a color for the given circle and outline for the circle.
     * It must stay in the method due to a scope issue when using this function inside the method.
     * @param d common d3 term to refer to data
     * @returns an array with the coloring of both the cirlce and the outline
     */
    function colorCircle(d) {
      var transparentWhite = "rgba(255, 255, 255, 0)";
      var solidOutline = "#133559";
      var name = d.data.name;
      var circleColor;
      var outline;

      if (d.data.fill && d.data.outline) {
        if (name == "scale1") {
          var parentName = d.parent.data.name;
          circleColor = color(parentName);
          outline = outlineColor(parentName);
        } else {
          circleColor = color(name);
          outline = outlineColor(name);
        }
      } else if (d.data.outline) {
        circleColor = transparentWhite;
        outline = solidOutline;
      } else {
        circleColor = transparentWhite;
        outline = transparentWhite;
      }

      return [circleColor, outline]
    }

    /**
     * This function gets the name of the node. It must stay in the method due to a scope issue
     * when using this function inside the method.
     * @param d common d3 term to refer to data
     * @returns the name given the data
     */
    function getName(d) {
      var name = "";
      var id = d.data.name;

      if (d.data.fill) {
        if (id == "scale1") {
          name = d.parent.data.name;
        } else {
          name = id;
        }
      }

      return name;
    }

    /**
     * This function gets the radius for the given node, and properly scales the inner
     * circle based on the ratio of scale1 to the overall size of the node.
     * @param d the given data for the node
     * @returns a size for the radius
     */
    function getRadius(d) {
      var radius = d.r;
      var name = d.data.name;

      if (name == "scale1") {
        var scale1 = d.data.size
        var scale2 = d.parent.children[1].data.size;
        var ratio = scale1 / (scale1 + scale2);
        var parentRadius = d.parent.r;

        radius = parentRadius * ratio;
      }

      return radius;
    }

    /**
     * This function gets the location for a given circle, and rotates the inner circle
     * @param d the given node
     * @param width svg width
     * @param height svg height
     * @returns a pos array with [x,y]
     */
    function getPos(d, width, height): Array<number> {
      var xPos = d.x;
      var yPos = d.y;
      var name = d.data.name;

      if (name == "scale1") {
        var radius = getRadius(d);
        var parentRadius = d.parent.r;
        var radiusDelta = parentRadius - radius;

        var parentXPos = d.parent.x;
        var parentYPos = d.parent.y;
        var centerXPos = width / 2;
        var centerYPos = height / 2;

        var xDelta = centerXPos - parentXPos;
        var yDelta = centerYPos - parentYPos;
        var hypotenuse = Math.sqrt(Math.pow(xDelta, 2) + Math.pow(yDelta, 2));
        var angle = Math.asin(yDelta / hypotenuse);

        var translateX = Math.cos(angle) * radiusDelta;
        var translateY = Math.sin(angle) * radiusDelta;

        if (xDelta > 0) {
          xPos = parentXPos + translateX;
        } else {
          xPos = parentXPos - translateX;
        }

        yPos = parentYPos + translateY;
      }

      return [xPos, yPos]
    }
  }

  /**
   * This method gets the domain for the values that will be colored
   * @returns a string array with the domain of values that will be colored
   */
  private getColorDomain(): string[] {
    let domain: string[] = [];

    this.data.children.forEach(child => {
      domain.push(child.name);
    });

    return domain;
  }
}
