<div class="data-quality">
    <app-hero heroHeader="Continuous Improvement of Data Quality" heroSubHeader="Behind the Data"
        heroDescription="From pre-collection to post-collection, OCR uses different strategies to review the CRDC data reported from the nation's school districts."
        heroImageSrc="assets/images/data_quality_hero.png" heroImageAlt="Hand holding a pen writing in a notebook"
        paddingTop="4.5rem" paddingBottom="6rem">
    </app-hero>
    <div class="data-quality-content">
        <div class="data-quality-content-container is-flex is-flex-direction-column is-align-items-center">
            <div class="data-quality-content-container-header is-flex is-flex-direction-column">
                <h2 class="has-text-primary">The CRDC Data Quality Continuous Improvement Process</h2>
                <hr class="data-quality-line-separator">
            </div>
            <div>
                <!-- Image Map Generated by http://www.image-map.net/ -->
                <!-- <img id="data-quality-flow-chart" src="assets/images/data_quality_flow_chart.png"
                    alt="Data quality flow chart." usemap="#data-quality-flow-chart" (load)="updateImageMap()"
                    #flowChart>

                <map name="data-quality-flow-chart">
                    <area *ngFor="let area of imageMap" tabindex="0" aria-labelledby="tooltip-popup" [alt]="area.alt"
                        [coords]="area.coords" shape="circle" [tooltip]="area.tooltip">
                </map> -->

                <img src="assets/images/CRDC_Process.png" alt="The CRDC data process" />
            </div>
            <div class="data-quality-content-container-header is-flex is-flex-direction-column">
                <h2 class="has-text-primary">High Quality Technical Assistance to Data Submitters</h2>
                <hr class="data-quality-line-separator">
            </div>
            <div class="data-quality-content-container-intro is-flex">
                <h2 class="data-quality-content-container-intro-header has-text-primary">Reaching Out</h2>
                <div class="data-quality-content-container-intro-text is-flex is-flex-direction-column">
                    <p class="large-body has-text-black">OCR supports all districts and schools during the data
                        submission and review process with a variety of outreach methods and technical assistance
                        supports.
                    </p>
                    <hr class="data-quality-line-separator">
                    <p class="large-body has-text-black">The 2020-21 collection included:</p>
                </div>
            </div>
            <div class="data-quality-content-container-grid">
                <div
                    class="data-quality-content-container-grid-element is-flex is-flex-direction-column is-align-items-center">
                    <h3 class="data-quality-h1-text has-text-primary">10,000+</h3>
                    <div
                        class="data=quality-content-container-grid-element-description is-flex is-flex-direction-column is-align-items-center">
                        <p class="lead has-text-primary has-text-centered">E-mails Sent</p>
                        <p class="large-body has-text-primary has-text-centered">
                            to data submitters with reminders and tips
                        </p>
                    </div>
                </div>
                <div
                    class="data-quality-content-container-grid-element is-flex is-flex-direction-column is-align-items-center">
                    <h3 class="data-quality-h1-text has-text-primary">50</h3>
                    <div
                        class="data=quality-content-container-grid-element-description is-flex is-flex-direction-column is-align-items-center">
                        <p class="lead has-text-primary has-text-centered">Webinar Trainings Provided</p>
                        <p class="large-body has-text-primary has-text-centered">
                            to school districts and state educational agencies
                        </p>
                    </div>
                </div>
                <div
                    class="data-quality-content-container-grid-element is-flex is-flex-direction-column is-align-items-center">
                    <h3 class="data-quality-h1-text has-text-primary">16,000+</h3>
                    <div
                        class="data=quality-content-container-grid-element-description is-flex is-flex-direction-column is-align-items-center">
                        <p class="lead has-text-primary has-text-centered">Phone Calls Received</p>
                        <p class="large-body has-text-primary has-text-centered">
                            from data submitters
                        </p>
                    </div>
                </div>
                <div
                    class="data-quality-content-container-grid-element is-flex is-flex-direction-column is-align-items-center">
                    <h3 class="data-quality-h1-text has-text-primary">24,000+</h3>
                    <div
                        class="data=quality-content-container-grid-element-description is-flex is-flex-direction-column is-align-items-center">
                        <p class="lead has-text-primary has-text-centered">Responses to Questions Sent</p>
                        <p class="large-body has-text-primary has-text-centered">
                            to data submitters
                        </p>
                    </div>
                </div>
                <div
                    class="data-quality-content-container-grid-element is-flex is-flex-direction-column is-align-items-center">
                    <h3 class="data-quality-h1-text has-text-primary">35</h3>
                    <div
                        class="data=quality-content-container-grid-element-description is-flex is-flex-direction-column is-align-items-center">
                        <p class="lead has-text-primary has-text-centered">Training Videos Accessible</p>
                        <p class="large-body has-text-primary has-text-centered">
                            to help data submitters accurately report data
                        </p>
                    </div>
                </div>
                <div
                    class="data-quality-content-container-grid-element is-flex is-flex-direction-column is-align-items-center">
                    <h3 class="data-quality-h1-text has-text-primary">70+</h3>
                    <div
                        class="data=quality-content-container-grid-element-description is-flex is-flex-direction-column is-align-items-center">
                        <p class="lead has-text-primary has-text-centered">Technical Assistance Documents Available</p>
                        <p class="large-body has-text-primary has-text-centered">
                            specifically supporting data quality
                        </p>
                    </div>
                </div>
            </div>
            <div class="data-quality-content-container-header is-flex is-flex-direction-column">
                <h2 class="has-text-primary">Data Quality Checks and Corrections</h2>
                <hr class="data-quality-line-separator">
            </div>
            <div class="data-quality-content-container-metrics is-flex is-flex-direction-column is-align-items-center">
                <div
                    class="data-quality-content-container-metrics-1 is-flex is-align-items-center is-justify-content-space-between">
                    <div class="data-quality-content-container-metrics-1-img">
                        <img class="data-quality-has-rounded-corners" src="assets/images/metric_1.png"
                            alt="two people deliberating over a document">
                    </div>
                    <div class="data-quality-content-container-metrics-text is-flex is-flex-direction-column">
                        <div>
                            <h3 class="data-quality-h1-text has-text-primary">140+</h3>
                            <p class="data-quality-h4-text has-text-primary">Business Rules</p>
                        </div>
                        <hr class="data-quality-line-separator">
                        <p class="large-body has-text-black">A business rule is an automatic data check that will alert
                            data submitters when potentially erroneous data are reported in the submission system.
                            Business rules are used to improve data quality.</p>
                    </div>
                </div>
                <div class="data-quality-content-container-metrics-2 is-flex is-align-items-center">
                    <div class="data-quality-content-container-metrics-2-img">
                        <img class="data-quality-has-rounded-corners" src="assets/images/metric_2.jpg"
                            alt="many post it notes stuck to a board">
                    </div>
                    <div class="data-quality-content-container-metrics-text is-flex is-flex-direction-column">
                        <div>
                            <h3 class="data-quality-h1-text has-text-primary">179</h3>
                            <p class="data-quality-h4-text has-text-primary">Data Quality Checks</p>
                        </div>
                        <hr class="data-quality-line-separator">
                        <p class="large-body has-text-black">OCR reviews the data for quality during and after the
                            collection.</p>
                    </div>
                    <div class="data-quality-content-container-metrics-2-background"></div>
                </div>
                <div
                    class="data-quality-content-container-metrics-3 is-flex is-align-items-center is-justify-content-space-between">
                    <div class="data-quality-content-container-metrics-3-img">
                        <img class="data-quality-has-rounded-corners" src="assets/images/metric_3.png"
                            alt="map of us states, district of columbia, and Puerto Rico representing locations of school districts">
                    </div>
                    <div class="data-quality-content-container-metrics-text is-flex is-flex-direction-column">
                        <div>
                            <h3 class="data-quality-h1-text has-text-primary">15,582</h3>
                            <p class="data-quality-h4-text has-text-primary">Districts Contacted</p>
                        </div>
                        <hr class="data-quality-line-separator">
                        <p class="large-body has-text-black">OCR reaches out reaches out to districts through calls,
                            emails, and formal mail to help them address data quality.</p>
                    </div>
                </div>
                <div class="data-quality-content-container-metrics-4 is-flex is-align-items-center">
                    <div class="data-quality-content-container-metrics-4-img">
                        <img class="data-quality-has-rounded-corners" src="assets/images/metric_4.png"
                            alt="person holding a pen checking graphs of data">
                    </div>
                    <div class="data-quality-content-container-metrics-text is-flex is-flex-direction-column">
                        <div>
                            <h3 class="data-quality-h1-text has-text-primary">5,728</h3>
                            <p class="data-quality-h4-text has-text-primary">Corrections</p>
                        </div>
                        <hr class="data-quality-line-separator">
                        <p class="large-body has-text-black">Districts made post-collection corrections to the CRDC data
                            as a result of OCR's data quality reviews.</p>
                    </div>
                    <div class="data-quality-content-container-metrics-4-background"></div>
                </div>
            </div>
            <div class="data-quality-content-container-header-2 is-flex is-flex-direction-column">
                <h2 class="has-text-primary">Publications</h2>
                <hr class="data-quality-line-separator">
            </div>
            <div class="data-quality-content-container-publications is-flex is-justify-content-center">
                <div
                    class="data-quality-content-container-publications-article is-flex is-flex-direction-column is-align-items-center">
                    <img class="data-quality-has-rounded-corners" src="assets/images/publication_1.png" tabindex="-1"
                        aria-hidden="true" alt="Person pulling a book off of a book shelf">
                    <a class="large-body has-text-primary has-text-centered" href="" target="_blank" rel="noreferrer noopener">An Overview
                        of the 2020-21 Civil Rights Data Collection Snapshot</a>
                </div>
                <div
                    class="data-quality-content-container-publications-article is-flex is-flex-direction-column is-align-items-center">
                    <img class="data-quality-has-rounded-corners" src="assets/images/publication_2.png" tabindex="-1"
                        aria-hidden="true" alt="stacks of binders">
                    <a class="large-body has-text-primary has-text-centered" href="" target="_blank" rel="noreferrer noopener">Ensuring
                        Civil Rights Data Quality Snapshot</a>
                </div>
            </div>
            <div class="data-quality-content-container-question is-flex is-flex-direction-column">
                <h2 class="has-text-primary">How Can I Submit a CRDC Data Quality Concern with OCR?</h2>
                <hr class="data-quality-line-separator">
                <p class="large-body has-text-black">
                    The CRDC data can be accessed at <a href="https://civilrightsdata.ed.gov"
                        target="_blank" rel="noreferrer noopener">civilrightsdata.ed.gov</a>. To submit a CRDC data
                    quality concern with OCR, please contact us by emailing <a
                        href="mailto:civilrightsdata&#64;ed.gov">civilrightsdata&#64;ed.gov</a>. Please include “CRDC Data
                    Quality Concern” in the subject heading.
                </p>
            </div>
            <div class="data-quality-content-container-end is-flex is-align-items-center">
                <h3 class="data-quality-content-container-end-header has-text-white">Data on Equal Access to Education
                </h3>
                <div class="data-quality-content-container-end-text is-flex is-flex-direction-column">
                    <p class="large-body has-text-white">View state, district, and school profiles</p>
                    <button class="button is-rounded primary-button is-regular-button" routerLink="/view">Explore the
                        Data</button>
                </div>
                <div class="data-quality-content-container-end-background"></div>
            </div>
        </div>
    </div>
</div>
