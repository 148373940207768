<div class="data-tool-login-landing-container">
    <h2 class="is-size-4 has-text-centered has-text-weight-bold has-text-primary">Terms of Service</h2>
    <div class="content has-text-primary">
        <p> You are accessing a U.S. Federal Government computer system intended to be solely accessed by individual
            users expressly authorized to access the system by the U.S. Department of Education. Usage may be monitored,
            recorded, and/or subject to audit. For security purposes and in order to ensure that the system remains
            available to all expressly authorized users, the U.S. Department of Education monitors the system to
            identify unauthorized users. Anyone using this system expressly consents to such monitoring and recording.
            Unauthorized use of this information system is prohibited and subject to criminal and civil penalties.
            Except as expressly authorized by the U.S. Department of Education, unauthorized attempts to access, obtain,
            upload, modify, change, and/or delete information on this system are strictly prohibited and are subject to
            criminal prosecution under 18.U.S.C. 1030, and other applicable statutes, which may result in fines and
            imprisonment. For purposes of this system, unauthorized access includes, but is not limited to: </p>
        <ul>
            <li> Any access by an employee or agent of a commercial entity, or other third
                party, who is not the individual user, for purposes of commercial advantage or private financial gain
                (regardless of whether the commercial entity or third party is providing a service to an authorized user
                of the system); and
            </li>
            <li> Any access in furtherance of any criminal or tortious act in violation of
                the Constitution of laws of the United States or any State.
            </li>
        </ul>
        <p> If system monitoring reveals information indicating possible criminal activity,
            such evidence may be provided to law enforcement personnel. </p>
    </div>
    <div class="has-text-centered">
        <div name="terms-agree" label="I agree" arialabel="I agree">
            <div style="display: block">
                <input type="checkbox" id="terms-agree"
                    style="height: 20px;width: 20px;vertical-align: middle; position: relative; bottom: 3px;"
                    [(ngModel)]="selectToAgree" (change)="selectedIAgree()" name="terms-agree" aria-checked="false">
                <label class="large-body has-text-primary" style="margin-left: 5px;" for="terms-agree">I Agree</label>
            </div>
        </div>
    </div>

    <hr class="mx-4">
    <div class="has-text-centered pt-3 pb-6">
        <button type="button" class="button is-rounded is-outlined outline-button is-regular-button"
            style="background-color: lightgreen; text-decoration: none;" (click)="requestAuthorization()"
            [disabled]="!selectToAgree"> SIGN IN </button>
    </div>
</div>


